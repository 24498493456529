<!-- content starts-->
<div class="content-wrapper theme-font-size">
    <div class="row1 p-r-0">
        <div class=" h-25 p-r-0">
          <div class="default-according" id="accordion">
            <div class="card shadow mb-3">
              <div class="tab-content card-body border-0 p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
                <div class="tab-pane fade show active" id="app-info" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                  <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                    <div class="row p-l-20">
                      <div class="row  col-xl-12 p-l-0">
                       <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 p-r-0">
                         Notes:
                        <hr class="mt-1">
                       </span>
                     </div>
                      <div class="col-xl-12 px-0">
                        <div class="col-xl-5 px-0 mt-2">
                            <div class="row">
                              <div class="col-xl-11 col-11 mt-2 ">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font">
                                  Details:
                                </label>
                                <textarea class="form-control b-r-5 mt-1" placeholder=""
                                name="notesDetail" [(ngModel)]="applicantService.notesModel.notesDetail"
                                  style="height: 100px" [disabled]="notEditable">
                                </textarea>
                              </div>
                            </div>
                       
                        </div>
                        <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit" [disabled]="isSaving">
                          <i class="fa mr-1" [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                          {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- Family information -->
              </div>
              <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewApplicantInformation">
                <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewApplicantInformation"
                  hasBorder="true" hasFullHeight="true"></app-user-access-permission>
              </div>
            </div>
            
          </div>
        </div>
      </div>
</div>
<!-- content Ends-->
