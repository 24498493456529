import { Subscription } from "rxjs";

export class EditDocuments {
}

export enum FolderType {
    Program = 1,
    Legal = 2
}

export interface UploadDocumentRequestModel {
    applicantId: number;
    documentTypesId: number;
    file: File;
    //fileName: string;
    folderTypeId: number;
}

export class DocumentModel {
    applicantId: number;
    documentTypesId: number;    
    folderTypeId: number;
    file: File | null;
    fileMimeType: string;
    fileName: string | null;
    fileExt: string;
    uploadProgressValue: number;
    isFileSaved: boolean;
    isSaving: boolean;
    uploadSubscription: Subscription;
    src: string;
}

export class UpdateDocumentModalDataModel {
    id: number;
    applicantId: number;
    documentTypesId: number;    
    folderTypeId: number;
    file: File | null;
    fileMimeType: string;
    fileName: string | null;
    fileExt: string;
    uploadProgressValue: number;
    isSaving: boolean;
    src: string;
}

export class UpdateDocumentRequestModel {
    documentId: number;
    applicantId: number;
    documentTypesId: number;
    documentFolderTypeId: number;
}
