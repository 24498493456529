import { PaginationBaseModel } from "@models/pagination.model";

export class GetAllDocumentsQuery extends PaginationBaseModel  {
    ApplicantId: number;
    DocumentFolderId: number;
}



export enum FolderType {
    program = 1,
    legal = 2
}

export class DocumentVM {
    id: number;
    applicantId: number;
    documentTypesId: string;
    documentTypesName: string;
    documentFolderId: number;
    documentFolderName: string;
    fileName: string;
    applicationNumber: string;
    fileId: string;
    size: number;
    version: number;
    mimeType: string;
    webContentLink: string;
    webViewLink: string;
    documentCreatedTime: string;
}