import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import {
  getAllActivityRequestModel,
  Task,
} from 'src/app/components/applicants/applicant.model';
import {
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbModalConfig,
  NgbModal,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';
import { DashboardService } from '../../components/dashboard/dashboard.service';
import { ChartOptions } from '.././../shared/chart/apex';
import {
  CalenderDataPost,
  CallScheduleDataPost,
  DashboardPost,
  DashboardResponse,
  ListCalendarData,
  ListCallSchedule,
  ListCountSponsor,
  ListCountStage,
  ListCountYearWise,
  TimePeriodVM,
} from '../../components/dashboard/dashboard.model';
import * as chartData from '.././../shared/chart/apex';
import { StorageService } from '@services/storage.service';
import { User } from '../adminConsole/users-management/user-list/user-list.model';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { UserPermissionsService } from '../adminConsole/users-management/user-permissions/user-permissions.service';
import { HttpStatusCode } from '@angular/common/http';

const I18N_VALUES = {
  'en-US': {
    weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: [
      'January, ',
      'February, ',
      'March, ',
      'April, ',
      'May, ',
      'June, ',
      'July, ',
      'August, ',
      'September, ',
      'October, ',
      'November, ',
      'December,',
    ],
  },
  // other languages you would support
};
@Injectable()
export class I18n {
  language = 'en-US';
}
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
  ngxdtConfig = new NgxDatatableSSRConfig();
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    NgbModalConfig,
    NgbModal,
  ],
})
export class DashboardComponent implements OnInit {
  navigation = 'arrows';
  public donut = chartData.donut;
  public bar = chartData.bar;
  public column = chartData.column;

  public selected = [];
  getAllActivityRequestModel = new getAllActivityRequestModel();
  ngxdtConfigTodays = new NgxDatatableSSRConfig();
  ngxdtConfigUpcoming = new NgxDatatableSSRConfig();
  ngxdtConfigInCompleted = new NgxDatatableSSRConfig();
  ngxdtConfigCompleted = new NgxDatatableSSRConfig();
  public task = Task;
  yearList: TimePeriodVM[];
  dashboardPost: DashboardPost;
  dashboardResponse: DashboardResponse;
  callListData: ListCallSchedule[];
  calendarListData: ListCalendarData[];

  calenderDataPost: CalenderDataPost;
  callScheduleDataPost: CallScheduleDataPost;

  userDetails: User;
  viewDashboardWidgets: boolean = false;
  _currentDate = new Date();

  currentYear: string;

  constructor(
    public dashboardService: DashboardService,
    public headerTitleService: HeaderTitleService,
    public router: Router,
    private config1: NgbModalConfig,
    private toaster: ToastrService,
    public storageService: StorageService,
    public userPermissionsService: UserPermissionsService
  ) {
    this.config1.centered = true;
  }

  ngOnInit(): void {
    this.currentYear = this._currentDate.getFullYear().toString();

    this.headerTitleService.title = 'Dashboard';
    this.userDetails = this.storageService.retrieve('user');
    this.getAllActivityRequestModel.userId =
      this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId;

    this.viewDashboardWidgets =
      this.headerTitleService.PermissionVM.dashboardWidgets;
    if (this.viewDashboardWidgets) {
      this.getYearsForDashboardTimePeriod();
      this.getDashboardData();
    }
    this.onDateSelection({
      year: this._currentDate.getFullYear(),
      month: this._currentDate.getMonth() + 1,
      day: this._currentDate.getDate(),
    });

    if (this.headerTitleService.PermissionVM.menuDashboard) 
     this.resetAll();
    
  }

  getYearsForDashboardTimePeriod() {
    this.dashboardService
      .getYearsForDashboardTimePeriod()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.yearList = res.response.yearsList;
        let years = this.yearList.filter(
          (a) => a.timePeriodYear == this.currentYear
        )[0];
        this.currentYear = years?.timePeriodYear;
      });
  }

  onChange(event) {
    this.currentYear = event;
    this.getDashboardData();
  }

  GetPermissionAccessByUser() {
    this.userPermissionsService
      .getPermissionAccessByUser()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if (res.isSuccess === true) {
          this.headerTitleService.PermissionVM = res.response;
          this.storageService.store('permissionVM', res?.response);
          this.viewDashboardWidgets =
            this.headerTitleService.PermissionVM.menuAdminConsole;
          if (this.viewDashboardWidgets) {
            this.getDashboardData();
          }
        }
      });
  }

  ngbDateToString(selectedDate) {
    var dd = String(selectedDate.getDate()).padStart(2, '0');
    var mm = String(selectedDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = selectedDate.getFullYear();
    var convertedDate = yyyy + '-' + mm + '-' + dd;
    return convertedDate;
  }

  currentYearDate(valueType) {
    var today = new Date();
    // var yyyy = today.getFullYear();
    let convertedDate: string;
    if (valueType === 1) {
      convertedDate = this.currentYear + '-01-01';
    } else if (valueType === 2) {
      convertedDate = this.currentYear + '-12-31';
    }
    return convertedDate;
  }

  selectedMonth(isFromDate, dateValue) {
    var convertedDate;

    if (isFromDate === 1) {
      convertedDate = new Date(dateValue.year, dateValue.month - 1, 1);
    } else if (isFromDate === 2) {
      convertedDate = new Date(dateValue.year, dateValue.month, 0);
    }

    return (
      convertedDate.getFullYear() +
      '-' +
      String(convertedDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(convertedDate.getDate()).padStart(2, '0')
    );
  }

  getDashboardData() {
    this.dashboardPost = new DashboardPost();
    this.dashboardPost.startDate = this.currentYearDate(1);
    this.dashboardPost.endDate = this.currentYearDate(2);
    this.dashboardPost.currentDate = this.ngbDateToString(new Date());
    this.dashboardPost.userId =
      this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId;

    this.dashboardService
      .getAllDashboardData(this.dashboardPost)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.dashboardResponse = res.response;
        // this.callListData = this.dashboardResponse.listCallSchedule;
        // this.calendarListData = this.dashboardResponse.listCalendarData;
        this.fillColumnChart(this.dashboardResponse.listCountYearWise);
        this.fillDonutChart(this.dashboardResponse.listCountSponsors);
        this.fillBarChart(this.dashboardResponse.listCountStage);
      });
  }

  fillColumnChart(listCountYearWise: ListCountYearWise[]) {
    let column: ChartOptions = {
      series: [
        {
          name: 'Applicants',
          data: listCountYearWise.map((x) => x.countApplicant),
        },
      ],
      colors: ['#0E4AB3FF'],
      chart: {
        type: 'bar',
        height: 300,
        fontFamily: 'Roboto',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent'],
      },
      xaxis: {
        categories: listCountYearWise.map((x) => x.months),
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            if (value == Infinity) value = 1;
            return value.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
    };

    this.column = column;
  }

  fillDonutChart(listCountSponsors: ListCountSponsor[]) {
    let donut: ChartOptions = {
      pieseries: listCountSponsors.map((x) => x.applicantCount),
      chart: {
        type: 'donut',
        height: '250px',
        fontFamily: 'Roboto',
      },
      colors: ['#C600CCFF', '#00CC66FF', '#FF7D00FF', '#00CCCBFF', '#F88989FF'],
      labels: listCountSponsors.map((x) => x.sponsorsName),
      legend: {
        show: false,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
              // height: 100,
            },
            legend: {
              position: 'top',
            },
          },
        },
      ],
    };

    this.donut = donut;
  }

  fillBarChart(listCountStage: ListCountStage[]) {
    let bar: ChartOptions = {
      series: [
        {
          name: 'Applicants',
          data: listCountStage.map((x) => x.applicantCount),
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        fontFamily: 'Roboto',
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          borderRadius: 7,
        },
      },
      colors: [
        '#FF7D00FF',
        '#D10000FF',
        '#C600CCFF',
        '#6300CCFF',
        '#00CC66FF',
        '#00CCCBFF',
      ],
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: listCountStage.map((x) => x.stageName),
      },
      yaxis: {
        max: function (max) {
          return max;
        },
      },
    };
    this.bar = bar;
  }

  //Todays Activity Start
  loadListTodaysActivityData() {
    const getAllActivityRequestModel: getAllActivityRequestModel = {
      applicantId: 0,
      id: 0,
      taskId: this.task.todays,
      userId:
        this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId,
      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: this.ngxdtConfigTodays.currentPageNumber,
      pageSize: this.ngxdtConfigTodays.pageSize,
      searchText: '',
      getAll: false,
    };

    // this.getAllActivityRequestModel.taskId = this.task.todays;
    // this.getAllActivityRequestModel.applicantId = 0;
    // this.getAllActivityRequestModel.sortColumn = '';
    // this.getAllActivityRequestModel.sortDirection = 'DESC';
    // this.getAllActivityRequestModel.getAll = false;
    // this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigTodays.currentPageNumber;
    // this.getAllActivityRequestModel.pageSize = this.ngxdtConfigTodays.pageSize;

    this.dashboardService
      .getAllActivity(getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.ngxdtConfigTodays.updateTableData(res);
      });
  }
  //Todays Activity Start

  //Incomplate Activity Start
  loadListIncomplateActivityData() {
    const getAllActivityRequestModel: getAllActivityRequestModel = {
      applicantId: 0,
      id: 0,
      taskId: this.task.inCompleted,
      userId:
        this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId,
      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: this.ngxdtConfigInCompleted.currentPageNumber,
      pageSize: this.ngxdtConfigInCompleted.pageSize,
      searchText: '',
      getAll: false,
    };

    // this.getAllActivityRequestModel.taskId = this.task.inCompleted;
    // this.getAllActivityRequestModel.applicantId = 0;
    // this.getAllActivityRequestModel.sortColumn = '';
    // this.getAllActivityRequestModel.sortDirection = 'DESC';
    // this.getAllActivityRequestModel.getAll = false;
    // this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigInCompleted.currentPageNumber;
    // this.getAllActivityRequestModel.pageSize = this.ngxdtConfigInCompleted.pageSize;

    this.dashboardService
      .getAllActivity(getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.ngxdtConfigInCompleted.updateTableData(res);
      });
  }
  //Incomplate Activity Close

  //Upcoming Activity Start
  loadListUpcomingActivityData() {
    const getAllActivityRequestModel: getAllActivityRequestModel = {
      applicantId: 0,
      id: 0,
      taskId: this.task.upcoming,
      userId:
        this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId,
      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: this.ngxdtConfigUpcoming.currentPageNumber,
      pageSize: this.ngxdtConfigUpcoming.pageSize,
      searchText: '',
      getAll: false,
    };
    // this.getAllActivityRequestModel.taskId = this.task.upcoming;
    // this.getAllActivityRequestModel.applicantId = 0;
    // this.getAllActivityRequestModel.sortColumn = '';
    // this.getAllActivityRequestModel.sortDirection = 'DESC';
    // this.getAllActivityRequestModel.getAll = false;
    // this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigUpcoming.currentPageNumber;
    // this.getAllActivityRequestModel.pageSize = this.ngxdtConfigUpcoming.pageSize;

    this.dashboardService
      .getAllActivity(getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.ngxdtConfigUpcoming.updateTableData(res);
      });
  }
  //Upcoming Activity Close

  //Completed Activity Start
  loadListCompleteActivityData() {
    const getAllActivityRequestModel: getAllActivityRequestModel = {
      applicantId: 0,
      id: 0,
      taskId: this.task.completed,
      userId:
        this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId,
      sortColumn: this.ngxdtConfigCompleted.sortColumn,
      sortDirection: this.ngxdtConfigCompleted.sortDirection,
      pageNumber: this.ngxdtConfigCompleted.currentPageNumber,
      pageSize: this.ngxdtConfigCompleted.pageSize,
      searchText: '',
      getAll: false,
    };
    // this.getAllActivityRequestModel.taskId = this.task.upcoming;
    // this.getAllActivityRequestModel.applicantId = 0;
    // this.getAllActivityRequestModel.sortColumn = '';
    // this.getAllActivityRequestModel.sortDirection = 'DESC';
    // this.getAllActivityRequestModel.getAll = false;
    // this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigUpcoming.currentPageNumber;
    // this.getAllActivityRequestModel.pageSize = this.ngxdtConfigUpcoming.pageSize;

    this.dashboardService
      .getAllActivity(getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.ngxdtConfigCompleted.updateTableData(res);
      });
  }
  //Upcoming Activity Close

  resetAll() {
    this.ngxdtConfigUpcoming = new NgxDatatableSSRConfig();
    this.ngxdtConfigUpcoming.currentPageNumber = 1;
    this.ngxdtConfigUpcoming.pageSize = 5;

    this.ngxdtConfigInCompleted = new NgxDatatableSSRConfig();
    this.ngxdtConfigInCompleted.currentPageNumber = 1;
    this.ngxdtConfigInCompleted.pageSize = 5;

    this.ngxdtConfigTodays = new NgxDatatableSSRConfig();
    this.ngxdtConfigTodays.currentPageNumber = 1;
    this.ngxdtConfigTodays.pageSize = 5;

    this.ngxdtConfigCompleted = new NgxDatatableSSRConfig();
    this.ngxdtConfigCompleted.currentPageNumber = 1;
    this.ngxdtConfigCompleted.pageSize = 5;

    //Todays Activity Start
    this.loadListTodaysActivityData();
    //Incomplate Activity Start
    this.loadListIncomplateActivityData();
    //Todays Activity Start
    this.loadListUpcomingActivityData();
    //Completed Activity Start
    this.loadListCompleteActivityData();
  }

  onPageSizeChanged(pageSize: number, taskId: number) {
    switch (taskId) {
      case this.task.inCompleted: {
        //statements;
        this.ngxdtConfigInCompleted.rows = [];
        this.ngxdtConfigInCompleted.onPageSizeChanged(pageSize);
        this.loadListIncomplateActivityData();
        break;
      }
      case this.task.todays: {
        //statements;
        this.ngxdtConfigTodays.rows = [];
        this.ngxdtConfigTodays.onPageSizeChanged(pageSize);
        this.loadListTodaysActivityData();
        break;
      }
      case this.task.upcoming: {
        //statements;
        this.ngxdtConfigUpcoming.rows = [];
        this.ngxdtConfigUpcoming.onPageSizeChanged(pageSize);
        this.loadListUpcomingActivityData();
        break;
      }
      case this.task.completed: {
        //statements;
        this.ngxdtConfigCompleted.rows = [];
        this.ngxdtConfigCompleted.onPageSizeChanged(pageSize);
        this.loadListCompleteActivityData();
        break;
      }
    }
  }

  onPageChanged(pageNum: number, taskId: number) {
    switch (taskId) {
      case this.task.inCompleted: {
        //statements;
        this.ngxdtConfigInCompleted.rows = [];
        this.ngxdtConfigInCompleted.onPageChanged(pageNum);
        this.loadListIncomplateActivityData();
        break;
      }
      case this.task.todays: {
        //statements;
        this.ngxdtConfigTodays.rows = [];
        this.ngxdtConfigTodays.onPageChanged(pageNum);
        this.loadListTodaysActivityData();
        break;
      }
      case this.task.upcoming: {
        //statements;
        this.ngxdtConfigUpcoming.rows = [];
        this.ngxdtConfigUpcoming.onPageChanged(pageNum);
        this.loadListUpcomingActivityData();
        break;
      }
      case this.task.completed: {
        //statements;
        this.ngxdtConfigCompleted.rows = [];
        this.ngxdtConfigCompleted.onPageChanged(pageNum);
        this.loadListCompleteActivityData();
        break;
      }
    }
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  model: NgbDateStruct;
  date: { year: number; month: number };
  todaySelected: true;

  onClick(tabId: number) {
    this.storageService.store('activityLogTID', tabId);
    this.router.navigate(['dashboard/activity-log-tab'], { queryParams: {} });
  }

  isLoadingCalenderEvents: boolean = false;
  onDateSelection(selectedDate: NgbDateStruct) {
    this.isLoadingCalenderEvents = true;
    this.callListData = [];

    if (
      !this.hasActivityLog(
        new NgbDate(selectedDate.year, selectedDate.month, selectedDate.day)
      )
    ) {
      this.isLoadingCalenderEvents = false;
      return;
    }

    this.callScheduleDataPost = new CallScheduleDataPost();
    this.callScheduleDataPost.currentDate = this.ngbDateToString(
      selectedDate
        ? new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day)
        : null
    );
    this.callScheduleDataPost.userId =
      this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId;
    this.dashboardService
      .getCallScheduleDataForDashboard(this.callScheduleDataPost)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.callListData = res?.response?.listCallSchedule;
        this.isLoadingCalenderEvents = false;
      });
  }
  onCalenderDateClick(event) {
    // remove selected from all
    var allDays = document.querySelectorAll('.ngb-dp-day');
    allDays.forEach((s) => s.classList.remove('selected'));

    // mark as selected
    if (
      event.target.classList.contains('currentMonthDay') ||
      event.target.classList.contains('event-day')
    ) {
      event = event.target.parentElement;
      event.classList.add('selected');
    } else {
      event.target.classList.add('selected');
    }
  }

  isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
    date.month !== current.month;
  hasActivityLog = (date: NgbDate) => this.isAvailableDateActivityLog(date); //this.calendar.getWeekday(date) == this.calendarListData.map(a =>a.isAvailable &&  this.toDate(a.dates) === date)) ;

  isAvailableDateActivityLog(date: NgbDate) {
    let strDate = this.ngbDateToString(
      date ? new Date(date.year, date.month - 1, date.day) : null
    );
    var output = this.calendarListData?.filter(
      (a) => a.dates === strDate && a.isAvailable
    );
    return output?.length === 0 ? false : true;
  }

  onNavigateMonth(event) {
    this.calenderDataPost = new CalenderDataPost();
    this.calenderDataPost.startDate = this.selectedMonth(1, event);
    this.calenderDataPost.endDate = this.selectedMonth(2, event);
    this.calenderDataPost.userId =
      this.userDetails?.userRole == 'Admin' ? '' : this.userDetails?.userId;
    this.getAllCalendarDataForDashboard(this.calenderDataPost);
  }

  getAllCalendarDataForDashboard(calenderDataPost: CalenderDataPost) {
    this.dashboardService
      .getAllCalendarDataForDashboard(calenderDataPost)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.calendarListData = res?.response?.listCalendarData;
      });
  }

  onSelect({ selected }) {
    this.storageService.store('applicantDetailsTID', 4);
    this.router.navigate(['applicants-list/edit-applicant'], {
      queryParams: {
        applicantId: selected[0].applicantId,
        isArchive: selected[0].isArchive,
      },
    });
  }
  gotoActivityLog(applicantId: number, isArchive: boolean) {
    this.storageService.store('applicantDetailsTID', 4);
    this.router.navigate(['applicants-list/edit-applicant'], {
      queryParams: { applicantId: applicantId, isArchive: isArchive },
    });
  }

  onSort(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    this.ngxdtConfigCompleted.sortColumn = sortInfo.sorts[0].prop;
    this.ngxdtConfigCompleted.sortDirection = sortInfo.sorts[0].dir;
    this.loadListCompleteActivityData();
  }
}
