import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Children, MaritalStatus } from '../applicant.model';
import { ApplicantsService } from '../applicants.service';
import * as moment from 'moment';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-family-information',
  templateUrl: './family-information.component.html',
  styleUrls: ['./family-information.component.scss']
})
export class FamilyInformationComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;
  notEditable: boolean = true;
  isSaving: boolean = false;
  selectMaritalStatusAsMarried: boolean = true;
  Child_Age;
  age;
  showAge;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();;
  maxDate = moment({ year: this.year, month: this.month, day: this.day }).format('YYYY-MM-DD');
  maxDate1 = moment({ year: this.year, month: this.month, day: this.day }).format('YYYY-MM-DD');
  // minDate1 = moment({ year: this.year - 21, month: this.month, day: this.day }).format('YYYY-MM-DD');

  constructor(public applicantService: ApplicantsService,
    public headerTitleService: HeaderTitleService,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn ;
  }

  ngAfterContentChecked() {
    if (this.applicantService.familyInfoModel.spouseInfo.dob == undefined) {
      this.applicantService.familyInfoModel.spouseInfo.age = 0;
    }
    else {
      this.applicantService.calculateAge(this.applicantService.familyInfoModel.spouseInfo.dob);
    }
  }
  updateChildren() {
    let diff = this.applicantService.familyInfoModel.numberOfChildren - this.applicantService.familyInfoModel.numberOfChildrenOLD;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        let childAdd = new Children();
        childAdd.child_Age = 0;
        this.applicantService.familyInfoModel.childrens.push(childAdd);
      }
    }
    else if (diff < 0) {
      for (let i = 0; i < -diff; i++) {
        this.applicantService.familyInfoModel.childrens.pop();
      }
    }
    this.applicantService.familyInfoModel.numberOfChildrenOLD = this.applicantService.familyInfoModel.numberOfChildren;
  }

  AddChildren() {
    let childAdd = new Children();
    childAdd.child_Age = 0;
    this.applicantService.familyInfoModel.childrens.push(childAdd);
    this.applicantService.familyInfoModel.numberOfChildrenOLD = this.applicantService.familyInfoModel.childrens.length;
    this.applicantService.familyInfoModel.numberOfChildren = this.applicantService.familyInfoModel.childrens.length;
  }

  removeChildren(index: number) {
    this.applicantService.familyInfoModel.childrens.splice(index, 1);
    this.applicantService.familyInfoModel.numberOfChildrenOLD = this.applicantService.familyInfoModel.childrens.length;
    this.applicantService.familyInfoModel.numberOfChildren = this.applicantService.familyInfoModel.childrens.length;
  }

  OnlyletterAllowed(event): Boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return false;
    }
    return true;
  }

  onlyNumbersLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)))) {
      return false;
    }
    return true;
  }

  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      //call update function here

      if (this.applicantService.familyInfoModel.maritalStatusId === MaritalStatus.Single ||
        this.applicantService.familyInfoModel.maritalStatusId === MaritalStatus.Divorced ||
        this.applicantService.familyInfoModel.maritalStatusId === MaritalStatus.Widowed) {
        this.applicantService.familyInfoModel.spouseInfo.firstName = '';
        this.applicantService.familyInfoModel.spouseInfo.middleName = '';
        this.applicantService.familyInfoModel.spouseInfo.lastName = '';
        this.applicantService.familyInfoModel.spouseInfo.dob = '';
        this.applicantService.familyInfoModel.spouseInfo.age = 0;
      }

      this.applicantService.updateApplicantFamilyInformation(this.applicantService.familyInfoModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          this.toaster.success(res.message)
        });
    }
  }


  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    this.isSaving = false;
    return throwError(error);
  }

  // Function to enable date
  enableChildBirthDate(event) {
    var element = event.target.offsetParent.offsetParent;
    var input1 = element.querySelector('input.firstName');
    // var input2 = element.querySelector('input.middleName');
    var input3 = element.querySelector('input.lastName');
    var input4 = element.querySelector('input.dob');
    let a = input1.value.trim();
    // let b = input2.value.trim();
    let c = input3.value.trim();
    // if (a.length > 0 && b.length > 0 && c.length > 0) {
    if (a.length > 0 && c.length > 0) {
      input4.removeAttribute('disabled');
    }
    else {
      input4.setAttribute('disabled', 'disabled')
    }
  }

  enablePartnerBirthDate(event) {
    var element = event.target.offsetParent.offsetParent;
    var input1 = element.querySelector('input.firstName');
    // var input2 = element.querySelector('input.middleName');
    var input3 = element.querySelector('input.lastName');
    var input4 = element.querySelector('input.dob');
    let a = input1.value.trim();
    // let b = input2.value.trim();
    let c = input3.value.trim();
    // if (a.length > 0 && b.length > 0 && c.length > 0) {
    if (a.length > 0 && c.length > 0) {
      input4.removeAttribute('disabled');
    }
    else {
      input4.setAttribute('disabled', 'disabled')
    }
  }

}
