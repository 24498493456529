<!-- content starts-->
<div class="content-wrapper">
    <div class="card card-full-height">
        <div class="card-body px-0 pt-2 pb-3">
            <div class="ngxdt audit-logs">
        
              <div class="ngxdt-header">
                <div class="ngxdt-reports-button" >
                    <span ngbDropdown placement="bottom" autoClose="true">
                        <button class="btn-download" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icofont icofont-download-alt"></i>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li (click)="downloadAccountsReceivableReportData('PDF')">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                              <span>Export as PDF</span>
                            </li>
                            <hr class="hr-gray">
                            <li (click)="downloadAccountsReceivableReportData('Excel')">
                              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                              <span>Export as Excel</span>
                            </li>
                          </div>
                    </span>
                </div>
              </div>
          
              <div class="ngxdt-body">
                <ngx-datatable 
                #table 
                class="bootstrap" 
                [messages]="{
                  emptyMessage: ngxDatatableSSRConfig.emptyMessage | translate,
                  totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                  selectedMessage: false
                }" 
                [footerHeight]="ngxDatatableSSRConfig.footerHeight" 
                [limit]="ngxDatatableSSRConfig.pageSize" 
                [rows]="rows"
                [columnMode]="ngxDatatableSSRConfig.columnMode.force" 
                [offset]="ngxDatatableSSRConfig.tableOffset" 
                [loadingIndicator]="false"
                [scrollbarH]="true"
                [scrollbarV]="true" 
                [virtualization]="false">
                  <ngx-datatable-column maxWidth="100" headerClass="make-center" cellClass="make-center" prop="id" name="#"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                      {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.currentPageNumber - 1)) + rowIndex}}.
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [flexGrow]="0.75" prop="applicantName" name="Applicant Name" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div [title]="value">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [flexGrow]="0.75" prop="outstandingDueAmount" name="Outstanding Due" [sortable]="false"
                  [draggable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                  </ng-template>
                </ngx-datatable-column>
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                      <div>
                        <div class="page-size-control">
                          Show
                          <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                            (ngModelChange)="onPageSizeChanged($event)">
                            <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}
                            </option>
                          </select>
                          entries
                        </div>
                        <div class="pagination-control">
                          <ngb-pagination #ngbPage [boundaryLinks]="false"
                            [collectionSize]="ngxDatatableSSRConfig.totalRecords" [pageSize]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize"
                            [page]="ngxDatatableSSRConfig.currentPageNumber" [maxSize]="5"
                            (pageChange)="onPageChanged($event)">
                          </ngb-pagination>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>
      
                </ngx-datatable>
              </div>
              <div class="ngxdt-footer">
              </div>
            </div>
          </div>
    </div>
</div>
<!-- content Ends -->

