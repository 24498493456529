<div class="container-fluid theme-font-size" *ngIf="headerTitleService.PermissionVM.documents">
  <div class="row dropzone-custom">
    <div class="col-sm-12 px-0">
      <div class="card border-radius-top-0 p-t-30 mb-0">
        <div class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-5 mb-3">
          Applicant Documents
          <div class="pull-right" *ngIf="headerTitleService.PermissionVM.auditLog">
            <svg (click)="openAuditLog()" style="position:relative;bottom:7px;" type="button"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
              <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
                <g id="Group_605" data-name="Group 605">
                  <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7"
                    transform="translate(10.764 3)" fill="#a1a1a1" />
                  <path id="XMLID_32_"
                    d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z"
                    transform="translate(14.762 -10.655)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <hr class="mt-1">
        </div>
        <div class="row col-xl-12 px-5">
          <div class="col-xl-1"></div>
          <span class="theme-font-color-blue f-12">Upload Document:</span>
        </div>
        <div class="card-body">
          <div class="dropzone-wrapper" *ngIf="headerTitleService.PermissionVM.documentsUpload">
            <ngx-dropzone [maxFileSize]="	104857600" (change)="onSelect($event, modalId)" class="dropzone dropzone-primary col-md-7 py-4">
              <ngx-dropzone-label class="dropzone-custom-label">
                <i class="icon-cloud-up icon"></i>
                <span class="title">Drop files here or click to upload.</span>
                <span class="subtitle">(Only upload png, jpeg, jpg, pdf files)</span>
                <span class="warning">Maximum number of files allowed: 20</span>
                <span *ngIf="!masSize" class="warning">Maximum size of files allowed: 100MB</span>
                <span *ngIf="masSize" class="warning title">File size is greater than: 100MB</span>
              </ngx-dropzone-label>
            </ngx-dropzone>

          </div>
          <div style="display: grid;justify-items: center;position: relative;top: 30%;"
            *ngIf="!headerTitleService.PermissionVM.documentsUpload">
            <svg xmlns="http://www.w3.org/2000/svg" width="83.771" height="111.695" viewBox="0 0 83.771 111.695">
              <g id="padlock_2_" data-name="padlock (2)" transform="translate(-5 -1)">
                <path id="Path_232" data-name="Path 232"
                  d="M46.886,1A31.284,31.284,0,0,0,15.471,32.414V46.376A10.285,10.285,0,0,0,5,56.848v45.376A10.285,10.285,0,0,0,15.471,112.7H78.3a10.285,10.285,0,0,0,10.471-10.471V56.848A10.285,10.285,0,0,0,78.3,46.376V32.414A31.284,31.284,0,0,0,46.886,1Zm34.9,55.848v45.376a3.3,3.3,0,0,1-3.49,3.49H15.471a3.3,3.3,0,0,1-3.49-3.49V56.848a3.3,3.3,0,0,1,3.49-3.49H78.3A3.3,3.3,0,0,1,81.791,56.848ZM22.452,46.376V32.414a24.433,24.433,0,0,1,48.867,0V46.376Z"
                  fill="#0e4ab3" />
                <path id="Path_233" data-name="Path 233"
                  d="M24.471,19A10.285,10.285,0,0,0,14,29.471a10.167,10.167,0,0,0,6.981,9.773v7.679a3.3,3.3,0,0,0,3.49,3.49,3.3,3.3,0,0,0,3.49-3.49V39.245a10.167,10.167,0,0,0,6.981-9.773A10.285,10.285,0,0,0,24.471,19Zm0,13.962a3.3,3.3,0,0,1-3.49-3.49,3.3,3.3,0,0,1,3.49-3.49,3.3,3.3,0,0,1,3.49,3.49A3.3,3.3,0,0,1,24.471,32.962Z"
                  transform="translate(22.414 44.829)" fill="#0e4ab3" />
              </g>
            </svg>
            <div class="font-roboto f-16 f-w-600 mt-4">Access Restricted</div>
            <div class="text-center font-roboto f-16 theme-font-color-dark mt-3">You don’t have permission to access
              this section.
              <br>
              Please contact your administration to request access.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal popup for add document-->
<ng-template #modalId let-modal>
  <div class="document-modal-wrapper p-3">
    <div class="modal-header">
      <span class="modal-title mb-1">Upload Documents</span>
      <span class="modal-close-btn" (click)="closeModal(modal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
          <path
            d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
            transform="translate(0 -0.001)" fill="#a1a1a1" />
        </svg>
      </span>
    </div>
    <div class="modal-body pt-5 pb-0 px-0 document-screens" style="height: 100%;">
      <div *ngIf="activeScreen == 1" class="screen1" >
        <div class="row h-100" style="height: 100%;">
          <div class="col-xl-3">
            <div class="dropzone-wrapper">
              <ngx-dropzone [maxFileSize]="	104857600" (change)="onSelect($event, modalId)" class="dropzone dropzone-primary">
                <ngx-dropzone-label class="dropzone-custom-label">
                  <i class="icon-cloud-up icon"></i>
                  <span class="title">Drop files here or click to upload.</span>
                  <span class="subtitle">(Only upload png, jpeg, jpg, pdf files)</span>
                  <span *ngIf="!masSize" class="warning">Maximum size of files allowed: 100MB</span>
                  <span *ngIf="masSize" class="warning title">File size is greater than: 100MB</span>
                  <span class="warning">Maximum number of files allowed: 20</span>

                </ngx-dropzone-label>
              </ngx-dropzone>
              <span class="more-label">{{files.length}} files selected</span>
            </div>
          </div>
          <div class="col-xl-9">
            <div class="d-flex flex-column h-100">
              <div class="main-content-wrapper mt-3 mt-xl-0">
                <ngx-dropzone-preview class="dropzone-file" *ngFor="let f of files" [removable]="true"
                  (removed)="onRemove(f)">
                  <ngx-dropzone-label class="dropzone-file-info">
                    <span class="file-type">{{ f.name.split('.').pop() }}</span>
                    <span class="file-name" [title]="f.name">{{ f.name.substr(0,(f.name.lastIndexOf('.')))}}</span>
                    <span class="file-size">Size: {{ formatBytes(f.size) }}</span>
                  </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </div>
              <div class="action-buttons-wrapper">
                <button class="btn btn-modal btn-gray" (click)="closeModal(modal)">CANCEL</button>
                <button class="btn btn-modal btn-blue ml-3" (click)="showScreen2()"
                  [disabled]="files.length == 0 || isContinued">
                  {{isContinued? "Processing..." : "CONTINUE"}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="activeScreen == 2" class="screen2">
        <div class="row h100">
          <div class="col-xl-5">
            <div class="content-wrapper mx-0 mx-md-4 px-md-5 px-2 left-section h100">
              <div class="preview-wrapper p-2 d-flex align-items-center justify-content-center"
                [ngSwitch]="currentFile?.fileExt">
                <img *ngSwitchCase="['jpg','jpeg','png'].includes(currentFile?.fileExt) ? currentFile?.fileExt : ''"
                  [src]="currentFile?.src" class="iframe" [alt]="currentFile?.fileName" width="100%" />
                <i *ngSwitchCase="'pdf'" class="fa fa-file-pdf-o" style="color: firebrick;"></i>
                <i *ngSwitchCase="['xlsx','xls'].includes(currentFile?.fileExt) ? currentFile?.fileExt : ''"
                  class="fa fa-file-excel-o" style="color: green;"></i>
                <i *ngSwitchCase="['doc','docx'].includes(currentFile?.fileExt) ? currentFile?.fileExt : ''"
                  class="fa fa-file-word-o" style="color: darkblue;"></i>
                <i *ngSwitchDefault class="fa fa-file-o"></i>
              </div>
              <div class="progress-wrapper p-2">
                <div *ngIf="currentFile?.isSaving || currentFile?.isFileSaved">
                  <div class="progress-text d-flex justify-content-between">
                    <span class="progress-status">{{ currentFile?.isFileSaved ? 'Uploaded' : 'Uploading' }} {{
                      currentFile?.uploadProgressValue }}%</span>
                    <span *ngIf="!currentFile?.isFileSaved" class="progress-cancel-btn"
                      (click)="cancelUpload()">Cancel</span>
                  </div>
                  <div class="progress-statusbar mt-1">
                    <ngb-progressbar type="info" [value]="currentFile?.uploadProgressValue" height=".2rem">
                    </ngb-progressbar>
                  </div>
                </div>
              </div>
              <div class="navigation-wrapper">
                <div class="d-flex w80">
                  <button class="btn-navigation" (click)="movePrevious()">
                    <i class="fa fa-angle-left"></i>
                  </button>
                  <div class="navigation-text">
                    <span>Document: {{currentFileindex + 1}}/{{fileList.length}}</span>
                  </div>
                  <button class="btn-navigation" (click)="moveNext()">
                    <i class="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7">
            <div class="content-wrapper px-2 pr-md-0 pl-md-5 right-section d-flex flex-column h100">
              <div class="main-content-wrapper mt-4">
                <div class="row form-wrapper">
                  <div class="col-md-9 offset-md-1 p-2">
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Name:
                      </div>
                      <div class="col-xl-7 input-field">
                        <input class="form-control mt-0 bg-transparent" type="text" name="fileName"
                          [(ngModel)]="currentFile.fileName" disabled>
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Type:
                      </div>
                      <div class="col-xl-7 input-field">
                        <select class="form-control mt-0" name="documentTypesId"
                          [(ngModel)]="currentFile.documentTypesId">
                          <option class="text-muted" disabled value="0"></option>
                          <option class="text-muted" *ngFor="let option of mastersVM.masterDocumentType.masterValues"
                            [value]="option.id">{{option.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Folder:
                      </div>
                      <div class="col-xl-7 input-field">
                        <select class="form-control mt-0" name="folderTypeId" [(ngModel)]="currentFile.folderTypeId">
                          <option class="text-muted" disabled value="0"></option>
                          <option class="text-muted" *ngFor="let option of folderTypeEnum | populateEnum"
                            [value]="option.key">{{option.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-12" style="text-align: right;">
                        <button *ngIf="!(currentFile?.isSaving || currentFile?.isFileSaved)" class="btn btn-modal btn-red"
                          (click)="removeSelectedFile()">REMOVE</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-buttons-wrapper">
                <button class="btn btn-modal btn-gray" (click)="closeModal(modal)">CANCEL</button>
                <button class="btn btn-modal btn-blue ml-3" (click)="uploadDoc()" type="button"
                  [disabled]="currentFile?.isSaving || currentFile?.isFileSaved">
                  {{ currentFile?.isSaving ? 'Saving...' : 'SAVE (' + savedFileCount + '/' + fileList.length + ')' }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-template>