import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _  from 'lodash';
import * as moment from 'moment';
import { id } from '@swimlane/ngx-datatable';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    json = json.map((element,index) =>{
      delete element.totalRecords;
      delete element.applicantId;
      delete element.stageId;
      delete element.assignToUserId;

      element.id = index + 1;
      return element
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    // code to make First letter Capatial of Header in Xl Sheet
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for(var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if(!worksheet[address]) continue;
      if(worksheet[address].v == 'id'){
        worksheet[address].v = '#'
      }
      worksheet[address].v = worksheet[address].v.charAt(0).toUpperCase() + worksheet[address].v.slice(1);
      if(!worksheet[address].v) continue;
      worksheet[address].v = worksheet[address].v.charAt(0).toUpperCase() + worksheet[address].v.slice(1);
    } 
    // code to make First letter Capatial of Header in Xl Sheet

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '-'+ moment(new Date()).format('YYYY-MM-DD') + EXCEL_EXTENSION);
  }
}