import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ApplicantsService } from '../applicants.service';
import { MasterService } from 'src/app/components/adminConsole/master/master.service';
import { MastersVM, MasterValueVM } from 'src/app/components/adminConsole/master/master.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CheckApplicationNumberExistsModel } from '../applicant.model';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-program-information',
  templateUrl: './program-information.component.html',
  styleUrls: ['./program-information.component.scss']
})
export class ProgramInformationComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;

  notEditable: boolean = true;
  isSaving: boolean = false;

  mastersVM = new MastersVM();
  masterAdvisorsList: MasterValueVM[] = [];

  isApplicationNumberRequired = true;
  checkApplicationNumberExistsModel = new CheckApplicationNumberExistsModel();

  constructor(
    public applicantService: ApplicantsService,
    public headerTitleService: HeaderTitleService,
    public masterService: MasterService,
    public renderer: Renderer2,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn;
    this.loadMasters();
  }  

  loadMasters() {
    this.masterService.getMasters(this.isApplicationNumberRequired)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
        this.applicantService.masterListData.next(res?.response);
        this.masterAdvisorsList = this.mastersVM.masterAdvisor.masterValues;
        if (!this.notEditable) {
          this.applicantService.programInfoModel.applicationNumber = this.mastersVM.newApplicationNumber;
        }
      });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }

  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      
      //call update function here
      this.checkApplicationNumberExistsModel.isEdit = true;
      this.checkApplicationNumberExistsModel.id = this.applicantService.programInfoModel.id;
      this.checkApplicationNumberExistsModel.gerApplicationNumber = this.applicantService.programInfoModel.gerApplicationNumber;
      this.applicantService.checkApplicationNumberExists(this.checkApplicationNumberExistsModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          if (!res.response.IsExists) {            
            this.applicantService.updateApplicantProgramInformation(this.applicantService.programInfoModel)
              .pipe(catchError((err) => this.handleError(err)))
              .subscribe(res => {
                this.isSaving = false;
                this.toaster.success(res.message);
                this.applicantService.applicant.next(true);
              });
          }
          else {
            this.toaster.success(res.message)
          }
        });
    }
  }
  onKeydown(event, value) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }

  // disableAdvisor(agencyId) {
  //   if (this.mastersVM.masterAgency.masterValues.find(s => s.id == agencyId).name.toLowerCase() == "ger") {
  //     this.applicantService.disableAdvisorSelect = false;
  //     this.masterAdvisorsList = this.mastersVM.masterAdvisor.masterValues;
  //     this.applicantService.programInfoModel.advisorsId = 0;
  //   }
  //   else {
  //     this.applicantService.disableAdvisorSelect = true;
  //     this.masterAdvisorsList = [];
  //   }
  // }
}
