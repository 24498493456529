export class Login {
}

// export class LoginModel {
//     email: string;
//     password: string;
//     confirmPassword: string;
//     rememberMe :boolean= false;
//     deviceToken: string;
//     otp: string;
// }
// export class ForgotPasswordModel {
//     email: string;
// }
// export class SendOTPModel {
//     userId:string;
//     otp: string;
//     token: string;
// }
// export class ResetPasswordViewModel {
//     userId:string;
//     email: string;
//     password: string;
//     confirmPassword: string;
//     token: string;
// }
export enum MethoodType {
    SendOTP ='SendOTP',
    VerifyOTP = 'VerifyOTP',
    ResetPassword = 'ResetPassword'
}
//==================NEW==============
export class LoginRequest {
    email: string;
    password: string;
    rememberMe :boolean= false;
    deviceToken: string;
}

export class ForgotPasswordResetPasswordRequest {
    email: string;
    newPassword: string;
    confirmPassword: string;
    resetPasswordToken: string;
}
export class ForgotPasswordResetPasswordResponse {

}
export class ForgotPasswordSendOTPRequest {
    email: string;
}
export class ForgotPasswordSendOTPResponse {
    otpToken: string;
}
export class ForgotPasswordVerifyOTPRequest {
    email: string;
    otpToken: string;
    otp: string;
}
export class ForgotPasswordVerifyOTPResponse {
    resetPasswordToken: string;
}