import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, OnChanges, HostListener, OnInit, Input, OnDestroy, SimpleChanges } from '@angular/core';
import {NgControl} from '@angular/forms';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';

@Directive({
  selector: '[appShowCurrencySign]'
})

export class ShowCurrencySignDirective implements OnInit, OnDestroy{

  private subscription: Subscription;

  constructor(
    private currencyPipe: CurrencyPipe,
    private el: ElementRef,
    private ngControl: NgControl) { 
      //this.el.nativeElement.value = this.currencyPipe.transform(this.el.nativeElement.value);
    }
    
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const ctrl = this.ngControl.control;
    //ctrl.patchValue(this.currencyPipe.transform(ctrl.value))
    // if(this.isInitialized){
      
    // }
    // else{
    //   this.isInitialized = true;
    // }
    this.subscription = ctrl.valueChanges
        .subscribe(v => ctrl.setValue(this.currencyPipe.transform(v), { emitEvent: false }));
    
  }

  @HostListener('focus', ['$event']) public onFocus(event: Event) {
    this.removeCurrency();
  }
  removeCurrency(){
    let newValue: string = this.el.nativeElement.value;
    newValue = newValue.replace("$","");
    //newValue = newValue.substring(1);
    newValue = newValue.split(",").join("");
    this.el.nativeElement.value = parseInt(newValue);
  }

  @HostListener('blur', ['$event']) public onBlur(event: Event) {
    this.el.nativeElement.value = this.currencyPipe.transform(this.el.nativeElement.value);
  }
  // @HostListener('change', ['$event']) public onChange(event: Event) {
    
  //   if(!this.isInitialized){
  //     this.el.nativeElement.value = this.currencyPipe.transform(this.el.nativeElement.value);
  //     this.isInitialized = true;
  //   }    
  // }

}
