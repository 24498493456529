import { ExcelService } from './../../../shared/services/export_service/excel.service';
import { AuditLogsComponent } from 'src/app/components/adminConsole/audit-logs/audit-logs.component';
import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { ApplicantsService } from '../applicants.service';
import { getAllApplicantsRequestModel } from "../applicant.model";
import { HeaderTitleService } from '@services/header-title.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { StorageService } from '@services/storage.service';
import { ReportService } from '../../adminConsole/reports/report.service';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-applicants-list',
  templateUrl: './applicants-list.component.html',
  styleUrls: ['./applicants-list.component.scss']
})
export class ApplicantsListComponent implements OnInit {
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  pageRequestModel = new getAllApplicantsRequestModel();
  public selected = [];
  totalRows: any[] = [];

  constructor(public applicantsService: ApplicantsService,
    public storageservice: StorageService,
    public headerTitleService: HeaderTitleService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    public excelService:ExcelService) {
  }

  ngOnInit(): void {
    this.headerTitleService.title = "Applicant List";

    if (this.headerTitleService.PermissionVM.applicantList) {
    this.initDatatable();
    this.loadListData();
    }
  }

  initDatatable(): void {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.pageSize = 50;
    this.pageRequestModel = {
      sortColumn: 'applicantName',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      isArchive:false
    };
  }
  loadListData() {
    this.applicantsService.getAllApplicants(this.pageRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxDatatableSSRConfig.updateTableData(res);
      });
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);

    this.pageRequestModel.pageSize = this.ngxDatatableSSRConfig.pageSize ;
    this.pageRequestModel.getAll = this.ngxDatatableSSRConfig.getAll ;
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);

    this.pageRequestModel.pageNumber = this.ngxDatatableSSRConfig.currentPageNumber ;
    this.pageRequestModel.getAll = this.ngxDatatableSSRConfig.getAll ;
    this.loadListData();
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.ngxDatatableSSRConfig.rows = [];
    this.pageRequestModel.pageNumber = 1;
    this.pageRequestModel.sortDirection = sort.dir;
    this.pageRequestModel.sortColumn = sort.prop;

    this.loadListData();
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }

  search() {
    this.pageRequestModel.pageNumber = 1;
    this.pageRequestModel.searchText = this.ngxDatatableSSRConfig.searchText;
    this.loadListData();
  }

  onSelect({ selected }) {
    this.storageservice.store('applicantDetailsTID', 1);
    this.router.navigate(['/applicants-list/edit-applicant'], { queryParams: { applicantId: this.selected[0].applicantId, isArchive: this.selected[0].isArchive } });
  }

  archiveClicked() {
    this.pageRequestModel.isArchive = !this.pageRequestModel.isArchive;
    this.loadListData();
  }
  
  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, {size:'xl', centered:true});
    modalRef.componentInstance.pageId = 0;
  }
  // Open Audit Trail Model //


  downloadApplicantListData(fileType): void{
    if(this.headerTitleService.PermissionVM.exportApplicantList){
      this.pageRequestModel.getAll = true;
      this.applicantsService.getAllApplicants(this.pageRequestModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.totalRows = res.response;
        if(fileType == 'PDF'){
          this.downloadAsPDF();
        }
        else if(fileType == 'Excel'){
          this.exportAsXLSX();
        }
        });
    }
  }

    // for export as Excel
    exportAsXLSX(): void {
      var filename = this.headerTitleService.title
      this.excelService.exportAsExcelFile(this.totalRows, filename);
    }
  
    public downloadAsPDF() {
      const doc = new jsPDF('l', 'mm', 'a4')
      autoTable(doc, {
  
        head: [['#', 'Applicant Name ', 'Date of Engagement','GER ID Number','Sponsor Name','Agency','Stage','Applicant Number']],
        body: this.getBody(),
      })
      doc.save(this.headerTitleService.title + '-' + moment(new Date()).format('YYYY-MM-DD'));
    }
  
    getBody(): any[] {
      let data = [];
      this.totalRows.forEach((s,index)=>data.push([index+1, s.applicantName, s.doEngagement,s.gerApplicationNumber,s.sponsorName,s.agencyName,s.
        stageName,s.applicationNumber]));
      return data;
    }

}
