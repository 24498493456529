import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { UserPermissionsService } from "./user-permissions.service";
import { PermissionRoleMappingModel, Allowed, PermissionRoleMapping, PermissionRecordModel, Role } from "./user-permissions.model";
import { StorageService } from '@services/storage.service';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit, AfterViewInit {  
  public permissionModel: PermissionRoleMappingModel;
  public allowedPermissions: Allowed[];
  public allPermissionRoleMapping: PermissionRoleMapping[];

  rows: any[];
  copiedRows: PermissionRecordModel[] = [];
  
  checkboxValue: typeof CheckboxValue = CheckboxValue;
  
  @ViewChild('table') table: any;
  sub$: Subscription;

  constructor(
    public userPermissionsService: UserPermissionsService,
    public storageService: StorageService,
    public headerTitleService: HeaderTitleService,
    private toaster: ToastrService) {
  }
  ngAfterViewInit() {
    setTimeout(() => {
      // console.log(this.table.element.children[0].children[0].children[0].scrollWidth);
      //console.log(this.table.bodyComponent.scroller);
      
      this.table?.bodyComponent?.scroller?.element?.addEventListener("scroll", (event) => {
        this.table.element.children[0].children[0].children[0].scrollLeft = event?.target?.scrollLeft;
      });
      ////DO NOT DELETE////
      // var width = this.table.element.children[0].children[0].children[0].scrollWidth;
      // this.table.bodyComponent.scroller.scrollWidth = width;
      // var rows = this.table?.bodyComponent?.scroller?.element?.querySelectorAll(".datatable-row-wrapper");
      // console.log(rows);
      // for (let i = 0; i < rows.length; i++) {
      //   console.log(rows[i]);
      //   //rows[i].clientWidth = width;
      // }
    }, 1200);
  } 

  ngOnInit(): void {
    this.storageService.store('userManagementTID', 2);
    this.loadListData();
  }

  loadListData() {
    this.userPermissionsService.getAllPermissions()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess === true) {
          // this.allowedPermissions = res.response.allowed;
          this.permissionModel = res.response;
          this.allPermissionRoleMapping = this.permissionModel.permissionRoleMappings;

          this.rows = this.permissionModel?.availablePermissions;
          this.copiedRows = this.permissionModel?.availablePermissions;
        }
      });
  }

  ngOnDestroy() {
    this.GetPermissionAccessByUser();
  }
  private GetPermissionAccessByUser() {
    this.userPermissionsService.getPermissionAccessByUser()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess === true) {
          this.headerTitleService.PermissionVM = res.response;
          this.storageService.store('permissionVM', res?.response);
        }
      });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
  
  search(event): void {
    if(event.target.value){
      const val = event.target.value.toLowerCase();
      // filter our data
      const filteredRows = this.copiedRows.filter(s=>s.name.toLocaleLowerCase().includes(val));
      // update the rows
      this.rows = [...filteredRows];      
    }
    else{
      this.rows = [...this.copiedRows];
    }
    // whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }  
  
  hasPermission(permissionId, roleId): boolean {
    return !!(this.allPermissionRoleMapping?.find(a => a.permissionRecordId == permissionId && a.roleId == roleId));
  }  
  isAdmin(userRole): boolean {
    return (userRole.toLowerCase() == 'Admin'.toLowerCase());
  }
  toggleExpandGroup(group) {
    this.table.groupHeader.toggleExpandGroup(group);
  }
  getGroupIndex(group): string {
    return (this.table.groupedRows.findIndex(s => s.key == group.key) + 1);
  }
  getCheckboxValue(groupItems: PermissionRecordModel[], role: Role): CheckboxValue {
    if(groupItems)
    {
      // get checked item count
      const count = groupItems.filter(group=>this.hasPermission(group.id, role.id))?.length;
      if(count == 0){
        return CheckboxValue.none;
      }
      else if(count == groupItems.length){
        return CheckboxValue.all;
      }
      else{
        return CheckboxValue.partial;
      }
    }
    return CheckboxValue.none;
  }
  
  updatePermissionGroup(groupItems: PermissionRecordModel[], role: Role, event: any) {
    // stop default event action
    event.preventDefault();

    // get event's current value
    var isChecked = event.target.checked;

    // confirm before proceeding
    Swal.fire({
      title: 'Do you want to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) { // confirmed; proceed to update the permission
        event.target.disabled = true;
        
        const requestModel: any[] = [];
        groupItems.forEach(group => {
          requestModel.push({ PermissionName: group.systemName, RoleId: role.id, IsSelected: isChecked });
        });
        
        this.userPermissionsService.SavePermission(requestModel)
          .pipe(catchError((err) => {
            event.target.disabled = false;
            return this.handleError(err);
          }))
          .subscribe(res => {
            if (res == true) {
              
            }
            this.loadListData();
          });
      }
    });
  }
  updatePermission(row: PermissionRecordModel, role: Role, event: any) {
    // stop default event action
    event.preventDefault();

    // get event's current value
    var isChecked = event.target.checked;

    // confirm before proceeding
    Swal.fire({
      title: 'Do you want to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) { // confirmed; proceed to update the permission
        event.target.disabled = true;
        const requestModel = [{ PermissionName: row.systemName, RoleId: role.id, IsSelected: isChecked }];
        this.userPermissionsService.SavePermission(requestModel)
          .pipe(catchError((err) => {
            event.target.disabled = false;
            return this.handleError(err);
          }))
          .subscribe(res => {
            if (res == true) {
              // event.target.checked = isChecked;
            }
            // event.target.disabled = false;
            this.loadListData();
          });
      }
    });
  }

}

export enum CheckboxValue {
  none,
  partial,
  all
}