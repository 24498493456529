<div class="ngxdt applicant-list">
  <div class="ngxdt-body pt-4">
    <ngx-datatable #table class="bootstrap material fullscreen" [footerHeight]="ngxdtConfigTodays.footerHeight"
      [limit]="ngxdtConfigTodays.pageSize" [rows]="ngxdtConfigTodays.rows"
      [columnMode]="ngxdtConfigTodays.columnMode.flex" [offset]="ngxdtConfigTodays.tableOffset"
      [scrollbarH]="false"
      [scrollbarV]="true"
      [selectionType]="ngxdtConfigTodays.selectionType.single"
      [selected]="selected"
      (select)="onSelect($event)"
      [sortType]="'single'"
      (sort)="onSort($event)"
      [externalSorting]="true"
      [virtualization]="false">


      <ngx-datatable-column [width]="200" minWidth="100" [flexGrow]="1" headerClass="pl-5" cellClass="pl-5 font-weight-500"
        prop="activityTitle" name="Activity" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div title="{{ value }}">
            {{ value }}
          </div>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.1" prop="activityTypeName" name="Type" [sortable]="false"
        [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div title="{{ value }}">
            {{ value }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1" prop="dueDate" name="Due Date" [sortable]="task == taskId.completed"
        [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value | date:'MM/dd/yy' }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="task == taskId.completed" [width]="150" minWidth="100" [flexGrow]="1" prop="completedDate" name="Completed Date" [sortable]="true"
      [draggable]="false">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ value | date:'MM/dd/yy' }}
      </ng-template>
    </ngx-datatable-column>

      <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1" prop="applicantName" name="Applicant" [sortable]="false"
        [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div title="{{ value }}">
            {{ value }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="assignToName" name="Assigned To" [sortable]="false"
        [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div title="{{ value }}">
            {{ value }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="405" minWidth="200" [flexGrow]="2.4" prop="details" name="Details" [sortable]="false"
        [draggable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div title="{{ value }}">
            <div title="{{ value }}">
              {{ value }}
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="headerTitleService.PermissionVM.viewActivityLogs" [width]="50" minWidth="70" [flexGrow]="0.90" headerClass="pull-right" cellClass="pull-right"
        [sortable]="false" [draggable]="false">
        <ng-template  let-value="value" let-row="row" ngx-datatable-cell-template>
          <div class="btn-position">
            <button class="btn btn-view-sm mr-2" *ngIf="!headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.View)">
              <i class="fa fa-search">
              </i>
            </button>
            <button class="btn btn-edit-sm mr-2" *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.Update)">
              <i class="fa fa-edit">
              </i>
            </button>
            <button class="btn btn-cross" *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="deleteActivity(row)">
              <i class="icofont icofont-close">
              </i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <div>
            <div class="page-size-control">
              Show
              <select class="page-size-dropdown" [(ngModel)]="ngxdtConfigTodays.pageSize"
                (ngModelChange)="onPageSizeChanged($event)">
                <option *ngFor="let opt of ngxdtConfigTodays.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
              </select>
              entries
            </div>
            <div class="pagination-control">
              <ngb-pagination #ngbPage [boundaryLinks]="false" [collectionSize]="ngxdtConfigTodays.totalRecords"
                [pageSize]="ngxdtConfigTodays.pageSize == 1?ngxdtConfigTodays.totalRecords:ngxdtConfigTodays.pageSize" [page]="ngxdtConfigTodays.currentPageNumber" [maxSize]="5"
                (pageChange)="onPageChanged($event)">
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
    <div class="ngxdt-footer">
    </div>
  </div>
</div>
