import { Component, OnInit, Renderer2 } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoginService } from './login.service';
import { ForgotPasswordSendOTPRequest, 
  ForgotPasswordVerifyOTPRequest, 
  ForgotPasswordResetPasswordRequest, 
  ForgotPasswordSendOTPResponse, 
  ForgotPasswordVerifyOTPResponse, 
  ForgotPasswordResetPasswordResponse, 
  LoginRequest } from './login.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  backspacePressed: boolean = false;
  show: boolean = false;
  verifying: boolean = false;
  formId: number = 1;
  modalTitle: string;
  animation: boolean;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;  

  loginRequest: LoginRequest = new LoginRequest();
  sendOTPRequest: ForgotPasswordSendOTPRequest = new ForgotPasswordSendOTPRequest();
  verifyOTPRequest: ForgotPasswordVerifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
  resetPasswordRequest: ForgotPasswordResetPasswordRequest = new ForgotPasswordResetPasswordRequest();

  sendOTPResponse: ForgotPasswordSendOTPResponse = new ForgotPasswordSendOTPResponse();
  verifyOTPResponse: ForgotPasswordVerifyOTPResponse = new ForgotPasswordVerifyOTPResponse();
  resetPasswordResponse: ForgotPasswordResetPasswordResponse = new ForgotPasswordResetPasswordResponse();

  modalData: any;
  isSaving: boolean;
  OTP: string = '';
  constructor(
    public loginService: LoginService,
    public storageservice: StorageService,
    private router: Router,
    private toaster: ToastrService,
    private config: NgbModalConfig,
    public renderer: Renderer2,
    public headerTitleService: HeaderTitleService,
  ) {
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe) {
      const storage = (rememberMe == "true") ? localStorage : sessionStorage;
      const token = storage.getItem('authorizationDataIdToken');
      const user = storage.getItem('user');
      if (token && user) {
        this.loginService.isLoggedIn = true;
        this.router.navigate(['/dashboard']); //redirect to landing page
      }
    }
    this.storageservice.clear();
    this.loginService.isLoggedIn = false;
    this.config.centered = true;
  }

  ngOnInit(): void {
    //this.storageservice.clear();
    this.hidePassword = true;
    this.hideConfirmPassword = true;
    this.formId = 1;
    this.loginRequest = new LoginRequest();
    this.sendOTPRequest = new ForgotPasswordSendOTPRequest();
    this.verifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
    this.resetPasswordRequest = new ForgotPasswordResetPasswordRequest();
  }

  onKeydown(event: any, value: any) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }

  changePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  changeConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  authenticate() {
    this.loginService.SignIn(this.loginRequest)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {
          this.headerTitleService.PermissionVM = res?.response?.permissionVM;
          this.storageservice.setRememberMe(this.loginRequest.rememberMe ? "true" : "false");
          this.storageservice.store('authorizationDataIdToken', res.response.accessToken);
          this.storageservice.store('user', res.response.user);
          this.storageservice.store('permissionVM', res?.response?.permissionVM);
          this.router.navigate(['dashboard']);
        }
      });
  }

  onKeyUp(event: any, forwardtext: any, backwordtext: any) {
    if (event.key === "Backspace") {
      if (this.backspacePressed) {
        this.backspacePressed = false;
        event.target.setAttribute('disabled', 'disabled');
        const element = this.renderer.selectRootElement(backwordtext, true);
        element.select();
        element.focus();
      }
      else {
        if (backwordtext != `#${event.target.id}`) {
          this.backspacePressed = true;
          this.OTP = this.OTP.substring(0, this.OTP.length - 1);
        }
      }
    }
    else if (event.target.value.length > 0) {
      this.OTP += (event.target.value);
      if (forwardtext == `#${event.target.id}`) {
        if (this.OTP.length > 5) {
          this.verifyOTP(this.OTP);
        }
      }
      else {
        const element = this.renderer.selectRootElement(forwardtext, true);
        element.removeAttribute('disabled');
        element.focus();
      }
    }
  }

  onForgetPassword() {
    this.sendOTPRequest.email = this.loginRequest.email
    this.formId = 2;
    this.OTP = '';
    this.loginRequest = new LoginRequest();
  }

  sendOTP() {
    this.verifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
    this.loginService.SendOTP(this.sendOTPRequest)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.OTP = '';
          this.verifyOTPRequest = {
            email: this.sendOTPRequest.email,
            otpToken: res.response?.otpToken,
            otp: this.OTP
          };
          //this.sendOTPRequest = new ForgotPasswordSendOTPRequest();
          this.formId = 3;
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }

  verifyOTP(OTP) {
    document.querySelectorAll('.digit-group input').forEach(el => el.setAttribute('disabled', 'disabled'));
    this.show = false;
    this.verifying = true;

    this.verifyOTPRequest.otp = OTP;

    this.loginService.VerifyOTP(this.verifyOTPRequest)
      .pipe(catchError((err) => {
        this.show = false;
        this.verifying = false;
        this.clearOTP();
        return this.handleError(err)
      }))
      .subscribe(res => {
        this.verifying = false;
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.show = true;
          this.resetPasswordRequest = {
            email: this.verifyOTPRequest.email,
            resetPasswordToken: res.response?.resetPasswordToken,
            newPassword: "",
            confirmPassword: ""
          }
        }
        else {
          this.show = false;
          this.verifying = false;

          if (res.message == 'User role not matched for reset password!!') {
            this.formId = 5;
          }
          else {
            this.clearOTP();
            this.toaster.error(res.message);
            //this.OTP = '';
            //this.verifyOTPRequest = new ForgotPasswordVerifyOTPRequest();
          }
        }
      });
  }

  clearOTP(){
    var a = document.querySelectorAll('.digit-group input');
    a.forEach(el => {
      const element = this.renderer.selectRootElement(`#${el.id}`, true);
      element.removeAttribute('disabled');
      element.value = "";
      element.setAttribute('disabled', 'disabled');
    });
    const firstEl = this.renderer.selectRootElement(`#digit1`, true);
    firstEl.removeAttribute('disabled');
    firstEl.focus();

    this.OTP = '';
  }

  onResetPassword() {   
    this.loginService.ResetPassword(this.resetPasswordRequest)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {
          this.toaster.success(res.message);
          this.resetPasswordRequest = new ForgotPasswordResetPasswordRequest();
          this.loginRequest = new LoginRequest();
          this.formId = 1;
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }   

  private handleError(response: any) {    
    if (response?.status === 499) {
      this.toaster.error("Server response time out. Please try again.");
    }
    else if (response?.error?.message === "Input validation failed!") {
      this.toaster.error(response?.error?.errors[0]);
    }
    else if (response?.error?.message === "Sending OTP failed. Please try again.") {
      this.verifying = false;
      document.querySelectorAll('.digit-group input').forEach(el => el.removeAttribute('disabled'));
      document.querySelectorAll('.digit-group input').forEach(el => el.setAttribute('value', ''));
      this.toaster.warning(response?.error?.message);
      this.OTP = '';
    }
    else if (response?.error?.message == 'User role not matched for reset password!!') {
      this.formId = 5;
    }
    else {
      this.toaster.error(response?.error?.message);
    }
    return throwError(response);
  }

}