<!-- content starts-->
<div class="content-wrapper">
  <form (ngSubmit)="showConfirm(modalId)" class="needs-validation add-applicant-form"
    [ngClass]="{'was-validated': validate}" ngNativeValidate>
    <app-personal-information></app-personal-information>
    <app-family-information></app-family-information>
    <app-background-information></app-background-information>
    <app-program-information></app-program-information>
    <app-immigration-information></app-immigration-information>
    <app-payment-information (payment_valid)="check($event)"></app-payment-information>
    <app-notes></app-notes>

    <div class="row my-2">
      <div class="col-xl-6 mt-3">
      </div>
      <div class="col-xl-6 mt-3 px-5">
        <button class="btn btn-save pull-right" type="submit" data-toggle="tooltip" title="" role="button"
          data-bs-original-title="" [disabled]="isLoading">
          <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></i>
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
<!-- content Ends-->

<!-- Model popup on New Activity-->
<ng-template #modalId let-modal>
  <div class="text-center py-3 f-16 theme-font-color-blue font-weight-bold f-family-oswald">
    Please confirm all information is correct
    <button class="review-close" type="" (click)="modal.dismiss()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22.64 22.64">
        <path
          d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
          transform="translate(0 -0.001)" fill="#a1a1a1" />
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <div class="add-applicant-preview">
      <form>
        <app-personal-information [isEditable]="false"></app-personal-information>
        <app-family-information [isEditable]="false"></app-family-information>
        <app-background-information [isEditable]="false"></app-background-information>
        <app-program-information [isEditable]="false"></app-program-information>
        <app-immigration-information [isEditable]="false"></app-immigration-information>
        <app-payment-information [isEditable]="false"></app-payment-information>
        <app-notes [isEditable]="false"></app-notes>
      </form>
    </div>
  </div>
  <div class="modal-footer mb-3">
    <div class="col-xl-6 mt-3">
    </div>
    <div class="row col-xl-6 mt-3 px-5">
      <div class="col-xl-8">
        <button class="btn py-2 btn-EDIT pull-right" style="width: 135px;" data-toggle="tooltip" title="" role="button"
          data-bs-original-title="" [disabled]="isLoading" (click)="modal.dismiss()"><i class="fa"
            [ngClass]="{'fa-spin fa-spinner': isLoading}"></i>
          EDIT
        </button>
      </div>
      <div class="col-xl-4 px-0">
        <button class="btn py-2 btn-save pull-right" style="width: 135px;" type="submit" data-toggle="tooltip" title=""
          role="button" data-bs-original-title="" [disabled]="isLoading" (click)="CreateApplicant()">
          <i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></i>
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</ng-template>