import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EditPaymentsRecord, EditPaymentsModel, AdditionalPaymentModel } from '../edit-payments/edit-payments.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../shared/models/response.model';
import { DataService } from '../../../shared/services/data.service'
@Injectable({
  providedIn: 'root'
})
export class EditPaymentsService {
  editpaymentsrecord = new EditPaymentsRecord();
  editpaymentsmodel = new EditPaymentsModel();
  editpaymentsLiaisonmodel = new EditPaymentsModel();
  editpaymentsAgencymodel = new EditPaymentsModel();
  constructor(public router: Router, private service: DataService) { }


  getEditPaymentsRecordsReceiverIdForApplicant(editpaymentsrecord: EditPaymentsRecord): Observable<ResponseModel> {
    let url = '/gerapi/PaymentReceived/GetPaymentReceived';
    return this.service.post(url, editpaymentsrecord).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  updatePaymentRecordForApplicant(model: EditPaymentsModel): Observable<ResponseModel> {
    let url = '/gerapi/PaymentReceived/update';
    return this.service.post(url, model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getAdditionalPaymetsByApplicantId(applicantId: number): Observable<ResponseModel> {
    let url = '/gerapi/AdditionalPayment/get/' + applicantId.toString();
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  addUpdateAdditionalPaymetsForApplicant(additionalPayments: AdditionalPaymentModel): Observable<ResponseModel> {
    let url = '/gerapi/AdditionalPayment/addUpdate';
    return this.service.post(url, additionalPayments).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}