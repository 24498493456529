import { PaginationBaseModel } from "@models/pagination.model";
import { ImmigrationInfoModel } from "./applicant-immigration.model";

export class ApplicantModel {
    personalInfo = new PersonalInfoModel();
    familyInfo = new FamilyInfoModel();
    backgroundInfo = new BackgroundInfoModel();
    programInfo = new ProgramInfoModel();
    immigrationInfo = new ImmigrationInfoModel();
    paymentInfo = new PaymentInfoModel();
    notes = new NotesModel();
}

export class ApplicantVM {
    applicantId: number;
    applicantName: string;
    doEngagement: string;
    applicationNumber: string;
    sponsorName: string;
    agencyName: string;
    stageName: string;
    totalRecords: number;
}

export class ActivityVM {
    id   : number;
    applicantId: number;
    activityTypesId: number;
    activityTitle: string;
    dueDate: string='';
    completedDate: string='';
    assignToUserId: string='';
    stagesId: number;
    details: string;
    isCompleted: boolean;
}

export class ActivityModalModel {
    id   : number;
    applicantId: number;
    activityTypesId: number;
    activityTitle: string;
    dueDate: string='';
    completedDate: string='';
    assignToUserId: string;
    stagesId: number;
    details: string;
    isCompleted: boolean;
}


export class getAllApplicantsRequestModel extends PaginationBaseModel {
isArchive: boolean;
}

export class getAllActivityRequestModel extends PaginationBaseModel {
    applicantId: number;
    id: number;
    taskId: number;
    userId:string='';
}

export enum Task {
     all = 0,
     todays = 10,
     upcoming = 20,
     completed = 30,
     inCompleted = 40
}

export class Children {
    id: number;
    applicantId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    child_Age :number;
}

export class Spouse {
    id: number;
    applicantId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    age: number;
}

export class PersonalInfoModel {
    id: number;
    applicantId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    gender: string;
    phone: string;
    email: string;
    status: string;
    NotesDetail: string;
    age: number;
}

export class FamilyInfoModel {
    id: number;
    applicantId: number;
    maritalStatusId: number = 0;
    spouseInfo: Spouse = new Spouse();
    childrens: Children[]=[];
    numberOfChildrenOLD : number =0;
    numberOfChildren : number =0;
}

export class BackgroundInfoModel {
    id: number;
    applicantId: number;
    citizenshipCountry: string;
    birthCountry: string;
    educationLevel: string;
    career: string;
    currentResidence: string;
}

export class ProgramInfoModel {
    id: number;
    applicantId: number;
    doEngagement: string;
    doJobLetterSent: string;
    applicationNumber: string;
    gerApplicationNumber: string;
    currentVisasId: number;
    visaProgramsId: number;
    agencysId: number;
    sponsorsId: number;
    disableSponsor: boolean;
    attorneysId: number;
    advisorsId: number;
    sponsorLiaisonsId: number;
    levelId: number;
    activityTypeId: number;
    stageId: number;
    dateCompleted: string;
    dateDue: string;
    activityType: string;
    details: string;
    tandemProgramId?:number;
}

export class CheckApplicationNumberExistsModel {
    id: number;
    isEdit: boolean
    gerApplicationNumber: string;
}

export class PaymentInfoModel {
    id: number;
    applicantId: number;
    applicant_TotalAgreed: number = 0;
    applicant_P1: number = 0;
    applicant_P2: number = 0;
    applicant_P3: number = 0;
    applicant_P4: number = 0;
    coordinator_TotalAgreed: number = 0;
    coordinator_P1: number = 0;
    coordinator_P2: number = 0;
    coordinator_P3: number = 0;
    coordinator_P4: number = 0;
    agency_TotalAgreed: number = 0;
    agency_P1: number = 0;
    agency_P2: number = 0;
    agency_P3: number = 0;
    agency_P4: number = 0;
    gerAdvisor_TotalAgreed: number = 0;
    gerAdvisor_P1: number = 0;
    gerAdvisor_P2: number = 0;
    gerAdvisor_P3: number = 0;
    gerAdvisor_P4: number = 0;
}

export class NotesModel {
    applicantId: number;
    notesDetail: string;
}

export enum MaritalStatus {
    Single = 1,
    Married = 2,
    Divorced = 3,
    Widowed = 4,
    DomesticPartnership = 5
}

export class AssignToUserVM {
    userId: string;
    fullName: string;
}
