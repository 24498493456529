import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { MastersVM, StageEnum } from 'src/app/components/adminConsole/master/master.model';
import { MasterService } from 'src/app/components/adminConsole/master/master.service';
import { ActivityVM, AssignToUserVM, getAllActivityRequestModel, Task } from '../../applicant.model';
import { ApplicantsService } from '../../applicants.service';
import { throwError } from 'rxjs';
import { UserListService } from 'src/app/components/adminConsole/users-management/user-list/user-list.service';
import { User } from 'src/app/components/adminConsole/users-management/user-list/user-list.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';
import { OperationTypeEnum } from '@services/header-title.service';
import * as moment from 'moment';
import { MasterConstant } from '@models/response.model';
import { HttpStatusCode } from '@angular/common/http';

export enum NumberOfDays{
  "15 Days" = 15,
  "30 Days" = 30,
  "60 Days" = 60,
}

@Component({
  selector: 'app-add-update-activity-log',
  templateUrl: './add-update-activity-log.component.html',
  styleUrls: ['./add-update-activity-log.component.scss']
})
export class AddUpdateActivityLogComponent implements OnInit {

  @Output() activityLogListRefresh: EventEmitter<boolean> = new EventEmitter();
  @Input() activityModalModel: ActivityVM;
  public operationTypeEnum = OperationTypeEnum;
  public operationId: OperationTypeEnum = OperationTypeEnum.View;
  isSaving: boolean = false;
  @Input() applicantId: number = 0;
  notEditable: boolean = false;
  userDetails: User[];
  assignToUsers: AssignToUserVM[];

  selectedNumberOfDays: number | null = null;
  showScheduleAndSaveButton  : boolean;
  numberOfDays = NumberOfDays;
  masterConstant = new MasterConstant();

  constructor(public applicantService: ApplicantsService,
    public userService: UserListService,
    public masterService: MasterService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService) { }
  
  activityModel = new ActivityVM();
  enumStage = StageEnum;
  modalTitle: string;
  getAllActivityRequestModel = new getAllActivityRequestModel();
  public task = Task;

  mastersVM = new MastersVM();
  public isLoading = false;
  ngOnInit(): void {
    this.loadMasters();
    this.activityModel = this.activityModalModel;
    this.setPage();
    this.loadAssignToUsersList();
  }

  setPage() {
    if (this.operationId == OperationTypeEnum.Create) {
      this.modalTitle = "New Activity";
      this.notEditable = false;
    }
    else if (this.operationId == OperationTypeEnum.Update) {
      this.modalTitle = "Edit Activity";
      this.notEditable = false;
    }
    else {
      this.modalTitle = "View Activity";
      this.notEditable = true;
    }
  }

  loadMasters() {
    this.masterService.getMasters()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
        this.isShowScheduleAndSaveButton();
      });
  }

  loadAssignToUsersList() {
    this.applicantService.getAssignToUsers(this.activityModel.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.assignToUsers = res.response;
      });
  }

  public CreateActivityLog(isShowScheduleAndSaveButton) {
    this.isLoading = true;
    this.applicantService.createActivity(this.activityModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.isLoading = false;
          this.toaster.success(res.message);
          this.activityLogListRefresh.emit(res.isSuccess);
          if(isShowScheduleAndSaveButton){
            this.activeModal.dismiss();
            this.showScheduleAndSaveButton = isShowScheduleAndSaveButton;
          }
          else{
            this.showScheduleAndSaveButton = isShowScheduleAndSaveButton;
            this.onSelectNumberOfDays()
            this.activityModel.id = 0;
            this.activityModel.dueDate = this.activityModel.completedDate;
            this.activityModel.completedDate = "";
            this.activityModel.details = "";
          }
        }
      });
  }

  onSelectNumberOfDays() {
    var selectedDate: Date = new Date(this.activityModel.completedDate);
    selectedDate.setDate(selectedDate.getDate() + this.selectedNumberOfDays);
    if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0) {
      selectedDate.setDate(selectedDate.getDate() - (selectedDate.getDay() === 6 ? 1 : 2));
    }
    this.activityModel.completedDate =  moment(selectedDate).format('YYYY-MM-DD');
  }

  isShowScheduleAndSaveButton():boolean{
    var SelectedItem = this.mastersVM.masterActivityType.masterValues.find(item => item.id == this.activityModel.activityTypesId);
    if(SelectedItem != undefined){
      this.showScheduleAndSaveButton = (this.activityModel.completedDate != '' && this.activityModel.completedDate != null && this.activityModel.completedDate != undefined) && SelectedItem.name == this.masterConstant.scheduledCall;
    }
    else{
      this.showScheduleAndSaveButton = false;
    }
    return this.showScheduleAndSaveButton;
  }

  resetAll() {
    this.activityModel = new ActivityVM();
  }

  setEnabled(): boolean {
    if(this.notEditable)
      return true;
    
    if (this.activityModel.dueDate === '' || this.activityModel.dueDate === undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  private handleError(error: any) {
    let messageString = error.error.message;
    if (error.status === 400) {
      let length = error.error.errors.length;
      (length > 0)
      {
        messageString = error.error.errors[length - 1].toString();
      }
    }
    else  if (error.status === HttpStatusCode.Forbidden) {
    } 
    else
    {
    this.toaster.error(messageString);
    }
    return throwError(error);
  }


}
