<div class="container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header py-3 border-bottom-0">
            <div class="d-flex align-items-center flex-wrap ">
            <h6 class="main-title mb-0">All Notifications</h6>
            <div  *ngIf="notifications != null" class="ml-auto">
              <button class="btn-save mr-2" (click)="markAllAsRead()">Mark All as read</button>
              <button class="btn-save" (click)="deleteAllNotification()">Delete All</button>
          </div>
          </div>

          </div>
          <div class="card-body py-0">
                <ul class="list-group">
                    <div class="card mb-3" *ngFor="let alert of notifications"  [ngClass]="{'':alert.isRead==true}">
                      <li class="list-group-item">
                          <h6 class="title">{{ alert.title }} <span class="pull-right time roboto-font">{{alert.createdAt+'Z' | date:"MM-dd-yy hh:mm a"}}</span></h6> 
                          <p class="message mb-0">{{alert.body}} </p>
                           <div>
                              <button class="btn-save mr-2 mt-2" *ngIf="!alert.isRead" (click)="markAsRead(alert.notificationId)">Mark as read</button>
                              <button class="btn-save mt-2" (click)="deleteNotification(alert.notificationId)">Delete</button>
                          </div>
                      </li>
                  </div>
                </ul>
          </div>
        </div>
      </div>
    </div>
</div>