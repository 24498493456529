import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../master/master.service';
import { MasterModel, SaveMasterModel, MasterValueVM, MasterTableVM } from '../master/master.model';
import { HeaderTitleService } from '@services/header-title.service';
import { MasterConstant, ResponseModel } from '@models/response.model';
import { HttpStatusCode } from '@angular/common/http';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit  {
  saveMasterModel: SaveMasterModel;
  masterModel = new MasterModel();

  masterConstant = new MasterConstant();
  

  constructor(public masterService: MasterService,
    private toaster: ToastrService,
    public headerTitleService: HeaderTitleService,) {}

  ngOnInit(): void {
    this.headerTitleService.title = "Master";
    if (this.headerTitleService.PermissionVM.menuAdminConsole) {
      this.loadMasters();
    }
  }
 
  loadMasters() {
    this.masterService.getAllMasters()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.masterModel = res.response;
      });
  }
  
  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  async updateStatus(item: MasterValueVM) {
    var isActive = item.isActive;
    Swal.fire({
      title: 'Do you want to save the changes?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Discard',
    }).then((result) => {
      if (result.isConfirmed) {
        item.isCheckBoxDisabled = true;
        this.masterService.saveMaster(item)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe(res => {
            item = res.response;
            item.isCheckBoxDisabled = false;
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        item.isActive = !isActive;
      }
    });
  }

  enableEdit(event, item: MasterValueVM) {
    item.isTextEditable = true;
    item.showSaveBtn = true;
    event.target.parentElement.previousSibling.focus();
  }

  saveData(item: MasterValueVM, master: MasterTableVM) {
    if (item.name == "") {
      Swal.fire('Name cannot be empty.');
      return false;
    }

    Swal.fire({
      title: 'Do you want to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Discard',
    }).then((result) => {
      if (result.isConfirmed) {
        item.isCheckBoxDisabled = true;
        item.isTextEditable = false;
        item.isSaving = true;
        item.isSaveBtnDisabled = true;

        this.masterService.saveMaster(item)
          .pipe(
            catchError(err => {
              item.isSaving = false;
              item.isTextEditable = true;
              item.isSaveBtnDisabled = false;
              return this.handleError(err);
            })
          )
          .subscribe(res => {
            item.isCheckBoxDisabled = false;
            item.isTextEditable = false;
            item.isSaving = false;
            item.showSaveBtn = false;
            item.isSaveBtnDisabled = false;
            item = res.response;
            master.hasAddedNewItem = false;
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  onlyNumbersLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)))) {
      return false;
    }
    return true;
  }

  addNewMasterItem(event, master: MasterTableVM) {
    if (master.hasAddedNewItem) {
      master.masterValues.pop();
      master.hasAddedNewItem = false;
      return;
    };

    var masterValueVM = new MasterValueVM();
    masterValueVM.id = 0;
    masterValueVM.name = "";
    masterValueVM.isActive = true;
    masterValueVM.description = "";
    masterValueVM.masterTableId = master.id;
    masterValueVM.isCheckBoxDisabled = true;
    masterValueVM.isTextEditable = true;
    masterValueVM.showSaveBtn = true;
    masterValueVM.isSaving = false;
    masterValueVM.isEditBtnDisabled = false;
    masterValueVM.isSaveBtnDisabled = false;

    master.masterValues.push(masterValueVM);
    master.hasAddedNewItem = true;
  }
}
