import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit {

  @Input() isAdminRoleSelected: boolean = false;
  @Input() list: any[];
  @Input() selectedAgencyList: any[];

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  @Output() hoverEvent = new EventEmitter<boolean>();
  showDropDown: boolean = false;

  checkedList: any[];
  currentSelected: {};
  checkIdsList: any[] = [];
  isDisabled: boolean = false;

  constructor() {
    this.checkedList = [];
    this.checkIdsList = [];
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.isDisabled = this.isAdminRoleSelected;

    if (this.selectedAgencyList?.length > 0) {
      var selectedAgencies = [];
      this.list.map(a => a.checked = false);
      if(this.selectedAgencyList[0] == 0)
      {
        selectedAgencies = this.list
      }
      else
      {
        selectedAgencies = this.list.filter(element => this.selectedAgencyList.includes(parseInt(element.id)));
      }

      selectedAgencies.map(a => a.checked = true);
      this.checkedList = selectedAgencies.map(a => a.name);
      this.checkIdsList = selectedAgencies.map(a => a.id);
    }
    else{
      // this.checkedList = [];
      // this.checkIdsList = [];
      this.list.forEach(a => {
        if (!a.isInUsed) {
          a.checked = false;
        }
      });
    }
  }

  checkAlreadyUsedAgency(item){
    var checkLengthforAll = this.list.filter(a => a.isAlreadyUsed == true).length;
      if (this.list.length - 1 == checkLengthforAll) {
        this.list[0].isAlreadyUsed = true;
      }
  }

  getSelectedValue(status: Boolean, value: String, id: number) {
    if (status) {
      if (value == 'All') {
        this.checkedList = [];
        this.checkedList = this.list.map(a => a.name);
        this.checkIdsList = this.list.map(a => a.id);
        this.list.map(a => a.checked = true);
      }
      else {
        this.checkedList.push(value);
        this.checkIdsList.push(id);
      }
      var checkLengthforAll = this.list.filter(a => a.checked == true).length;
      if (this.list.length - 1 == checkLengthforAll) {
        this.checkedList = ['All', ...this.checkedList];
        this.checkIdsList = [0, ...this.checkIdsList];
        this.list[0].checked = true;
      }
    }
    else {
      var index = this.checkedList.indexOf(value);
      if (index == 0 && value == 'All') {
        var a = this.list.filter(a=>a.isInUsed == true);
        this.checkedList = a.map(a=>a.name)??[];
        this.checkIdsList = a.map(a=>a.id)??[];
        this.list.forEach(a => {
          if (!a.isInUsed) {
            a.checked = false;
          }
        });
      }
      else if (index != -1 && value != 'All') {
        this.list[0].checked = false;
        this.checkedList.splice(index, 1);
        this.checkIdsList.splice(index, 1);
      }

    }

    this.currentSelected = { checked: status, name: value };

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkIdsList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  onClickDisabledAgecny(item) {
    if (item.isInUsed) {
      this.hoverEvent.emit(true);
    }
    else{
      this.hoverEvent.emit(false);
    }
  }

}
