import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxDatatableSSRConfig } from "@models/ngx-datatable-ssr-config.model";
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { StageVM } from '../../reports/report.model'
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../reports/report.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../../shared/services/export_service/excel.service';
import {HeaderTitleService} from '@services/header-title.service';
import { PaginationBaseModel } from '@models/pagination.model';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { HttpStatusCode } from '@angular/common/http';

    @Component({
      selector: 'app-stages-report',
      templateUrl: './stages-report.component.html',
      styleUrls: ['./stages-report.component.scss'],
      providers: [NgbDropdownConfig]
    })
    export class StagesReportComponent implements OnInit {
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  
    fromDate: any;
    toDate: any;
    model: any;
    rows: StageVM[] = [];
    getAllAgencyRequestModel = new PaginationBaseModel();
    totalRows: StageVM[] = [];
  

  constructor(config: NgbDropdownConfig, private renderer: Renderer2, 
  
    private toaster: ToastrService,
    private headerTitleService: HeaderTitleService,
    public service: ReportService,
    private excelService: ExcelService,
    private _parserFormatter: NgbDateParserFormatter,) {
    config.placement = 'top-left';
    config.autoClose = false;
   }
   ngAfterViewInit(): void {
    this.loadStageData();
  }

  ngOnInit(): void {
    this.headerTitleService.title='Stage Report'
    this.initDatatable()
  }
 
  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.getAllAgencyRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: true
     
    };
  }

  loadStageData(): void{
    this.service.getStageData(this.getAllAgencyRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
    });
  }

  downloadStageReportData(fileType): void{
    this.getAllAgencyRequestModel.getAll = true;
    this.service.getStageData(this.getAllAgencyRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.totalRows = res.response;
      if(fileType == 'PDF'){
        this.downloadAsPDF();
      }
      else if(fileType == 'Excel'){
        this.exportAsXLSX();
      }
    });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

   // for export as Excel
   exportAsXLSX(): void {
    var filename = this.headerTitleService.title
    this.excelService.exportAsExcelFile(this.totalRows,filename);
  }
  organise(arr) {
    var headers = [], // an Array to let us lookup indicies by group
      objs = [], // the Object we want to create
      i,
      j;
    for (i = 0; i < arr.length; ++i) {
      j = headers.indexOf(arr[i].id); // lookup
      if (j === -1) {
        // this entry does not exist yet, init
        j = headers.length;
        headers[j] = arr[i].id;
        objs[j] = {};
        objs[j].id = arr[i].id;
        objs[j].data = [];
      }
      objs[j].data.push(
        // create clone
        {
          case_worked: arr[i].case_worked,
          note: arr[i].note,
          id: arr[i].id,
        }
      );
    }
    return objs;
  }

  getBody(): any[]{
    let data = [];
    this.totalRows.forEach((s,index)=>data.push([index+1,s.stageName,s.applicantCount]));
    return data;
  }
  
  public downloadAsPDF() {
  const doc = new jsPDF('l', 'mm', 'a4')
  autoTable(doc, {
   
    head: [['#','StageName','Applicant Count']],
    body: this.getBody(),
  })
  doc.save(this.headerTitleService.title+ '-'+ moment(new Date()).format('YYYY-MM-DD'));

  }
}
 






