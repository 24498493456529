import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../../shared/models/response.model';
import { DataService } from '../../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  constructor(private service: DataService) { }

  // get preparePermissionMapping
  getAllPermissions(): Observable<ResponseModel> {
    let url = '/identityapi/Permission/PreparePermissionMapping';
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // update permissionSave
  SavePermission(body: any): Observable<boolean> {
    let url = '/identityapi/Permission/PermissionSave';
    return this.service.post(url, body).pipe<boolean>(tap((response: any) => {
      return response;
    }));
  }

    // get getPermissionAccessByUser
    getPermissionAccessByUser(): Observable<ResponseModel> {
      let url = '/identityapi/Permission/GetPermissionAccessByUser';
      return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
        return response;
      }));
    }
}
