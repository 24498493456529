import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { Task } from 'src/app/components/applicants/applicant.model';

@Component({
  selector: 'app-activity-log-tab',
  templateUrl: './activity-log-tab.component.html',
  styleUrls: ['./activity-log-tab.component.scss']
})
export class ActivityLogTabComponent implements OnInit {
  sub: any;
  tabId: number = 1;
  task: Task;
  constructor(public storageservice: StorageService,
    private activateRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this.tabId = this.storageservice.retrieve('activityLogTID');
  }

  public onGoToPage(tabId)
  {
    this.storageservice.store('activityLogTID', tabId);
  }
}
