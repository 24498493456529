import { Component, OnInit, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UserRolesService } from "./user-roles.service";
import { UserRole } from "./user-roles.model";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { StorageService } from '@services/storage.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class UserRolesComponent implements OnInit {
  rows: any[];
  temp = [];
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;
  modalTitle: string;
  isSaving: boolean = false;
  modalData: UserRole;

  constructor(public service: UserRolesService, 
              private config: NgbModalConfig,
              private modalService: NgbModal,
              public storageService: StorageService,
              private toaster: ToastrService) 
  {
    this.config.centered = true;
  }

  ngOnInit(): void {
    this.storageService.store('userManagementTID', 1);
    this.loadListData();
  }  

  loadListData(){
    this.service.getAllRoles('')
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      this.temp = res.response;
    });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  openModal(modalId:any, userRole: UserRole = null) {
    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new UserRole();
    if(userRole !== null){
      this.modalTitle = "Edit Role";
      this.modalData.id = userRole.id;
      this.modalData.name = userRole.name;
      this.modalData.isActive = userRole.isActive;
    }
    else{
      this.modalTitle = "Add New Role";
    }
    this.modalService.open(modalId);
  }

  saveData(modalData: UserRole, modal:any){
    this.isSaving = true;
    this.service.saveRole(modalData)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if(res.isSuccess){
          this.loadListData();
          modal.close();
          this.toaster.success(res.message);
        }
        else{
          this.toaster.error(res.message);
        }
        this.isSaving = false;        
      });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (index) {
      return (index.name.toLowerCase().indexOf(val) !== -1 ||
        !val);
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }
}
