import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TopTimelineData } from '../edit-timeline/edit-timeline.model';
import { EditTimelineService } from '../edit-timeline/edit-timeline.service';
import { ApplicantsService } from '../applicants.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-edit-applicant-header',
  templateUrl: './edit-applicant-header.component.html',
  styleUrls: ['./edit-applicant-header.component.scss']
})
export class EditApplicantHeaderComponent implements OnInit {
  @Input() applicantId : number = 0;
  toptimeline = new TopTimelineData();
  hidden:boolean=false;
  constructor
  (
    private toaster: ToastrService,
    public service: EditTimelineService,
    public applicantservice :ApplicantsService,
  ) { }

  ngOnInit(): void {
    this.getTopTimelineData();
    this.applicantservice.applicant.subscribe((res=>{
      this.getTopTimelineData();
    }))
  }

  getTopTimelineData() {
    this.service.getTopTimelineData(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if(res.isSuccess==true){
          this.toptimeline = res.response;
        }
      });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
    }
    
  topTimelineRefreshfn(event)
  {
    if(event)
    {
      this.getTopTimelineData()
    }
  }
}
