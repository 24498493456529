<!-- content starts-->
<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.viewActivityLogs">
  <div class="">
    <div class="row d-flex align-items-start">
      <div class="col-sm-12 col-xl-12 h-100 ">
        <div class="card shadow border-radius-top-0">
          <div class="default-according p-t-30 pb-3  px-4" id="accordion">
            <div class="row theme-font-color-blue font-weight-bold f-14 roboto-font mx-0">
              <span class="col-xl-10 col-sm-9 col-6 text-nowrap align-self-center pl-0">Activity Tracker</span>
              <div class="col-xl-2 col-sm-3 col-6 px-0">
                <div *ngIf="headerTitleService.PermissionVM.auditLog" class="pull-right ml-2">
                  <svg (click)="openAuditLog()" type="button" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                    <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
                      <g id="Group_605" data-name="Group 605">
                        <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7" transform="translate(10.764 3)" fill="#a1a1a1"/>
                        <path id="XMLID_32_" d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z" transform="translate(14.762 -10.655)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <button class="button-small pull-right" *ngIf="headerTitleService.PermissionVM.addActivityLogs" (click)="openModal()">
                  <svg id="checked" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                    viewBox="0 0 14.848 14.848">
                    <g id="Group_166" data-name="Group 166" transform="translate(3.525 1.865)">
                      <g id="Group_165" data-name="Group 165">
                        <path id="Path_49" data-name="Path 49"
                          d="M132.465,64.492a.559.559,0,0,0-.791,0l-6.713,6.7-2.422-2.631a.56.56,0,0,0-.823.758l2.817,3.059a.559.559,0,0,0,.4.18h.012a.56.56,0,0,0,.4-.163l7.125-7.107A.559.559,0,0,0,132.465,64.492Z"
                          transform="translate(-121.568 -64.327)" fill="#efefef" />
                      </g>
                    </g>
                    <g id="Group_168" data-name="Group 168">
                      <g id="Group_167" data-name="Group 167">
                        <path id="Path_50" data-name="Path 50"
                          d="M14.288,6.864a.56.56,0,0,0-.56.56,6.3,6.3,0,1,1-6.3-6.3A.56.56,0,1,0,7.424,0a7.424,7.424,0,1,0,7.424,7.424A.56.56,0,0,0,14.288,6.864Z"
                          fill="#efefef" />
                      </g>
                    </g>
                  </svg>
                  <span class="text-nowrap">New Activity</span>
                </button>
              </div>
            </div>
            <hr class="my-2">
            <div class="ngxdt activity-list">
              <div class="ngxdt-header">
                <div style="flex: 1 1 auto; font-weight: 600;" class="theme-font-color-dark f-family-oswald">
                  Today’s Tasks
                  <hr class="mt-1">
                </div>
              </div>
              <div class="ngxdt-body">
                <ngx-datatable #table 
                  class="bootstrap material fullscreen"
                  [footerHeight]="ngxdtConfigTodays.footerHeight" 
                  [limit]="ngxdtConfigTodays.pageSize == 1?ngxdtConfigTodays.totalRecords:ngxdtConfigTodays.pageSize"
                  [rows]="ngxdtConfigTodays.rows" 
                  [columnMode]="ngxdtConfigTodays.columnMode.flex"
                  [offset]="ngxdtConfigTodays.tableOffset"
                  [scrollbarH]="true"
                  [scrollbarV]="true" 
                  [virtualization]="false">
                  <ngx-datatable-column [width]="5" minWidth="18" [flexGrow]="0.25" headerClass="p-0" cellClass="p-0" prop="id"
                    name="" [sortable]="false" [draggable]="false">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                      <div class="action-btn">
                        <button class="btn-small bg-light-gray rounded-circle" disabled>
                          <i class="fa fa-circle"></i>
                        </button>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="200"  minWidth="100" [flexGrow]="1" prop="activityTitle" name="Activity"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.1" prop="activityTypeName" name="Type"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1" prop="dueDate" name="Due Date" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value | date:'MM/dd/yy'}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="completedDate" name="Completion Date"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value | date:'MM/dd/yy'}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="assignToName" name="Assigned To"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="405" minWidth="200" [flexGrow]="2.4" prop="details" name="Details" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="90" minWidth="70" [flexGrow]="0.90" [sortable]="false" [draggable]="false">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                      <div class="action-btn">
                        <button *ngIf="!headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.View)" class="bg-yellow" title="View">
                          <i class="fa fa-search"></i>
                        </button>
                        <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.Update)" class="bg-gray" title="Edit">
                          <i class="fa fa-edit"></i>
                        </button>
                        <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="deleteActivity(row)" class="bg-red" title="Delete">
                          <i class="icofont icofont-close"></i>
                        </button>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                      <div>
                        <div class="page-size-control">
                          Show
                          <select class="page-size-dropdown" [(ngModel)]="ngxdtConfigTodays.pageSize"
                            (ngModelChange)="onPageSizeChanged($event, task.todays)">
                            <option *ngFor="let opt of ngxdtConfigTodays.limitOptions" [ngValue]="opt.value">{{opt.key}}
                            </option>
                          </select>
                          entries
                        </div>
                        <div class="pagination-control">
                          <ngb-pagination #ngbPage [boundaryLinks]="false"
                            [collectionSize]="ngxdtConfigTodays.totalRecords" [pageSize]="ngxdtConfigTodays.pageSize == 1?ngxdtConfigTodays.totalRecords:ngxdtConfigTodays.pageSize"
                            [page]="ngxdtConfigTodays.currentPageNumber" [maxSize]="5"
                            (pageChange)="onPageChanged($event, task.todays)">
                          </ngb-pagination>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>

                </ngx-datatable>
              </div>
              <div class="ngxdt-footer">

              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>


    <div class="card shadow">
      <div class="default-according px-4 py-3" id="accordion">
        <div class="ngxdt activity-list">
          <div class="ngxdt-header">
            <div style="flex: 1 1 auto; font-weight: 600;" class="theme-font-color-red f-family-oswald">
              Incomplete Tasks
              <hr class="mt-1 hr-red">
            </div>
          </div>
          <div class="ngxdt-body">
            <ngx-datatable #table 
              class="bootstrap material fullscreen"
              [footerHeight]="ngxdtConfigIncomplate.footerHeight" 
              [limit]="ngxdtConfigIncomplate.pageSize== 1?ngxdtConfigIncomplate.totalRecords:ngxdtConfigIncomplate.pageSize"
              [rows]="ngxdtConfigIncomplate.rows" 
              [columnMode]="ngxdtConfigIncomplate.columnMode.flex"
              [offset]="ngxdtConfigIncomplate.tableOffset"
              [scrollbarH]="true"
              [scrollbarV]="true"
              [virtualization]="false">
              <ngx-datatable-column [width]="5" minWidth="18" [flexGrow]="0.25" headerClass="p-0" cellClass="p-0" prop="id" name=""
                [sortable]="false" [draggable]="false">
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <div class="action-btn">
                    <button class="btn-small bg-light-gray rounded-circle" disabled>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="200" minWidth="100" [flexGrow]="1" cellClass="font-weight-500" prop="activityTitle"
                name="Activity" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column [width]="150" minWidth="100"[flexGrow]="1.1" prop="activityTypeName" name="Type"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1" prop="dueDate" name="Due Date" [sortable]="false"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'MM/dd/yy'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="completedDate" name="Completion Date"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'MM/dd/yy'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="assignToName" name="Assigned To"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="405" minWidth="200" [flexGrow]="2.4" prop="details" name="Details" [sortable]="false"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="90" minWidth="70" [flexGrow]="0.90" [sortable]="false" [draggable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>                  
                  <div class="action-btn">
                    <button *ngIf="!headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.View)" class="bg-yellow" title="View">
                      <i class="fa fa-search"></i>
                    </button>
                    <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.Update)" class="bg-gray" title="Edit">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="deleteActivity(row)" class="bg-red" title="Delete">
                      <i class="icofont icofont-close"></i>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxdtConfigIncomplate.pageSize"
                        (ngModelChange)="onPageSizeChanged($event, task.inCompleted)">
                        <option *ngFor="let opt of ngxdtConfigIncomplate.limitOptions" [ngValue]="opt.value">{{opt.key}}
                        </option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination #ngbPage 
                        [boundaryLinks]="false"
                        [collectionSize]="ngxdtConfigIncomplate.totalRecords"
                        [pageSize]="ngxdtConfigIncomplate.pageSize== 1?ngxdtConfigIncomplate.totalRecords:ngxdtConfigIncomplate.pageSize"
                        [page]="ngxdtConfigIncomplate.currentPageNumber" 
                        [maxSize]="5"
                        (pageChange)="onPageChanged($event, task.inCompleted)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>

            </ngx-datatable>
          </div>          
        </div>
      </div>
    </div>

    <div class="card shadow">
      <div class="default-according px-4 py-3" id="accordion">
        <div class="ngxdt activity-list">
          <div class="ngxdt-header">
            <div style="flex: 1 1 auto; font-weight: 600;" class="theme-font-color-lightblue f-family-oswald">
              Upcoming Tasks
              <hr class="mt-1 hr-light-blue">
            </div>
          </div>
          <div class="ngxdt-body">
            <ngx-datatable #table class="bootstrap material fullscreen"
              [footerHeight]="ngxdtConfigUpcoming.footerHeight" [limit]="ngxdtConfigUpcoming.pageSize== 1?ngxdtConfigUpcoming.totalRecords:ngxdtConfigUpcoming.pageSize"
              [rows]="ngxdtConfigUpcoming.rows" [columnMode]="ngxdtConfigUpcoming.columnMode.flex"
              [offset]="ngxdtConfigUpcoming.tableOffset" [scrollbarH]="true"
              [scrollbarV]="true" [virtualization]="false">
              <ngx-datatable-column [width]="5" minWidth="18" [flexGrow]="0.25" headerClass="p-0" cellClass="p-0" prop="id" name=""
                [sortable]="false" [draggable]="false">
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <div class="action-btn">
                    <button class="btn-small bg-light-gray rounded-circle" disabled>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="200" minWidth="100" [flexGrow]="1" cellClass="font-weight-500" prop="activityTitle"
                name="Activity" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.1" prop="activityTypeName" name="Type"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1" prop="dueDate" name="Due Date" [sortable]="false"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'MM/dd/yy'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="completedDate" name="Completion Date"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'MM/dd/yy'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="150" minWidth="100" [flexGrow]="1.5" prop="assignToName" name="Assigned To"
                [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [width]="405" minWidth="200" [flexGrow]="2.4" prop="details" name="Details" [sortable]="false"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="90" minWidth="70" [flexGrow]="0.90" [sortable]="false" [draggable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  <div class="action-btn">
                    <button *ngIf="!headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.View)" class="bg-yellow" title="View">
                      <i class="fa fa-search"></i>
                    </button>
                    <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.Update)" class="bg-gray" title="Edit">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="deleteActivity(row)" class="bg-red" title="Delete">
                      <i class="icofont icofont-close"></i>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxdtConfigUpcoming.pageSize"
                        (ngModelChange)="onPageSizeChanged($event, task.upcoming)">
                        <option *ngFor="let opt of ngxdtConfigUpcoming.limitOptions" [ngValue]="opt.value">{{opt.key}}
                        </option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination #ngbPage [boundaryLinks]="false"
                        [collectionSize]="ngxdtConfigUpcoming.totalRecords"
                        [pageSize]="ngxdtConfigUpcoming.pageSize== 1?ngxdtConfigUpcoming.totalRecords:ngxdtConfigUpcoming.pageSize"
                        [page]="ngxdtConfigUpcoming.currentPageNumber" [maxSize]="5"
                        (pageChange)="onPageChanged($event, task.upcoming)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>

            </ngx-datatable>
          </div>          
        </div>

      </div>
      <div class="ngxdt-footer">

      </div>
    </div>
  </div>

<div class="card shadow">
  <div class="default-according px-4 py-3" id="accordion">
    <div class="ngxdt activity-list">
      <div class="ngxdt-header">
        <div style="flex: 1 1 auto; font-weight: 600;" class="theme-font-color-dark f-family-oswald">
          Completed Tasks
          <hr class="mt-1 hr-gray">
        </div>

      </div>
      <div class="ngxdt-body">
        <ngx-datatable #table class="bootstrap material fullscreen" [footerHeight]="ngxdtConfigCompleted.footerHeight"
          [limit]="ngxdtConfigCompleted.pageSize== 1?ngxdtConfigCompleted.totalRecords:ngxdtConfigCompleted.pageSize" [rows]="ngxdtConfigCompleted.rows"
          [columnMode]="ngxdtConfigCompleted.columnMode.flex" [offset]="ngxdtConfigCompleted.tableOffset" 
          [scrollbarH]="true"
          [scrollbarV]="true"
          [sortType]="'single'"
          (sort)="onSort($event)"
          [externalSorting]="true"
          [virtualization]="false">
          <ngx-datatable-column [width]="5" minWidth="18" [flexGrow]="0.25" headerClass="p-0" cellClass="p-0" prop="id" name=""
            [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="action-btn">
                <button class="btn-small bg-green rounded-circle" disabled>
                  <i class="icofont icofont-check-alt"></i>
                </button>
              </div>
              <!-- <input type="radio" [checked]="value" class="custom-radio" disabled> -->
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="200" [flexGrow]="1" minWidth="100" cellClass="font-weight-500" prop="activityTitle"
            name="Activity" [sortable]="false" [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div title="{{ value }}">
                {{ value }}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="150" [flexGrow]="1.1"  minWidth="100" prop="activityTypeName" name="Type" [sortable]="false"
            [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div title="{{ value }}">
                {{ value }}
              </div>
            </ng-template>
          </ngx-datatable-column>          

          <ngx-datatable-column [width]="150" minWidth="75" [flexGrow]="1" prop="dueDate" name="Due Date" [sortable]="true"
            [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ value | date:'MM/dd/yy'}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="150" minWidth="75" [flexGrow]="1.5" prop="completedDate" name="Completion Date"
            [sortable]="true" [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ value | date:'MM/dd/yy'}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="150" minWidth="75" [flexGrow]="1.5" prop="assignToName" name="Assigned To" [sortable]="false"
            [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div title="{{ value }}">
                {{ value }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [width]="405" minWidth="150" [flexGrow]="2.4" prop="details" name="Details" [sortable]="false"
            [draggable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div title="{{ value }}">
                {{ value }}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="90" minWidth="70" [flexGrow]="0.90" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div class="action-btn">
                <button *ngIf="!headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.View)" class="bg-yellow" title="View">
                  <i class="fa fa-search"></i>
                </button>
                <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="openModal(row, operationId.Update)" class="bg-gray" title="Edit">
                  <i class="fa fa-edit"></i>
                </button>
                <button *ngIf="headerTitleService.PermissionVM.editActivityLogs" (click)="deleteActivity(row)" class="bg-red" title="Delete">
                  <i class="icofont icofont-close"></i>
                </button>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <div>
                <div class="page-size-control">
                  Show
                  <select class="page-size-dropdown" [(ngModel)]="ngxdtConfigCompleted.pageSize"
                    (ngModelChange)="onPageSizeChanged($event, task.completed)">
                    <option *ngFor="let opt of ngxdtConfigCompleted.limitOptions" [ngValue]="opt.value">{{opt.key}}
                    </option>
                  </select>
                  entries
                </div>
                <div class="pagination-control">
                  <ngb-pagination #ngbPage [boundaryLinks]="false"
                    [collectionSize]="ngxdtConfigCompleted.totalRecords"
                    [pageSize]="ngxdtConfigCompleted.pageSize== 1?ngxdtConfigCompleted.totalRecords:ngxdtConfigCompleted.pageSize"
                    [page]="ngxdtConfigCompleted.currentPageNumber" [maxSize]="5"
                    (pageChange)="onPageChanged($event, task.completed)">
                  </ngb-pagination>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-footer>

        </ngx-datatable>
      </div>      
    </div>
  </div>
</div>

</div>