import { Component, ViewEncapsulation, HostListener, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { StorageService } from '@services/storage.service';
import { HeaderTitleService } from '@services/header-title.service';
import { UserPermissionsService } from 'src/app/components/adminConsole/users-management/user-permissions/user-permissions.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public iconSidebar;
  public menuItems: Menu[] = [];
  public url: any;
  public fileurl: any;
  
 
  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  menuItem: any;
  adminMenus = ['Admin Console','Reports'];
  
  public href: string = "";

  constructor(
    private router: Router, 
    public navServices: NavService,
    public storageService: StorageService,
    public headerTitleService: HeaderTitleService,
    public layout: LayoutService,
    public userPermissionsService: UserPermissionsService,
    private toaster: ToastrService,) 
  {
    this.GetPermissionAccessByUser()
  }
  ngOnInit(): void {
    // this.GetPermissionAccessByUser()
    // this.menuItems = this.navServices.MENUITEMS
    this.href = this.router.url;
    if(this.href == '/master/users-management'){
      this.menuItems[4].active = true;
    }
    if(this.href == '/master'){
      this.menuItems[4].active = true;
    }
    
  }

  // ngOnInit(): void {
  // this.GetPermissionAccessByUser()
  // }

  GetPermissionAccessByUser() {
    this.userPermissionsService.getPermissionAccessByUser()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess === true) {
        this.headerTitleService.PermissionVM = res.response;
        this.storageService.store('permissionVM',res?.response);
        this.navServices.items.subscribe(menuItems => {
          
          this.menuItems = this.getMenuListByPermission(menuItems);

          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.menuItems.filter(items => {
                if (items.path === event.url) {
                  this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {

                  if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                  }
                  if (!subItems.children) { return false; }
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems);
                    }
                  });
                });
              });
            }
          });
        });
        }
      });
  }

  getMenuListByPermission(menuItems: Menu[]): Menu[] {

    if(!this.headerTitleService.PermissionVM.menuDashboard){
      menuItems = menuItems.filter(s=> !['Dashboard'].includes(s.title));
    }

    if(!this.headerTitleService.PermissionVM.menuApplicantList){
      menuItems = menuItems.filter(s=> !['Applicant List'].includes(s.title));
    }

    if(!this.headerTitleService.PermissionVM.menuNewApplicant){
      menuItems = menuItems.filter(s=> !['New Applicant'].includes(s.title));
    }

    if(!this.headerTitleService.PermissionVM.menuReports){
      menuItems = menuItems.filter(s=> !['Reports'].includes(s.title));
    }

    if(!this.headerTitleService.PermissionVM.menuAdminConsole){
      menuItems = menuItems.filter(s=> !['Admin Console'].includes(s.title));
    }
    return menuItems;
  }
  

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }
  
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
