import { Component, Input, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ApplicantModel } from '../applicant.model';
import { ApplicantsService } from '../applicants.service';
import { HeaderTitleService } from '@services/header-title.service';
import { StorageService } from '@services/storage.service';
import { HttpStatusCode } from '@angular/common/http';
@Component({
  selector: 'app-edit-applicant-information',
  templateUrl: './edit-applicant-information.component.html',
  styleUrls: ['./edit-applicant-information.component.scss']
})
export class EditApplicantInformationComponent implements OnInit {
  @Input() applicantId: number = 0;
  applicantModel = new ApplicantModel();
  mobilePattern = "^[0-9]{4}-[0-9]{3}-[0-9]{3}$";


  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  isSaving_Applicant: boolean;

  constructor(private toaster: ToastrService,
    public storageservice: StorageService,
    public applicantService: ApplicantsService,
    public headerTitleService: HeaderTitleService) { }

  ngOnInit(): void {
    this.applicantService.ResetAll();
    this.storageservice.store('applicantDetailsTID', 1);
    if (this.headerTitleService.PermissionVM.applicantInformation) {
      this.getApplicantInformation();
    }
  }

  getApplicantInformation() {
    this.applicantService.getApplicantInformation(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.applicantModel = res.response;
          this.applicantService.personalInfoModel = this.applicantModel.personalInfo;
          this.applicantService.personalInfoModel.age = this.applicantService.calculateAge(this.applicantService.personalInfoModel.dob);

          this.applicantService.familyInfoModel = this.applicantModel.familyInfo;
          this.applicantService.familyInfoModel.spouseInfo.age = this.applicantService.calculateAge(this.applicantService.familyInfoModel.spouseInfo.dob);
          this.applicantService.familyInfoModel.numberOfChildren = this.applicantModel.familyInfo.childrens.length;
          this.applicantService.familyInfoModel.numberOfChildrenOLD = this.applicantModel.familyInfo.childrens.length;

          this.applicantService.familyInfoModel.childrens.forEach(element => {
            element.child_Age = this.applicantService.calculateAge(element.dob);
          });

          this.applicantService.backgroundInfoModel = this.applicantModel.backgroundInfo;
          this.applicantService.programInfoModel = this.applicantModel.programInfo;
          this.applicantService.immigrationInfoModel = this.applicantModel.immigrationInfo;
          this.applicantService.paymentInfoModel = this.applicantModel.paymentInfo;
          this.applicantService.notesModel = this.applicantModel.notes;
          this.applicantService.updateSponsorEditableStatus();
        }

      });
  }

  private handleError(error: any) {
    this.isSaving_Applicant = false;
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
    }
    
  onlyNumbersLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)))) {
      return false;
    }
    return true;
  }
  PhoneNumberOnBlur(event) {
    const originalValue = event.target.value;
    var a = event.target.value.split("-");
    var aa = a.join("");
    let v1 = aa.slice(0, 4);
    let v2 = aa.slice(4, 7);
    let v3 = aa.slice(7, 10);
    event.target.value = `${v1}-${v2}-${v3}`;
    if (event.target.value == "--") {
      event.target.value = null;
    }
  }
}