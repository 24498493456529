<!-- content starts-->
<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.menuAdminConsole">
  <div class="card ">
      <div class="card-body">
          <div class="masters-list-wrapper"> 
              <div class="masters">                  
                  <div *ngFor="let master of masterModel.masterTables" class="masters-list-item">
                    <div class="card">
                      <div class="card-header">
                        <div class="masters-list-item-heading">
                          <span class="masters-list-item-heading-title">{{master.name}}</span>
                          <hr>
                          <span class="masters-list-item-heading-description" [title]="master.description">{{master.description}}</span>
                        </div>
                      </div>
                      <div class="card-body">
                        <ul class="masters-list overflow-auto">                              
                          <li *ngFor="let item of master.masterValues" class="list-group-item master-list-item">
                            <div class="master-list-item-wrapper">
                              <input [ngClass]="{'visiblity-hidden':item.name == masterConstant.scheduledCall}" type="checkbox" [disabled]="item.isCheckBoxDisabled" [(ngModel)]="item.isActive" (change)="updateStatus(item)" [checked]="item.isActive"  [id]="item.id" class="checkbox_animated master-list-item-checkbox">
                              <input [readOnly]="master.name == masterConstant.activityType && item.name == masterConstant.scheduledCall" [disabled]="!item.isTextEditable" [(ngModel)]="item.name" type="text" name="Name" class="master-list-item-inputbox" value="{{item.name}}" (keypress)="onlyNumbersLettersAllowed($event)"/>
                              <button *ngIf="item.name != masterConstant.scheduledCall" (click)="enableEdit($event, item)" [hidden]="item.showSaveBtn" class="btn btn-transparent master-list-item-btn">
                                <i class="fa fa-edit"></i>
                              </button>
                              <button (click)="saveData(item,master)" [disabled]="item.isSaveBtnDisabled" [hidden]="!item.showSaveBtn" class="btn btn-transparent master-list-item-btn">
                                <i class="fa"  [ngClass]="{'fa-floppy-o':!item.isSaving, 'fa-spin':item.isSaving, 'fa-spinner':item.isSaving }"></i>
                              </button>
                            </div>
                          </li>                          
                        </ul>
                      </div>
                      <div class="card-footer p-0">
                        <button (click)="addNewMasterItem($event, master)" class="btn btn-block btn-transparent text-center" type="button">
                          <i class="fa " [ngClass]="master.hasAddedNewItem?'fa-times text-danger':'fa-plus-square-o'"></i>
                          <span class="ml-2" [ngClass]="{'text-danger':master.hasAddedNewItem}">{{master.hasAddedNewItem?'Cancel':'Add New Field'}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<app-user-access-permission [userPermission]="headerTitleService.PermissionVM.menuAdminConsole"></app-user-access-permission>
<!-- content Ends-->
