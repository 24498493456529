<div class="container-fluid theme-font-size">
  <div class="row">
      <div class="col-xl-12 px-0">
          <div class="card border-radius-top-0 p-t-15 p-b-40">
              <div>  
                  <div class="ngxdt">
                    <div class="ngxdt-body px-4">
                      <ngx-datatable
                        #table
                        class="bootstrap"
                        [limit]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize"
                        [rows]="ngxDatatableSSRConfig.rows"
                        [columnMode]="ngxDatatableSSRConfig.columnMode.force"
                        [offset]="ngxDatatableSSRConfig.tableOffset"
                        [scrollbarH]="true"
                        [scrollbarV]="true"
                      >
                        <ngx-datatable-column [width]="200" prop="documentTypesName" name="Document Type" headerClass="pl-5" cellClass="pl-5" [sortable]="false" [draggable]="false">
                          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ value }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="300" prop="fileName" name="File Name" [sortable]="true" [draggable]="false">
                          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div [title]="value">{{ value }}</div>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="70" prop="size" name="File Size"  [sortable]="true" [draggable]="false">
                          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ viewAllDocumentsService.formatBytes(value) }}
                          </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column [width]="150" prop="documentCreatedTime" name="Date Uploaded"  [sortable]="true" [draggable]="false">
                          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ value+'Z' | date:'MM/dd/yy' }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="150" prop="" name=""  [sortable]="false" [draggable]="false">
                          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div class="action-btn">
                              <button (click)="viewDocument(row)" *ngIf="headerTitleService.PermissionVM.documentsView" class="bg-yellow" title="View">
                                <i class="fa fa-search"></i>
                              </button>
                              <button *ngIf="!headerTitleService.PermissionVM.documentsView" ngbTooltip="Access Restricted" triggers="click" [autoClose]="true" placement="left" class="bg-yellow">
                                <i class="fa fa-search"></i>
                              </button>

                              <button (click)="editDocument(row, modalIdUpdateDocument)" *ngIf="headerTitleService.PermissionVM.documentsEdit" class="bg-gray" title="Edit">
                                <i class="fa fa-edit"></i>
                              </button>
                              <button *ngIf="!headerTitleService.PermissionVM.documentsEdit" ngbTooltip="Access Restricted" triggers="click" [autoClose]="true" placement="left" class="bg-gray">
                                <i class="fa fa-edit"></i>
                              </button>

                              <button (click)="downloadDocument(row)" *ngIf="headerTitleService.PermissionVM.documentsDownload" class="bg-blue" title="Download">
                                <i class="fa fa-download"></i>
                              </button>
                              <button *ngIf="!headerTitleService.PermissionVM.documentsDownload" ngbTooltip="Access Restricted" triggers="click" [autoClose]="true" placement="left" class="bg-blue">
                                <i class="fa fa-download"></i>
                              </button>

                              <button (click)="deleteDocument(row)" *ngIf="headerTitleService.PermissionVM.documentsDelete" class="bg-red" title="Delete">
                                <i class="icofont icofont-close"></i>
                              </button>
                              <button *ngIf="!headerTitleService.PermissionVM.documentsDelete" ngbTooltip="Access Restricted" triggers="click" [autoClose]="true" placement="left" class="bg-red">
                                <i class="icofont icofont-close"></i>
                              </button>

                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>
                              <div>
                                <div class="page-size-control">
                                  Show
                                  <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize" (ngModelChange)="onPageSizeChanged($event)">
                                    <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                                  </select>
                                  entries
                                </div>
                                <div class="pagination-control">
                                  <ngb-pagination
                                        #ngbPage
                                        [boundaryLinks]="false"
                                        [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                                        [pageSize]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize"
                                        [page]="ngxDatatableSSRConfig.currentPageNumber"
                                        [maxSize]="5"
                                        (pageChange)="onPageChanged($event)">
                                  </ngb-pagination>
                                </div>
                              </div>
                            </ng-template>
                        </ngx-datatable-footer> -->
                
                      </ngx-datatable>
                    </div>
                  </div>
                </div>
          </div>
      </div>
  </div>
</div>

<!-- Modal popup for update document-->
<ng-template #modalIdUpdateDocument let-modal>
  <div class="document-modal-wrapper p-3">
    <div class="modal-header">
      <span class="modal-title mb-1">Edit Document</span>
      <span class="modal-close-btn" (click)="closeModal(modal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
          <path d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z" transform="translate(0 -0.001)" fill="#a1a1a1"/>
        </svg>
      </span>
    </div>
    <div class="modal-body pt-5 pb-0 px-0 document-screens">
      
      <div class="screen2">
        <div class="row h100">
          <div class="col-xl-5">
            <div class="content-wrapper mx-0 mx-md-4 px-md-5 px-2 left-section h100">
              <div class="preview-wrapper p-2 d-flex align-items-center justify-content-center" [ngSwitch]="currentFile.fileExt">                
                <img *ngSwitchCase="['jpg','jpeg','png'].includes(currentFile.fileExt) ? currentFile.fileExt : ''" [src]="currentFile.src" class="iframe" alt="loading preview..." width="100%" />                
                <i *ngSwitchCase="'pdf'" class="fa fa-file-pdf-o" style="color: firebrick;"></i>
                <i *ngSwitchCase="['xlsx','xls'].includes(currentFile.fileExt) ? currentFile.fileExt : ''" class="fa fa-file-excel-o" style="color: green;"></i>
                <i *ngSwitchCase="['doc','docx'].includes(currentFile.fileExt) ? currentFile.fileExt : ''" class="fa fa-file-word-o" style="color: darkblue;"></i>
                <i *ngSwitchDefault class="fa fa-file-o"></i>
              </div>
              <div class="progress-wrapper p-2">
                <div>
                  <div class="progress-text d-flex justify-content-between">
                    <span class="progress-status">{{ 'Uploaded' }} {{ currentFile.uploadProgressValue }}%</span>                    
                  </div>
                  <div class="progress-statusbar mt-1">
                    <ngb-progressbar type="info" [value]="currentFile.uploadProgressValue" height=".2rem"></ngb-progressbar>
                  </div>
                </div>                
              </div>
              <div class="navigation-wrapper">
                <div class="d-flex w80">
                  <button class="btn-navigation" (click)="movePrevious()" disabled>
                    <i class="fa fa-angle-left"></i>
                  </button>
                  <div class="navigation-text">
                    <span>Document: 1/1</span>
                  </div>
                  <button class="btn-navigation" (click)="moveNext()" disabled>
                    <i class="fa fa-angle-right"></i>
                  </button>
                </div>                
              </div>
            </div>
          </div>
          <div class="col-xl-7">
            <div class="content-wrapper px-2 pr-md-0 pl-md-5 right-section d-flex flex-column h100">
              <div class="main-content-wrapper mt-4">
                <div class="row form-wrapper">
                  <div class="col-md-9 offset-md-1 p-2">
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Name:
                      </div>
                      <div class="col-xl-7 input-field">
                        <input class="form-control mt-0 bg-transparent" type="text" name="fileName" [(ngModel)]="currentFile.fileName" disabled>
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Type:
                      </div>
                      <div class="col-xl-7 input-field">
                        <select class="form-control mt-0" name="documentTypesId" [(ngModel)]="currentFile.documentTypesId">
                          <option class="text-muted" disabled value="0"></option>
                          <option class="text-muted" *ngFor="let option of mastersVM.masterDocumentType.masterValues"
                          [value]="option.id">{{option.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col-xl-5 label-field">
                        Document Folder:
                      </div>
                      <div class="col-xl-7 input-field">
                        <select class="form-control mt-0" name="folderTypeId" [(ngModel)]="currentFile.folderTypeId">
                          <option class="text-muted" disabled value="0"></option>
                          <option class="text-muted" *ngFor="let option of folderTypeEnum | populateEnum"
                          [value]="option.key">{{option.value}}</option>
                        </select>
                      </div>
                    </div>                    
                  </div>
                </div>                
              </div>
              <div class="action-buttons-wrapper">
                <button class="btn btn-modal btn-gray" (click)="closeModal(modal)">CANCEL</button>
                <button class="btn btn-modal btn-blue ml-3" (click)="updateDoc()" type="button" [disabled]="currentFile.isSaving">                  
                  {{ currentFile.isSaving ? 'Updating...' : 'UPDATE' }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>    
</div>
</ng-template>
