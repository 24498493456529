import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MastersVM } from '../../adminConsole/master/master.model';
import { MasterService } from '../../adminConsole/master/master.service';
import { DocumentModel, FolderType, UpdateDocumentModalDataModel, UpdateDocumentRequestModel } from '../edit-documents/edit-documents.model';
import { EditDocumentsService } from '../edit-documents/edit-documents.service';
import { GetAllDocumentsQuery, DocumentVM } from './view-all-documents.model';
import { ViewAllDocumentsService } from './view-all-documents.service';
import { HttpStatusCode } from '@angular/common/http';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-view-all-documents',
  templateUrl: './view-all-documents.component.html',
  styleUrls: ['./view-all-documents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewAllDocumentsComponent implements OnInit {
  @Input() applicantId: number = 0;
  @Input('folderTypeId') folderTypeId: number;
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  getAllDocumentsQuery = new GetAllDocumentsQuery();
  
  //**edit functionality*
  mastersVM = new MastersVM();
  currentFile: UpdateDocumentModalDataModel;
  currentFileindex: number = 0;  
  folderTypeEnum = FolderType;

  editDocument(row: DocumentVM, modalId){
    this.modalService.dismissAll();

    this.currentFile = {
      id: row.id,
      applicantId: row.applicantId,
      documentTypesId: parseInt(row.documentTypesId),
      folderTypeId: row.documentFolderId,
      file: null,
      fileMimeType: row.mimeType,
      fileName: row.fileName,
      fileExt: row.fileName.split('.').pop(),
      uploadProgressValue: 100,
      isSaving: false,
      src: ''
    };

    this.viewAllDocumentsService.getPublicUrl(row.id, "View")
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if(res.isSuccess){
        this.currentFile.src = res.response.url;
      }
    });

    this.modalService.open(modalId);
  }

  closeModal(modal: any){
    Swal.fire({
      title: 'Are you sure you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentFile = new UpdateDocumentModalDataModel();
        modal.close();
        this.modalService.dismissAll();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
   
  }

  updateDoc(){
    this.currentFile.isSaving = true;

    const requestModel: UpdateDocumentRequestModel = {
      documentId: this.currentFile.id,
      applicantId: this.currentFile.applicantId,
      documentTypesId: this.currentFile.documentTypesId,
      documentFolderTypeId: this.currentFile.folderTypeId
    };
    this.service.updateDocument(requestModel)
      .pipe(catchError((err) => {        
        this.currentFile.isSaving = false;
        this.toaster.error("failed to update document.");
        return this.handleError(err)
      }))
      .subscribe(res => {
        if(res.isSuccess){
          this.currentFile.isSaving = false;
          this.modalService.dismissAll();
          this.toaster.success(res.message);
          this.ngOnInit();
        }
      });
  }
  //**end edit functionality*
  
  constructor(
    public headerTitleService:HeaderTitleService,
    private toaster: ToastrService,    
    private configModal: NgbModalConfig, 
    private modalService: NgbModal,
    private viewAllDocumentsService: ViewAllDocumentsService,
    private service: EditDocumentsService,
    private masterService: MasterService
  )
  {
    this.configModal.centered = true;
    this.configModal.size = "xl";
  }


  ngOnInit(): void {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.currentPageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    if (this.headerTitleService.PermissionVM.documents) {
      this.getAllDocuments();
      this.loadMasters();
        }
  }
  loadMasters() {
    this.masterService.getMasters(false)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
      });
  }

  getAllDocuments() {
    this.getAllDocumentsQuery.getAll = true;
    this.getAllDocumentsQuery.ApplicantId = this.applicantId;
    this.getAllDocumentsQuery.DocumentFolderId = this.folderTypeId;
    this.getAllDocumentsQuery.pageNumber = this.ngxDatatableSSRConfig.currentPageNumber;
    this.getAllDocumentsQuery.pageSize = 1;
    
    this.viewAllDocumentsService.getAllDocumentData(this.getAllDocumentsQuery)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxDatatableSSRConfig.updateTableData(res);
      });
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.getAllDocumentsQuery.getAll = this.ngxDatatableSSRConfig.getAll;
    this.getAllDocuments();
  }

  onPageChanged(pageNum: number) {
     this.ngxDatatableSSRConfig.rows = [];
     this.getAllDocumentsQuery.getAll = this.ngxDatatableSSRConfig.getAll;
     this.ngxDatatableSSRConfig.onPageChanged(pageNum);
     this.getAllDocuments();
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }

  viewDocument(data) {
    this.viewAllDocumentsService.getPublicUrl(data.id, "View")
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if(res.isSuccess){
        const publicUrl = res.response.url;
        const element = document.createElement('a');
        element.setAttribute('href', publicUrl);
        element.setAttribute('target', '_blank');
        // element.setAttribute('download', data.fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //this.toaster.success(res.message);
      }
    });
  }

  downloadDocument(data:DocumentVM) {
    this.viewAllDocumentsService.getPublicUrl(data.id, "Download")
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if(res.isSuccess){
        const publicUrl = res.response.url;
        const element = document.createElement('a');
        element.setAttribute('id', new Date().toString());
        element.setAttribute('href', publicUrl);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', data.fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        //this.toaster.success(res.message);
      }
    });
  }

  // getPublicUrl(id: number, action: string) : string{
  //   let publicUrl: string = null;
  //   this.viewAllDocumentsService.getPublicUrl(id, action)
  //   .pipe(catchError((err) => this.handleError(err)))
  //   .subscribe(res => {
  //     if(res.isSuccess){
  //       publicUrl = res.response.url;
  //       //this.toaster.success(res.message);
  //     }
  //   });
  //   return publicUrl;
  // }

  deleteDocument(data) {
    this.viewAllDocumentsService.deleteDocument(data.id)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if(res.isSuccess){
          this.toaster.success(res.message);
        }        
        this.getAllDocuments();
      });
  }
}
