
export class EditPaymentsModel {
    id: number;
    applicantId: number;
    paymentReceiverId: number;
    p1_ReceivedAmount: number = 0;
    p1_ReceivedDate:string ;
    p2_ReceivedAmount: number = 0;
    p2_ReceivedDate: string ;
    p3_ReceivedAmount: number = 0;
    p3_ReceivedDate: string ;
    p4_ReceivedAmount: number = 0;
    p4_ReceivedDate: string ;
    paymentInfo: PaymentInfo;
    outstandingAmount: number = 0;

}
export interface PaymentInfo {
    id: number;
    applicantId: number;
    paymentReceiverId: number;
    totalAgreed: number;
    p1: number;
    p2: number;
    p3: number;
    p4: number;
}

export class EditPaymentsRecord {
    applicantId: number;
    paymentReceiverId: number;
}

export class AdditionalPaymentModel {
    additionalPayments: AdditionalPayment[] = [];
}

export class AdditionalPayment {
    id: number;
    applicantId: number;
    payment_Name: string;
    payment_Amount: number;
    payment_ReceivedDate: string ;
    isDeleted: boolean;
}