<!-- content starts-->
<div class="content-wrapper">
    <div class="card card-full-height">
        <div class="card-body px-0 py-0">
          <nav ngbNav #nav="ngbNav" [activeId]="tabId" class="nav-tabs nav-pills">
            <ng-container [ngbNavItem]="1">
              <a (click)="onGoToPage(1)" ngbNavLink>Today's Tasks</a>
              <ng-template ngbNavContent>
                <app-view-all-activity-log [taskId]="10"></app-view-all-activity-log>
              </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="2">
              <a (click)="onGoToPage(2)" ngbNavLink>Incomplete Tasks</a>
              <ng-template ngbNavContent>
               <app-view-all-activity-log [taskId]="40"></app-view-all-activity-log> 
              </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="3">
              <a (click)="onGoToPage(3)" ngbNavLink>Upcoming Tasks</a>
              <ng-template ngbNavContent>
               <app-view-all-activity-log [taskId]="20"></app-view-all-activity-log> 
              </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="4">
              <a (click)="onGoToPage(4)" ngbNavLink>Completed Tasks</a>
              <ng-template ngbNavContent>
               <app-view-all-activity-log [taskId]="30"></app-view-all-activity-log> 
              </ng-template>
            </ng-container>
          </nav>
          
          <div [ngbNavOutlet]="nav" class="mt-0"></div>
          
        </div>
    </div>
  </div>
  
