<!-- <input type="text" 
(dateSelect)="updateSelectedDate($event,d)" 
(keydown.enter)="d.open()" 
class="form-control col-xl-9 b-r-5 f-12 mt-1" 
(click)="d.toggle()" 
id="validationCustom4"  
[disabled]="notEditable" 
autocomplete="off" 
name="dOB" 
[(ngModel)]="applicantService.personalInfoModel.dOB" 
[(ngModel)]="age" 
ngbDatepicker 
#d="ngbDatepicker"> -->

<div>asd</div>
