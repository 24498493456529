import { Component, OnInit } from '@angular/core';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { ExcelService } from '@services/export_service/excel.service';
import { HeaderTitleService } from '@services/header-title.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AccountsReceivableVM, ReportRequestModel } from '../report.model';
import { ReportService } from '../report.service';
import { throwError } from 'rxjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-accounts-receivable',
  templateUrl: './accounts-receivable.component.html',
  styleUrls: ['./accounts-receivable.component.scss']
})
export class AccountsReceivableComponent implements OnInit {
  rows: AccountsReceivableVM[] = [];
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  getAllAccountsReceivableAmountRequestModel = new ReportRequestModel();
  totalRows: AccountsReceivableVM[] = [];

  constructor(
    private toaster: ToastrService,
    private headerTitleService: HeaderTitleService,
    public service: ReportService,
    private excelService: ExcelService
  ) {

  }

  ngOnInit(): void {
    this.headerTitleService.title = 'Accounts Receivable'
    this.initDatatable()
  }


  ngAfterViewInit(): void {
    this.loadAccountsReceivableReportData();
  }


  loadAccountsReceivableReportData(): void {
    this.service.getAccountsReceivableReportData(this.getAllAccountsReceivableAmountRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        this.ngxDatatableSSRConfig.totalRecords = res.totalRecords;
      });
  }

  downloadAccountsReceivableReportData(fileType): void{
    this.getAllAccountsReceivableAmountRequestModel.getAll = true;
    this.service.getAccountsReceivableReportData(this.getAllAccountsReceivableAmountRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.totalRows = res.response;
      if(fileType == 'PDF'){
        this.downloadAsPDF();
      }
      else if(fileType == 'Excel'){
        this.exportAsXLSX();
      }
    });
  }

  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.getAllAccountsReceivableAmountRequestModel = {
      sortColumn: 'applicantName',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: '',
      toDate: '',
      searchTypeId: 0
    };
  }


  onPageSizeChanged(pageSize: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);

    this.getAllAccountsReceivableAmountRequestModel = {
      sortColumn: 'applicantName',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: '',
      toDate: '',
      searchTypeId: 0

    };
    this.loadAccountsReceivableReportData();
  }

  onPageChanged(pageNum: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);

    this.getAllAccountsReceivableAmountRequestModel = {
      sortColumn: 'applicantName',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: '',
      toDate: '',
      searchTypeId: 0

    };
    this.loadAccountsReceivableReportData();
  }

  // for export as Excel
  exportAsXLSX(): void {
    var filename = this.headerTitleService.title
    this.excelService.exportAsExcelFile(this.totalRows, filename);
  }

  public downloadAsPDF() {
    const doc = new jsPDF('l', 'mm', 'a4')
    autoTable(doc, {

      head: [['#', 'Applicant Name ', 'Outstanding Due']],
      body: this.getBody(),
    })
    doc.save(this.headerTitleService.title + '-' + moment(new Date()).format('YYYY-MM-DD'));
  }

  getBody(): any[] {
    let data = [];
    this.totalRows.forEach((s,index)=>data.push([index+1, s.applicantName, s.outstandingDueAmount]));
    return data;
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

}
