import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { TimelineVM, Timeline, StageStatus, UpdateTimelineStatusModel, TimelineModalData } from './edit-timeline.model';
import { EditTimelineService } from './edit-timeline.service'
import { ApplicantsService } from '../applicants.service';
import { HeaderTitleService } from '@services/header-title.service';
import { AuditLogsComponent } from '../../adminConsole/audit-logs/audit-logs.component';
import { StorageService } from '@services/storage.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-edit-timeline',
  templateUrl: './edit-timeline.component.html',
  styleUrls: ['./edit-timeline.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})

export class EditTimelineComponent implements OnInit {
  model: NgbDateStruct;
  date: {year: number, month: number};
  @Input() applicantId: number = 0;
  @Input() isArchive: boolean;
  @Output() topTimelineRefresh: EventEmitter<boolean> = new EventEmitter();
  timelineStartDate:boolean=true;
  timelineCompletionDate:boolean=true;
  timeline = new Timeline();
  modalData: TimelineModalData;
  isSaving: boolean = false;
  enumStageStatus = StageStatus;
  updateTimelineStatusModel = new UpdateTimelineStatusModel();

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();;
  min_Date:string;
  selectID:number;
  progressBarValue:number;

  constructor(
    public storageservice: StorageService,
    public headerTitleService:HeaderTitleService,
    public applicantService: ApplicantsService,
    public service: EditTimelineService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService) {
    this.config.centered = true;
  }

  ngOnInit(): void {
    this.storageservice.store('applicantDetailsTID', 5);
    if (this.headerTitleService.PermissionVM.timeline) {
    this.getTimelineData();
      }
    //this.setDate(this.updateTimelineStatusModel.stageStatusId);
    this.timelineStartDate=false;
    this.timelineCompletionDate=false;
    this.selectID;
  }

  getTimelineData() {
    this.service.getTimeline(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.timeline = res.response;
          this.progressBarValue = this.timeline.progressBarValue;
        }
      });
  }

  openModal(modalId: any, editModel: TimelineVM) {
    if (this.timeline.stage1.id != editModel.id && editModel.stageStatusId == StageStatus.NotStarted) return;
    
    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new TimelineModalData();

    if (editModel !== null) {
      this.modalData.id = editModel.id;
      this.modalData.applicantId = editModel.applicantId;
      this.modalData.stageId = editModel.stageId;
      this.modalData.stageName = editModel.stageName;
      this.modalData.stageStatusId = editModel.stageStatusId;
      this.modalData.startDate = editModel.startDate;
      this.modalData.completionDate = editModel.completionDate;
      this.setDate(this.modalData.stageStatusId);
    }

    this.modalService.open(modalId);
  }

  public saveModalData(modal: any) {
    this.isSaving = true;
    this.updateTimelineStatusModel.id = this.modalData.id;
    this.updateTimelineStatusModel.applicantId = this.modalData.applicantId;
    this.updateTimelineStatusModel.stageStatusId = this.modalData.stageStatusId;
    let stageStatusId = parseInt(this.updateTimelineStatusModel.stageStatusId.toString());
    switch(stageStatusId){
      case 1:{
        this.updateTimelineStatusModel.startDate = this.modalData.startDate;
        this.updateTimelineStatusModel.completionDate = this.modalData.completionDate;
        break;
      }
      case 2:{
        this.updateTimelineStatusModel.startDate = this.modalData.startDate;
        this.updateTimelineStatusModel.completionDate = this.modalData.completionDate;
        break;
      }
      case 3:{
        this.updateTimelineStatusModel.startDate = this.modalData.startDate;
        this.updateTimelineStatusModel.completionDate = null;
        break;
        }
      case 4:{
        this.updateTimelineStatusModel.startDate = null;
        this.updateTimelineStatusModel.completionDate = null;
        break;
        }
      default:{
        "Please Change Status !!!";
        break;
      }
    }
    this.service.updateTimelineStatus(this.updateTimelineStatusModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          modal.close();
          this.toaster.success("Status updated successfully");
          this.timeline = res.response;
          this.getTimelineData();
          // this.progressBarValue =
          // Refresh topTimeline data

          this.topTimelineRefresh.emit(true);
          this.headerTitleService.notificationRefresh.emit(true);
        }
        else {
          this.toaster.error(res.message);
        }
        this.isSaving = false;
      });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    this.isSaving = false;
    return throwError(error);
  }

  archiveClicked() {
    this.applicantService.ArchiveUnArchiveById(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.isArchive = !this.isArchive;
        }
      });
  }
  onStatusChange(event){
    this.selectID=(parseInt(event.target.value));
      this.setDate(parseInt(event.target.value));

  }
  setDate(statusId : number){
    switch(statusId){
      case 1:{
        if(this.modalData.startDate == null){
          this.timelineStartDate=false; 
          this.timelineCompletionDate=true;
        }
        else
        {
          if(this.modalData.startDate != null){
            this.timelineStartDate=false; 
            this.timelineCompletionDate=false;
          }
        }
        
        break;
      }
      case 2:{
        this.modalData.completionDate=null;
        this.timelineStartDate=false;
        this.timelineCompletionDate=true;
        break;
      }
      case 3:{
        this.modalData.startDate=null;
        this.modalData.completionDate=null;
        this.timelineStartDate=true;
        this.timelineCompletionDate=true;
        break;
        }
      case 4:{
        this.modalData.startDate=null;
        this.modalData.completionDate=null;
        this.timelineStartDate=true;
        this.timelineCompletionDate=true;
        break;
        }
      default:{
        "Please Change Status !!!";
        break;
      }
    }
  }

  checkDate(event,statusId){
    this.min_Date = event.target.value;
    this.setDate(statusId);
  }


  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, {size:'xl', centered:true});
    modalRef.componentInstance.pageId = 5;
  }
  // Open Audit Trail Model //
}
