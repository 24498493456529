<ng-container *ngIf="bHasApplicantAccess">
  <app-edit-applicant-header [applicantId]="applicantId"></app-edit-applicant-header>
  <!-- content starts-->
  <div class="content-wrapper">
          <div class="px-0 py-4">
            <nav ngbNav #nav="ngbNav" [activeId]="tabId" class="nav-tabs nav-pills">
              <ng-container [ngbNavItem]="1">
                <a ngbNavLink>Applicant Information</a>
                <ng-template ngbNavContent>
                    <app-edit-applicant-information [applicantId]="applicantId"></app-edit-applicant-information>
                    <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.applicantInformation"></app-user-access-permission>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="2">
                <a ngbNavLink>Documents</a> 
                <ng-template ngbNavContent>
                    <app-edit-documents [applicantId]="applicantId" (refreshDocumentList)="refreshDocumentListfn($event)"></app-edit-documents>
                    <app-documents-list-tab [applicantId]="applicantId"></app-documents-list-tab>
                    <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.documents"></app-user-access-permission>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="3">
                <a ngbNavLink>Payments</a>
                <ng-template ngbNavContent>
                    <app-edit-payments [applicantId]="applicantId"></app-edit-payments>
                    <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.payments"></app-user-access-permission>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="4">
                  <a ngbNavLink>Activity Log</a>
                  <ng-template ngbNavContent>
                      <app-edit-activity-log [applicantId]="applicantId"></app-edit-activity-log>
                      <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewActivityLogs"></app-user-access-permission>
                  </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="5">
                  <a ngbNavLink>Timeline</a>
                  <ng-template ngbNavContent>
                      <app-edit-timeline [applicantId]="applicantId" [isArchive]="isArchive" (topTimelineRefresh)="topTimelineRefreshfn($event)" ></app-edit-timeline>
                      <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.timeline"></app-user-access-permission>
                    </ng-template>
                </ng-container>
            </nav>
            
            <div [ngbNavOutlet]="nav" class=""></div>
            
          </div>
    </div>
</ng-container>
<ng-container>
  <app-user-access-permission *ngIf="accessPermissionHidden" [hasBorder]="true" [userPermission]="bHasApplicantAccess" [message] = "'You do not have permission to access this profile.'"></app-user-access-permission>
</ng-container>
