import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxDatatableSSRConfig } from "@models/ngx-datatable-ssr-config.model";
import { ToastrService } from 'ngx-toastr';

import { AuditLogsService } from './audit-logs.service';
import { AuditLogsRequestModel, AuditLogsModel, AuditActionSource, AuditActionSourceModel } from './audit-logs.model';
import { HeaderTitleService } from '@services/header-title.service';
import { ApplicantsService } from '../../applicants/applicants.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicantModel } from '../../applicants/applicant.model';
import { ActivatedRoute } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})

export class AuditLogsComponent implements OnInit, OnDestroy {

  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  rows: AuditLogsModel[] = [];
  getAllRequestModel = new AuditLogsRequestModel();
  auditActionSourceEnum = AuditActionSource;
  auditActionSourceModel: AuditActionSourceModel[] = [];
  selectedActionSourceId: number = 0;
  public applicantId: number = 0;
  public applicantName: string = '';
  public sectionFieldCanVisible: boolean = false;
  @Input() pageId: number = 0;

  applicantModel = new ApplicantModel();
  sub$: Subscription;

  constructor(public service: AuditLogsService,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private activateRouter: ActivatedRoute,
    public applicantsService: ApplicantsService,
    public headerTitleService: HeaderTitleService,) { }

  ngOnDestroy(): void {    
    this.sub$.unsubscribe();
  }

  getApplicantInformation(applicantId) {
    if (!applicantId) return;

    this.applicantsService.getApplicantInformation(applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.applicantModel = res.response;
          this.applicantsService.personalInfoModel = this.applicantModel.personalInfo;

          this.applicantName = this.applicantsService.personalInfoModel.firstName + ' ' +
            this.applicantsService.personalInfoModel.middleName + ' ' +
            this.applicantsService.personalInfoModel.lastName;
        }
        else{
          this.applicantName = "";
        }
      });
  }

  ngOnInit(): void {

    if (this.pageId != 0) {
      this.sub$ = this.activateRouter.queryParams.subscribe(params => {
        this.applicantId = parseInt(params['applicantId']);
        if(this.applicantsService.personalInfoModel.applicantId && (this.applicantsService.personalInfoModel.applicantId == this.applicantId)){
          this.applicantName = this.applicantsService.personalInfoModel.firstName + ' ' +
            this.applicantsService.personalInfoModel.middleName + ' ' +
            this.applicantsService.personalInfoModel.lastName;
        }
        else{
          this.getApplicantInformation(this.applicantId);
        }        
      });
    }

    switch (this.pageId) {
      case 0:
        this.selectedActionSourceId = AuditActionSource['New Applicants'];
        this.auditActionSourceModel = [];
        break;

      case 1:
        this.selectedActionSourceId = AuditActionSource['Personal Information'];
        this.auditActionSourceModel = [];
        for (const [propertyKey, propertyValue] of Object.entries(AuditActionSource)) {
          if (AuditActionSource['Personal Information'] == propertyValue ||
            AuditActionSource['Family Information'] == propertyValue ||
            AuditActionSource['Background Information'] == propertyValue ||
            AuditActionSource['Program Information'] == propertyValue ||
            AuditActionSource['Payment Information'] == propertyValue||
            AuditActionSource['Immigration Information'] == propertyValue
          ) {
            if (!Number.isNaN(Number(propertyKey))) {
              continue;
            }
            this.auditActionSourceModel.push({ displayValue: Number(propertyValue), displayName: AuditActionSource[propertyValue] });
          }
        }
        break;

      case 2:
        this.selectedActionSourceId = AuditActionSource.Documents;
        this.auditActionSourceModel = [];
        break;

      case 3:
        this.selectedActionSourceId = AuditActionSource['Applicant Payments'];

        this.auditActionSourceModel = [];
        for (const [propertyKey, propertyValue] of Object.entries(AuditActionSource)) {
          if (AuditActionSource['Applicant Payments'] == propertyValue ||
            AuditActionSource['Liaison Payments'] == propertyValue ||
            AuditActionSource['Agency Payments'] == propertyValue ||
            AuditActionSource['GER Advisor Payments'] == propertyValue ||
            AuditActionSource['Additional Payments'] == propertyValue
          ) {
            if (!Number.isNaN(Number(propertyKey))) {
              continue;
            }
            this.auditActionSourceModel.push({ displayValue: Number(propertyValue), displayName: AuditActionSource[propertyValue] });
          }
        }
        break;

      case 4:
        this.selectedActionSourceId = AuditActionSource['Activity Log'];
        this.auditActionSourceModel = [];
        break;

      case 5:
        this.selectedActionSourceId = AuditActionSource.Timeline;
        this.auditActionSourceModel = [];
        break;
    }


    this.sectionFieldCanVisible = (this.auditActionSourceModel.length == 0) ? false : true;
    this.initDatatable();
    this.loadListData();
  }

  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    // this.ngxDatatableSSRConfig.pageSize = 50;
    this.getAllRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      auditActionSourceId: this.selectedActionSourceId,
      applicantId: this.applicantId,
    };
  }

  loadListData(): void {
    this.service.getAllAuditLog(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        //this.ngxdtConfig.updateTableData(res.response);
        this.rows = res.response;
        this.ngxDatatableSSRConfig.totalRecords = res.totalRecords;
      });
  }

  // Turn enum into array
  ToArray(enumme) {
    return Object.keys(enumme)
      .filter(this.StringIsNumber)
      .map(key => enumme[key]);
  }

  StringIsNumber = value => isNaN(Number(value)) === false;

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  sortByActionSource() {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.getAllRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      auditActionSourceId: this.selectedActionSourceId,
      applicantId: this.applicantId,
    };
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);

    this.getAllRequestModel = {
      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      auditActionSourceId: this.selectedActionSourceId,
      applicantId: this.applicantId,
    };
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);

    this.getAllRequestModel = {
      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      auditActionSourceId: this.selectedActionSourceId,
      applicantId: this.applicantId,
    };
    this.loadListData();
  }

  closed() {
    this.activeModal.dismiss();
  }
}
