<div  class="container-fluid access-restrict" *ngIf="!userPermission">
    <div class="row">
      <div class="col-xl-12 px-0">
        <div class="card">
            <div class="card-body {{hasFullHeight ?  'full-height' : 'default-height'}}"  [class.has-border]="hasBorder">
                <div class="card-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="83.771" height="111.695" viewBox="0 0 83.771 111.695">
                        <g id="padlock_2_" data-name="padlock (2)" transform="translate(-5 -1)">
                          <path id="Path_232" data-name="Path 232" d="M46.886,1A31.284,31.284,0,0,0,15.471,32.414V46.376A10.285,10.285,0,0,0,5,56.848v45.376A10.285,10.285,0,0,0,15.471,112.7H78.3a10.285,10.285,0,0,0,10.471-10.471V56.848A10.285,10.285,0,0,0,78.3,46.376V32.414A31.284,31.284,0,0,0,46.886,1Zm34.9,55.848v45.376a3.3,3.3,0,0,1-3.49,3.49H15.471a3.3,3.3,0,0,1-3.49-3.49V56.848a3.3,3.3,0,0,1,3.49-3.49H78.3A3.3,3.3,0,0,1,81.791,56.848ZM22.452,46.376V32.414a24.433,24.433,0,0,1,48.867,0V46.376Z" fill="#0e4ab3"/>
                          <path id="Path_233" data-name="Path 233" d="M24.471,19A10.285,10.285,0,0,0,14,29.471a10.167,10.167,0,0,0,6.981,9.773v7.679a3.3,3.3,0,0,0,3.49,3.49,3.3,3.3,0,0,0,3.49-3.49V39.245a10.167,10.167,0,0,0,6.981-9.773A10.285,10.285,0,0,0,24.471,19Zm0,13.962a3.3,3.3,0,0,1-3.49-3.49,3.3,3.3,0,0,1,3.49-3.49,3.3,3.3,0,0,1,3.49,3.49A3.3,3.3,0,0,1,24.471,32.962Z" transform="translate(22.414 44.829)" fill="#0e4ab3"/>
                        </g>
                    </svg>
                    <div class="font-roboto f-16 f-w-600 mt-4 content-title">Access Restricted</div>
                    <div class="text-center font-roboto f-16 theme-font-color-dark mt-3">{{message}}
                        <br>
                        Please contact your administration to request access.</div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>