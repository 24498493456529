import { AddUpdateActivityLogComponent } from './add-update-activity-log/add-update-activity-log.component';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import { MasterService } from 'src/app/components/adminConsole/master/master.service';
import { ActivityVM, ActivityModalModel } from '../applicant.model';
import { ApplicantsService } from '../applicants.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersVM } from 'src/app/components/adminConsole/master/master.model';
import { getAllActivityRequestModel, Task } from 'src/app/components/applicants/applicant.model';
import { NgbDatepickerI18n, NgbDateStruct, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserListService } from '../../adminConsole/users-management/user-list/user-list.service';
import { User } from '../../adminConsole/users-management/user-list/user-list.model';
import { AuditLogsComponent } from '../../adminConsole/audit-logs/audit-logs.component';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { HeaderTitleService, OperationTypeEnum } from '@services/header-title.service';
import { StorageService } from '@services/storage.service';
import { HttpStatusCode } from '@angular/common/http';

declare var require;
const Swal = require('sweetalert2');
const I18N_VALUES = {
  'fr': {
    weekdays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    months: ['January,', 'February,', 'March,', 'April,', 'May,', 'June,', 'July,', 'August,', 'September,', 'October,', 'November,', 'December,'],
  }
  // other languages you would support
};
@Injectable()
export class I18n {
  language = 'fr';
}
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) { super(); }
  getWeekdayShortName(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]; }
  getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}

@Component({
  selector: 'app-edit-activity-log',
  templateUrl: './edit-activity-log.component.html',
  styleUrls: ['./edit-activity-log.component.scss'],
  providers:
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, NgbModalConfig, NgbModal]
})
export class EditActivityLogComponent implements OnInit {
  @Input() applicantId: number = 0;
  getAllActivityRequestModel = new getAllActivityRequestModel();
  public task = Task;
  public operationId = OperationTypeEnum;
  modalTitle: string;
  activityModel = new ActivityVM();
  userDetails: User;

  ngxdtConfigTodays = new NgxDatatableSSRConfig();
  ngxdtConfigUpcoming = new NgxDatatableSSRConfig();
  ngxdtConfigIncomplate = new NgxDatatableSSRConfig();
  ngxdtConfigCompleted = new NgxDatatableSSRConfig();

  mastersVM = new MastersVM();
  public isLoading = false;

  constructor(public applicantService: ApplicantsService,
    public storageService: StorageService,
    public userService: UserListService,
    private modalService: NgbModal,
    public headerTitleService:HeaderTitleService,
    public masterService: MasterService,
    public router: Router,
    private config1: NgbModalConfig,
    private toaster: ToastrService) {
    this.config1.centered = true;
  }

  ngOnInit(): void {
    this.userDetails = this.storageService.retrieve('user');
    this.getAllActivityRequestModel.userId = (this.userDetails?.userRole == "Admin") ? "" : this.userDetails?.userId;
    this.storageService.store('applicantDetailsTID', 4);
    if (this.headerTitleService.PermissionVM.viewActivityLogs) {
      this.resetAll();
      }
  }

  //Todays Activity Start
  loadListTodaysActivityData() {

    this.getAllActivityRequestModel.taskId = this.task.todays;
    this.getAllActivityRequestModel.applicantId = this.applicantId;
    this.getAllActivityRequestModel.sortColumn = '';
    this.getAllActivityRequestModel.sortDirection = 'DESC';
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigTodays.getAll;
    this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigTodays.currentPageNumber;
    this.getAllActivityRequestModel.pageSize = this.ngxdtConfigTodays.pageSize;

    this.applicantService.getAllActivity(this.getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxdtConfigTodays.updateTableData(res);
      });
  }
  //Todays Activity Start

  //Incomplate Activity Start
  loadListIncomplateActivityData() {

    this.getAllActivityRequestModel.taskId = this.task.inCompleted;
    this.getAllActivityRequestModel.applicantId = this.applicantId;
    this.getAllActivityRequestModel.sortColumn = '';
    this.getAllActivityRequestModel.sortDirection = 'DESC';
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigIncomplate.getAll;
    this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigIncomplate.currentPageNumber;
    this.getAllActivityRequestModel.pageSize = this.ngxdtConfigIncomplate.pageSize;

    this.applicantService.getAllActivity(this.getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxdtConfigIncomplate.updateTableData(res);
      });
  }
  //Incomplate Activity Close

  //Upcoming Activity Start
  loadListUpcomingActivityData() {

    this.getAllActivityRequestModel.taskId = this.task.upcoming;
    this.getAllActivityRequestModel.applicantId = this.applicantId;
    this.getAllActivityRequestModel.sortColumn = '';
    this.getAllActivityRequestModel.sortDirection = 'DESC';
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigUpcoming.getAll;
    this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigUpcoming.currentPageNumber;
    this.getAllActivityRequestModel.pageSize = this.ngxdtConfigUpcoming.pageSize;

    this.applicantService.getAllActivity(this.getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxdtConfigUpcoming.updateTableData(res);
      });
  }
  //Upcoming Activity Close

  //Completed Activity Start
  loadListCompletedActivityData() {

    this.getAllActivityRequestModel.taskId = this.task.completed;
    this.getAllActivityRequestModel.applicantId = this.applicantId;
    this.getAllActivityRequestModel.sortColumn = this.ngxdtConfigCompleted.sortColumn??'';
    this.getAllActivityRequestModel.sortDirection = this.ngxdtConfigCompleted.sortDirection??'DESC';
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigCompleted.getAll;
    this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigCompleted.currentPageNumber;
    this.getAllActivityRequestModel.pageSize = this.ngxdtConfigCompleted.pageSize;

    this.applicantService.getAllActivity(this.getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxdtConfigCompleted.updateTableData(res);
      });
  }
  //Completed Activity Close

  resetAll() {

    this.ngxdtConfigCompleted = new NgxDatatableSSRConfig();
    this.ngxdtConfigCompleted.currentPageNumber = 1;
    this.ngxdtConfigCompleted.pageSize = 5;

    this.ngxdtConfigUpcoming = new NgxDatatableSSRConfig();
    this.ngxdtConfigUpcoming.currentPageNumber = 1;
    this.ngxdtConfigUpcoming.pageSize = 5;

    this.ngxdtConfigIncomplate = new NgxDatatableSSRConfig();
    this.ngxdtConfigIncomplate.currentPageNumber = 1;
    this.ngxdtConfigIncomplate.pageSize = 5;

    this.ngxdtConfigTodays = new NgxDatatableSSRConfig();
    this.ngxdtConfigTodays.currentPageNumber = 1;
    this.ngxdtConfigTodays.pageSize = 5;

    this.activityModel = new ActivityVM();
    //Todays Activity Start
    this.loadListTodaysActivityData();
    //Incomplate Activity Start
    this.loadListIncomplateActivityData();
    //Todays Activity Start
    this.loadListUpcomingActivityData();
    //Todays Activity Start
    this.loadListCompletedActivityData();
  }

  onPageSizeChanged(pageSize: number, taskId: number) {
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigTodays.getAll;
    switch (taskId) {
      case this.task.completed: {
        //statements;
        this.ngxdtConfigCompleted.rows = [];
        this.ngxdtConfigCompleted.onPageSizeChanged(pageSize);
        this.loadListCompletedActivityData();
        break;
      }
      case this.task.inCompleted: {
        //statements; 
        this.ngxdtConfigIncomplate.rows = [];
        this.ngxdtConfigIncomplate.onPageSizeChanged(pageSize);
        this.loadListIncomplateActivityData();
        break;
      }
      case this.task.todays: {
        //statements;
        this.ngxdtConfigTodays.rows = [];
        this.ngxdtConfigTodays.onPageSizeChanged(pageSize);
        this.loadListTodaysActivityData();
        break;
      }
      case this.task.upcoming: {
        //statements;
        this.ngxdtConfigUpcoming.rows = [];
        this.ngxdtConfigUpcoming.onPageSizeChanged(pageSize);
        this.loadListUpcomingActivityData();
        break;
      }
    }
  }

  onPageChanged(pageNum: number, taskId: number) {
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigTodays.getAll;
    switch (taskId) {
      case this.task.completed: {
        //statements;
        this.ngxdtConfigCompleted.rows = [];
        this.ngxdtConfigCompleted.onPageChanged(pageNum);
        this.loadListCompletedActivityData();
        break;
      }
      case this.task.inCompleted: {
        //statements; 
        this.ngxdtConfigIncomplate.rows = [];
        this.ngxdtConfigIncomplate.onPageChanged(pageNum);
        this.loadListIncomplateActivityData();
        break;
      }
      case this.task.todays: {
        //statements;
        this.ngxdtConfigTodays.rows = [];
        this.ngxdtConfigTodays.onPageChanged(pageNum);
        this.loadListTodaysActivityData();
        break;
      }
      case this.task.upcoming: {
        //statements;
        this.ngxdtConfigUpcoming.rows = [];
        this.ngxdtConfigUpcoming.onPageChanged(pageNum);
        this.loadListUpcomingActivityData();
        break;
      }
    }
  }

  deleteActivity(deleteModel: ActivityModalModel) {
    {
      this.isLoading = true;
      Swal.fire({
        title: 'Do you want to delete activity?',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.applicantService.deleteActivity(deleteModel)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe(res => {
              if (res.isSuccess == true) {

                this.toaster.success(res.message);
                this.resetAll();
              }
              this.isLoading = false;
            });
        }
      });
    }

  }

  openModal(editModel: ActivityModalModel = null, operationId: OperationTypeEnum = this.operationId.Create): void {
    this.activityModel = new ActivityVM();
    if (editModel !== null) {
      
      this.activityModel.id = editModel.id;
      this.activityModel.applicantId = editModel.applicantId;
      this.activityModel.activityTypesId = editModel.activityTypesId;
      this.activityModel.activityTitle = editModel.activityTitle;
      this.activityModel.dueDate = editModel.dueDate;
      this.activityModel.completedDate = editModel.completedDate;
      this.activityModel.assignToUserId = editModel.assignToUserId;
      this.activityModel.stagesId = editModel.stagesId;
      this.activityModel.details = editModel.details;
    }
    else {
      this.activityModel.id = 0;
      this.activityModel.applicantId = this.applicantId;
    }
    this.config1.centered = true;
    const modalRef = this.modalService.open(AddUpdateActivityLogComponent);
    modalRef.componentInstance.operationId = operationId;
    modalRef.componentInstance.activityModalModel = this.activityModel;
    // modalRef.componentInstance.com. = this.activityModel;

    modalRef.componentInstance.activityLogListRefresh.subscribe((receivedEntry) => {
      if (receivedEntry) {
        this.ngOnInit();
      }
    })
  }


  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    this.isLoading = false;
    return throwError(error);
  }


  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.pageId = 4;
  }
  // Open Audit Trail Model //

  onSort(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.getAllActivityRequestModel.sortColumn = sortInfo.sorts[0].prop;
    this.getAllActivityRequestModel.sortDirection =sortInfo.sorts[0].dir;
    this.loadListCompletedActivityData();
   }
}
