<!-- content starts-->
<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.menuDashboard">
  <div class="">
    <div *ngIf="viewDashboardWidgets" class="col-xl-12 px-0">
      <div class="card shadow m-b-20">
          <div class="mt-2 px-2 d-flex flex-wrap gap-5">
            <div class="f-family-oswald theme-font-color-dark font-weight-bold text-nowrap f-14 col-xl-9 col-md-7 col-lg-7 col-sm-4 col-4 d-flex align-self-center">
              Number of Applicants
            </div>
            <div class="col-xl-3 col-md-5 col-lg-5  text-nowrap  col-sm-8 col-8 text-right">
              <span class="theme-font-color-dark bg-transparent font-weight-bold roboto-font">
                Time-Period: 
                <select title="Year" class="b-r-5 f-sm-12 px-0 py-1 theme-font-color-blue font-weight-bold f-14 col-md-3 col-sm-7 col-xl-3 col-lg-3 col-4" 
                id="" name=""
                [(ngModel)]="currentYear" (ngModelChange)="onChange($event)">
                  <option *ngFor="let year of yearList" [value]="year.timePeriodYear">
                    {{year.timePeriodYear}}
                  </option>
                </select> Year
              </span>
            </div>
          </div>
          <hr class="hr-dark mx-4 mt-2">
          <div id="column-chart">
            <apx-chart [series]="column.series" [chart]="column.chart" [dataLabels]="column.dataLabels"
              [plotOptions]="column.plotOptions" [yaxis]="column.yaxis" [legend]="column.legend" [fill]="column.fill"
              [stroke]="column.stroke" [tooltip]="column.tooltip" [xaxis]="column.xaxis" [colors]="column.colors">
            </apx-chart>
          </div>
      </div>
    </div>
    <div *ngIf="viewDashboardWidgets" class="row">
      <div class="col-xl-6">
        <div class="card shadow m-b-20" style="height:425px;">
          <div class="f-family-oswald theme-font-color-dark font-weight-bold f-14 p-3">
            Total Applicants per Sponsor
            <hr class="hr-dark mt-2">
          </div>
          <div class="card-body apex-chart mx-2 padding-0">
            <div id="donutchart">
              <div class="box-shadow mb-0">
                <div class="card-body m-l-90 m-l-65 py-0 px-0">
                  <apx-chart [series]="donut.pieseries" [chart]="donut.chart" [labels]="donut.labels"
                            [responsive]="donut.responsive" [colors]="donut.colors" [dataLabels]="">
                  </apx-chart>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card shadow mb-0" >
          <div class="f-family-oswald theme-font-color-dark font-weight-bold f-14 p-3">
            Applicant Progress
            <hr class="hr-dark mt-2">
          </div>
          <div class="card-body py-0">
            <!-- <div id="basic-bar"> -->
              <apx-chart [series]="bar.series" [chart]="bar.chart"
                [plotOptions]="bar.plotOptions" [xaxis]="bar.xaxis" [yaxis]="bar.yaxis" [colors]="bar.colors"></apx-chart>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-start p-t-20">
      <div class="col-sm-12 col-xl-8 h-100">  
        <div class="card shadow">
          <div class="default-according p-3" id="accordion">
            <div class="f-family-oswald theme-font-color-blue font-weight-bold f-14">
              Today's Tasks
              <a *ngIf="ngxdtConfigTodays.totalRecords > 5"  (click)="onClick(1)" class="f-10 theme-font-color-dark pull-right pr-2 p-t-5">
                View All
              </a>
              <hr class="mt-1">
            </div>
            <div class="ngxdt dashboard">
              <div class="ngxdt-body">
                <ngx-datatable #table 
                class="bootstrap"
                  [footerHeight]="ngxdtConfigTodays.footerHeight" 
                  [limit]="ngxdtConfigTodays.pageSize"
                  [rows]="ngxdtConfigTodays.rows" 
                  [columnMode]="ngxdtConfigTodays.columnMode.force"
                  [offset]="ngxdtConfigTodays.tableOffset"
                  [scrollbarH]="true"
                  [scrollbarV]="true"
                  [selectionType]="ngxdtConfigTodays.selectionType.single"
                  [selected]="selected"
                  (select)="onSelect($event)"
                  [virtualization]="false">


                  <ngx-datatable-column [width]="100" [flexGrow]="1" headerClass="pl-4"
                    cellClass="pl-4 font-weight-500" prop="activityTitle" name="Activity" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>


                  <ngx-datatable-column [width]="100" [flexGrow]="1.1"
                    prop="activityTypeName" name="Type" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1" prop="dueDate"
                    name="Due Date" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value | date:'d MMM, y' }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1"
                    prop="applicantName" name="Applicant" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1.5" prop="assignToName" name="Assigned To"
                  [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="2.4"
                    prop="details" name="Details" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="default-according p-3" id="accordion">
            <div class="font-weight-bold f-family-oswald f-14 theme-font-color-red">
              Incomplete Tasks
              <a *ngIf="ngxdtConfigInCompleted.totalRecords > 5" (click)="onClick(2)" class="f-10 theme-font-color-dark pull-right pr-2 p-t-5">
                View All
              </a>
              <hr class="mt-1 hr-red">
            </div>
            <div class="ngxdt dashboard">
              <div class="ngxdt-body">
                <ngx-datatable #table class="bootstrap"
                  [footerHeight]="ngxdtConfigInCompleted.footerHeight" [limit]="ngxdtConfigInCompleted.pageSize"
                  [rows]="ngxdtConfigInCompleted.rows" [columnMode]="ngxdtConfigInCompleted.columnMode.force"
                  [offset]="ngxdtConfigInCompleted.tableOffset"
                  [scrollbarH]="true"
                  [scrollbarV]="true"
                  [selectionType]="ngxdtConfigTodays.selectionType.single"
                  [selected]="selected"
                  (select)="onSelect($event)"
                  [virtualization]="false">


                  <ngx-datatable-column [width]="100" [flexGrow]="1" headerClass="pl-4"
                    cellClass="pl-4 font-weight-500" prop="activityTitle" name="Activity" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="100" [flexGrow]="1.1"
                    prop="activityTypeName" name="Type" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1" prop="dueDate"
                    name="Due Date" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value | date:'d MMM, y' }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1"
                    prop="applicantName" name="Applicant" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1.5"
                    prop="assignToName" name="Assigned To" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="2.4"
                    prop="details" name="Details" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="default-according p-3" id="accordion">
            <div class="theme-font-color-blue font-weight-bold f-14 f-family-oswald">
              Upcoming Tasks
              <a *ngIf="ngxdtConfigUpcoming.totalRecords > 5" (click)="onClick(3)" class="f-10 theme-font-color-dark pull-right pr-2 p-t-5">
                View All
              </a>
              <hr class="mt-1">
            </div>
            <div class="ngxdt dashboard">
              <div class="ngxdt-body">
                <ngx-datatable #table class="bootstrap"
                  [footerHeight]="ngxdtConfigUpcoming.footerHeight" [limit]="ngxdtConfigUpcoming.pageSize"
                  [rows]="ngxdtConfigUpcoming.rows" [columnMode]="ngxdtConfigUpcoming.columnMode.force"
                  [offset]="ngxdtConfigUpcoming.tableOffset"
                  [scrollbarH]="true"
                  [scrollbarV]="true"
                  [selectionType]="ngxdtConfigTodays.selectionType.single"
                  [selected]="selected"
                  (select)="onSelect($event)"
                  [virtualization]="false">


                  <ngx-datatable-column [width]="100" [flexGrow]="1" headerClass="pl-4"
                    cellClass="pl-4 font-weight-500" prop="activityTitle" name="Activity" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>


                  <ngx-datatable-column [width]="100" [flexGrow]="1.1"
                    prop="activityTypeName" name="Type" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1" prop="dueDate"
                    name="Due Date" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value | date:'d MMM, y'}}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1"
                    prop="applicantName" name="Applicant" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1.5"
                    prop="assignToName" name="Assigned To" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="2.4"
                    prop="details" name="Details" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow">
          <div class="default-according p-3" id="accordion">
            <div class="theme-font-color-blue font-weight-bold f-14 f-family-oswald">
              Completed Tasks
              <a *ngIf="ngxdtConfigCompleted.totalRecords > 5" (click)="onClick(4)" class="f-10 theme-font-color-dark pull-right pr-2 p-t-5">
                View All
              </a>
              <hr class="mt-1">
            </div>
            <div class="ngxdt dashboard">
              <div class="ngxdt-body">
                <ngx-datatable #table class="bootstrap"
                  [footerHeight]="ngxdtConfigCompleted.footerHeight" [limit]="ngxdtConfigCompleted.pageSize"
                  [rows]="ngxdtConfigCompleted.rows" [columnMode]="ngxdtConfigCompleted.columnMode.force"
                  [offset]="ngxdtConfigCompleted.tableOffset"
                  [scrollbarH]="true"
                  [scrollbarV]="true"
                  [selectionType]="ngxdtConfigTodays.selectionType.single"
                  [selected]="selected"
                  (select)="onSelect($event)"
                  [sortType]="'single'"
                  (sort)="onSort($event)"
                  [externalSorting]="true"
                  [virtualization]="false">


                  <ngx-datatable-column [width]="100" [flexGrow]="1" headerClass="pl-4"
                    cellClass="pl-4 font-weight-500" prop="activityTitle" name="Activity" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>


                  <ngx-datatable-column [width]="100" [flexGrow]="1.1"
                    prop="activityTypeName" name="Type" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1" prop="dueDate"
                    name="Due Date" [sortable]="true" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value | date:'d MMM, y'}}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1" prop="completedDate"
                  name="Completed Date" [sortable]="true" [draggable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div title="{{ value }}">
                      {{ value | date:'d MMM, y'}}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1"
                    prop="applicantName" name="Applicant" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="1.5"
                    prop="assignToName" name="Assigned To" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column [width]="100" [flexGrow]="2.4"
                    prop="details" name="Details" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div title="{{ value }}">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-12 col-xl-4">
        <div class="card">
          <div class="card-body mb-4">
            <div class="datepicker-here dashboard-datepicker f-12 d-flex justify-content-center" data-language="en">
              <ngb-datepicker (click)="onCalenderDateClick($event)" firstDayOfWeek = '7' (navigate)="onNavigateMonth($event.next)" [(ngModel)]="model" [navigation]="navigation" [dayTemplate]="customDay" (dateSelect)="onDateSelection($event)">
                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
                  <span [class.currentMonthDay]="date.month == currentMonth">{{ date.day }}</span>
                  <span *ngIf="hasActivityLog(date)" class="event-day"
                         [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                  </span>
                </ng-template>
              </ngb-datepicker>              
            </div>
            <div class="row mt-5">
              <div class="w-100">
              </div>
              <div class="col" style="display: inline-block;
              overflow-y: auto;
              height: 510px;
              width: 100% !important;">
                <span class="theme-font-color-blue f-14 font-weight-bold roboto-font">
                  Activity Log
                  <hr>
                </span>
                <h6 class="text-center text-muted mt-3 roboto-font" *ngIf="isLoadingCalenderEvents">Loading events...</h6>
                <h6 class="text-center text-muted mt-3 roboto-font" *ngIf="!isLoadingCalenderEvents && callListData?.length === 0">No Activity Found.</h6>
                <div class="roboto-font" *ngFor="let callList of callListData">
                  <div class="col bg-light-gray my-3 container-fluid card px-3 pt-1 pb-2 w-100">
                    <div class="mb-1">
                      <span class="theme-font-color-blue font-weight-bold f-12 roboto-font">
                        {{ callList.activityTitle }}
                      </span>
                      <span (click)="gotoActivityLog(callList.applicantId, callList.isArchive)" class="theme-font-color-blue f-12 pull-right roboto-font pt-1 underlineText">
                        {{ callList.applicantName }}
                      </span>
                    </div>
                    <span class="theme-font-color-dark roboto-font" style="font-size: 11px;">
                      {{ callList.details ==='' ? 'NA' : callList.details  }} 
                    </span>
                  </div>
                  <div class="w-100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-user-access-permission [userPermission]="headerTitleService.PermissionVM.menuDashboard" hasFullHeight="true"></app-user-access-permission>
<!-- content Ends-->