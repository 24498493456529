import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../shared/models/response.model';
import { DataService }  from '../../../shared/services/data.service'
import { UpdateTimelineStatusModel} from './edit-timeline.model';

@Injectable({
  providedIn: 'root'
})
export class EditTimelineService {

  constructor(private service: DataService) { }

  getTimeline(applicantId:number): Observable<ResponseModel> {
    let url = '/gerapi/Timeline/get/'+ applicantId;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
    }));
  }
  updateTimelineStatus(model:UpdateTimelineStatusModel): Observable<ResponseModel> {
    let url = '/gerapi/Timeline/update';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  
  }

  //Top Timeline
  getTopTimelineData(applicantId:number): Observable<ResponseModel> {
    let url = '/gerapi/Timeline/getTopTimelineData/'+ applicantId;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
    }));
  }
}


