import { Component, Input, OnInit } from '@angular/core';
import { HeaderTitleService } from '@services/header-title.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicantsService } from '../applicants.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;
  notEditable: boolean = true;
  isSaving: boolean = false;

  constructor(public applicantService: ApplicantsService,
        public headerTitleService: HeaderTitleService,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn ;
  }
  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      //call update function here
      this.applicantService.personalInfoModel.NotesDetail = this.applicantService.notesModel.notesDetail;
      this.applicantService.updateApplicantPersonalInformation(this.applicantService.personalInfoModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          this.toaster.success(res.message)
        });;

    }
  }


  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    this.isSaving = false;
    return throwError(error);
  }

}

