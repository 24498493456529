import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgxDatatableSSRConfig } from "@models/ngx-datatable-ssr-config.model";
import {NgbDateStruct, NgbDropdownConfig, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { MastersVM, MasterValueVM } from 'src/app/components/adminConsole/master/master.model'
import { MasterService } from 'src/app/components/adminConsole/master/master.service'
import { ActivityLogVM, ReportRequestModel } from '../../reports/report.model'
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../reports/report.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../../shared/services/export_service/excel.service';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import {HeaderTitleService} from '@services/header-title.service';
import * as moment from 'moment';
import { HttpStatusCode } from '@angular/common/http';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-activity-log-report',
  templateUrl: './activity-log-report.component.html',
  styleUrls: ['./activity-log-report.component.scss'],
  providers: [NgbDropdownConfig]
})
export class ActivityLogReportComponent implements OnInit {
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  startDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    hoveredDate: NgbDateStruct;
    fromDate: any;
    toDate: any;
    model: any;
    mastersVM = new MastersVM();
    masterAdvisorsList: MasterValueVM[] = [];
     rows: ActivityLogVM[] = [];
     getAllActivityRequestModel = new ReportRequestModel();
     selectedSearchType: string = '';
     selectedApplicationNumber: string = '';
     selectFromDate:string='';
     selectToDate:string='';
     isApplicationNumberRequired = false;
    @ViewChild("d") input: NgbInputDatepicker;
    @ViewChild(NgModel) datePick: NgModel;
    @ViewChild('myRangeInput') myRangeInput: ElementRef;

    isHovered = date => 
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
    isInside = date => after(date, this.fromDate) && before(date, this.toDate);
    isFrom = date => equals(date, this.fromDate);
    isTo = date => equals(date, this.toDate);
    filename: string;
    totalRows: ActivityLogVM[] = [];

  constructor(config: NgbDropdownConfig,private calendar: NgbCalendar,element: ElementRef, private renderer: Renderer2, 
    public masterService: MasterService,
    private toaster: ToastrService,
    private headerTitleService: HeaderTitleService,
    public service: ReportService,
    private excelService: ExcelService,
    private _parserFormatter: NgbDateParserFormatter,) {
    config.placement = 'top-left';
    config.autoClose = false;
   }
   ngAfterViewInit(): void {
    this.loadActivityLogData();
  }

  ngOnInit(): void {
    this.headerTitleService.title='Activity-Log'
    this.initDatatable()
    this.loadAgencyDropdown();

    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
  }
  loadAgencyDropdown() {
    this.masterService.getMasters(this.isApplicationNumberRequired)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
        this.masterAdvisorsList = this.mastersVM.masterAdvisor.masterValues;
      });
  }
  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.getAllActivityRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: false,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchTypeId : 0
    };
  }

  loadActivityLogData(): void{
    this.service.getactivityLogData(this.getAllActivityRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res?.response;
      this.ngxDatatableSSRConfig.totalRecords = res?.totalRecords;
    });
  }

  downloadActivityLogReportData(fileType): void{
    this.getAllActivityRequestModel.getAll = true;
    this.service.getactivityLogData(this.getAllActivityRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.totalRows = res.response;
      if(fileType == 'PDF'){
        this.downloadAsPDF();
      }
      else if(fileType == 'Excel'){
        this.exportAsXLSX();
      }
    });
  }

  onPageSizeChanged(pageSize: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.getAllActivityRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchTypeId : 0
    };
    this.loadActivityLogData();
  }

  onPageChanged(pageNum: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);

    this.getAllActivityRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchTypeId : 0
    };
    this.loadActivityLogData();
  }
  search(){
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.getAllActivityRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchTypeId : 0
    };
    this.loadActivityLogData();
  }
  sortByAgency() {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.getAllActivityRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchTypeId : 0
    };
    this.loadActivityLogData();
  }
  
  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.input.close();
    } else {
      this.fromDate = date;      
      this.toDate = null;
    }
    if (this.fromDate) {
      let from= this._parserFormatter.format(this.fromDate);
      parsed += from;
      this.selectFromDate=parsed;
    }
    if (this.toDate) {
      let to=' - ' + this._parserFormatter.format(this.toDate);
      parsed += to;
      this.selectToDate=parsed;
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    if(this.fromDate!==null && this.toDate !=null){this.sortByAgency();}
  }

  exportAsXLSX(): void {
    var filename = this.headerTitleService.title
    this.excelService.exportAsExcelFile(this.totalRows,filename);
  }
  organise(arr) {
    var headers = [], // an Array to let us lookup indicies by group
      objs = [], // the Object we want to create
      i,
      j;
    for (i = 0; i < arr.length; ++i) {
      j = headers.indexOf(arr[i].id); // lookup
      if (j === -1) {
        // this entry does not exist yet, init
        j = headers.length;
        headers[j] = arr[i].id;
        objs[j] = {};
        objs[j].id = arr[i].id;
        objs[j].data = [];
      }
      objs[j].data.push(
        // create clone
        {
          case_worked: arr[i].case_worked,
          note: arr[i].note,
          id: arr[i].id,
        }
      );
    }
    return objs;
  }

  getBody(): any[]{
    let data = [];
    this.totalRows.forEach((s,index)=>data.push([index+1,s.applicantName,s.assignToUserName,s.dueDate]));
    return data;
  }
  
  public downloadAsPDF() {
  const doc = new jsPDF('l', 'mm', 'a4')
  autoTable(doc, {
    head: [['#', 'Applicant Name','Assign To User Name','Due Date']],
    body: this.getBody(),
  })
  doc.save(this.headerTitleService.title+ '-'+ moment(new Date()).format('YYYY-MM-DD'));

  }
  onDateRangeChange(data){
    this.selectFromDate=this._parserFormatter.format(data.startDate);
    this.selectToDate=this._parserFormatter.format(data.endDate);
    this.sortByAgency();
    this.searchdata();
  }
  searchdata() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadActivityLogData();
  }

}
