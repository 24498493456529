import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService, OperationTypeEnum } from '@services/header-title.service';
import { StorageService } from '@services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MastersVM } from '../../adminConsole/master/master.model';
import { MasterService } from '../../adminConsole/master/master.service';
import { User } from '../../adminConsole/users-management/user-list/user-list.model';
import { getAllActivityRequestModel, ActivityVM, ActivityModalModel, Task } from '../../applicants/applicant.model';
import { ApplicantsService } from '../../applicants/applicants.service';
import { AddUpdateActivityLogComponent } from '../../applicants/edit-activity-log/add-update-activity-log/add-update-activity-log.component';
import { HttpStatusCode } from '@angular/common/http';
import { DashboardService } from '../dashboard.service';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-view-all-activity-log',
  templateUrl: './view-all-activity-log.component.html',
  styleUrls: ['./view-all-activity-log.component.scss']
})
export class ViewAllActivityLogComponent implements OnInit {
  
  @Input('taskId') task: number;
  taskId=Task;

  getAllActivityRequestModel = new getAllActivityRequestModel();
  ngxdtConfigTodays = new NgxDatatableSSRConfig();
  modalTitle: string;
  mastersVM = new MastersVM();
  public isLoading = false;
  activityModel = new ActivityVM();
  userDetails: User;
  public selected = [];
  public operationId = OperationTypeEnum;
  
  constructor(public applicantService: ApplicantsService,
    public dashboardService: DashboardService,
    config: NgbModalConfig,
    public storageService: StorageService,
    public router: Router,
    private modalService: NgbModal,
    public headerTitleService: HeaderTitleService,
    public masterService: MasterService,
    private config1: NgbModalConfig,
    private toaster: ToastrService) {
    this.config1.centered = true;
  }
  ngOnInit(): void {

    this.ngxdtConfigTodays = new NgxDatatableSSRConfig();
    this.ngxdtConfigTodays.currentPageNumber = 1;
    this.ngxdtConfigTodays.pageSize = 15;

    
    this.userDetails = this.storageService.retrieve('user');
    this.getAllActivityRequestModel.userId = (this.userDetails?.userRole == "Admin") ? "" : this.userDetails?.userId;

    if (this.headerTitleService.PermissionVM.menuDashboard)
    this.loadListData();
    
    this.loadMasters();
  }
  loadListData() {    
    //Todays Activity Start
    this.getAllActivityRequestModel.taskId = this.task;
    this.getAllActivityRequestModel.applicantId = 0;
    this.getAllActivityRequestModel.sortColumn = this.getAllActivityRequestModel.sortColumn??'';
    this.getAllActivityRequestModel.sortDirection = this.getAllActivityRequestModel.sortDirection.toLocaleUpperCase()??'DESC';
    this.getAllActivityRequestModel.getAll = this.ngxdtConfigTodays.getAll;
    this.getAllActivityRequestModel.pageNumber = this.ngxdtConfigTodays.currentPageNumber;
    this.getAllActivityRequestModel.pageSize = this.ngxdtConfigTodays.pageSize;
    
    this.dashboardService.getAllActivity(this.getAllActivityRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.ngxdtConfigTodays.updateTableData(res);
      });
    //Todays Activity Close
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxdtConfigTodays.rows = [];
    this.ngxdtConfigTodays.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
     //statements;
     this.ngxdtConfigTodays.rows = [];
     this.ngxdtConfigTodays.onPageChanged(pageNum);
     this.loadListData();
  }

  loadMasters() {
    this.masterService.getMasters()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
      });
  }
  
  deleteActivity(deleteModel: ActivityModalModel) {
    {
      this.isLoading = true;
      Swal.fire({
        title: 'Do you want to delete activity?',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.applicantService.deleteActivity(deleteModel)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe(res => {
              if (res.isSuccess == true) {

                this.toaster.success(res.message);
                this.resetAll();
                this.loadListData();
              }
              this.isLoading = false;
            });
        }
      });
    }
  }

  openModal(editModel: ActivityModalModel = null, operationId: OperationTypeEnum = this.operationId.Create): void {
    this.activityModel = new ActivityVM();
    if (editModel !== null) {
      this.activityModel.id = editModel.id;
      this.activityModel.applicantId = editModel.applicantId;
      this.activityModel.activityTypesId = editModel.activityTypesId;
      this.activityModel.activityTitle = editModel.activityTitle;
      this.activityModel.dueDate = editModel.dueDate;
      this.activityModel.completedDate = editModel.completedDate;
      this.activityModel.assignToUserId = editModel.assignToUserId;
      this.activityModel.stagesId = editModel.stagesId;
      this.activityModel.details = editModel.details;

      this.config1.centered = true;
      const modalRef = this.modalService.open(AddUpdateActivityLogComponent);
      modalRef.componentInstance.operationId = operationId;
      modalRef.componentInstance.activityModalModel = this.activityModel;


      modalRef.componentInstance.activityLogListRefresh.subscribe((receivedEntry) => {
        if (receivedEntry) {
          this.ngOnInit();
        }
      })
    }
  }

  resetAll() {
    this.activityModel = new ActivityVM();
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }


  onSelect({ selected }) {
    this.storageService.store('applicantDetailsTID', 4);
    this.router.navigate(['applicants-list/edit-applicant'], { queryParams: { applicantId: this.selected[0].applicantId, isArchive: this.selected[0].isArchive } });
  }

  onSort(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.getAllActivityRequestModel.sortColumn = sortInfo.sorts[0].prop;
    this.getAllActivityRequestModel.sortDirection =sortInfo.sorts[0].dir;
    this.loadListData();
   }
}


