import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {

  // @Input('value') value:string; // see enum CheckboxValue for possible values
  // @Input('disabled') disabled:boolean;

  // @Output('change') change: EventEmitter<boolean> = new EventEmitter();

  // checkboxValue: typeof CheckboxValue = CheckboxValue;

  constructor() { }

  ngOnInit(): void {
  }

  // onChange(event){
  //   this.change.emit(event.target.checked);
  // }

}

export enum CheckboxValue{
  none,
  partial,
  all
}
