import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {ResponseModel} from '../../shared/models/response.model'
import {DataService} from '../../shared/services/data.service'

@Injectable({
  providedIn: 'root'
})
export class AllNotificationsService {

  constructor(private service: DataService) { }

  //for all notification list
  getAllNotifications(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Notification/get';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

 
//for notification count
  addMarkAsRead(id:number, isAll:boolean): Observable<ResponseModel> {
    let url = `/gerapi/Notification/markasread/${id}/${isAll}`
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
    }));
  }
  //delete Notification
  deleteNotification(id:number, isAll:boolean): Observable<ResponseModel> {
    let url = `/gerapi/Notification/delete/${id}/${isAll}`
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
    }));
  }
}


