import { HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';
import { StorageService } from '@services/storage.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuditLogsComponent } from '../../adminConsole/audit-logs/audit-logs.component';
import { MastersVM } from '../../adminConsole/master/master.model';
import { MasterService } from '../../adminConsole/master/master.service';

import { FolderType, UploadDocumentRequestModel, DocumentModel } from './edit-documents.model';
import { EditDocumentsService } from "./edit-documents.service";
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-edit-documents',
  templateUrl: './edit-documents.component.html',
  styleUrls: ['./edit-documents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditDocumentsComponent implements OnInit {
  @Input() applicantId: number = 0;
  @Output() refreshDocumentList: EventEmitter<boolean> = new EventEmitter();
  activeScreen: number = 1;
  mastersVM = new MastersVM();
  folderTypeEnum = FolderType;

  isContinued: boolean = false;

  files: File[] = [];
  fileList: DocumentModel[];
  currentFile: DocumentModel;
  currentFileindex: number = 0;
  savedFileCount: number = 0;

  public configDropZone: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: true,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };

  constructor(
    public storageservice: StorageService,
    private configModal: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private service: EditDocumentsService,
    private masterService: MasterService,
    private config1: NgbModalConfig,
    public headerTitleService: HeaderTitleService,
  ) {
    this.configModal.centered = true;
    this.configModal.size = "xl";
  }

  ngOnInit(): void {
    this.storageservice.store('applicantDetailsTID', 2);
    this.activeScreen = 1;
    this.currentFileindex = 0;
    this.savedFileCount = 0;
    this.isContinued = false;

    if (this.headerTitleService.PermissionVM.documents) {
      this.loadMasters();
    }
  }

  loadMasters() {
    this.masterService.getMasters(false)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
      });
  }

  private handleError(error: any) {
if (error.status === HttpStatusCode.Forbidden) {
} else {
  this.toaster.error(error.error.message);
}
return throwError(error);
}

  //#region Modal functions
  openModal(modalId: any) {
    this.modalService.open(modalId);
  }
  closeModal(modal: any) {
    Swal.fire({
      title: 'Are you sure you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.files = [];
        this.activeScreen = 1;
        modal.close();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });


  }
  //#endregion Modal functions

  //#region screen-1 functions
  onSelect(event, modalId) {
    if ((this.files.length + event.addedFiles.length) <= 20) {
      this.files.push(...event.addedFiles);
    }
    else {
      this.toaster.warning('You can add maximum 20 files.', "Limit reached");
    }

    if (!this.modalService.hasOpenModals()) {
      this.modalService.open(modalId);
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async showScreen2() {
    this.isContinued = true;

    return new Promise((_resolve, _reject) => {
      this.fileList = [];
      this.files.forEach(async (file) => {
        const fileExt = file.name.split('.').pop();
        const result = await this.getPreviewImage(file);
        this.fileList.push({
          applicantId: this.applicantId,
          documentTypesId: 0,
          folderTypeId: 0,
          file: file,
          fileMimeType: file.type,
          fileName: file.name,
          fileExt: fileExt,
          uploadProgressValue: 0,
          isFileSaved: false,
          isSaving: false,
          uploadSubscription: null,
          src: result
        })
      });
      setTimeout(() => {
        this.currentFile = this.fileList[0];
        this.currentFileindex = 0;
        this.isContinued = false;
        this.activeScreen = 2;
      }, 5000);
    });
  };

  getPreviewImage(file: File): Promise<string> {
    return new Promise((resolve, _reject) => {
      let fileAsString: string;
      const reader = new FileReader();
      reader.onload = () => {
        fileAsString = reader.result as string;
      }
      reader.readAsDataURL(file);

      setTimeout(() => {
        resolve(fileAsString);
      }, 5000);
    });
  }

  formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  //#endregion screen-1 functions

  //#region screen-2 functions
  moveNext() {
    if (this.currentFileindex != this.fileList.length - 1) {
      this.currentFileindex += 1;
      this.currentFile = this.fileList[this.currentFileindex];
    }
  }
  movePrevious() {
    if (this.currentFileindex > 0 && this.fileList.length > 0) {
      this.currentFileindex -= 1;
      this.currentFile = this.fileList[this.currentFileindex];
    }
  }

  removeSelectedFile(): void {
    this.fileList.splice(this.currentFileindex, 1);
    if (this.currentFileindex == this.fileList.length) {
      this.currentFileindex -= 1;
    }
    this.currentFile = this.fileList[this.currentFileindex];
    if(this.currentFile == undefined){
      this.currentFile =  new DocumentModel();
    }
  }

  updateSavedFileCount() {
    this.savedFileCount = this.fileList.filter(s => s.isFileSaved == true)?.length;
    if (this.fileList.length == this.savedFileCount) {
      this.toaster.success('All files are uploaded successfully!', "Upload Done");
      this.modalService.dismissAll();
      this.files = [];
      this.activeScreen = 1;
    }
  }

  uploadDoc() {
    const currentFileindex = this.currentFileindex;
    this.fileList[currentFileindex].isSaving = true;

    let uploadDocumentRequestModel: UploadDocumentRequestModel = {
      applicantId: this.currentFile.applicantId,
      documentTypesId: this.currentFile.documentTypesId,
      folderTypeId: this.currentFile.folderTypeId,
      file: this.currentFile.file
    };

    let formData = new FormData();

    // Optional, append other kev:val rest data to the form.
    Object.keys(uploadDocumentRequestModel).forEach(key => {
      formData.append(key, uploadDocumentRequestModel[key]);
    });

    const upload$ = this.service.uploadDocument(formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        finalize(() => this.reset(currentFileindex))
      );

    this.fileList[currentFileindex].uploadSubscription = upload$.subscribe(event => {
      if (event.type == HttpEventType.UploadProgress) {
        this.fileList[currentFileindex].uploadProgressValue = Math.round(100 * (event.loaded / event.total));
      }
      if (event.type == HttpEventType.Response) {
        this.fileList[currentFileindex].isFileSaved = true;
        this.updateSavedFileCount();
        this.refreshDocumentList.emit(true);
      }
    });

  }

  cancelUpload() {
    Swal.fire({
      title: 'Are you sure you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.fileList[this.currentFileindex].uploadSubscription.unsubscribe();
        this.reset(this.currentFileindex);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  reset(currentFileindex) {
    this.fileList[currentFileindex].uploadSubscription = null;
    this.fileList[currentFileindex].isSaving = false;
  }
  //#endregion screen-2 functions

  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.pageId = 2;
  }
  // Open Audit Trail Model //
}
