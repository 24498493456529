import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../components/auth/login/login.service';

@Injectable({
    providedIn: 'root'
  })
  export class AdminGuard implements CanActivate {
    private storage :any;
    storageSetDone: boolean = false;
    constructor(public loginService: LoginService,
      public router: Router) {
        this.setStorage();
       }
  
      setStorage() {
        const rememberMe = localStorage.getItem('rememberMe');
        this.storage = (rememberMe && rememberMe == "true") ? localStorage: sessionStorage; 
      }

    canActivate(next: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // Guard for user is login or not      
      // let url = "";
      // this.router.getCurrentNavigation().extractedUrl.root.children.primary.segments.forEach(s=> url = url + '/' + s.path);
      if(!this.storageSetDone)this.setStorage();
      let user = this.storage.getItem('user');
      if (!user || user === null) {
        this.router.navigate(['/auth/login']);
        return true
      }
      else if (user) {
        if (!Object.keys(user).length) {
          this.router.navigate(['/auth/login']);
          return true
        }
      }
      return true
    }
    
  }
  