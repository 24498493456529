import { PaginationBaseModel } from "@models/pagination.model";

export class AuditLogsModel {
  id: number;
  user: string;
  userRole: string;
  createdOn: string;
  auditActionSourceId: number;
  auditActionSource: string;
  auditActionId: number;
  auditAction: string;
  applicantId: number;
  applicantName: string;
  fieldName: string;
  oldValue: string;
  newValue: string;
  timeStamp: string;
  totalRecords: number;
}


export enum AuditActionSource {
  "New Applicants" = 1,
  // "Applicant Information" = 2,
  "Documents" = 3,
  "Timeline" = 4,
  // "Payment Received" = 5,
  "Activity Log" = 6,
  // "User Creation" = 7,
  // "Additional Payment"=8

  //please assign value from 11 to 20 for the Applicant Information
  "Personal Information" = 11,
  "Family Information" = 12,
  "Background Information" = 13,
  "Program Information" = 14,
  "Payment Information" = 15,
  "Immigration Information" = 16,

  //please assign value from 31 to 40 for the Payment Received
  // "Payment Received" = 31,
  "Applicant Payments" = 32,
  "Liaison Payments" = 33,
  "Agency Payments" = 34,
  "GER Advisor Payments" = 36,
  "Additional Payments" = 35,

}

export class AuditActionSourceModel
{
  displayName: string;
  displayValue:number;
}

export class AuditLogsRequestModel extends PaginationBaseModel{
  auditActionSourceId: number = 0;
  applicantId: number = 0;
}
