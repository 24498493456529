import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../shared/models/response.model';
import { DataService } from '../../../shared/services/data.service';
import { GetAllDocumentsQuery } from '../view-all-documents/view-all-documents.model';
@Injectable({
  providedIn: 'root'
})
export class ViewAllDocumentsService {

  constructor(private service: DataService) { }

  getAllDocumentData(data: GetAllDocumentsQuery): Observable<ResponseModel> {
    let url = '/gerapi/Document/GetAll';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
   }

  deleteDocument(id: number): Observable<ResponseModel> {
    let url = '/gerapi/document/delete/' + id;
    return this.service.putWithId(url,{}).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getPublicUrl(id: number, action: string): Observable<ResponseModel> {
    let url = `/gerapi/document/getPublicUrl?id=${encodeURIComponent(id)}&action=${encodeURIComponent(action)}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

   formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}