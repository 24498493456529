<div class="content-wrapper">
  <div class="card card-full-height" *ngIf="headerTitleService.PermissionVM.applicantList">
    <div class="card-body px-0 pt-2 pb-2">
      <div class="ngxdt applicant-list">
        <div class="ngxdt-header applicant">
          <div class="ngxdt-reports-button mx-3" *ngIf="headerTitleService.PermissionVM.exportApplicantList">
            <span ngbDropdown placement="bottom" autoClose="true">
                <button class="btn-download" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="icofont icofont-download-alt"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <li (click)="downloadApplicantListData('PDF')">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      <span>Export as PDF</span>
                    </li>
                    <hr class="hr-gray">
                    <li (click)="downloadApplicantListData('Excel')">
                      <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                      <span>Export as Excel</span>
                    </li>
                  </div>
            </span>
        </div>
          <svg (click)="openAuditLog()" class="align-self-center mr-3" *ngIf="headerTitleService.PermissionVM.auditLog"
            type="button" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
            <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
              <g id="Group_605" data-name="Group 605">
                <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7"
                  transform="translate(10.764 3)" fill="#a1a1a1" />
                <path id="XMLID_32_"
                  d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z"
                  transform="translate(14.762 -10.655)" fill="#fff" />
              </g>
            </g>
          </svg>
          <div class="ngxdt-buttons">
            <button type="button" class="btn btn-add nav-pills"
              [ngClass]="{'bg-gray':pageRequestModel.isArchive,'bg-blue':!pageRequestModel.isArchive}"
              (click)="archiveClicked()" type="button">
              <svg class="mr-2 align-middle" *ngIf="!pageRequestModel.isArchive" xmlns="http://www.w3.org/2000/svg"
                width="16.659" height="12.057" viewBox="0 0 16.659 15.057">
                <path id="folder"
                  d="M15.217,28.2V27.082a1.443,1.443,0,0,0-1.442-1.442H7.029l-.252-.411a.481.481,0,0,0-.41-.23H1.442A1.443,1.443,0,0,0,0,26.442V38.615a1.443,1.443,0,0,0,1.442,1.442H15.217a1.443,1.443,0,0,0,1.442-1.442v-8.97A1.443,1.443,0,0,0,15.217,28.2Zm-1.442-1.6a.481.481,0,0,1,.481.481V28.2H8.6l-.643-1.05L7.617,26.6ZM15.7,38.615a.481.481,0,0,1-.481.481H1.442a.481.481,0,0,1-.481-.481V26.442a.481.481,0,0,1,.481-.481H6.1l.252.411h0l.926,1.513.643,1.051a.481.481,0,0,0,.41.23h6.888a.481.481,0,0,1,.481.481v8.97Z"
                  transform="translate(0 -25)" fill="#fff" />
              </svg>
              <svg class="mr-2 align-middle" xmlns="http://www.w3.org/2000/svg" *ngIf="pageRequestModel.isArchive"
                width="14.147" height="14.147" viewBox="0 0 19.147 19.147">
                <path id="user_5_" data-name="user (5)"
                  d="M16.343,2.8A9.574,9.574,0,0,0,2.8,16.343,9.574,9.574,0,0,0,16.343,2.8ZM4.155,16.055a5.477,5.477,0,0,1,5.419-4.82,5.457,5.457,0,0,1,5.419,4.82,8.434,8.434,0,0,1-10.837,0Zm5.419-5.976a2.908,2.908,0,1,1,2.908-2.908A2.911,2.911,0,0,1,9.574,10.079Zm6.384,5.027a6.6,6.6,0,0,0-4.128-4.6,4.03,4.03,0,1,0-4.511,0,6.589,6.589,0,0,0-4.13,4.6,8.452,8.452,0,1,1,12.77,0Zm0,0"
                  fill="#fff" />
              </svg>
              <span class="f-10" style="position: relative;top: 1px;">
                {{!pageRequestModel.isArchive?'VIEW ARCHIVE':'VIEW ACTIVE APPLICANT LIST'}}
              </span>
            </button>
          </div>
          <div class="ngxdt-search">
            <div class="ngxdt-search-wrapper">
              <i class="fa fa-search"></i>
              <input type="text" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText"
                (keyup.enter)="search()">
              <button class="btn-search" (click)="search()">
                <i class="fa fa-filter"></i>
              </button>
            </div>
          </div>
        </div>
  
        <div class="ngxdt-body">
          <ngx-datatable #table class="bootstrap" (sort)="onSort($event)" [footerHeight]="ngxDatatableSSRConfig.footerHeight"
            [limit]="ngxDatatableSSRConfig.pageSize" [rows]="ngxDatatableSSRConfig.rows"
            [columnMode]="ngxDatatableSSRConfig.columnMode.force" [offset]="ngxDatatableSSRConfig.tableOffset"
            [selectionType]="ngxDatatableSSRConfig.selectionType.single" [selected]="selected" (select)="onSelect($event)"
            [scrollbarH]="true" [scrollbarV]="true"  [externalSorting]="true" [virtualization]="false">
            <ngx-datatable-column [width]="100" headerClass="make-center" cellClass="make-center" name="#"
              [sortable]="false" [draggable]="false">
              <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.currentPageNumber - 1)) + rowIndex}}.
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column  [width]="200" prop="applicantName" name="Name" [sortable]="true" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div title="{{ value }}">
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="120" prop="doEngagement" name="Date of Engagement" [sortable]="true"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'MM/dd/YY'  }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="120" prop="gerApplicationNumber" name="GER ID Number" [sortable]="true"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="sponsorName" name="Sponsor" [sortable]="true" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div title="{{ value }}">
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" prop="agencyName" name="Agency" [sortable]="true" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="stageName"  name="Stage" [sortable]="true" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div title="{{ value }}">
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="120" prop="applicationNumber" name="Applicant Number" [sortable]="true"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div>
                  <div class="page-size-control">
                    Show
                    <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                      (ngModelChange)="onPageSizeChanged($event)">
                      <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}
                      </option>
                    </select>
                    entries
                  </div>
                  <div class="pagination-control">
                    <ngb-pagination #ngbPage [boundaryLinks]="false" [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                      [pageSize]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize" [page]="ngxDatatableSSRConfig.currentPageNumber"
                      [maxSize]="5" (pageChange)="onPageChanged($event)">
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
  
          </ngx-datatable>
        </div>
        <div class="ngxdt-footer">
  
        </div>
      </div>
  
    </div>
  </div>
</div>


<app-user-access-permission [userPermission]="headerTitleService.PermissionVM.applicantList">
</app-user-access-permission>