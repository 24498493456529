<div>
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat d-flex align-items-center" (click)="showDropDown=!showDropDown" [disabled]="isDisabled">
            <span *ngIf="checkedList.length<=0" class="color-gray f-12">Select</span>
            <span *ngIf="checkedList.length>0" class="color-gray f-12">{{ checkedList.length >= (list.length-1) ? 'All' : checkedList.join(', ') }}</span>
            <i class="fa fa-angle-down color-gray"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <label *ngFor="let a of list" class="d-flex align-items-center mb-0">
                <div class="d-flex align-items-center w-100" (click)="onClickDisabledAgecny(a)">
                    <input type="checkbox" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked,a.name,a.id)" [disabled]="a.isInUsed" />
                    <span class="ml-2 text-muted f-12">{{a.name}}</span>
                </div>
            </label>
        </div>
    </div>
</div>
