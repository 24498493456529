import { Injectable } from '@angular/core';
import { ApplicantModel, ActivityVM, MaritalStatus, BackgroundInfoModel, FamilyInfoModel, NotesModel, PaymentInfoModel, PersonalInfoModel, ProgramInfoModel, CheckApplicationNumberExistsModel } from './applicant.model';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/services/data.service';
import { ImmigrationInfoModel, ImmigrationLCDeniedChangeSponsorOptionEnum } from './applicant-immigration.model';

 
@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {
  personalInfoModel = new PersonalInfoModel();
  familyInfoModel = new FamilyInfoModel();
  backgroundInfoModel = new BackgroundInfoModel();
  programInfoModel = new ProgramInfoModel();
  immigrationInfoModel = new ImmigrationInfoModel();
  paymentInfoModel = new PaymentInfoModel();
  notesModel = new NotesModel();

  immigrationLCDeniedChangeSponsorOptionEnum = ImmigrationLCDeniedChangeSponsorOptionEnum;
  
  maritalStatus = MaritalStatus;
  disableAdvisorSelect: boolean = false;
  public applicant =new BehaviorSubject<any>(undefined);
  masterListData = new Subject();

  constructor(private service: DataService) { }

  ResetAll() {
    this.personalInfoModel = new PersonalInfoModel();
    this.familyInfoModel = new FamilyInfoModel();
    this.backgroundInfoModel = new BackgroundInfoModel();
    this.programInfoModel = new ProgramInfoModel();
    this.immigrationInfoModel = new ImmigrationInfoModel()
    this.paymentInfoModel = new PaymentInfoModel();
    this.notesModel = new NotesModel();
    this.maritalStatus = MaritalStatus;
    this.disableAdvisorSelect = false;
  }

  updateSponsorEditableStatus(isEditingApplicant: boolean = false){
    if(isEditingApplicant) return;

    if(this.immigrationInfoModel.lcDenied_ChangeSponsorOptionId == this.immigrationLCDeniedChangeSponsorOptionEnum.Yes){
      if(this.immigrationInfoModel.lcDenied_NewSponsorId > 0){
        this.programInfoModel.sponsorsId = this.immigrationInfoModel.lcDenied_NewSponsorId;
        this.programInfoModel.disableSponsor = true;
      }
      else{
        this.programInfoModel.disableSponsor = false;
      }
    }
    else{
      this.programInfoModel.disableSponsor = false;
      this.immigrationInfoModel.lcDenied_NewSponsorId = 0;
    }
  }

  calculateAge(dob): number {
    if (dob == 0) {
      return 0;
    }
    const convertAge = new Date(dob);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  createApplicant(model: ApplicantModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/create';
    return this.service.post(url, model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  createActivity(model: ActivityVM): Observable<ResponseModel> {
    if (model.id == 0) {
      let url = '/gerapi/ActivityLog/create';
      return this.service.post(url, model).pipe<ResponseModel>(tap((response: any) => {
        return response;
      }));
    }
    else {
      let url = '/gerapi/ActivityLog/update';
      return this.service.post(url, model).pipe<ResponseModel>(tap((response: any) => {
        return response;
      }));
    }
  }

  deleteActivity(deleteActivity: any): Observable<ResponseModel> {
    let url = '/gerapi/ActivityLog/delete';
    return this.service.post(url, deleteActivity).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));

  }

  getAllApplicants(data: any): Observable<ResponseModel> {
    let url = '/gerapi/applicant/getall';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getAllActivity(data: any): Observable<ResponseModel> {
    let url = '/gerapi/activitylog/getall';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  #region //edit applicant

  hasApplicantAccess(applicantId: number): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/hasApplicantAccess/' + applicantId.toString();
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getApplicantInformation(applicantId: number): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/get/' + applicantId.toString();
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Personal Information
  updateApplicantPersonalInformation(personalInfoModel: PersonalInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/personalinfo/update';
    return this.service.post(url, personalInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Family Information
  updateApplicantFamilyInformation(familyInfoModel: FamilyInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/familyInfo/update';
    return this.service.post(url, familyInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Background Information
  updateApplicantBackgroundInformation(backgroundInfoModel: BackgroundInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/backgroundinfo/update';
    return this.service.post(url, backgroundInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Program Information
  updateApplicantProgramInformation(programInfoModel: ProgramInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/programinfo/update';
    return this.service.post(url, programInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Immigration Information
  updateApplicantImmigrationInformation(immigrationInfoModel: ImmigrationInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/immigrationinfo/update';
    return this.service.post(url, immigrationInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  checkApplicationNumberExists(checkApplicationNumberExistsModel: CheckApplicationNumberExistsModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/programinfo/checkApplicationNumberExists';
    return this.service.post(url, checkApplicationNumberExistsModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // Payment Information
  updateApplicantPaymentInformation(paymentInfoModel: PaymentInfoModel): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/paymentInfo/update';
    return this.service.post(url, paymentInfoModel).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }


  //Archive UnArchive ById

  ArchiveUnArchiveById(applicantId: number): Observable<ResponseModel> {
    let url = '/gerapi/Applicant/archiveUnArchiveById/' + applicantId.toString();
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  #endregion

  //get assignTo users by agency
  public getAssignToUsers(applicantId: number): Observable<ResponseModel> {
    let url = `/gerapi/activitylog/getassigntousers?applicantId=${applicantId}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}
