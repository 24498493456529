<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="header">
            <div class="header-content-left">
                <div class="card">
                    <div class="title">
                        <span class="font-weight-bold f-family-oswald">{{toptimeline.applicantName }}</span>
                        <div class="content">
                            GER ID Number: {{ toptimeline?.gerApplicationNumber }}
                            <br>
                            Sponsor: {{ toptimeline?.sponsorName }}
                            <br>
                            Agency: {{ toptimeline?.agencyName }}
                            <br>
                            Level: {{ toptimeline?.levelName }}
                            <br>
                            Tandem: {{ toptimeline?.tandemProgram??"NA" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-content-right">
                <div class="status top-timeline">
                    <!-- <div class="status-bar"></div> -->
                    <!-- <ngb-progressbar class="header-progressbar" [value]="toptimeline?.progressBarValue" height="5px"></ngb-progressbar> -->
                    <ngb-progressbar class="header-progressbar" [ngClass]="{'header-progressbar-0':toptimeline.progressBarValue == 0,'header-progressbar':toptimeline.progressBarValue != 0}" [value]="toptimeline.progressBarValue" height="5px"></ngb-progressbar>
                    <div class="status-content">
                        <div class="status-item">                            
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage1?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage1?.stageName}}</span>
                            </div>
                        </div>
                        <div class="status-item">
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage2?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage2?.stageName}}</span>
                            </div>
                        </div>
                        <div class="status-item">
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage3?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage3?.stageName}}</span>
                            </div>
                        </div>
                        <div class="status-item">
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage4?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage4?.stageName}}</span>
                            </div>
                        </div>
                        <div class="status-item">
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage5?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage5?.stageName}}</span>
                            </div>
                        </div>
                        <div class="status-item">
                            <div class="status-action">
                                <div class="timeline-action">
                                    <app-timeline-status-icon [timelineStatusId]="toptimeline?.stage6?.stageStatusId">
                                    </app-timeline-status-icon>
                                </div>
                            </div>
                            <div class="status-heading">
                                <span class="title">{{toptimeline?.stage6?.stageName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>