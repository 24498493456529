import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { HeaderTitleService } from '@services/header-title.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicantsService } from '../applicants.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;
  notEditable: boolean = true;
  isSaving: boolean = false;
  
  constructor(public applicantService: ApplicantsService,
    private toaster: ToastrService,
    public headerTitleService:HeaderTitleService,
    public renderer: Renderer2) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn ;
  }

  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      //call update function here
      this.applicantService.updateApplicantPaymentInformation(this.applicantService.paymentInfoModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          this.toaster.success(res.message)
        });
    }
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    this.isSaving = false;
    return throwError(error);
  }

  onKeydown(event: any, value: any) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }


//#region calculations

  // For Agreement with Applicant //
  calApplAgreement(event): number {
    return this.stringToNumber(event.target.value);
  }

  sumApplPAgreement() {
    let installmentSum = this.stringToNumber(this.applicantService.paymentInfoModel.applicant_P1) +
      this.stringToNumber(this.applicantService.paymentInfoModel.applicant_P2) +
      this.stringToNumber(this.applicantService.paymentInfoModel.applicant_P3) +
      this.stringToNumber(this.applicantService.paymentInfoModel.applicant_P4)
    // return  installmentSum;
    this.applicantService.paymentInfoModel.applicant_TotalAgreed = this.stringToNumber(installmentSum.toFixed(2));

  }

  // For Agreement with Liaison //
  calLiaisonAgreement(event): number {
    return this.stringToNumber(event.target.value);
  }

  sumLiaisonPAgreement() {
    let installmentSum = this.stringToNumber(this.applicantService.paymentInfoModel.coordinator_P1) +
      this.stringToNumber(this.applicantService.paymentInfoModel.coordinator_P2) +
      this.stringToNumber(this.applicantService.paymentInfoModel.coordinator_P3) +
      this.stringToNumber(this.applicantService.paymentInfoModel.coordinator_P4)
    // return  installmentSum;
    this.applicantService.paymentInfoModel.coordinator_TotalAgreed = this.stringToNumber(installmentSum.toFixed(2));

  }

  // For Agreement with Agency //
  calAgencyAgreement(event): number {
    return this.stringToNumber(event.target.value);
  }

  sumAgencyPAgreement() {
    let installmentSum = this.stringToNumber(this.applicantService.paymentInfoModel.agency_P1) +
      this.stringToNumber(this.applicantService.paymentInfoModel.agency_P2) +
      this.stringToNumber(this.applicantService.paymentInfoModel.agency_P3) +
      this.stringToNumber(this.applicantService.paymentInfoModel.agency_P4)
    // return  installmentSum;
    this.applicantService.paymentInfoModel.agency_TotalAgreed = this.stringToNumber(installmentSum.toFixed(2));

  }


  // For GerAdvisor with Agency //
  calGerAdvisorAgreement(event): number {
    return this.stringToNumber(event.target.value);
  }

  sumGerAdvisorPAgreement() {
    let installmentSum = this.stringToNumber(this.applicantService.paymentInfoModel.gerAdvisor_P1) +
      this.stringToNumber(this.applicantService.paymentInfoModel.gerAdvisor_P2) +
      this.stringToNumber(this.applicantService.paymentInfoModel.gerAdvisor_P3) +
      this.stringToNumber(this.applicantService.paymentInfoModel.gerAdvisor_P4)
    // return  installmentSum;
    this.applicantService.paymentInfoModel.gerAdvisor_TotalAgreed = this.stringToNumber(installmentSum.toFixed(2));

  }

//#endregion

//#region  Convertors
  stringToNumber(value) {
    if (value === '') {
      return parseFloat("0");
    }
    else {
      return parseFloat(value);
    }
  }

  stringToDecimal(event) {
    if (event.target.value == "") {
      event.target.value = "0.00";
    }
    event.target.value = parseFloat(event.target.value).toFixed(2);
  }

  //#endregion

}
