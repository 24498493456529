
export class ImmigrationInfoModel {
    id: number;
    applicantId: number;
    immigrationProcessId: number;
    priorityDate: string;
    lc_CaseNumber: string;
    lc_ApprovalDate: string;
    lc_ExpirationDate: string;

    lcAudit_OptionSelected:boolean;
    lcAudit_DOLNotificationDate: string;//new
    lcAudit_DOLAuditDueDate: string;
    lcAudit_AuditResponseDate: string;

    lcDenied_OptionSelected:boolean;
    lcDenied_DenialDate: string;//new
    lcDenied_DateRefile: string;
    lcDenied_NewLCCaseNumber: string;
    lcDenied_ChangeSponsorOptionId: number;
    lcDenied_NewSponsorId: number;

    i94_ArrivalToUSADate: string;//new
    i94_90DaysPostArrivalDate: string;//new // readonly
    i94_ExpirationDate: string;//new

    i140_FilingDate: string;
    i140_PremiumProcessId: number;
    i140_ApprovalDate: string;
    i140_uscisCaseNumber: string;
    i140_RadioOptionId: number;
    i140RFE_NotificationDate: string;//new
    i140RFE_DueDate: string;
    i140RFE_ResponseSentDate: string;
    i140Denied_Note: string;
    adjustment_I485FilingDate: string;
    adjustment_I485InterviewOptionId: number;
    adjustment_I485InterviewDate: string;
    adjustment_I485AOSGreenCardApprovalDate: string;//new
    
    consular_nvcLIVAOSDate: string;//new
    consular_nvcCaseNumber: string;
    consular_DS260SubmittalDate: string;//new
    consular_DocumentarilyReadyDate: string;//new
    consular_DS260InterviewDate: string;
    consular_DS260EB3ApprovalDate: string;//new
}

// enums
export enum ImmigrationAdjustmentI485InterviewOptionEnum {
    "Yes" = 1,
    "No" = 2
}
export enum ImmigrationConsularDS260CompletedByEnum {
    "Client" = 1,
    "Paralegel" = 2,
    "Attorney" = 3
}
export enum ImmigrationI140OptionEnum {
    "I140RFE" = 1,
    "I140Denied" = 2
}
export enum ImmigrationI140PremiumProcessEnum {
    "Yes" = 1,
    "No" = 2
}
export enum ImmigrationLCDeniedChangeSponsorOptionEnum {
    "Yes" = 1,
    "No" = 2
}
export enum ImmigrationLCOptionEnum {
    "LCAudit" = 1,
    "LCDenied" = 2
}
export enum ImmigrationProcessEnum {
    "Adjustment" = 1,
    "Consular" = 2,
    "Undecided" = 3
}