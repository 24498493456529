import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgxDatatableSSRConfig } from "@models/ngx-datatable-ssr-config.model";
import { NgbDateStruct, NgbDropdownConfig, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';
import { catchError } from 'rxjs/operators';
import { MastersVM, MasterValueVM, UserAgencyVM } from 'src/app/components/adminConsole/master/master.model'
import { MasterService } from 'src/app/components/adminConsole/master/master.service'
import { TotalPaymentVM, TotalPaymentRequestModel } from '../../reports/report.model'
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../reports/report.service';
import { throwError } from 'rxjs';
import { ExcelService } from '../../../../shared/services/export_service/excel.service';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { HttpStatusCode } from '@angular/common/http';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-total-payment-report',
  templateUrl: './total-payment-report.component.html',
  styleUrls: ['./total-payment-report.component.scss'],
  providers: [NgbDropdownConfig]
})
export class TotalPaymentReportComponent implements OnInit {
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  model: any;
  mastersVM = new MastersVM();
  getAllPaymentsRequestModel = new TotalPaymentRequestModel();
  //masterAdvisorsList: MasterValueVM[] = [];
  userAgencyList: UserAgencyVM[] = [];
  rows: TotalPaymentVM[] = [];
  isApplicationNumberRequired = false;
  selectFromDate: string = '';
  selectToDate: string = '';
  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  isHovered = date =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
  searchAgencyTypeId: number = 0;
  searchSponsorTypeId: number = 0;
  inputDate: any;
  totalRows: TotalPaymentVM[] = [];

  constructor(config: NgbDropdownConfig,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter,
    public masterService: MasterService,
    private toaster: ToastrService,
    private excelService: ExcelService,
    private headerTitleService: HeaderTitleService,

    public service: ReportService
  ) {
    config.placement = 'top-left';
    config.autoClose = false;
  }

  ngAfterViewInit(): void {
    this.loadTotalPaymentReportData();
  }
  ngOnInit(): void {
    this.headerTitleService.title = 'Total Payments';
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };
    this.initDatatable()
    this.loadDropdown();
    this.loadAgencyDropdown();
  }


  loadDropdown() {
    this.masterService.getMasters(this.isApplicationNumberRequired)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.mastersVM = res.response;
        //this.masterAdvisorsList = this.mastersVM.masterAdvisor.masterValues;
      });
  }

  loadAgencyDropdown() {
    this.masterService.getUserAgencies()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.userAgencyList = res.response?.userAgencies;
      });
  }

  initDatatable(): void {
    this.rows = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.getAllPaymentsRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchAgencyTypeId: this.searchAgencyTypeId,
      searchSponsorTypeId: this.searchSponsorTypeId
    };
  }

  loadTotalPaymentReportData(): void {
    this.service.getTotalPaymentReportData(this.getAllPaymentsRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        this.ngxDatatableSSRConfig.totalRecords = res.totalRecords;
      });
  }

  downloadTotalPaymentReportData(fileType): void {
    this.getAllPaymentsRequestModel.getAll = true;
    this.service.getTotalPaymentReportData(this.getAllPaymentsRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.totalRows = res.response;
        if (fileType == 'PDF') {
          this.downloadAsPDF();
        }
        else if (fileType == 'Excel') {
          this.exportAsXLSX();
        }
      });
  }

  onPageSizeChanged(pageSize: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);

    this.getAllPaymentsRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchAgencyTypeId: this.searchAgencyTypeId,
      searchSponsorTypeId: this.searchSponsorTypeId
    };
    this.loadTotalPaymentReportData();
  }

  onPageChanged(pageNum: number) {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);

    this.getAllPaymentsRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchAgencyTypeId: this.searchAgencyTypeId,
      searchSponsorTypeId: this.searchSponsorTypeId

    };
    this.loadTotalPaymentReportData();
  }
  sortByDropdown() {
    this.rows = [];
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.getAllPaymentsRequestModel = {
      sortColumn: '',
      sortDirection: 'ASC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      fromDate: this.selectFromDate,
      toDate: this.selectToDate,
      searchAgencyTypeId: this.searchAgencyTypeId,
      searchSponsorTypeId: this.searchSponsorTypeId

    };
    this.loadTotalPaymentReportData();
  }
  onDateSelection(date: NgbDateStruct) {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.fromDate = date;
      this.toDate = null;
    }
    if (this.fromDate) {
      let from = this._parserFormatter.format(this.fromDate);
      parsed += from;
      this.selectFromDate = parsed;
    }
    if (this.toDate) {
      // let to=' - ' + this._parserFormatter.format(this.toDate);
      let to = this._parserFormatter.format(this.toDate);
      parsed += to;
      // this.selectToDate=parsed;
      this.selectToDate = to;
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    this.inputDate = this._parserFormatter.format(this.fromDate) + ' - ' + this._parserFormatter.format(this.toDate);
    if (this.fromDate !== null && this.toDate != null) { this.sortByDropdown(); }
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  // for export as Excel
  exportAsXLSX(): void {
    var filename = this.headerTitleService.title
    this.excelService.exportAsExcelFile(this.totalRows, filename);
  }
  organise(arr) {
    var headers = [], // an Array to let us lookup indicies by group
      objs = [], // the Object we want to create
      i,
      j;
    for (i = 0; i < arr.length; ++i) {
      j = headers.indexOf(arr[i].id); // lookup
      if (j === -1) {
        // this entry does not exist yet, init
        j = headers.length;
        headers[j] = arr[i].id;
        objs[j] = {};
        objs[j].id = arr[i].id;
        objs[j].data = [];
      }
      objs[j].data.push(
        // create clone
        {
          case_worked: arr[i].case_worked,
          note: arr[i].note,
          id: arr[i].id,
        }
      );
    }
    return objs;
  }

  getBody(): any[] {
    let data = [];
    this.totalRows.forEach((s, index) => data.push([index + 1, s.applicantName, s.agencyName, s.sponsorName, s.coordinator, s.amountOwedByApplicant, s.firstPayment, s.dateOfFirstPayment, s.secondPayment, s.dateOfSecondPayment, s.thirdPayment, s.dateOfThirdPayment, s.fourthPayment, s.dateOfFourthPayment, s.coordinatorFeeTotal, s.coordinatorFirstPayment, s.coordinatorSecondPayment, s.coordinatorThirdPayment, s.coordinatorFourthPayment]));
    return data;
  }

  public downloadAsPDF() {
    const doc = new jsPDF('l', 'mm', 'a2')
    autoTable(doc, {

      head: [['#', 'Applicant Name', 'Agency Name', 'Sponsor Name', 'Coordinator', 'Amount OwnedBy Applicant', 'First Payment', 'Date Of First Payment', 'Second Payment', 'Date Of Second Payment', 'Third Payment', 'Date Of Third Payment', 'Fourth Payment', 'Date Of Fourth Payment', 'Coordinator FeeTotal', 'Coordinator First Payment', 'Coordinator Second Payment', 'Coordinator Third Payment', 'Coordinator Fourth Payment']],
      body: this.getBody(),
    })
    doc.save(this.headerTitleService.title + '-' + moment(new Date()).format('YYYY-MM-DD'));
  }

  onDateRangeChange(data) {
    this.selectFromDate = this._parserFormatter.format(data.startDate);
    this.selectToDate = this._parserFormatter.format(data.endDate);
    this.sortByDropdown();
    this.search();
  }
  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadTotalPaymentReportData();
  }
}
