import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ApplicantsService } from '../applicants.service';
import { catchError, delay, takeLast, timeInterval } from "rxjs/operators";
import { throwError, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-background-information',
  templateUrl: './background-information.component.html',
  styleUrls: ['./background-information.component.scss']
})
export class BackgroundInformationComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;
  notEditable: boolean = true;
  isSaving: boolean = false;
  constructor(public applicantService: ApplicantsService,
    public headerTitleService: HeaderTitleService,
    private toaster: ToastrService,
    public renderer: Renderer2) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn ;
  }
  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      //call update function here
      this.applicantService.updateApplicantBackgroundInformation(this.applicantService.backgroundInfoModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          this.toaster.success(res.message)
        });
    }
  }
  onKeydown(event, value) {
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }

  OnlyletterAllowed(event): Boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return false;
    }
    return true;
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    this.isSaving = false;
    return throwError(error);
  }
  
}
