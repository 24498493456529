<div class="container-fluid px-0" *ngIf="headerTitleService.PermissionVM.timeline">
    <div class="card border-radius-top-0 py-3">
            <button type="button" class="button-small pull-right" style="position: absolute;right: 60px;" [ngClass]="{'bg-gray':isArchive,'bg-blue':!isArchive}" (click)="archiveClicked()" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" *ngIf="!isArchive" width="16.659" height="12.057" viewBox="0 0 16.659 15.057">
                <path id="folder" d="M15.217,28.2V27.082a1.443,1.443,0,0,0-1.442-1.442H7.029l-.252-.411a.481.481,0,0,0-.41-.23H1.442A1.443,1.443,0,0,0,0,26.442V38.615a1.443,1.443,0,0,0,1.442,1.442H15.217a1.443,1.443,0,0,0,1.442-1.442v-8.97A1.443,1.443,0,0,0,15.217,28.2Zm-1.442-1.6a.481.481,0,0,1,.481.481V28.2H8.6l-.643-1.05L7.617,26.6ZM15.7,38.615a.481.481,0,0,1-.481.481H1.442a.481.481,0,0,1-.481-.481V26.442a.481.481,0,0,1,.481-.481H6.1l.252.411h0l.926,1.513.643,1.051a.481.481,0,0,0,.41.23h6.888a.481.481,0,0,1,.481.481v8.97Z" transform="translate(0 -25)" fill="#fff"/>
              </svg>
              <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" *ngIf="isArchive" width="14.147" height="14.147" viewBox="0 0 19.147 19.147">
                <path id="user_5_" data-name="user (5)" d="M16.343,2.8A9.574,9.574,0,0,0,2.8,16.343,9.574,9.574,0,0,0,16.343,2.8ZM4.155,16.055a5.477,5.477,0,0,1,5.419-4.82,5.457,5.457,0,0,1,5.419,4.82,8.434,8.434,0,0,1-10.837,0Zm5.419-5.976a2.908,2.908,0,1,1,2.908-2.908A2.911,2.911,0,0,1,9.574,10.079Zm6.384,5.027a6.6,6.6,0,0,0-4.128-4.6,4.03,4.03,0,1,0-4.511,0,6.589,6.589,0,0,0-4.13,4.6,8.452,8.452,0,1,1,12.77,0Zm0,0" fill="#fff"/>
              </svg>
              <span>
                {{!isArchive?'ARCHIVE APPLICANT':'UNARCHIVE APPLICANT'}}
              </span>
            </button>
            <div *ngIf="headerTitleService.PermissionVM.auditLog">
                <svg (click)="openAuditLog()" class="pull-right mr-4" type="button" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                    <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
                      <g id="Group_605" data-name="Group 605">
                        <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7" transform="translate(10.764 3)" fill="#a1a1a1"/>
                        <path id="XMLID_32_" d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z" transform="translate(14.762 -10.655)" fill="#fff"/>
                      </g>
                    </g>
                </svg>
            </div>
        <div class="timeline">
            <!-- <div class="timeline-bar"></div> -->
            <ngb-progressbar [ngClass]="{'custom-progressbar-0':this.timeline.progressBarValue == 0,'custom-progressbar':this.timeline.progressBarValue != 0}" [value]="progressBarValue" height="5px"></ngb-progressbar>
                
            <div class="timeline-content">
                <div class="stage-item">
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage1?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage1?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage1)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage1?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage1?.startDate | date:'MM/dd/yy'}}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage1?.completionDate | date:'MM/dd/yy'}}</span>
                        </div>
                    </div>
                </div>
                
                <div class="stage-item"> 
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage2?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage2?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage2)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage2?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage2?.startDate | date:'MM/dd/yy' }}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage2?.completionDate | date:'MM/dd/yy'}}</span>
                        </div>
                    </div>
                </div>
                <div class="stage-item">
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage3?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage3?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage3)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage3?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage3?.startDate | date:'MM/dd/yy' }}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage3?.completionDate | date:'MM/dd/yy' }}</span>
                        </div>
                    </div>
                </div>
                 <div class="stage-item"> 
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage4?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage4?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage4)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage4?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage4?.startDate | date:'MM/dd/yy' }}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage4?.completionDate | date:'MM/dd/yy'}}</span>
                        </div>
                    </div>
                </div> 
                 <div class="stage-item"> 
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage5?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage5?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage5)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage5?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage5?.startDate | date:'MM/dd/yy'}}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage5?.completionDate | date:'MM/dd/yy' }}</span>
                        </div>
                    </div>
                </div>
                <div class="stage-item">
                    <div class="card card-timeline timeline-heading shadow">
                        <span>{{timeline?.stage6?.stageName}}</span>
                    </div>
                    <div class="timeline-action" [ngClass]="{'notClickable': timeline?.stage6?.stageStatusId == enumStageStatus.NotStarted}" (click)="openModal(modalId, timeline?.stage6)">
                        <app-timeline-status-icon [timelineStatusId]="timeline?.stage6?.stageStatusId"></app-timeline-status-icon>
                    </div>
                    <div class="card card-timeline timeline-details">
                        <div class="start-date">
                            <span class="title">Start Date:</span>    
                            <span class="value">{{timeline?.stage6?.startDate | date:'MM/dd/yy' }}</span>
                        </div>
                        <div class="completion-date">
                            <span class="title">Completion Date:</span>    
                            <span class="value">{{timeline?.stage6?.completionDate | date:'MM/dd/yy' }}</span>
                        </div>
                    </div>
                </div> 
            </div>
        </div> 
    </div>
</div>

<ng-template #modalId let-modal>
    <form class="theme-form">
      <div class="modal-header">
        <span class="modal-title">Update Stage</span>
        <button class="modal-close" type="" (click)="modal.dismiss()">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64"><path d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z" transform="translate(0 -0.001)" fill="#a1a1a1"/></svg>
        </button>
      </div>
      <div class="modal-body">
          
        <input type="hidden" [(ngModel)]="modalData.id" name="id" [value]="modalData.id">
        <div class="row">
            <div class="col-md-5 pl-0">
              <label class="form-label">Update status:
                <select class="form-control" id="timelineStageStatusId" name="stageStatusId" (change)="onStatusChange($event)" [(ngModel)]="modalData.stageStatusId">
                    <option *ngFor="let status of enumStageStatus | populateEnum" class="text-muted" [value]="status.key">
                        {{status.value}}
                    </option>
                </select>
              </label>          
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 pl-0">
              <label class="form-label">Start Date:
                <div>
                    <input type="date" class="form-control" name="startDate" (change)="checkDate($event,selectID)" [ngModel]="modalData.startDate | date:'yyyy-MM-dd'" (ngModelChange)="modalData.startDate=$event" required=""
                [disabled]="timelineStartDate">
                    <!-- <input type=date id="1" class="form-control" [disabled]="Editable1" (change)="checkDate($event)"> -->
                         <!-- ngbDatepicker #d1="ngbDatepicker"> -->
                    <!-- <svg (click)="d1.toggle()" type="button" class="date-svg" xmlns="http://www.w3.org/2000/svg" width="18.72" height="17.496" viewBox="0 0 18.72 17.496">
                      <g id="calendar_2_" data-name="calendar (2)" transform="translate(-1 -2)">
                        <path id="Path_207" data-name="Path 207" d="M17.679,2H15.93V3.75a.583.583,0,1,1-1.166,0V2H5.432V3.75a.583.583,0,1,1-1.166,0V2H2.516A1.606,1.606,0,0,0,1,3.75v2.1H19.662V3.75A1.968,1.968,0,0,0,17.679,2ZM1,7.074V17.746A1.583,1.583,0,0,0,2.575,19.5H17.737a1.968,1.968,0,0,0,1.983-1.75V7.074Zm5.19,9.8h-1.4a.46.46,0,0,1-.467-.467V14.947a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467V16.4a.511.511,0,0,1-.525.467Zm0-5.249h-1.4a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467v1.458a.511.511,0,0,1-.525.467Zm4.665,5.249H9.4a.46.46,0,0,1-.467-.467V14.947A.46.46,0,0,1,9.4,14.48h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,10.856,16.871Zm0-5.249H9.4a.46.46,0,0,1-.467-.467V9.7A.46.46,0,0,1,9.4,9.232h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,10.856,11.623Zm4.665,5.249H14.063A.46.46,0,0,1,13.6,16.4V14.947a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,15.521,16.871Zm0-5.249H14.063a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,15.521,11.623Z" fill="#a1a1a1"/>
                      </g>
                    </svg> -->
                </div>
              </label>          
            </div>
            <div class="col-md-1 px-4"></div>
            <div class="col-md-5 pl-0">
              <label class="form-label">Completion Date:
                <div>
                    <input type="date" class="form-control" name="completionDate" (change)="checkDate($event)" [ngModel]="modalData.completionDate | date:'yyyy-MM-dd'" (ngModelChange)="modalData.completionDate=$event" required=""
                [disabled]="timelineCompletionDate">
                    <!-- <input type=date id="2" class="form-control" [disabled]="timelineCompletionDate" [min]="min_Date"> -->
                        <!-- ngbDatepicker #d2="ngbDatepicker"-->
                    <!-- <svg (click)="d2.toggle()" type="button" class="date-svg" xmlns="http://www.w3.org/2000/svg" width="18.72" height="17.496" viewBox="0 0 18.72 17.496">
                      <g id="calendar_2_" data-name="calendar (2)" transform="translate(-1 -2)">
                        <path id="Path_207" data-name="Path 207" d="M17.679,2H15.93V3.75a.583.583,0,1,1-1.166,0V2H5.432V3.75a.583.583,0,1,1-1.166,0V2H2.516A1.606,1.606,0,0,0,1,3.75v2.1H19.662V3.75A1.968,1.968,0,0,0,17.679,2ZM1,7.074V17.746A1.583,1.583,0,0,0,2.575,19.5H17.737a1.968,1.968,0,0,0,1.983-1.75V7.074Zm5.19,9.8h-1.4a.46.46,0,0,1-.467-.467V14.947a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467V16.4a.511.511,0,0,1-.525.467Zm0-5.249h-1.4a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467v1.458a.511.511,0,0,1-.525.467Zm4.665,5.249H9.4a.46.46,0,0,1-.467-.467V14.947A.46.46,0,0,1,9.4,14.48h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,10.856,16.871Zm0-5.249H9.4a.46.46,0,0,1-.467-.467V9.7A.46.46,0,0,1,9.4,9.232h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,10.856,11.623Zm4.665,5.249H14.063A.46.46,0,0,1,13.6,16.4V14.947a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,15.521,16.871Zm0-5.249H14.063a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,15.521,11.623Z" fill="#a1a1a1"/>
                      </g>
                    </svg> -->
                </div>
              </label>        
            </div>
        </div>
      </div>
      <div class="modal-footer">        
        <button type="button" class="btn btn-save" (click)="saveModalData(modal)" [disabled]="isSaving">
            <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
            {{isSaving?"Saving":"UPDATE"}}
        </button>
      </div>
    </form>
  </ng-template>
