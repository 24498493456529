<!-- content starts-->
<div class="content-wrapper theme-font-size">
  <div class="row1 p-r-0">
    <div class="h-25 p-r-0">
      <div class="default-according" id="accordion">
        <div class="card shadow mb-3">
          <div class="tab-content card-body border-0 p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewImmigrationInformation">
            <div class="tab-pane fade show active" id="app-info" role="tabpanel" aria-labelledby="pills-home-tab">
              <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                <div class="row p-l-20">
                  <div class="row col-xl-12 p-l-0">
                    <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 p-r-0">
                      Immigration Information
                      <hr class="mt-1" />
                    </span>
                  </div>
                  <div class="col-xl-12 px-0">
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="immigrationProcessId">
                              Adjustment / Consular Process:<span class="text-danger">*</span>
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" 
                              id="immigrationProcessId"
                              name="immigrationProcessId"
                              [(ngModel)]="applicantService.immigrationInfoModel.immigrationProcessId"
                              [disabled]="notEditable"
                              required >
                              <option *ngFor="let item of immigrationProcess | populateEnum" [value]="item.key">
                                {{ item.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="priorityDate">
                              Priority Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="priorityDate" 
                              name="priorityDate"
                              type="date" 
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.priorityDate = $event"
                              [ngModel]="applicantService.immigrationInfoModel.priorityDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                        </div>
                        <div class="row g-3 my-2">
                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="lc_CaseNumber">
                              LC Case #:
                            </label>
                            <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" 
                              id="lc_CaseNumber" 
                              name="lc_CaseNumber"
                              type="text"
                              autocomplete="off" 
                              [(ngModel)]="applicantService.immigrationInfoModel.lc_CaseNumber"
                              (keypress)="onlyAlphaNumericAllowed($event)"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="lc_ApprovalDate">
                              LC Approval Date:
                            </label>
                            <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" 
                              id="lc_ApprovalDate" 
                              name="lc_ApprovalDate"
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.lc_ApprovalDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.lc_ApprovalDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3 mt-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="lc_ExpirationDate">
                              LC Expiration Date:
                            </label>
                            <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" 
                              id="lc_ExpirationDate" 
                              name="lc_ExpirationDate"
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.lc_ExpirationDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.lc_ExpirationDate = $event"                               
                              [disabled]="notEditable" />
                          </div>
                        </div>
                       
                        <div>
                          <div class="checkbox checkbox-custom1">
                            <input type="checkbox" 
                              id="lcAudit_OptionSelected"
                              name="lcAudit_OptionSelected"
                              [checked]="applicantService.immigrationInfoModel.lcAudit_OptionSelected"
                              [(ngModel)]="applicantService.immigrationInfoModel.lcAudit_OptionSelected" 
                              [disabled]="notEditable" />
                            <label for="lcAudit_OptionSelected">LC Audit</label>
                          </div>
                          <div class="row g-3 my-2" *ngIf="applicantService.immigrationInfoModel.lcAudit_OptionSelected">
                            <div class="col-md-3">
                              <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="lcAudit_DOLNotificationDate">
                                DOL Audit Notification Date:
                              </label>
                              <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                                id="lcAudit_DOLNotificationDate" 
                                name="lcAudit_DOLNotificationDate"
                                type="date"
                                autocomplete="off" 
                                (ngModelChange)="applicantService.immigrationInfoModel.lcAudit_DOLNotificationDate = $event"  
                                [ngModel]="applicantService.immigrationInfoModel.lcAudit_DOLNotificationDate | date : 'yyyy-MM-dd'" 
                                [disabled]="notEditable" />
                            </div>
                            <div class="col-md-3">
                              <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="lcAudit_DOLAuditDueDate">
                                DOL Audit Due Date:
                              </label>
                              <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                                id="lcAudit_DOLAuditDueDate" 
                                name="lcAudit_DOLAuditDueDate"
                                type="date"
                                autocomplete="off" 
                                (ngModelChange)="applicantService.immigrationInfoModel.lcAudit_DOLAuditDueDate = $event"  
                                [ngModel]="applicantService.immigrationInfoModel.lcAudit_DOLAuditDueDate | date : 'yyyy-MM-dd'" 
                                [disabled]="notEditable" />
                            </div>
                            <div class="col-md-3">
                              <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="lcAudit_AuditResponseDate">
                                Audit Response Date:</label>
                              <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                                id="lcAudit_AuditResponseDate" 
                                name="lcAudit_AuditResponseDate" 
                                type="date"
                                autocomplete="off" 
                                (ngModelChange)="applicantService.immigrationInfoModel.lcAudit_AuditResponseDate = $event" 
                                [ngModel]="applicantService.immigrationInfoModel.lcAudit_AuditResponseDate | date : 'yyyy-MM-dd'" 
                                [disabled]="notEditable" />
                            </div>
                          </div>
                        </div>
                        
                        <div>
                          <div class="checkbox checkbox-custom1">
                            <input type="checkbox" 
                              id="lcDenied_OptionSelected"
                              name="lcDenied_OptionSelected"
                              [checked]="applicantService.immigrationInfoModel.lcDenied_OptionSelected"
                              [(ngModel)]="applicantService.immigrationInfoModel.lcDenied_OptionSelected"
                              [disabled]="notEditable" />
                            <label for="lcDenied_OptionSelected">LC Denied</label>
                          </div>
                          <ng-container *ngIf="applicantService.immigrationInfoModel.lcDenied_OptionSelected">
                            <div class="row g-3 my-2">
                              <div class="col-md-3">
                                <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="lcDenied_DenialDate">
                                  LC Denial Date:
                                </label>
                                <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                                  id="lcDenied_DenialDate" 
                                  name="lcDenied_DenialDate"
                                  type="date"
                                  autocomplete="off" 
                                  (ngModelChange)="applicantService.immigrationInfoModel.lcDenied_DenialDate = $event"  
                                  [ngModel]="applicantService.immigrationInfoModel.lcDenied_DenialDate | date : 'yyyy-MM-dd'" 
                                  [disabled]="notEditable" />
                              </div>
                              <div class="col-md-3">
                                <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="lcDenied_DateRefile">
                                  Date Refile:
                                </label>
                                <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                                  id="lcDenied_DateRefile" 
                                  name="lcDenied_DateRefile"
                                  type="date"
                                  autocomplete="off" 
                                  (ngModelChange)="applicantService.immigrationInfoModel.lcDenied_DateRefile = $event"  
                                  [ngModel]="applicantService.immigrationInfoModel.lcDenied_DateRefile | date : 'yyyy-MM-dd'" 
                                  [disabled]="notEditable" />
                              </div>
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="lcDenied_NewLCCaseNumber">
                                  New LC Case #:
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1"
                                  id="lcDenied_NewLCCaseNumber" 
                                  name="lcDenied_NewLCCaseNumber"
                                  type="text"
                                  autocomplete="off" 
                                  [(ngModel)]="applicantService.immigrationInfoModel.lcDenied_NewLCCaseNumber"
                                  (keypress)="onlyAlphaNumericAllowed($event)"                                 
                                  [disabled]="notEditable" />
                              </div>
                            </div>
                            <div class="row g-3 my-2">
                              <div class="col-md-3">
                                <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="lcDenied_ChangeSponsorOptionId">
                                  Change Sponsor?
                                </label>
                                <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" 
                                  id="lcDenied_ChangeSponsorOptionId"
                                  name="lcDenied_ChangeSponsorOptionId" 
                                  [(ngModel)]="applicantService.immigrationInfoModel.lcDenied_ChangeSponsorOptionId" 
                                  (change)="applicantService.updateSponsorEditableStatus(showEditBtn)"
                                  [disabled]="notEditable" >
                                  <option *ngFor="let item of immigrationLCDeniedChangeSponsorOptionEnum | populateEnum" [value]="item.key">
                                    {{ item.value }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-md-3" *ngIf="applicantService.immigrationInfoModel.lcDenied_ChangeSponsorOptionId == immigrationLCDeniedChangeSponsorOptionEnum.Yes">
                                <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="lcDenied_NewSponsorId">
                                  New Sponsor:
                                </label>
                                <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" 
                                  id="lcDenied_NewSponsorId"
                                  name="lcDenied_NewSponsorId" 
                                  [(ngModel)]="applicantService.immigrationInfoModel.lcDenied_NewSponsorId"
                                  (change)="applicantService.updateSponsorEditableStatus(showEditBtn)"
                                  [disabled]="notEditable">
                                  <option class="text-muted" *ngFor="let option of sponsorList" [value]="option.id">
                                    {{option.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </ng-container>
                        </div>                        
                      </div>
                    </div>
                    <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit" [disabled]="isSaving">
                      <i class="fa mr-1" [ngClass]="isSaving ? 'fa-spin fa-spinner' : (notEditable ? 'fa-edit' : 'fa-floppy-o')"></i>
                      {{ isSaving ? "Saving..." : notEditable ? "Edit" : "Update" }}
                    </button>
                  </div>
                  <div class="row col-xl-12 p-l-0 mt-2">
                    <span class="col-xl-12 p-r-0">
                      <hr class="immigration-hr" />
                    </span>
                  </div>                  
                  <div class="col-xl-12 px-0 mb-2">
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i94_ArrivalToUSADate">
                              Date of Arrival to the USA:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i94_ArrivalToUSADate" 
                              name="i94_ArrivalToUSADate"
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i94_ArrivalToUSADate = $event"  
                              (ngModelChange)="onArrivalToUSADateChanged($event)"
                              [ngModel]="applicantService.immigrationInfoModel.i94_ArrivalToUSADate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>                          
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="i94_90DaysPostArrivalDate">
                              90 Days Post Arrival Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i94_90DaysPostArrivalDate" 
                              name="i94_90DaysPostArrivalDate" 
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.i94_90DaysPostArrivalDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.i94_90DaysPostArrivalDate = $event"
                              [disabled]="true" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="i94_ExpirationDate">
                              I-94 Expiration Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i94_ExpirationDate" 
                              name="i94_ExpirationDate" 
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.i94_ExpirationDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.i94_ExpirationDate = $event"
                              [disabled]="notEditable" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row col-xl-12 p-l-0 mt-2">
                    <span class="col-xl-12 p-r-0">
                      <hr class="immigration-hr" />
                    </span>
                  </div>
                  <div class="col-xl-12 px-0">
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i140_FilingDate">
                              I-140 Filing Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i140_FilingDate"
                              name="i140_FilingDate" 
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i140_FilingDate = $event" 
                              [ngModel]="applicantService.immigrationInfoModel.i140_FilingDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="i140_PremiumProcessId">
                              Premium Process:
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" 
                              id="i140_PremiumProcessId"
                              name="i140_PremiumProcessId" 
                              [(ngModel)]="applicantService.immigrationInfoModel.i140_PremiumProcessId"
                              [disabled]="notEditable">
                              <option *ngFor="let item of immigrationI140PremiumProcessEnum | populateEnum" [value]="item.key">
                                {{ item.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="">
                              I-140 Approval Date:</label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i140_ApprovalDate" 
                              name="i140_ApprovalDate" 
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i140_ApprovalDate = $event" 
                              [ngModel]="applicantService.immigrationInfoModel.i140_ApprovalDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i140_uscisCaseNumber">
                              USCIS Case #:
                            </label>
                            <div class="d-flex align-items-center">
                              <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1"
                                style="text-transform: uppercase;" 
                                id="i140_uscisCaseNumber" 
                                name="i140_uscisCaseNumber"
                                type="text"
                                [(ngModel)]="applicantService.immigrationInfoModel.i140_uscisCaseNumber"                                  
                                [disabled]="notEditable" />
                              <button class="btn btn-edit mt-1 ml-3" 
                                style="height: fit-content; white-space: nowrap;" 
                                type="button"
                                (click)="getStatus()"
                                [disabled]="notEditable">
                                Get Status
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row g-3 my-2">
                          <div class="col-md-11 mt-0">
                            <div class="col p-l-0" style="position: relative; z-index: 5;" [ngClass]="{'disabled-content': notEditable == true}">
                              <div *ngIf="!isEditable" [ngClass]="{'disabled-content': isEditable == true}"></div>
                              <div class="m-t-10 m-checkbox-inline custom-radio-ml media-body align-self-center f-12 roboto-font">
                                <div class="form-check form-check-inline radio radio-primary m-0">
                                  <input class="form-check-input" 
                                    id="i140_RadioOption_RFE" 
                                    name="i140_RadioOption_RFE"
                                    type="radio" 
                                    [value]="immigrationI140OptionEnum.I140RFE"
                                    [(ngModel)]="applicantService.immigrationInfoModel.i140_RadioOptionId"                                     
                                    [disabled]="notEditable" />
                                  <label class="form-check-label text-muted mb-0 " for="i140_RadioOption_RFE">
                                    I-140 RFE
                                  </label>
                                </div>
                                <div class="form-check form-check-inline radio radio-primary m-0">
                                  <input class="form-check-input" 
                                    id="i140_RadioOption_Denied"
                                    name="i140_RadioOption_Denied"
                                    type="radio"
                                    [value]="immigrationI140OptionEnum.I140Denied"
                                    [(ngModel)]="applicantService.immigrationInfoModel.i140_RadioOptionId"                                                                        
                                    [disabled]="notEditable" />
                                  <label class="form-check-label text-muted mb-0" for="i140_RadioOption_Denied">
                                    I-140 Denied
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row g-3 my-2" *ngIf="applicantService.immigrationInfoModel.i140_RadioOptionId == immigrationI140OptionEnum.I140RFE">
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i140RFE_NotificationDate">
                              RFE Notification Date:</label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i140RFE_NotificationDate"
                              name="i140RFE_NotificationDate"
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i140RFE_NotificationDate = $event" 
                              [ngModel]="applicantService.immigrationInfoModel.i140RFE_NotificationDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i140RFE_DueDate">
                              Due Date:</label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i140RFE_DueDate"
                              name="i140RFE_DueDate"
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i140RFE_DueDate = $event" 
                              [ngModel]="applicantService.immigrationInfoModel.i140RFE_DueDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="i140RFE_ResponseSentDate">
                              RFE Response Sent Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="i140RFE_ResponseSentDate"
                              name="i140RFE_ResponseSentDate"
                              type="date" 
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.i140RFE_ResponseSentDate = $event" 
                              [ngModel]="applicantService.immigrationInfoModel.i140RFE_ResponseSentDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                        </div>
                        <div class="col-xl-11 px-0" *ngIf="applicantService.immigrationInfoModel.i140_RadioOptionId == immigrationI140OptionEnum.I140Denied">
                          <div class="col-xl-6 px-0 mt-2 mb-2">
                            <div class="row">
                              <div class="col-xl-11 col-11 p-r-0">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="i140Denied_Note">
                                  Note:
                                </label>
                                <textarea class="form-control b-r-5 mt-1" style="height: 80px"
                                  placeholder="" 
                                  id="i140Denied_Note"
                                  name="i140Denied_Note"
                                  [(ngModel)]="applicantService.immigrationInfoModel.i140Denied_Note"
                                  [disabled]="notEditable">
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                  <div class="row col-xl-12 p-l-0 mt-2">
                    <span class="col-xl-12 p-r-0">
                      <hr class="immigration-hr" />
                    </span>
                  </div>                  
                  <div class="col-xl-12 px-0" *ngIf="applicantService.immigrationInfoModel.immigrationProcessId == immigrationProcess.Adjustment">
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 f-14 roboto-font" for="adjustment_I485FilingDate">
                              I-485 Filing Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="adjustment_I485FilingDate" 
                              name="adjustment_I485FilingDate"
                              type="date"
                              autocomplete="off" 
                              (ngModelChange)="applicantService.immigrationInfoModel.adjustment_I485FilingDate = $event"  
                              [ngModel]="applicantService.immigrationInfoModel.adjustment_I485FilingDate | date : 'yyyy-MM-dd'" 
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue col-xl-12 px-0 m-b-0 f-14 roboto-font" for="adjustment_I485InterviewOptionId">
                              Interview:
                            </label>
                            <select class="form-select b-r-5 f-12 py-2 w-100 col-xl-9 mt-1" 
                              id="adjustment_I485InterviewOptionId"
                              name="adjustment_I485InterviewOptionId" 
                              [(ngModel)]="applicantService.immigrationInfoModel.adjustment_I485InterviewOptionId" 
                              [disabled]="notEditable">
                              <option *ngFor="let item of immigrationAdjustmentI485InterviewOptionEnum | populateEnum" [value]="item.key">
                                {{ item.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3" *ngIf="applicantService.immigrationInfoModel.adjustment_I485InterviewOptionId == immigrationAdjustmentI485InterviewOptionEnum.Yes">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="adjustment_I485InterviewDate">
                              Interview Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="adjustment_I485InterviewDate" 
                              name="adjustment_I485InterviewDate" 
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.adjustment_I485InterviewDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.adjustment_I485InterviewDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="adjustment_I485AOSGreenCardApprovalDate">
                              AOS/Green Card Approval Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="adjustment_I485AOSGreenCardApprovalDate" 
                              name="adjustment_I485AOSGreenCardApprovalDate" 
                              type="date"
                              autocomplete="off" 
                              [ngModel]="applicantService.immigrationInfoModel.adjustment_I485AOSGreenCardApprovalDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.adjustment_I485AOSGreenCardApprovalDate = $event"
                              [disabled]="notEditable" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 px-0" *ngIf="applicantService.immigrationInfoModel.immigrationProcessId == immigrationProcess.Consular">
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12">
                        <div class="row g-3 mt-2">
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_nvcLIVAOSDate">
                              NVC LIVAOS Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="consular_nvcLIVAOSDate" 
                              name="consular_nvcLIVAOSDate"
                              type="date"
                              autocomplete="off"
                              [ngModel]="applicantService.immigrationInfoModel.consular_nvcLIVAOSDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.consular_nvcLIVAOSDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_nvcCaseNumber">
                              NVC Case #:
                            </label>
                            <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" 
                              id="consular_nvcCaseNumber" 
                              name="consular_nvcCaseNumber"
                              type="text"
                              autocomplete="off" 
                              [(ngModel)]="applicantService.immigrationInfoModel.consular_nvcCaseNumber"
                              (keypress)="onlyLetterAllowed($event)"
                              [disabled]="notEditable" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row col-md-11 px-0">
                      <div class="col-xl-12 mt-3">
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_DS260SubmittalDate">
                              DS-260 Submittal Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="consular_DS260SubmittalDate" 
                              name="consular_DS260SubmittalDate"
                              type="date"
                              autocomplete="off"
                              [ngModel]="applicantService.immigrationInfoModel.consular_DS260SubmittalDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.consular_DS260SubmittalDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_DocumentarilyReadyDate">
                              Documentarily Ready Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="consular_DocumentarilyReadyDate" 
                              name="consular_DocumentarilyReadyDate"
                              type="date"
                              autocomplete="off"
                              [ngModel]="applicantService.immigrationInfoModel.consular_DocumentarilyReadyDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.consular_DocumentarilyReadyDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_DS260InterviewDate">
                              Interview Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="consular_DS260InterviewDate" 
                              name="consular_DS260InterviewDate"
                              type="date"
                              autocomplete="off"
                              [ngModel]="applicantService.immigrationInfoModel.consular_DS260InterviewDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.consular_DS260InterviewDate = $event"
                              [disabled]="notEditable" />
                          </div>
                          <div class="col-md-3">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="consular_DS260EB3ApprovalDate">
                              EB-3 Consular Approval Date:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" 
                              id="consular_DS260EB3ApprovalDate" 
                              name="consular_DS260EB3ApprovalDate"
                              type="date"
                              autocomplete="off"
                              [ngModel]="applicantService.immigrationInfoModel.consular_DS260EB3ApprovalDate | date : 'yyyy-MM-dd'"
                              (ngModelChange)="applicantService.immigrationInfoModel.consular_DS260EB3ApprovalDate = $event"
                              [disabled]="notEditable" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </form>
            </div>
          </div>
            <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewImmigrationInformation">
              <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewImmigrationInformation"
                hasBorder="true" hasFullHeight="true"></app-user-access-permission>
              </div>
            <!-- Family information -->
         
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->