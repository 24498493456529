<!-- Model popup on New Activity-->
<div role="document">
  <form class="theme-form needs-validation" ngNativeValidate>
    <div class="modal-header">
      <span class="modal-title">{{modalTitle}}</span>
      <button class="modal-close" type="" (click)="activeModal.dismiss();selectedNumberOfDays=null;">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
          <path
            d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
            transform="translate(0 -0.001)" fill="#a1a1a1" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <input type="hidden" [(ngModel)]="activityModel.id" name="ID" [value]="activityModel.id">
      <div class="row">
        <div class="col-md-5 pl-0">
          <label class="form-label">Activity Type:<span class="text-danger">*</span>
            <select class="form-control" name="activityTypesId" id="activityTypesId" [ngModel]="activityModel.activityTypesId"
            required="true" [disabled]="notEditable" (ngModelChange)="activityModel.activityTypesId=$event;isShowScheduleAndSaveButton()" >
              <option class="text-muted" *ngFor="let option of mastersVM.masterActivityType.masterValues"
                [value]="option.id">{{option.name}}</option>
            </select>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 pl-0">
          <label class="form-label">Activity Title:<span class="text-danger">*</span>
            <input type="text" class="form-control" autocomplete="off" name="activityTitle"
              [(ngModel)]="activityModel.activityTitle" required="true" [disabled]="notEditable">
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 pl-0">
          <label class="form-label">Date Due:<span class="text-danger">*</span>
            <input type="date" class="form-control" name="activityModel.dueDate"
              [ngModel]="activityModel.dueDate | date:'yyyy-MM-dd'" (ngModelChange)="activityModel.dueDate=$event;"
              required="" [disabled]="notEditable">
          </label>
        </div>
        <div class="col-md-5 pl-0 ml-3">
          <label class="form-label">Date Completed:
            <input type="date" class="form-control" name="completedDate"
            [disabled]="setEnabled()"
              (ngModelChange)="activityModel.completedDate=$event;isShowScheduleAndSaveButton()"
              [ngModel]="setEnabled() ? null : activityModel.completedDate | date:'yyyy-MM-dd'" >
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 pl-0">
          <label class="form-label">Assigned To:<span class="text-danger">*</span>
            <select class="form-control" name="assignToUserId" [(ngModel)]="activityModel.assignToUserId" required=""
              [disabled]="notEditable">
              <option class="text-muted" *ngFor="let option of this.assignToUsers" [value]="option.userId">
                {{option.fullName}}</option>
            </select>
          </label>
        </div>
        <!-- <div class="col-md-5 pl-0 ml-3">
            <label class="form-label">Stage:
              <select class="form-control" name="stagesId" [(ngModel)]="activityModel.stagesId" required
                [disabled]="notEditable">
                  <option *ngFor="let status of enumStage | populateEnum" class="text-muted" [value]="status.key">
                    {{status.value}}
                </option>
              </select>
            </label>
          </div> -->
      </div>
      <div class="row">
        <div class="col-md-10 col-12 px-0">
          <label class="form-label">Details:<span class="text-danger">*</span>
            <textarea style="height: 74px;" class="form-control b-r-7" name="details" required="" [disabled]="notEditable"
              [(ngModel)]="activityModel.details"></textarea>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="(operationId != operationTypeEnum.View)" class="modal-footer">
      <button *ngIf="showScheduleAndSaveButton" type="button" ngbDropdown id="dropdownBasic1" ngbDropdownToggle class="d-inline-block btn btn-save b-r-7 mr-3" placement="bottom">SAVE & SCHEDULE CALL
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <li class="dropdown-item subhead-blue" (click)="selectedNumberOfDays = numberOfDays['15 Days'];CreateActivityLog(false)">15 Days</li>
          <li class="dropdown-item subhead-blue" (click)="selectedNumberOfDays = numberOfDays['30 Days'];CreateActivityLog(false)">30 Days</li>
          <li class="dropdown-item subhead-blue" (click)="selectedNumberOfDays = numberOfDays['60 Days'];CreateActivityLog(false)">60 Days</li>
        </div>
      </button>
      <button type="button" class="btn btn-save b-r-7" (click)="CreateActivityLog(true);" [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
        {{isSaving?"Saving":"SAVE"}}
      </button>
    </div>
  </form>
</div>