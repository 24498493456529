import { EventEmitter, Injectable, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissionVM } from 'src/app/components/adminConsole/users-management/user-permissions/user-permissions.model';
import { UserPermissionsService } from 'src/app/components/adminConsole/users-management/user-permissions/user-permissions.service';
import { StorageService } from './storage.service';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  PermissionVM = new PermissionVM();
  title: string = '';
  @Output() notificationRefresh: EventEmitter<boolean> = new EventEmitter();
  constructor(
    public storageservice: StorageService,
    private toaster: ToastrService,
    public userPermissionsService: UserPermissionsService) { 
      // this.GetPermissionAccessByUser();

    var permissionVM =  this.storageservice.retrieve('permissionVM');
    this.PermissionVM =permissionVM;
    }

  GetPermissionAccessByUser() {
    this.userPermissionsService.getPermissionAccessByUser()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess === true) {
        this.PermissionVM = res.response;
        }
      });
  }


  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }


}
export enum OperationTypeEnum {
  Create = 1,
  Update = 2,
  View = 3,
}