<!-- content starts-->
<div class="content-wrapper theme-font-size">
  <div>
      <div class="">
        <div class=" h-25">
          <div class="" id="">
            <div class="card shadow mb-3" [ngClass]="{'border-radius-top-0': showEditBtn}">
              <div class="tab-content card-body  p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
                <div class="tab-pane fade show active" id="app-info" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                  <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                    <div class="row p-l-20">
                      <div class="row col-xl-12 p-l-0">
                      <span class="subhead-blue font-weight-bold f-14 roboto-font  col-xl-12 p-r-0 ">
                        Personal Information
                        <div class="pull-right" *ngIf="headerTitleService.PermissionVM.auditLog && showEditBtn">
                          <svg (click)="openAuditLog(content)" type="button" style="position:relative;bottom:7px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                            <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
                              <g id="Group_605" data-name="Group 605">
                                <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7" transform="translate(10.764 3)" fill="#a1a1a1"/>
                                <path id="XMLID_32_" d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z" transform="translate(14.762 -10.655)" fill="#fff"/>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <hr class="mt-1">
                      </span>
                    </div>
                    <div class="col-xl-12 px-0">
                      <div class=" row col-md-11 px-0">
                        <div class="col-xl-12 mt-3">
                       
                            <div class="row g-5">
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom1">
                                  First Name:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control col-xl-9  f-12 b-r-5 mt-1" id="validationCustom1" type="text" autocomplete="off" type="search"
                                name="firstName" [(ngModel)]="applicantService.personalInfoModel.firstName" required=""
                                   onlyAlphabets (keypress)="onlyNumbersLettersAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom2">
                                  Middle Name:
                                </label>
                                <input class="form-control col-xl-9 f-12 b-r-5 mt-1" id="validationCustom2" type="text" autocomplete="off" type="search"
                                name="middleName" [(ngModel)]="applicantService.personalInfoModel.middleName"
                                (keypress)="onlyNumbersLettersAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom3">
                                  Last Name:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control col-xl-9  f-12 b-r-5 mt-1" id="validationCustom3" type="text" autocomplete="off" type="search"
                                name="lastName" [(ngModel)]="applicantService.personalInfoModel.lastName"
                                  required="" (keypress)=" OnlyletterAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable">
                              </div>
                            </div>
                            <div class="row g-5 my-1">
                              <div class="col-md-3 mt-0">
                                <label class="form-label subhead-blue m-b-0 pt-3 f-14 roboto-font" for="d">
                                  Date of Birth:<span class="text-danger">*</span>
                                </label>
                              
                                <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="validationCustom4" 
                                type="date" [max]="maxDate" required="" autocomplete="off"
                                name="applicantService.personalInfoModel.dob"
                                (ngModelChange)="applicantService.personalInfoModel.dob=$event"
                                [ngModel]="applicantService.personalInfoModel.dob | date:'yyyy-MM-dd'" 
                                (change)="applicantService.personalInfoModel.age=applicantService.calculateAge($event.target.value)" 
                                [disabled]="notEditable" >
                              </div> 
                              <div class="col-md-3 mt-0">
                                <label class="form-label subhead-blue m-b-0 pt-3 f-14 roboto-font"
                                  for="validationCustom5">
                                  Age:<span class="text-danger">*</span>      
                                </label>
                                <input class="form-control f-12 b-r-5 col-xl-9 mt-1" 
                                [value]="applicantService.personalInfoModel.age" 
                                id="validationCustom5"  
                                autocomplete="off" type="text"
                                  disabled>
                              </div>
                              <div class="col-md-3 mt-0">
                                <label class="form-label col-xl-12 px-0 subhead-blue m-b-0 pt-3 f-14 roboto-font"
                                  for="validationCustom6">
                                  Gender:<span class="text-danger">*</span>
                                </label>
                                <select class="form-select b-r-5 f-12 py-2 w-100 col-md-9 col-sm-12 col-xl-9 col-12 mt-1" id="validationCustom6" name="gender" [(ngModel)]="applicantService.personalInfoModel.gender" required [disabled]="notEditable">
                                  <option class="text-muted" value="Male">
                                    Male
                                  </option>
                                  <option class="text-muted" value="Female">
                                    Female
                                  </option>
                                  <option class="text-muted" value="Others">
                                    Others
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row g-5 my-1">
                              <div class="col-md-3 mt-0">
                                <label class="form-label subhead-blue m-b-0 pt-3 f-14 roboto-font"
                                  for="validationCustom7">
                                  Phone Number:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control f-12 b-r-5 col-xl-9 mt-1" maxlength="15" minlength="10" type="tel" id="validationCustom7" name="phone" autocomplete="disabled" 
                                name="phone" 
                                (keypress)="phoneNumbers($event)" 
          required="" 
          [(ngModel)]="applicantService.personalInfoModel.phone" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3 col-xl-3 px-xl-0 mx-xl-3 mt-0">
                                <label class="form-label subhead-blue m-b-0 pt-3 f-14 roboto-font"                             
                                  for="validationCustom8">
                                  Email Address:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control f-12 b-r-5 mt-1" id="validationCustom8" type="text" autocomplete="disabled" 
                                name="email" [(ngModel)]="applicantService.personalInfoModel.email"
                                  required="" [pattern]="emailPattern" data-bs-original-title="" title="" [disabled]="notEditable">
                              </div>
                            </div>
                        </div>
                      </div>
                      <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit" [disabled]="isSaving">
                        <i class="fa mr-1" [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                        {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                      </button>
                    </div>
                    </div>
                  </form>
                </div>
                <!-- Family information -->
                <!-- information End-->
                <!-- card body ends above -->
              </div>
              <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewApplicantInformation">
                <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewApplicantInformation"
                  hasBorder="true" hasFullHeight="true"></app-user-access-permission>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<!-- content Ends-->