import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {DataService} from '../../../../services/data.service'
import {ResponseModel} from '../../../../models/response.model'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private service: DataService) { }
  
  //for notification count
  getCount(): Observable<ResponseModel> {
    let url = '/gerapi/Notification/count';
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}


