import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditApplicantHeaderComponent } from '../edit-applicant-header/edit-applicant-header.component';
import { HeaderTitleService } from '@services/header-title.service';
import { DocumentsListTabComponent } from '../view-all-documents/documents-list-tab/documents-list-tab.component';
import { StorageService } from '@services/storage.service';
import { ApplicantsService } from '../applicants.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { UserPermissionsService } from '../../adminConsole/users-management/user-permissions/user-permissions.service';
@Component({
  selector: 'app-edit-applicant',
  templateUrl: './edit-applicant.component.html',
  styleUrls: ['./edit-applicant.component.scss'],
})
export class EditApplicantComponent implements OnInit {
  sub: any;
  tabId: number = 1;
  applicantId: number = 0;
  bHasApplicantAccess: boolean = false;
  accessPermissionHidden: boolean = false;
  isArchive: boolean;
  @ViewChild(EditApplicantHeaderComponent, { static: false })
  childC: EditApplicantHeaderComponent;
  @ViewChild(DocumentsListTabComponent, { static: false })
  childDocList: DocumentsListTabComponent;
  constructor(
    public storageservice: StorageService,
    private activateRouter: ActivatedRoute,
    public applicantService: ApplicantsService,
    private toaster: ToastrService,
    public headerTitleService: HeaderTitleService
  ) {}

  ngOnInit() {
    this.applicantService.ResetAll();
    this.headerTitleService.title = 'Applicant Information';
    if (this.headerTitleService.PermissionVM.applicantInformation) {
      this.sub = this.activateRouter.queryParams.subscribe((params) => {
        // Defaults to 0 if no query param provided.
        this.applicantId = parseInt(params['applicantId']);
        // this.tabId = parseInt(params['tab']);
        this.isArchive = params['isArchive'] === 'true' ? true : false;
      });
      this.tabId = this.storageservice.retrieve('applicantDetailsTID');

      this.hasApplicantAccess();
    }
  }

  hasApplicantAccess() {
    this.applicantService
      .hasApplicantAccess(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if (res.isSuccess == true) {
          this.headerTitleService.GetPermissionAccessByUser();
          this.bHasApplicantAccess = true;
          this.accessPermissionHidden = !this.bHasApplicantAccess;
        }
      });
  }

  topTimelineRefreshfn(event) {
    if (event) {
      // Refresh topTimeline data
      this.childC.getTopTimelineData();
    }
  }

  refreshDocumentListfn(event) {
    if (event) {
      this.childDocList.ngAfterViewInit();
    }
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
      this.bHasApplicantAccess = false;
      this.accessPermissionHidden = !this.bHasApplicantAccess;
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }
}
