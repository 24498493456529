import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

export class NgxDatatableSSRConfig {
  rows: any[] = [];
  totalRecords: number = 0;
  pageSize: number = 15;
  getAll:boolean= false;

  sortColumn;
  sortDirection;

  currentPageNumber: number = 1;
  tableOffset: number = 0;

  searchText: string = "";

  columnMode = ColumnMode;
  selectionType = SelectionType;

  footerHeight: any = 30;
  headerHeight : any = "auto";
  rowHeight: any = "auto";

  emptyMessage: string = 'No data found.';
  totalMessage: string = 'Total';
  selectedMessage: any = false;  

  limitOptions = [
    {
      key: '5',
      value: 5
    },
    {
      key: '10',
      value: 10
    },
    {
      key: '15',
      value: 15
    },
    {
      key: '20',
      value: 20
    },
    {
      key: '30',
      value: 30
    },
    {
      key: '50',
      value: 50
    },
    {
      key: 'All',
      value: 1
    },
  ];

  public onPageChanged(pageNum: number) {
    this.currentPageNumber = pageNum;
    this.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.getAll = pageSize == 1;
    this.pageSize = pageSize;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTableData(response:any){
    this.rows = [];
    this.rows = response?.response;
    this.totalRecords = response?.totalRecords;
  }

  public updateTableDataClientSide(response:any[]){
    this.rows = response;
    this.totalRecords = this.rows?.length;
    //this.setCurrentPage();
  }
  // public updateTableData(response:any){
  //   this.rows = response;
  //   this.totalRecordSize = this.rows.length;
  //   //this.setCurrentPage();
  // }

  // private setCurrentPage(){
  //   var totalPages = this.totalRecordSize/this.pageSize;
  //   var remainder = this.totalRecordSize%this.pageSize;
  //   var currentPageNumber = remainder > 0 ? totalPages : totalPages + 1;
  //   //this.onPageChanged(currentPageNumber);
    
  //   this.currentPage = currentPageNumber;
  //   this.tableOffset = currentPageNumber-1;
  // }
}
