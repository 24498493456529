import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DataService}  from '../../../shared/services/data.service';
import {ResponseModel} from '../../../shared/models/response.model'
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private service: DataService) { }

  getAgencyReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getAgencyReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  getSponsorReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getSponsorReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  getAttorneyReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getAttorneyReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getAdvisorReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getAdvisorReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getTotalPaymentReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getTotalPaymentReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getPaymentPerAgencyReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getPaymentPerAgencyReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getPaymentPerSponsorReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getPaymentPerSponsorReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getAccountsReceivableReportData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getAccountsReceivableReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  
  getactivityLogData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getActivityLogReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  getStageData(model:any): Observable<ResponseModel> {
    let url = '/gerapi/Report/getStageReport';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
