import { style, state } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Notification  } from "../all-notifications/all-notifications.model";
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AllNotificationsService } from '../all-notifications/all-notifications.service';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import {PaginationBaseModel} from '@models/pagination.model'
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  notifications: Notification[];
  ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
  paginationModel = new PaginationBaseModel();
  private _success = new Subject<string>();
  notificationId: number = 0;
  // alerts: Notification[] = [
  //   {
  //     id:1,
  //     title:'New Applicant Added',
  //     time:'10m',
  //     message: 'New Applicant Added: Pavan  Chandekar',
  //     isRead:false,
  //   },
  //   {
  //     id:2,
  //     title:'Applicant Stage Change',
  //     time:'10m',
  //     message: 'Rajat Bokde has moved to Sponsor Approval.',
  //     isRead:false,
  //   },
  //   {
  //     id:3,
  //     title:'New Documents Added',
  //     time:'10m',
  //     message: 'The documents for Pranay Randive have been updated.',
  //     isRead:false,
  //   },
  //   {
  //     id:4,
  //     title:'Payment Added',
  //     time:'10m',
  //     message: 'A payment for Aniket Dani has been logged.',
  //     isRead:false,
  //   },
  //   {
  //     id:5,
  //     title:'Applicant Stage Change',
  //     time:'10m',
  //     message: 'Amit Channe has moved to Sponsor Approval.',
  //     isRead:false,
  //   },
  // ];

  constructor(public service: AllNotificationsService, private toaster: ToastrService,) {
    this.reset();
  }
  ngOnInit(): void {
    this.initDatatable();
    this.loadListData();
  }
  initDatatable(): void{
    this.notifications = [];
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.paginationModel = {
      sortColumn : '',
      sortDirection:'DESC',
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: false
    };
  }
  loadListData(): void{
    this.service.getAllNotifications(this.paginationModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.notifications = res.response;
    });
  }
 
  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
  
  reset() {
    this.notifications = [];
  }
  markAsRead(notificationId){
    this.service.addMarkAsRead(notificationId, false)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if (res.isSuccess == true) {
        this.toaster.success("Success");
      let  notificationIndex = this.notifications.findIndex(a=>a.notificationId === notificationId);
      this.notifications[notificationIndex].isRead = true;
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  deleteNotification(notificationId) {
    this.service.deleteNotification(notificationId, false)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if (res.isSuccess == true) {
        this.toaster.success("Success");
        let  notificationIndex = this.notifications.findIndex(a=>a.notificationId === notificationId);
        this.notifications.splice(notificationIndex, 1);
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  markAllAsRead(){
    this.service.addMarkAsRead(0, true)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if (res.isSuccess == true) {
        this.toaster.success("Success");
        this.refreshNotification();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  deleteAllNotification() {
    this.service.deleteNotification(0, true)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if (res.isSuccess == true) {
        this.toaster.success("Success");
        this.refreshNotification();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }


  refreshNotification() {
    this.initDatatable();
    this.loadListData();
  }
}
  
