<div class="container-fluid theme-font-size" *ngIf="headerTitleService.PermissionVM.payments">
    <div class="row ">
        <div class="col-sm-12 px-0">
            <div class="card border-radius-top-0 p-t-30">
                <div class="px-xl-5 px-2" *ngIf="headerTitleService.PermissionVM.auditLog">
                    <svg (click)="openAuditLog()" class="pull-right m-r-15 mb-2" type="button"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                        <g id="Group_188" data-name="Group 188" transform="translate(-10.764 -3)">
                            <g id="Group_605" data-name="Group 605">
                                <rect id="Rectangle_251" data-name="Rectangle 251" width="30" height="30" rx="7"
                                    transform="translate(10.764 3)" fill="#a1a1a1" />
                                <path id="XMLID_32_"
                                    d="M16.9,24.288a2.053,2.053,0,1,0-2.053-2.053A2.055,2.055,0,0,0,16.9,24.288Zm0-3.21a1.157,1.157,0,1,1-1.157,1.157A1.158,1.158,0,0,1,16.9,21.079ZM5.182,33.022a2.053,2.053,0,1,0,2.053,2.053A2.055,2.055,0,0,0,5.182,33.022Zm0,3.21a1.157,1.157,0,1,1,1.157-1.157A1.158,1.158,0,0,1,5.182,36.232ZM20.815,30.5a3.687,3.687,0,0,0-3.394-2.3H12.3a1.372,1.372,0,0,0-2.594,0H4.582a2.785,2.785,0,0,1-2.5-1.589,1.372,1.372,0,0,0,0-2.346,2.785,2.785,0,0,1,2.5-1.589h8.692a3.658,3.658,0,1,0,0-.9H4.582a3.687,3.687,0,0,0-3.394,2.3,1.372,1.372,0,0,0,0,2.72,3.687,3.687,0,0,0,3.394,2.3H9.7a1.372,1.372,0,0,0,2.594,0h5.122a2.785,2.785,0,0,1,2.5,1.589,1.372,1.372,0,0,0,0,2.346,2.785,2.785,0,0,1-2.5,1.589H8.813a3.658,3.658,0,1,0,0,.9h8.608a3.687,3.687,0,0,0,3.394-2.3,1.372,1.372,0,0,0,0-2.72ZM16.9,19.474a2.762,2.762,0,1,1-2.762,2.762A2.765,2.765,0,0,1,16.9,19.474ZM1.447,25.916l-.011,0a.477.477,0,1,1-.064-.949.48.48,0,0,1,.064,0l.011,0a.477.477,0,0,1,0,.941ZM11,29.132a.477.477,0,1,1,.477-.477A.477.477,0,0,1,11,29.132Zm-5.819,8.7a2.762,2.762,0,1,1,2.762-2.762A2.765,2.765,0,0,1,5.182,37.837Zm15.448-5.5a.479.479,0,0,1-.064,0l-.012,0a.477.477,0,0,1,0-.941l.011,0a.477.477,0,1,1,.064.949Z"
                                    transform="translate(14.762 -10.655)" fill="#fff" />
                            </g>
                        </g>
                    </svg>
                </div>



                <!-- viewAgreementWithApplicantPayments -->
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithApplicantPayments"
                    class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-3 px-xl-5 mb-2">
                    Applicant Payments
                    <div class="pull-right">
                        Outstanding Due:
                        <span class="f-12 theme-font-color-dark mx-2">
                            ${{editpaymentsApplicantmodel.outstandingAmount}}
                        </span>
                    </div>
                    <hr class="mt-1">
                </div>
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithApplicantPayments"
                    class="row  col-xl-12 px-5">
                    <div class="col-xl-1 px-0"></div>
                    <div class=" col-xl-3 px-0 card-body bg-payment-cart py-1 mt-5 mb-3 mt-xl-2">
                        <div class="theme-font-color-blue font-weight-bold f-14 px-xl-3 px-2 col-12 py-2">Applicant
                            Agreement</div>
                        <hr class="mb-3 hr-grey">
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Total:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsApplicantmodel.paymentInfo?.totalAgreed}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                One:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsApplicantmodel.paymentInfo?.p1
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                Two:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsApplicantmodel.paymentInfo?.p2
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 text-nowrap">Payment
                                Three:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsApplicantmodel.paymentInfo?.p3
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 pb-3">Payment
                                Four:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1 pb-3">${{editpaymentsApplicantmodel.paymentInfo?.p4}}</span>
                        </div>
                    </div>
                    <div class="col-xl-1 px-0"></div>
                    <form class="col-xl-7 px-0">
                        <div class="col-xl-10 p-l-0">
                            <div class="row">
                                <div class="theme-font-color-blue font-weight-bold f-14 px-3 col-xl-12 p-t-20">
                                    Payments Received</div>
                            </div>
                            <input type="hidden" name="editpaymentsApplicantmodel.id"
                                [(ngModel)]="editpaymentsApplicantmodel.id">
                            <input type="hidden" name="editpaymentsApplicantmodel.applicantId"
                                [(ngModel)]="editpaymentsApplicantmodel.applicantId">
                            <input type="hidden" name="editpaymentsApplicantmodel.paymentReceiverId"
                                [(ngModel)]="editpaymentsApplicantmodel.paymentReceiverId">
                            <div class="row align-items-center mt-2 mt-xl-3">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment One:</div>
                                <div class="col-xl-2 px-xl-0">
                                    <input class="form-control f-12 theme-font-color-dark" type="text"
                                        name="editpaymentsApplicantmodel.p1_ReceivedAmount"
                                        [(ngModel)]="editpaymentsApplicantmodel.p1_ReceivedAmount"
                                        (keyup)="editpaymentsApplicantmodel.p1_ReceivedAmount = calApplPOutstandingDue($event); sumApplPOutstandingDue()"
                                        id="p1_ReceivedAmountApplP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Applicant" onlyNumbers>
                                </div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="text"
                                        name="editpaymentsApplicantmodel.p1_ReceivedAmount" type="date" [max]="maxDate"
                                        required="" autocomplete="off"
                                        (ngModelChange)="editpaymentsApplicantmodel.p1_ReceivedDate=$event"
                                        name="editpaymentsApplicantmodel.p1_ReceivedDate"
                                        [ngModel]="(editpaymentsApplicantmodel.p1_ReceivedAmount <= 0) ? '' : editpaymentsApplicantmodel.p1_ReceivedDate | date:'yyyy-MM-dd'"
                                        [disabled]="setEnabled(editpaymentsApplicantmodel.p1_ReceivedAmount, 1)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Two:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsApplicantmodel.p2_ReceivedAmount"
                                        [(ngModel)]="editpaymentsApplicantmodel.p2_ReceivedAmount"
                                        (keyup)="editpaymentsApplicantmodel.p2_ReceivedAmount = calApplPOutstandingDue($event); sumApplPOutstandingDue()"
                                        id="p2_ReceivedAmountApplP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Applicant" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsApplicantmodel.p2_ReceivedDate=$event"
                                        name="editpaymentsApplicantmodel.p2_ReceivedDate"
                                        [ngModel]="(editpaymentsApplicantmodel.p2_ReceivedAmount <= 0) ? '' : editpaymentsApplicantmodel.p2_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsApplicantmodel.p2_ReceivedAmount, 1)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Three:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsApplicantmodel.p3_ReceivedAmount"
                                        [(ngModel)]="editpaymentsApplicantmodel.p3_ReceivedAmount"
                                        (keyup)="editpaymentsApplicantmodel.p3_ReceivedAmount = calApplPOutstandingDue($event); sumApplPOutstandingDue()"
                                        id="p3_ReceivedAmountApplP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Applicant" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsApplicantmodel.p3_ReceivedDate=$event"
                                        name="editpaymentsApplicantmodel.p3_ReceivedDate"
                                        [ngModel]="(editpaymentsApplicantmodel.p3_ReceivedAmount <= 0) ? '' : editpaymentsApplicantmodel.p3_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsApplicantmodel.p3_ReceivedAmount,1)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Four:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsApplicantmodel.p4_ReceivedAmount"
                                        [(ngModel)]="editpaymentsApplicantmodel.p4_ReceivedAmount"
                                        (keyup)="editpaymentsApplicantmodel.p4_ReceivedAmount = calApplPOutstandingDue($event); sumApplPOutstandingDue()"
                                        id="p4_ReceivedAmountApplP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Applicant" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsApplicantmodel.p4_ReceivedDate=$event"
                                        name="editpaymentsApplicantmodel.p4_ReceivedDate"
                                        [ngModel]="(editpaymentsApplicantmodel.p4_ReceivedAmount <= 0) ? '' : editpaymentsApplicantmodel.p4_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsApplicantmodel.p4_ReceivedAmount,1)">
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-edit btn-position payment-edit-button" (click)="updateApplicantPayment()"
                            type="button" [disabled]="isSaving_Applicant">
                            <i class="fa mr-1"
                                [ngClass]="isSaving_Applicant?'fa-spin fa-spinner':(notEditable_Applicant?'fa-edit':'fa-floppy-o')"></i>
                            {{isSaving_Applicant?'Saving...':(notEditable_Applicant?'Edit':'Update')}}
                        </button>
                    </form>
                </div>
                <div *ngIf="!headerTitleService.PermissionVM.viewAgreementWithApplicantPayments"
                    class="col-xl-12 px-3 px-xl-5 mb-2">
                    <app-user-access-permission
                        [userPermission]="headerTitleService.PermissionVM.viewAgreementWithApplicantPayments"
                        hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                </div>




                <!-- viewAgreementWithLiaisonPayments -->
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments"
                    class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-3 px-xl-5 mb-2 mt-3">
                    Liaison Payments
                    <div class="pull-right">
                        Outstanding Due:
                        <span class="f-12 theme-font-color-dark mx-2">
                            ${{editpaymentsLiaisonmodel?.outstandingAmount}}
                        </span>
                    </div>
                    <hr class="mt-1">
                </div>
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments"
                    class="row col-xl-12 px-5">
                    <div class="col-xl-1 px-0"></div>
                    <div class=" col-xl-3 px-0 card-body bg-payment-cart py-1 mt-5 mb-3 mt-xl-2">
                        <div class="theme-font-color-blue font-weight-bold f-14 px-xl-3 px-2 col-12 py-2">Liaison
                            Agreement</div>
                        <hr class="mb-3 hr-grey">
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Total:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsLiaisonmodel.paymentInfo?.totalAgreed
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                One:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsLiaisonmodel.paymentInfo?.p1}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                Two:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsLiaisonmodel.paymentInfo?.p2
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 text-nowrap">Payment
                                Three:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsLiaisonmodel.paymentInfo?.p3
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 pb-3">Payment
                                Four:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1 pb-3">${{editpaymentsLiaisonmodel.paymentInfo?.p4}}</span>
                        </div>
                    </div>
                    <div class="col-xl-1 px-0"></div>
                    <form class="col-xl-7 px-0">
                        <div class="col-xl-10 p-l-0">
                            <div class="row">
                                <div class="theme-font-color-blue font-weight-bold f-14 px-3 col-xl-12 p-t-20">
                                    Payments Received</div>
                            </div>
                            <input type="hidden" name="editpaymentsLiaisonmodel.id"
                                [(ngModel)]="editpaymentsLiaisonmodel.id">
                            <input type="hidden" name="editpaymentsLiaisonmodel.applicantId"
                                [(ngModel)]="editpaymentsLiaisonmodel.applicantId">
                            <input type="hidden" name="editpaymentsLiaisonmodel.paymentReceiverId"
                                [(ngModel)]="editpaymentsLiaisonmodel.paymentReceiverId">
                            <div class="row align-items-center mt-2 mt-xl-3">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment One:</div>
                                <div class="col-xl-2 px-xl-0"><input
                                        class="form-control  b-r-5 f-12  theme-font-color-dark" type="text"
                                        name="editpaymentsLiaisonmodel.p1_ReceivedAmount"
                                        [(ngModel)]="editpaymentsLiaisonmodel.p1_ReceivedAmount"
                                        (keyup)="editpaymentsLiaisonmodel.p1_ReceivedAmount = cal_liaison_OutstandingDue($event); sum_liaison_OutstandingDue()"
                                        id="p1_ReceivedAmountliaiP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Liaison" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsLiaisonmodel.p1_ReceivedDate=$event"
                                        name="editpaymentsLiaisonmodel.p1_ReceivedDate"
                                        [ngModel]="(editpaymentsLiaisonmodel.p1_ReceivedAmount <= 0) ? '' : editpaymentsLiaisonmodel.p1_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="11/02/2021"
                                        [disabled]="setEnabled(editpaymentsLiaisonmodel.p1_ReceivedAmount,2)">

                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Two:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsLiaisonmodel.p2_ReceivedAmount"
                                        [(ngModel)]="editpaymentsLiaisonmodel.p2_ReceivedAmount"
                                        (keyup)="editpaymentsLiaisonmodel.p2_ReceivedAmount = cal_liaison_OutstandingDue($event); sum_liaison_OutstandingDue()"
                                        id="p2_ReceivedAmountliaiP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Liaison" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsLiaisonmodel.p2_ReceivedDate=$event"
                                        name="editpaymentsLiaisonmodel.p2_ReceivedDate"
                                        [ngModel]="(editpaymentsLiaisonmodel.p2_ReceivedAmount <= 0) ? '' : editpaymentsLiaisonmodel.p2_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsLiaisonmodel.p2_ReceivedAmount, 2)">
                                    <!-- <input class="form-control col-xl-9 b-r-5 f-12 mt-1" id="validationCustom4" type="date"  required="" autocomplete="off"  [(ngModel)]="editpaymentsLiaisonmodel.p2_ReceivedDate"  name="editpaymentsLiaisonmodel.p2_ReceivedDate"
                                        data-bs-original-title="" title=""  [disabled]="notEditable_Liaison"> -->
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Three:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsLiaisonmodel.p3_ReceivedAmount"
                                        [(ngModel)]="editpaymentsLiaisonmodel.p3_ReceivedAmount"
                                        (keyup)="editpaymentsLiaisonmodel.p3_ReceivedAmount = cal_liaison_OutstandingDue($event); sum_liaison_OutstandingDue()"
                                        id="p3_ReceivedAmountliaiP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Liaison" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsLiaisonmodel.p3_ReceivedDate=$event"
                                        name="editpaymentsLiaisonmodel.p3_ReceivedDate"
                                        [ngModel]="(editpaymentsLiaisonmodel.p3_ReceivedAmount <= 0) ? '' : editpaymentsLiaisonmodel.p3_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsLiaisonmodel.p3_ReceivedAmount, 2)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Four:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsLiaisonmodel.p4_ReceivedAmount"
                                        [(ngModel)]="editpaymentsLiaisonmodel.p4_ReceivedAmount"
                                        (keyup)="editpaymentsLiaisonmodel.p4_ReceivedAmount = cal_liaison_OutstandingDue($event); sum_liaison_OutstandingDue()"
                                        id="p4_ReceivedAmountliaiP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Liaison" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate"
                                        (ngModelChange)="editpaymentsLiaisonmodel.p4_ReceivedDate=$event"
                                        name="editpaymentsLiaisonmodel.p4_ReceivedDate"
                                        [ngModel]="(editpaymentsLiaisonmodel.p4_ReceivedAmount <= 0) ? '' : editpaymentsLiaisonmodel.p4_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsLiaisonmodel.p4_ReceivedAmount, 2)">
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-edit btn-position payment-edit-button" (click)="updateLiaisonPayment()"
                            type="button" [disabled]="isSaving_Liaison">
                            <i class="fa mr-1"
                                [ngClass]="isSaving_Liaison?'fa-spin fa-spinner':(notEditable_Liaison?'fa-edit':'fa-floppy-o')"></i>
                            {{isSaving_Liaison?'Saving...':(notEditable_Liaison?'Edit':'Update')}}
                        </button>
                    </form>
                </div>
                <div *ngIf="!headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments"
                    class="col-xl-12 px-3 px-xl-5 mb-2">
                    <app-user-access-permission
                        [userPermission]="headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments"
                        hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                </div>



                <!-- viewAgreementWithAgencyPayments -->
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithAgencyPayments"
                    class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-3 px-xl-5 mt-3 mb-2">
                    Agency Payments
                    <div class="pull-right">
                        Outstanding Due:
                        <span class="f-12 theme-font-color-dark mx-2">
                            ${{editpaymentsAgencymodel?.outstandingAmount}}
                        </span>
                    </div>
                    <hr class="mt-1">
                </div>
                <div *ngIf="headerTitleService.PermissionVM.viewAgreementWithAgencyPayments"
                    class="row  col-xl-12 px-5">
                    <div class="col-xl-1 px-0"></div>
                    <div class=" col-xl-3 px-0 card-body bg-payment-cart py-1 mt-5 mb-3 mt-xl-2">
                        <div class="theme-font-color-blue font-weight-bold f-14 px-xl-3 px-2 col-12 py-2">Agency
                            Agreement</div>
                        <hr class="mb-3 hr-grey">
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Total:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsAgencymodel.paymentInfo?.totalAgreed}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                One:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsAgencymodel.paymentInfo?.p1}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                Two:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsAgencymodel.paymentInfo?.p2
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 text-nowrap">Payment
                                Three:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsAgencymodel.paymentInfo?.p3
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 pb-3">Payment
                                Four:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1 pb-3">${{editpaymentsAgencymodel.paymentInfo?.p4}}</span>
                        </div>
                    </div>
                    <div class="col-xl-1 px-0"></div>
                    <form class="col-xl-7 px-0">
                        <div class="col-xl-10 p-l-0">
                            <div class="row">
                                <div class="theme-font-color-blue font-weight-bold f-14 px-3 col-xl-12 p-t-20">
                                    Payments Received</div>
                            </div>
                            <input type="hidden" name="editpaymentsAgencymodel.id"
                                [(ngModel)]="editpaymentsAgencymodel.id">
                            <input type="hidden" name="editpaymentsAgencymodel.applicantId"
                                [(ngModel)]="editpaymentsAgencymodel.applicantId">
                            <input type="hidden" name="editpaymentsAgencymodel.paymentReceiverId"
                                [(ngModel)]="editpaymentsAgencymodel.paymentReceiverId">
                            <div class="row align-items-center mt-2 mt-xl-3">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment One:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsAgencymodel.p1_ReceivedAmount"
                                        [(ngModel)]="editpaymentsAgencymodel.p1_ReceivedAmount"
                                        (keyup)="editpaymentsAgencymodel.p1_ReceivedAmount = cal_agency_OutstandingDue($event); sum_agency_OutstandingDue()"
                                        id="p1_ReceivedAmountAgencyP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Agency" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsAgencymodel.p1_ReceivedDate"
                                        (ngModelChange)="editpaymentsAgencymodel.p1_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsAgencymodel.p1_ReceivedAmount <= 0) ? '' : editpaymentsAgencymodel.p1_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsAgencymodel.p1_ReceivedAmount,3)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Two:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsAgencymodel.p2_ReceivedAmount"
                                        [(ngModel)]="editpaymentsAgencymodel.p2_ReceivedAmount"
                                        (keyup)="editpaymentsAgencymodel.p2_ReceivedAmount = cal_agency_OutstandingDue($event); sum_agency_OutstandingDue()"
                                        id="p2_ReceivedAmountAgencyP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Agency" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsAgencymodel.p2_ReceivedDate"
                                        (ngModelChange)="editpaymentsAgencymodel.p2_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsAgencymodel.p2_ReceivedAmount <= 0) ? '' : editpaymentsAgencymodel.p2_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsAgencymodel.p2_ReceivedAmount,3)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Three:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsAgencymodel.p3_ReceivedAmount"
                                        [(ngModel)]="editpaymentsAgencymodel.p3_ReceivedAmount"
                                        (keyup)="editpaymentsAgencymodel.p3_ReceivedAmount = cal_agency_OutstandingDue($event); sum_agency_OutstandingDue()"
                                        id="p3_ReceivedAmountAgencyP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Agency" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsAgencymodel.p3_ReceivedDate"
                                        (ngModelChange)="editpaymentsAgencymodel.p3_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsAgencymodel.p3_ReceivedAmount <= 0) ? '' : editpaymentsAgencymodel.p3_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsAgencymodel.p3_ReceivedAmount, 3)">

                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Four:</div>
                                <div class="col-xl-2 px-xl-0">
                                    <input class="form-control f-12 theme-font-color-dark" type="text"
                                        name="editpaymentsAgencymodel.p4_ReceivedAmount"
                                        [ngModel]="editpaymentsAgencymodel.p4_ReceivedAmount"
                                        (ngModelChange)="editpaymentsAgencymodel.p4_ReceivedAmount = $event"
                                        (keyup)="editpaymentsAgencymodel.p4_ReceivedAmount = cal_agency_OutstandingDue($event); sum_agency_OutstandingDue()"
                                        id="p4_ReceivedAmountAgencyP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_Agency" onlyNumbers>
                                </div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsAgencymodel.p4_ReceivedDate"
                                        (ngModelChange)="editpaymentsAgencymodel.p4_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsAgencymodel.p4_ReceivedAmount <= 0) ? '' : editpaymentsAgencymodel.p4_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsAgencymodel.p4_ReceivedAmount, 3)">
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-edit btn-position payment-edit-button" (click)="updateAgencyPayment()"
                            type="button" [disabled]="isSaving_Agency">
                            <i class="fa mr-1"
                                [ngClass]="isSaving_Agency?'fa-spin fa-spinner':(notEditable_Agency?'fa-edit':'fa-floppy-o')"></i>
                            {{isSaving_Agency?'Saving...':(notEditable_Agency?'Edit':'Update')}}
                        </button>
                    </form>
                </div>
                <div *ngIf="!headerTitleService.PermissionVM.viewAgreementWithAgencyPayments"
                    class="col-xl-12 px-3 px-xl-5 mb-2">
                    <app-user-access-permission
                        [userPermission]="headerTitleService.PermissionVM.viewAgreementWithAgencyPayments"
                        hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                </div>



                <!-- viewCommissionsToGERAdvisorsPayments -->
                <div *ngIf="headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments"
                    class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-3 px-xl-5 mt-3 mb-2">
                    Commissions to GER Advisors
                    <div class="pull-right">
                        Outstanding Due:
                        <span class="f-12 theme-font-color-dark mx-2">
                            ${{editpaymentsGerAdvisormodel?.outstandingAmount}}
                        </span>
                    </div>
                    <hr class="mt-1">
                </div>
                <div *ngIf="headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments"
                    class="row  col-xl-12 px-5">
                    <div class="col-xl-1 px-0"></div>
                    <div class=" col-xl-3 px-0 card-body bg-payment-cart py-1 mt-5 mb-3 mt-xl-2">
                        <div class="theme-font-color-blue font-weight-bold f-14 px-xl-3 px-2 col-12 py-2">GER Advisor
                            Agreement</div>
                        <hr class="mb-3 hr-grey">
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Total:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsGerAdvisormodel.paymentInfo?.totalAgreed}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                One:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsGerAdvisormodel.paymentInfo?.p1}}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1">Payment
                                Two:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsGerAdvisormodel.paymentInfo?.p2
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 text-nowrap">Payment
                                Three:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1">${{editpaymentsGerAdvisormodel.paymentInfo?.p3
                                }}</span>
                        </div>
                        <div class="col-xl-12 col-sm-12 col-12">
                            <span
                                class="col-xl-6 col-sm-5 col-7 pull-left theme-font-color-blue f-12 px-0 my-1 pb-3">Payment
                                Four:</span>
                            <span
                                class="col-xl-6 col-sm-6 col-5 p-r-0 pull-left theme-font-color-dark f-12 my-1 pb-3">${{editpaymentsGerAdvisormodel.paymentInfo?.p4}}</span>
                        </div>
                    </div>
                    <div class="col-xl-1 px-0"></div>
                    <form class="col-xl-7 px-0">
                        <div class="col-xl-10 p-l-0">
                            <div class="row">
                                <div class="theme-font-color-blue font-weight-bold f-14 px-3 col-xl-12 p-t-20">
                                    Payments Received</div>
                            </div>
                            <input type="hidden" name="editpaymentsGerAdvisormodel.id"
                                [(ngModel)]="editpaymentsGerAdvisormodel.id">
                            <input type="hidden" name="editpaymentsGerAdvisormodel.applicantId"
                                [(ngModel)]="editpaymentsGerAdvisormodel.applicantId">
                            <input type="hidden" name="editpaymentsGerAdvisormodel.paymentReceiverId"
                                [(ngModel)]="editpaymentsGerAdvisormodel.paymentReceiverId">
                            <div class="row align-items-center mt-2 mt-xl-3">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment One:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsGerAdvisormodel.p1_ReceivedAmount"
                                        [(ngModel)]="editpaymentsGerAdvisormodel.p1_ReceivedAmount"
                                        (keyup)="editpaymentsGerAdvisormodel.p1_ReceivedAmount = cal_gerAdvisor_OutstandingDue($event); sum_gerAdvisor_OutstandingDue()"
                                        id="p1_ReceivedAmountCommissionsP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_GerAdvisor" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsGerAdvisormodel.p1_ReceivedDate"
                                        (ngModelChange)="editpaymentsGerAdvisormodel.p1_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsGerAdvisormodel.p1_ReceivedAmount <= 0) ? '' : editpaymentsGerAdvisormodel.p1_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsGerAdvisormodel.p1_ReceivedAmount,4)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Two:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsGerAdvisormodel.p2_ReceivedAmount"
                                        [(ngModel)]="editpaymentsGerAdvisormodel.p2_ReceivedAmount"
                                        (keyup)="editpaymentsGerAdvisormodel.p2_ReceivedAmount = cal_gerAdvisor_OutstandingDue($event); sum_gerAdvisor_OutstandingDue()"
                                        id="p2_ReceivedAmountCommissionsP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_GerAdvisor" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsGerAdvisormodel.p2_ReceivedDate"
                                        (ngModelChange)="editpaymentsGerAdvisormodel.p2_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsGerAdvisormodel.p2_ReceivedAmount <= 0) ? '' : editpaymentsGerAdvisormodel.p2_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsGerAdvisormodel.p2_ReceivedAmount,4)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Three:</div>
                                <div class="col-xl-2 px-xl-0"><input class="form-control f-12 theme-font-color-dark"
                                        type="text" name="editpaymentsGerAdvisormodel.p3_ReceivedAmount"
                                        [(ngModel)]="editpaymentsGerAdvisormodel.p3_ReceivedAmount"
                                        (keyup)="editpaymentsGerAdvisormodel.p3_ReceivedAmount = cal_gerAdvisor_OutstandingDue($event); sum_gerAdvisor_OutstandingDue()"
                                        id="p3_ReceivedAmountCommissionsP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_GerAdvisor" onlyNumbers></div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsGerAdvisormodel.p3_ReceivedDate"
                                        (ngModelChange)="editpaymentsGerAdvisormodel.p3_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsGerAdvisormodel.p3_ReceivedAmount <= 0) ? '' : editpaymentsGerAdvisormodel.p3_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsGerAdvisormodel.p3_ReceivedAmount, 4)">
                                </div>
                            </div>
                            <div class="row align-items-center mt-2">
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Payment Four:</div>
                                <div class="col-xl-2 px-xl-0">
                                    <input class="form-control f-12 theme-font-color-dark" type="text"
                                        name="editpaymentsGerAdvisormodel.p4_ReceivedAmount"
                                        [ngModel]="editpaymentsGerAdvisormodel.p4_ReceivedAmount"
                                        (ngModelChange)="editpaymentsGerAdvisormodel.p4_ReceivedAmount = $event"
                                        (keyup)="editpaymentsGerAdvisormodel.p4_ReceivedAmount = cal_gerAdvisor_OutstandingDue($event); sum_gerAdvisor_OutstandingDue()"
                                        id="p4_ReceivedAmountCommissionsP" (blur)="stringToDecimal($event)"
                                        placeholder="Pending" [disabled]="notEditable_GerAdvisor" onlyNumbers>
                                </div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-3 theme-font-color-blue f-12 text-nowrap">Date Received:</div>
                                <div class="col-xl-3 px-xl-0">
                                    <input class="form-control f-12 px-2 theme-font-color-dark" type="date"
                                        [max]="maxDate" name="editpaymentsGerAdvisormodel.p4_ReceivedDate"
                                        (ngModelChange)="editpaymentsGerAdvisormodel.p4_ReceivedDate=$event"
                                        [ngModel]="(editpaymentsGerAdvisormodel.p4_ReceivedAmount <= 0) ? '' : editpaymentsGerAdvisormodel.p4_ReceivedDate | date:'yyyy-MM-dd'"
                                        placeholder="Pending"
                                        [disabled]="setEnabled(editpaymentsGerAdvisormodel.p4_ReceivedAmount, 4)">
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-edit btn-position payment-edit-button"
                            (click)="updateGerAdvisorPayment()" type="button" [disabled]="isSaving_GerAdvisor">
                            <i class="fa mr-1"
                                [ngClass]="isSaving_GerAdvisor?'fa-spin fa-spinner':(notEditable_GerAdvisor?'fa-edit':'fa-floppy-o')"></i>
                            {{isSaving_GerAdvisor?'Saving...':(notEditable_GerAdvisor?'Edit':'Update')}}
                        </button>
                    </form>
                </div>
                <div *ngIf="!headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments"
                    class="col-xl-12 px-3 px-xl-5 mb-2">
                    <app-user-access-permission
                        [userPermission]="headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments"
                        hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                </div>




                <!-- viewAdditionalPayments -->
                <div *ngIf="headerTitleService.PermissionVM.viewAdditionalPayments"
                    class="theme-font-color-blue font-weight-bold f-14 roboto-font  col-xl-12 px-3 px-xl-5 mt-3 mb-2">
                    Additional Payments
                    <hr class="mt-1">
                </div>
                <div *ngIf="headerTitleService.PermissionVM.viewAdditionalPayments" class="row col-xl-12 px-5 ml-0">
                    <div class="col-xl-1 px-0"> </div>
                    <div class="col-xl-12 row mt-1">
                        <div class="col-xl-1 px-0"></div>
                        <div class="col-xl-10">
                            <div *ngIf="filter(additionalPaymentModel.additionalPayments).length > 0">
                                <div class="row mb-3"
                                    *ngFor='let item of filter(additionalPaymentModel.additionalPayments) ;let i = index'>
                                    <input type="hidden" name="item.id" [(ngModel)]="item.id">
                                    <input type="hidden" name="item.applicantId" [(ngModel)]="item.applicantId">
                                    <div class="col-md-3 pl-0">
                                        <label class="form-label subhead-blue m-b-0 f-14 roboto-font"
                                            for="payment_Name">
                                            Payment Name:<span class="text-danger">*</span>
                                        </label>
                                        <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" type="text"
                                            [(ngModel)]="item.payment_Name" name="item.payment_Name"
                                            [disabled]="notEditable_AdditionalPayment" required
                                            data-bs-original-title="">
                                    </div>
                                    <div class="col-md-3 pl-0">
                                        <label class="form-label subhead-blue m-b-0 f-14 roboto-font"
                                            for="payment_Amount">
                                            Amount:<span class="text-danger">*</span>
                                        </label>
                                        <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1"
                                            [(ngModel)]="item.payment_Amount" name="item.payment_Amount"
                                            (blur)="stringToDecimal($event)" [disabled]="notEditable_AdditionalPayment"
                                            type="text" required onlyNumbers data-bs-original-title="">
                                    </div>
                                    <div class="col-md-2 pl-0" style="max-width: 20% !important;">
                                        <label class="form-label subhead-blue m-b-0 f-14 roboto-font"
                                            for="payment_ReceivedDate">
                                            Date Received:<span class="text-danger">*</span>
                                        </label>
                                        <input class="form-control py-2 f-12 b-r-5 col-xl-12 mt-1"
                                            name="item.payment_ReceivedDate"
                                            (ngModelChange)="item.payment_ReceivedDate=$event"
                                            [ngModel]="(item.payment_Amount <= 0) ? '' : item.payment_ReceivedDate | date:'yyyy-MM-dd'"
                                            [disabled]="setEnabled(item.payment_Amount, 5)" type="date" [max]="maxDate"
                                            required data-bs-original-title="">
                                    </div>
                                    <div *ngIf="!notEditable_AdditionalPayment" class="p-t-20 media"
                                        (click)="removePayment(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-trash-2 media-body align-self-center text-danger"
                                            _ngcontent-ucv-c109="">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                            </path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button class="btn btn-edit btn-position payment-edit-button mt-sm-3 mt-xl-0"
                                (click)="updateAdditionalPayment()" type="button"
                                [disabled]="isSaving_AdditionalPayment">
                                <i class="fa mr-1"
                                    [ngClass]="isSaving_AdditionalPayment?'fa-spin fa-spinner':(notEditable_AdditionalPayment?'fa-edit':'fa-floppy-o')"></i>
                                {{isSaving_AdditionalPayment?'Saving...':(notEditable_AdditionalPayment?'Edit':'Update')}}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!notEditable_AdditionalPayment" class=" col-xl-12 row g-3 my-1 px-5 pb-5">
                        <div class="col-xl-1 px-0"></div>
                        <span class="title subhead-blue media f-20 col-xl-2 pl-0" (click)="addAnotherPayment()">
                            <i class="fa fa-plus-square-o align-self-center"></i>
                            <span class="f-14 roboto-font media-body align-content-center p-l-10 ">Add
                                another
                                payment</span>
                        </span>
                    </div>
                </div>
                <div *ngIf="!headerTitleService.PermissionVM.viewAdditionalPayments"
                    class="col-xl-12 px-3 px-xl-5 mb-2">
                    <app-user-access-permission
                        [userPermission]="headerTitleService.PermissionVM.viewAdditionalPayments" hasBorder="true"
                        hasFullHeight="true"></app-user-access-permission>
                </div>
            </div>
        </div>
    </div>
</div>