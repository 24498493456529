import { Component, OnInit } from '@angular/core';
import {HeaderTitleService} from '@services/header-title.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(public headerTitleService:HeaderTitleService,) {}

  ngOnInit(): void {
    this.headerTitleService.title="Reports";
  }

  reportList = [
    {
      name: "Per Agency",
      color: "#F8B889", //orange
      link: "/reports/agency-report",
      isVisible:this.headerTitleService.PermissionVM.reportPerAgency
    },
    {
      name: "Per Sponsor",
      color: "#F4D174", //yellow
      link: "/reports/sponsor-report",
      isVisible:this.headerTitleService.PermissionVM.reportPerSponsor
    },
    {
      name: "Per Attorney",
      color: "#8CAD8E", //green
      link: "/reports/attorney-report",
      isVisible:this.headerTitleService.PermissionVM.reportPerAttorney
    },
    {
      name: "Per Advisor",
      color: "#87C4C2", //cyan
      link: "/reports/advisor-report",
      isVisible:this.headerTitleService.PermissionVM.reportPerAdvisor
    },
    {
      name: "Stages",
      color: "#F88989", //pink
      link: "/reports/stages-report",
      isVisible:this.headerTitleService.PermissionVM.reportStages
    },
    {
      name: "Activity Log",
      color: "#A08CAD", //gray
      link: "/reports/activity-log-report",
      isVisible:this.headerTitleService.PermissionVM.reportActivityLog
    },
    {
      name: "Total Payments",
      color: "#89F4F8", //skyblue
      link: "/reports/total-payment-report",
      isVisible:this.headerTitleService.PermissionVM.reportTotalPayments
    },
    {
      name: "Payments Per Sponsor",
      color: "#7F459D", //purple
      link: "/reports/payment-per-sponsor-report",
      isVisible:this.headerTitleService.PermissionVM.reportPaymentsPerSponsor
    },
    {
      name: "Payments Per Agency",
      color: "#744545", //chocolate
      link: "/reports/payment-per-agency-report",
      isVisible:this.headerTitleService.PermissionVM.reportPaymentsPerAgency
    },
    {
      name: "Accounts Receivable",
      color: "#5377B5", //light blue
      link: "/reports/accounts-receivable-report",
      isVisible:this.headerTitleService.PermissionVM.reportAccountsReceivable
    }
  ]

}
