export class DashboardResponse {
    listCountYearWise: ListCountYearWise[];
    listCountSponsors: ListCountSponsor[];
    listCountStage: ListCountStage[];
    listCallSchedule: ListCallSchedule[];
    listCalendarData: ListCalendarData[];
}

export class DashboardPost {
    startDate: string;
    endDate: string;
    currentDate: string;
    userId: string;
}
export class ListCountYearWise {
    months: string;
    countApplicant: number;
}

export class ListCountSponsor {
    applicantCount: number;
    sponsorsName: string;
}

export class ListCountStage {
    applicantCount: number;
    stageName: string;
}

export class ListCallSchedule {
    id: number;
    applicantId: number;
    isArchive: boolean;
    dueDate: string;
    details: string;
    applicantName: string;
    activityTitle: string;
}

export class ListCalendarData {
    dates: string;
    isAvailable: string;
}

export class ListDateWiseActivityLog {
    isDateAvailable: boolean;
    date: string;
}

export class CalenderDataPost {
    startDate: string;
    endDate: string;
    userId: string;
}

export class CallScheduleDataPost {
    currentDate: string;
    userId: string;
}

export class TimePeriodVM {
    timePeriodYear: string;
}