export class MasterConstant{
    activityType:string = "Activity Type";
    scheduledCall:string = "Scheduled Call";
}


export class ResponseModel {
    isSuccess: boolean;
    message: string;
    response: any;
    errors: string[];
    status: number;
    totalRecords:number=0;
}