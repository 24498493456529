
export class TimelineVM {
    id: number;
    applicantId: number;
    stageId: number;
    stageName: string;
    stageStatusId: number;
    startDate: string | null;
    completionDate: string | null;
    isCurrent: boolean;
}

export class TimelineResponse {
    id: number;
    stageStatusId: number;
    startDate: string | null;
    completionDate: string | null;
}

export class Timeline {
    stage1: TimelineVM;
    stage2: TimelineVM;
    stage3: TimelineVM;
    stage4: TimelineVM;
    stage5: TimelineVM;
    stage6: TimelineVM;
    progressBarValue: number;
}
export class TopTimelineData {
    stage1: TimelineVM;
    stage2: TimelineVM;
    stage3: TimelineVM;
    stage4: TimelineVM;
    stage5: TimelineVM;
    stage6: TimelineVM;
    applicantId: number;
    applicantName: string;
    applicationNumber: string;
    gerApplicationNumber: string;
    sponsorName: string;
    agencyName: string;
    levelName: string;
    progressBarValue: number;
    tandemProgram:string;
}
export enum StageStatus {
    "Completed" = 1,
    "InProcess" = 2,
    "Withdrawn" = 3,
    "NotStarted" = 4
}

// export enum StageEnum {
//     "Sponsor Approval" = 1,
//     "Initial Payment" = 2,
//     "LC Approved Date" = 3,
//     "I-140 Process" = 4,
//     "Adjustment / Consular Process" = 5,
//     "Placement Complete" = 6
// }

//   export const StageStatusList = {
//     [StageStatus.Completed]: "Completed",
//     [StageStatus.InProcess]: "InProcess",
//     [StageStatus.Withdrawn]: "Withdrawn",
//     [StageStatus.NotStarted]: "NotStarted",
// }

export class TimelineModalData {
    id: number;
    applicantId: number;
    stageId: number;
    stageName: string;
    stageStatusId: number;
    startDate: string | null;
    completionDate: string | null;
}

export class UpdateTimelineStatusModel {
    id: number;
    stageStatusId: number;
    applicantId: number;
    startDate: string | null ;
    completionDate: string  | null ;
}

