<!-- content starts-->
<div class="content-wrapper theme-font-size">
  <div class="row1 p-r-0">
    <div class=" h-25 p-r-0">
      <div class="default-according" id="accordion">
        <div class="card shadow mb-3">
          <div class="tab-content card-body border-0 p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
            <div class="tab-pane fade show active" id="app-info" role="tabpanel" aria-labelledby="pills-home-tab">
              <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                <div class="row p-l-35">
                  <div class="row col-xl-12 p-l-0">
                    <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 px-0">
                      Payment Information
                      <hr class="mt-1">
                    </span>
                  </div>
                  <div class="col-xl-12 p-l-0"  *ngIf="headerTitleService.PermissionVM.payments">
                    <div class="row col-xl-11 mt-xl-3 mt-md-3 mt-sm-3 mt-5 bg-light px-0  py-0 b-r-9">
                      <div class="col-md-3 p-0 pr-md-4">
                        <div class="py-2 px-3" *ngIf="headerTitleService.PermissionVM.viewAgreementWithApplicantPayments">
                          <div class="col-md-12 px-0 my-1">
                            <div class="form-label text-primary m-b-0 f-14 roboto-font" for="">
                              <span class="font-weight-bold subhead-blue letter-spacing-0">
                                Agreement with Applicant
                              </span>
                              <hr>
                            </div>



                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Total:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="applicant_TotalAgreed" type="text" name="applicant_TotalAgreed"
                              [(ngModel)]="applicantService.paymentInfoModel.applicant_TotalAgreed"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              disabled>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="applicant_P1">
                              Payment One:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="applicant_P1" type="text"
                              (keyup)="applicantService.paymentInfoModel.applicant_P1 = calApplAgreement($event); sumApplPAgreement()"
                              name="applicant_P1" [(ngModel)]="applicantService.paymentInfoModel.applicant_P1"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Two:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="applicant_P2" type="text"
                              (keyup)="applicantService.paymentInfoModel.applicant_P2 = calApplAgreement($event); sumApplPAgreement()"
                              name="applicant_P2" [(ngModel)]="applicantService.paymentInfoModel.applicant_P2"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Three:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="applicant_P3" type="text"
                              (keyup)="applicantService.paymentInfoModel.applicant_P3 = calApplAgreement($event); sumApplPAgreement()"
                              name="applicant_P3" [(ngModel)]="applicantService.paymentInfoModel.applicant_P3"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Four:
                              <span class="f-10 text-dark">
                                (Optional)
                              </span>
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="applicant_P4" type="text"
                              (keyup)="applicantService.paymentInfoModel.applicant_P4 = calApplAgreement($event); sumApplPAgreement()"
                              name="applicant_P4" [(ngModel)]="applicantService.paymentInfoModel.applicant_P4"
                              (blur)="stringToDecimal($event)" data-bs-original-title="" title=""
                              [disabled]="notEditable" onlyNumbers>
                          </div>
                        </div>
                        <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewAgreementWithApplicantPayments">
                          <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewAgreementWithApplicantPayments"
                            hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                        </div>
                      </div>
                      <div class="col-md-3 p-0 pr-md-4">
                        <div class="py-2 px-3" *ngIf="headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments">
                          <div class="col-md-12 px-0 my-1">
                            <div class="form-label text-primary m-b-0 f-14 roboto-font" for="">
                              <span class="font-weight-bold subhead-blue letter-spacing-0">
                                Agreement with Liaison
                              </span>
                              <hr>
                            </div>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Total:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="coordinator_TotalAgreed" type="text" name="coordinator_TotalAgreed"
                              [(ngModel)]="applicantService.paymentInfoModel.coordinator_TotalAgreed"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              disabled (keydown)="onKeydown($event,'#agency_TotalAgreed')">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom01">
                              Payment One:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="coordinator_P1" type="text"
                              (keyup)="applicantService.paymentInfoModel.coordinator_P1 = calLiaisonAgreement($event); sumLiaisonPAgreement()"
                              name="coordinator_P1" [(ngModel)]="applicantService.paymentInfoModel.coordinator_P1"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Two:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="coordinator_P2" type="text"
                              (keyup)="applicantService.paymentInfoModel.coordinator_P2 = calLiaisonAgreement($event); sumLiaisonPAgreement()"
                              name="coordinator_P2" [(ngModel)]="applicantService.paymentInfoModel.coordinator_P2"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Three:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="coordinator_P3" type="text"
                              (keyup)="applicantService.paymentInfoModel.coordinator_P3 = calLiaisonAgreement($event); sumLiaisonPAgreement()"
                              name="coordinator_P3" [(ngModel)]="applicantService.paymentInfoModel.coordinator_P3"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Four:
                              <span class="f-10 text-dark">
                                (Optional)
                              </span>
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="coordinator_P4" type="text"
                              (keyup)="applicantService.paymentInfoModel.coordinator_P4 = calLiaisonAgreement($event); sumLiaisonPAgreement()"
                              name="coordinator_P4" [(ngModel)]="applicantService.paymentInfoModel.coordinator_P4"
                              (blur)="stringToDecimal($event)" data-bs-original-title="" title=""
                              [disabled]="notEditable" onlyNumbers>
                          </div>
                        </div>
                        <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments">
                          <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments"
                            hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                        </div>
                      </div>
                      <div class="col-md-3 p-0 pr-md-4">
                        <div class="py-2 px-3" *ngIf="headerTitleService.PermissionVM.viewAgreementWithAgencyPayments">
                          <div class="col-md-12 px-0 my-1">
                            <div class="form-label text-primary m-b-0 f-14 roboto-font" for="">
                              <span class="font-weight-bold subhead-blue letter-spacing-0">
                                Agreement with Agency
                              </span>
                              <hr>
                            </div>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Total:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="agency_TotalAgreed" type="text" name="agency_TotalAgreed"
                              [(ngModel)]="applicantService.paymentInfoModel.agency_TotalAgreed" required onlyNumbers
                              data-bs-original-title="" title="" disabled>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="agency_P1">
                              Payment One:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="agency_P1" type="text" name="agency_P1"
                              (keyup)="applicantService.paymentInfoModel.agency_P1 = calAgencyAgreement($event); sumAgencyPAgreement()"
                              (blur)="stringToDecimal($event)" [(ngModel)]="applicantService.paymentInfoModel.agency_P1"
                              required onlyNumbers data-bs-original-title="" title="" [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Two:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="agency_P2" type="text" name="agency_P2"
                              (keyup)="applicantService.paymentInfoModel.agency_P2 = calAgencyAgreement($event); sumAgencyPAgreement()"
                              (blur)="stringToDecimal($event)" [(ngModel)]="applicantService.paymentInfoModel.agency_P2"
                              required onlyNumbers data-bs-original-title="" title="" [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Three:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="agency_P3" type="text" name="agency_P3"
                              (keyup)="applicantService.paymentInfoModel.agency_P3 = calAgencyAgreement($event); sumAgencyPAgreement()"
                              (blur)="stringToDecimal($event)" [(ngModel)]="applicantService.paymentInfoModel.agency_P3"
                              required onlyNumbers data-bs-original-title="" title="" [disabled]="notEditable">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Four:
                              <span class="f-10 text-dark">
                                (Optional)
                              </span>
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="agency_P4" type="text" name="agency_P4"
                              (keyup)="applicantService.paymentInfoModel.agency_P4 = calAgencyAgreement($event); sumAgencyPAgreement()"
                              (blur)="stringToDecimal($event)" [(ngModel)]="applicantService.paymentInfoModel.agency_P4"
                              data-bs-original-title="" title="" [disabled]="notEditable" onlyNumbers>
                          </div>
                        </div>
                        <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewAgreementWithAgencyPayments">
                          <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewAgreementWithAgencyPayments"
                            hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                        </div>
                      </div>
                      <div class="col-md-3 p-0 pr-md-4">
                        <div class="py-2 px-3" *ngIf="headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments">

                          <div class="col-md-12 px-0 my-1">
                            <div class="form-label text-primary m-b-0 f-14 roboto-font" for="">
                              <span class="font-weight-bold subhead-blue letter-spacing-0">
                                Commissions to GER Advisors
                              </span>
                              <hr>
                            </div>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Total:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="gerAdvisor_TotalAgreed" type="text" name="gerAdvisor_TotalAgreed"
                              [(ngModel)]="applicantService.paymentInfoModel.gerAdvisor_TotalAgreed" required
                              onlyNumbers data-bs-original-title="" title="" disabled>
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="commissions_P1">
                              Payment One:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="commissions_P1" type="text" name="commissions_P1"
                              (keyup)="applicantService.paymentInfoModel.gerAdvisor_P1 = calGerAdvisorAgreement($event); sumGerAdvisorPAgreement()"
                              (blur)="stringToDecimal($event)"
                              [(ngModel)]="applicantService.paymentInfoModel.gerAdvisor_P1" required onlyNumbers
                              data-bs-original-title="" title=""
                              [disabled]="notEditable || applicantService.disableAdvisorSelect">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Two:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="commissions_P2" type="text" name="commissions_P2"
                              (keyup)="applicantService.paymentInfoModel.gerAdvisor_P2 = calGerAdvisorAgreement($event); sumGerAdvisorPAgreement()"
                              (blur)="stringToDecimal($event)"
                              [(ngModel)]="applicantService.paymentInfoModel.gerAdvisor_P2" required onlyNumbers
                              data-bs-original-title="" title=""
                              [disabled]="notEditable || applicantService.disableAdvisorSelect">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Three:
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="commissions_P3" type="text" name="commissions_P3"
                              (keyup)="applicantService.paymentInfoModel.gerAdvisor_P3 = calGerAdvisorAgreement($event); sumGerAdvisorPAgreement()"
                              (blur)="stringToDecimal($event)"
                              [(ngModel)]="applicantService.paymentInfoModel.gerAdvisor_P3" required onlyNumbers
                              data-bs-original-title="" title=""
                              [disabled]="notEditable || applicantService.disableAdvisorSelect">
                          </div>
                          <div class="col-md-12 px-0 my-2">
                            <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="">
                              Payment Four:
                              <span class="f-10 text-dark">
                                (Optional)
                              </span>
                            </label>
                            <input class="form-control col-xl-12 input-height b-r-5 mt-1" placeholder="0.00"
                              id="gerAdvisor_P4" type="text"
                              (keyup)="applicantService.paymentInfoModel.gerAdvisor_P4 = calGerAdvisorAgreement($event); sumGerAdvisorPAgreement()"
                              name="gerAdvisor_P4" [(ngModel)]="applicantService.paymentInfoModel.gerAdvisor_P4"
                              (blur)="stringToDecimal($event)" required onlyNumbers data-bs-original-title="" title=""
                              [disabled]="notEditable || applicantService.disableAdvisorSelect">
                          </div>
                        </div>
                        <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments">
                          <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments"
                            hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                        </div>
                      </div>
                    </div>

                    <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit" [disabled]="isSaving">
                      <i class="fa mr-1"
                        [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                      {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                    </button>
                  </div>

                  <div style="height: 100%;" class="col-xl-12 mt-2" *ngIf="!headerTitleService.PermissionVM.payments">
                    <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.payments"
                      hasBorder="true" hasFullHeight="true"></app-user-access-permission>
                  </div>
                </div>
              </form>
              <!-- Family information -->
            </div>
          </div>
          <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewApplicantInformation">
            <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewApplicantInformation"
              hasBorder="true" hasFullHeight="true"></app-user-access-permission>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->