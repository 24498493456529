import { Component, Input, OnInit } from '@angular/core';
import { StageStatus } from '../edit-timeline.model';

@Component({
  selector: 'app-timeline-status-icon',
  templateUrl: './timeline-status-icon.component.html',
  styleUrls: ['./timeline-status-icon.component.scss']
})
export class TimelineStatusIconComponent implements OnInit {

@Input() timelineStatusId: number;

stageStatusEnum = StageStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
