import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderTitleService } from '@services/header-title.service';
import { ViewAllDocumentsComponent } from '../view-all-documents.component';
@Component({
  selector: 'app-documents-list-tab',
  templateUrl: './documents-list-tab.component.html',
  styleUrls: ['./documents-list-tab.component.scss']
})
export class DocumentsListTabComponent implements OnInit, AfterViewInit {
  @Input() applicantId: number = 0;

  @ViewChild(ViewAllDocumentsComponent, { static: false }) childC: ViewAllDocumentsComponent;

  constructor(public headerTitleService:HeaderTitleService) { }
  
  ngAfterViewInit(): void {
    this.childC.ngOnInit();
  }

  ngOnInit(): void {
    
  }

}
