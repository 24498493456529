import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppComponent } from './app.component';
import { AddApplicantComponent } from './components/applicants/add-applicant/add-applicant.component';
import { PersonalInformationComponent } from './components/applicants/personal-information/personal-information.component';
import { FamilyInformationComponent } from './components/applicants/family-information/family-information.component';
import { BackgroundInformationComponent } from './components/applicants/background-information/background-information.component';
import { ProgramInformationComponent } from './components/applicants/program-information/program-information.component';
import { PaymentInformationComponent } from './components/applicants/payment-information/payment-information.component';
import { NotesComponent } from './components/applicants/notes/notes.component';
import { ReportsComponent } from './components/adminConsole/reports/reports.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MasterComponent } from './components/adminConsole/master/master.component';
import { UsersManagementComponent } from './components/adminConsole/users-management/users-management.component';
import { UserListComponent } from './components/adminConsole/users-management/user-list/user-list.component';
import { UserPermissionsComponent } from './components/adminConsole/users-management/user-permissions/user-permissions.component';
import { UserRolesComponent } from './components/adminConsole/users-management/user-roles/user-roles.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AllNotificationsComponent } from './components/all-notifications/all-notifications.component';
import { EditApplicantComponent } from './components/applicants/edit-applicant/edit-applicant.component';
import { EditApplicantHeaderComponent } from './components/applicants/edit-applicant-header/edit-applicant-header.component';
import { EditApplicantInformationComponent } from './components/applicants/edit-applicant-information/edit-applicant-information.component';
import { EditDocumentsComponent } from './components/applicants/edit-documents/edit-documents.component';
import { EditPaymentsComponent } from './components/applicants/edit-payments/edit-payments.component';
import { EditActivityLogComponent } from './components/applicants/edit-activity-log/edit-activity-log.component';
import { EditTimelineComponent } from './components/applicants/edit-timeline/edit-timeline.component';
import { ApplicantsListComponent } from './components/applicants/applicants-list/applicants-list.component';
import { AuditLogsComponent } from './components/adminConsole/audit-logs/audit-logs.component';
import { PopulateEnumPipe } from './shared/pipes/populate-enum.pipe';
import { TimelineStatusIconComponent } from './components/applicants/edit-timeline/timeline-status-icon/timeline-status-icon.component';
import { ViewAllActivityLogComponent } from './components/dashboard/view-all-activity-log/view-all-activity-log.component';
import { ViewAllDocumentsComponent } from './components/applicants/view-all-documents/view-all-documents.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AgencyReportComponent } from './components/adminConsole/reports/agency-report/agency-report.component';
import { SponsorReportComponent } from './components/adminConsole/reports/sponsor-report/sponsor-report.component';
import { AttorneyReportComponent } from './components/adminConsole/reports/attorney-report/attorney-report.component';
import { AdvisorReportComponent } from './components/adminConsole/reports/advisor-report/advisor-report.component';
import { StagesReportComponent } from './components/adminConsole/reports/stages-report/stages-report.component';
import { ActivityLogReportComponent } from './components/adminConsole/reports/activity-log-report/activity-log-report.component';
import { TotalPaymentReportComponent } from './components/adminConsole/reports/total-payment-report/total-payment-report.component';
import { PaymentPerSponsorReportComponent } from './components/adminConsole/reports/payment-per-sponsor-report/payment-per-sponsor-report.component';
import { PaymentPerAgencyReportComponent } from './components/adminConsole/reports/payment-per-agency-report/payment-per-agency-report.component';
import { ActivityLogTabComponent } from './components/dashboard/view-all-activity-log/activity-log-tab/activity-log-tab.component';
import { AddUpdateActivityLogComponent } from './components/applicants/edit-activity-log/add-update-activity-log/add-update-activity-log.component';
import { DocumentsListTabComponent } from './components/applicants/view-all-documents/documents-list-tab/documents-list-tab.component';
import { UserAccessPermissionComponent } from './components/user-access-permission/user-access-permission.component';
import { AccountsReceivableComponent } from './components/adminConsole/reports/accounts-receivable/accounts-receivable.component';
import { MultiSelectDropdownComponent } from './components/adminConsole/users-management/multi-select-dropdown/multi-select-dropdown.component';
import { ImmigrationInformationComponent } from './components/applicants/immigration-information/immigration-information.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    AddApplicantComponent,
    PersonalInformationComponent,
    FamilyInformationComponent,
    BackgroundInformationComponent,
    ProgramInformationComponent,
    PaymentInformationComponent,
    NotesComponent,
    ReportsComponent,
    DashboardComponent,
    MasterComponent,
    UsersManagementComponent,
    UserListComponent,
    UserPermissionsComponent,
    UserRolesComponent,
    LoginComponent,
    AllNotificationsComponent,
    EditApplicantComponent,
    EditApplicantHeaderComponent,
    EditApplicantInformationComponent,
    EditDocumentsComponent,
    EditPaymentsComponent,
    EditActivityLogComponent,
    EditTimelineComponent,
    ApplicantsListComponent,
    AuditLogsComponent,
    PopulateEnumPipe,
    TimelineStatusIconComponent,
    ViewAllActivityLogComponent,
    ViewAllDocumentsComponent,
    AgencyReportComponent,
    SponsorReportComponent,
    AttorneyReportComponent,
    AdvisorReportComponent,
    StagesReportComponent,
    ActivityLogReportComponent,
    TotalPaymentReportComponent,
    PaymentPerSponsorReportComponent,
    PaymentPerAgencyReportComponent,
    ActivityLogTabComponent,
    AddUpdateActivityLogComponent,
    DocumentsListTabComponent,
    UserAccessPermissionComponent,
    AccountsReceivableComponent,
    MultiSelectDropdownComponent,
    ImmigrationInformationComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxDatatableModule,
    NgxChartsModule,
    NgApexchartsModule,
    DropzoneModule,
    NgxDropzoneModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
