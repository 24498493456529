<div class="image-width" *ngIf="icon=='grid'">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25.602 25.602">
        <g id="Iconly_Light_Category" data-name="Iconly/Light/Category" transform="translate(0.75 0.75)">
          <g id="Category" transform="translate(0)">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,4.686C0,1.171.038,0,4.686,0S9.373,1.171,9.373,4.686s.015,4.686-4.686,4.686S0,8.2,0,4.686Z" transform="translate(0)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
            <path id="Stroke_3" data-name="Stroke 3" d="M0,4.686C0,1.171.038,0,4.686,0S9.373,1.171,9.373,4.686s.015,4.686-4.686,4.686S0,8.2,0,4.686Z" transform="translate(14.729)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
            <path id="Stroke_5" data-name="Stroke 5" d="M0,4.686C0,1.171.038,0,4.686,0S9.373,1.171,9.373,4.686s.015,4.686-4.686,4.686S0,8.2,0,4.686Z" transform="translate(0 14.729)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
            <path id="Stroke_7" data-name="Stroke 7" d="M0,4.686C0,1.171.038,0,4.686,0S9.373,1.171,9.373,4.686s.015,4.686-4.686,4.686S0,8.2,0,4.686Z" transform="translate(14.729 14.729)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
          </g>
        </g>
      </svg>
</div>

<div class="image-width" *ngIf="icon=='users'">
    <svg xmlns="http://www.w3.org/2000/svg" id="users" width="24" height="24" viewBox="0 0 36.631 21.978">
        <g id="Group_304" data-name="Group 304" transform="translate(60 9643)">
          <path id="Path_71" data-name="Path 71" d="M20.613,20.655A5.756,5.756,0,1,1,26.37,14.9,5.756,5.756,0,0,1,20.613,20.655Zm0-10.466a4.71,4.71,0,1,0,4.71,4.71,4.71,4.71,0,0,0-4.71-4.71Z" transform="translate(-62.298 -9652.143)" />
          <path id="Path_72" data-name="Path 72" d="M29.648,32.276H21.275V31.23h7.849V29.137A5.239,5.239,0,0,0,23.891,23.9H16.565a5.239,5.239,0,0,0-5.233,5.233V31.23h7.849v1.047H10.809a.523.523,0,0,1-.523-.523V29.137a6.286,6.286,0,0,1,6.28-6.28h7.326a6.286,6.286,0,0,1,6.28,6.28v2.616A.523.523,0,0,1,29.648,32.276Z" transform="translate(-61.913 -9653.298)" fill="#a1a1a1"/>
          <path id="Path_73" data-name="Path 73" d="M32.747,24.256a4.3,4.3,0,1,1,4.3-4.3A4.3,4.3,0,0,1,32.747,24.256Zm0-7.558A3.256,3.256,0,1,0,36,19.954,3.256,3.256,0,0,0,32.747,16.7Z" transform="translate(-63.443 -9652.691)" />
          <path id="Path_74" data-name="Path 74" d="M39.7,32.5H31.33V31.457h7.849V30.236A3.638,3.638,0,0,0,35.545,26.6H30.254V25.556h5.292a4.686,4.686,0,0,1,4.68,4.68V31.98A.523.523,0,0,1,39.7,32.5Z" transform="translate(-63.595 -9653.525)" />
          <path id="Path_75" data-name="Path 75" d="M8.747,24.256a4.3,4.3,0,1,1,4.3-4.3,4.3,4.3,0,0,1-4.3,4.3Zm0-7.558A3.256,3.256,0,1,0,12,19.954,3.256,3.256,0,0,0,8.747,16.7Z" transform="translate(-61.421 -9652.691)" />
          <path id="Path_76" data-name="Path 76" d="M10.039,32.5H1.666a.523.523,0,0,1-.523-.523V30.236a4.686,4.686,0,0,1,4.68-4.68h5.292V26.6H5.823a3.638,3.638,0,0,0-3.634,3.634v1.221h7.849Z" transform="translate(-61.143 -9653.525)"/>
        </g>
      </svg>
</div>
<div class="image-width" *ngIf="icon=='add-user'">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 26.889 26.889">
        <g id="add-user_1_" data-name="add-user (1)" transform="translate(0)">
          <g id="Group_226" data-name="Group 226" transform="translate(0)">
            <g id="Group_225" data-name="Group 225">
              <path id="Path_77" data-name="Path 77" d="M19.3,13.492a13.665,13.665,0,0,0-1.58-.634,7.142,7.142,0,1,0-8.552,0A13.661,13.661,0,0,0,.068,23.04a3.191,3.191,0,0,0,3.119,3.848H16.123a1.05,1.05,0,1,0,0-2.1H3.187a1.067,1.067,0,0,1-.84-.4,1.1,1.1,0,0,1-.222-.92,11.6,11.6,0,0,1,11.041-9.189q.139.005.278.005t.281-.005a11.441,11.441,0,0,1,4.676,1.11,1.05,1.05,0,0,0,.9-1.9Zm-5.6-1.315-.256,0-.254,0a5.042,5.042,0,1,1,.51,0Z" transform="translate(0)" />
            </g>
          </g>
          <g id="Group_228" data-name="Group 228" transform="translate(16.91 16.91)">
            <g id="Group_227" data-name="Group 227">
              <path id="Path_78" data-name="Path 78" d="M34.084,29.1H31.2V26.207a1.05,1.05,0,0,0-2.1,0V29.1H26.207a1.05,1.05,0,0,0,0,2.1H29.1v2.888a1.05,1.05,0,0,0,2.1,0V31.2h2.888a1.05,1.05,0,0,0,0-2.1Z" transform="translate(-25.156 -25.156)"/>
            </g>
          </g>
        </g>
      </svg>
      
</div>
<div class="image-width" *ngIf="icon=='shield'">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24.419 32.56">
        <g id="shield" transform="translate(-5 0)">
          <path id="Path_64" data-name="Path 64" d="M28.75,4.133,17.558.063a1,1,0,0,0-.7,0L5.67,4.133A1.016,1.016,0,0,0,5,5.088V16.281c0,9.66,5.328,13.042,11.765,16.176a1.011,1.011,0,0,0,.89,0c6.437-3.134,11.765-6.516,11.765-16.176V5.088A1.016,1.016,0,0,0,28.75,4.133ZM17.21,30.408a23,23,0,0,1-7.081-4.9,24.158,24.158,0,0,1,14.162,0A23,23,0,0,1,17.21,30.408ZM27.385,16.281a14.036,14.036,0,0,1-1.815,7.5,26.251,26.251,0,0,0-16.719,0,14.036,14.036,0,0,1-1.815-7.5V5.8L17.21,2.1,27.385,5.8V16.281Z" />
          <path id="Path_65" data-name="Path 65" d="M12.5,16.106a6.1,6.1,0,1,0,6.1-6.1A6.112,6.112,0,0,0,12.5,16.106Zm10.175,0a4.07,4.07,0,1,1-4.07-4.07A4.075,4.075,0,0,1,22.675,16.106Z" transform="translate(-1.395 -1.86)" />
        </g>
      </svg>
</div>
<div class="image-width" *ngIf="icon=='report'">
  <svg xmlns="http://www.w3.org/2000/svg" width="25.72" height="25.723" viewBox="0 0 25.72 25.723">
    <g id="Analysis-report-document-develop-infromation" transform="translate(-2.009 -2)">
      <path id="Path_200" data-name="Path 200" d="M27.6,6.84,22.888,2.124a.443.443,0,0,0-.3-.124H11.866A1.29,1.29,0,0,0,10.58,3.286V5.88a8.992,8.992,0,0,0,0,17.964v2.594a1.29,1.29,0,0,0,1.286,1.286H26.442a1.29,1.29,0,0,0,1.286-1.286V7.145a.443.443,0,0,0-.124-.3ZM23.013,3.462l3.254,3.254H23.441a.429.429,0,0,1-.429-.429Zm-20.15,11.4A8.156,8.156,0,0,1,10.58,6.729v7.957L4.963,20.3a8.094,8.094,0,0,1-2.1-5.441Zm2.705,6.045,5.616-5.616h7.957A8.13,8.13,0,0,1,5.568,20.907Zm21.3,5.531a.429.429,0,0,1-.429.429H11.866a.429.429,0,0,1-.429-.429V23.843a9.009,9.009,0,0,0,8.574-8.982.429.429,0,0,0-.429-.429H11.437V3.286a.429.429,0,0,1,.429-.429H22.155v3.43a1.29,1.29,0,0,0,1.286,1.286h3.43Z" fill="#fff"/>
      <path id="Path_201" data-name="Path 201" d="M26.429,7A.429.429,0,0,0,26,7.429V16a.429.429,0,0,0,.429.429H35A.429.429,0,0,0,35.432,16a9.013,9.013,0,0,0-9-9Zm.429,8.574V7.87a8.153,8.153,0,0,1,7.7,7.7Z" transform="translate(-13.706 -2.856)" fill="#fff"/>
      <path id="Path_202" data-name="Path 202" d="M43.859,40a3.859,3.859,0,1,0,3.859,3.859A3.859,3.859,0,0,0,43.859,40Zm0,6.86a3,3,0,1,1,3-3A3,3,0,0,1,43.859,46.86Z" transform="translate(-21.703 -21.709)" fill="#fff"/>
      <path id="Path_203" data-name="Path 203" d="M44.882,47.562l-.983-.983-.606.606,1.286,1.286a.429.429,0,0,0,.606,0L47.758,45.9l-.606-.606Z" transform="translate(-23.585 -24.732)" fill="#fff"/>
      <path id="Path_204" data-name="Path 204" d="M50,19h3.43v.857H50Z" transform="translate(-27.416 -9.712)" fill="#fff"/>
      <path id="Path_205" data-name="Path 205" d="M50,25h3.43v.857H50Z" transform="translate(-27.416 -13.139)" fill="#fff"/>
      <path id="Path_206" data-name="Path 206" d="M50,31h3.43v.857H50Z" transform="translate(-27.416 -16.567)" fill="#fff"/>
    </g>
  </svg>
  
</div>