import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification/notification.service'
import { NotificationModel } from '../notification/notification.model'
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {


  public openNotification: boolean = false;
  totalNotificationCount: number;
  topNotifications: NotificationModel[];
  constructor(public service: NotificationService, private toaster: ToastrService, public headerTitleService: HeaderTitleService) { }
  ngOnInit() {
    this.headerTitleService.notificationRefresh.subscribe(isSent => {
      if (isSent) {
        this.getTotalCountData();
      }
    });
    this.getTotalCountData();
  }

  getTotalCountData() {
    this.service.getCount()
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.topNotifications = res.response;
          this.totalNotificationCount = res.totalRecords;
        }
      });
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    return throwError(error);
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
