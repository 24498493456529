import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '@models/response.model';
import { DataService } from '@services/data.service';

@Injectable({
  providedIn: 'root'
})

export class AuditLogsService {

  constructor(private service: DataService) { }

  getAllAuditLog(model:any): Observable<ResponseModel> {
    let url = '/gerapi/AuditLog/get/';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}
