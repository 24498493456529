import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ApplicantModel, CheckApplicationNumberExistsModel } from '../applicant.model';
import { ApplicantsService } from '../applicants.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-add-applicant',
  templateUrl: './add-applicant.component.html',
  styleUrls: ['./add-applicant.component.scss']
})

export class AddApplicantComponent implements OnInit {
  check_valid: boolean;
  applicantModel = new ApplicantModel();
  checkApplicationNumberExistsModel = new CheckApplicationNumberExistsModel();
  public validate = false;
  public isLoading = false;
  public gettPreview = false;

  constructor(
    private headerTitleService: HeaderTitleService,
    public applicantService: ApplicantsService,
    public router: Router,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private config1: NgbModalConfig,) {
      this.config1.centered = true;
    this.config1.size = "xl"
  }

  ngOnInit(): void {
    this.headerTitleService.title = 'New Applicant';
    this.applicantService.ResetAll();
  }

  check(newItem: boolean) {
    this.check_valid = newItem;
  }

  CreateApplicant() {
    this.isLoading = true;
    this.applicantModel.personalInfo = this.applicantService.personalInfoModel;
    this.applicantModel.familyInfo = this.applicantService.familyInfoModel;
    this.applicantModel.backgroundInfo = this.applicantService.backgroundInfoModel;
    this.applicantModel.programInfo = this.applicantService.programInfoModel;
    this.applicantModel.immigrationInfo = this.applicantService.immigrationInfoModel;
    this.applicantModel.paymentInfo = this.applicantService.paymentInfoModel;
    this.applicantModel.notes = this.applicantService.notesModel;
    this.applicantService.createApplicant(this.applicantModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.toaster.success(res.message);
          this.modalService.dismissAll();
          //this.applicantService.ResetAll();
          this.router.navigate(['applicants-list']);
        }
        this.isLoading = false;
      });
  }

    private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }

    this.isLoading = false;
    return throwError(error);
  }

  public showConfirm(modalId: any) {
    if(!this.canSaveApplicantPaymentInfo())
    {
      this.toaster.error('In Agreement with Applicant Total Amount And Sum of Payments Amount should be same');
      return;
    }
    if(!this.canSaveLiaisonPaymentInfo())
    {
      this.toaster.error('In Agreement with Liaison Total Amount And Sum of Payments Amount should be same');
      return;
    }
    if(!this.canSaveAgencyPaymentInfo())
    {
      this.toaster.error('In Agreement with Agency Total Amount And Sum of Payments Amount should be same');
      return;
    }
    if(!this.canSaveGERAdvisorPaymentInfo())
    {
      this.toaster.error('In Commissions to GER Advisors Total Amount And Sum of Payments Amount should be same');
      return;
    }
    if(this.applicantService.familyInfoModel.maritalStatusId == 0)
    {
      this.toaster.error('Please select marital status');
      return;
    }

    this.checkApplicationNumberExistsModel.isEdit = false;
    this.checkApplicationNumberExistsModel.id = 0;
    this.checkApplicationNumberExistsModel.gerApplicationNumber = this.applicantService.programInfoModel.gerApplicationNumber;
    this.applicantService.checkApplicationNumberExists(this.checkApplicationNumberExistsModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      if(!res.response.IsExists)
      {
        this.modalService.open(modalId);
      }
      else
      {
      this.toaster.success(res.message)
      }
    });
   
  }
  
  canSaveApplicantPaymentInfo():boolean
  {
   return (this.applicantService.paymentInfoModel.applicant_TotalAgreed == (this.applicantService.paymentInfoModel.applicant_P1 + 
      this.applicantService.paymentInfoModel.applicant_P2 + 
      this.applicantService.paymentInfoModel.applicant_P3 + 
      this.applicantService.paymentInfoModel.applicant_P4 ));
  }

  canSaveLiaisonPaymentInfo() {
    return (this.applicantService.paymentInfoModel.coordinator_TotalAgreed == (this.applicantService.paymentInfoModel.coordinator_P1 + 
      this.applicantService.paymentInfoModel.coordinator_P2 + 
      this.applicantService.paymentInfoModel.coordinator_P3 + 
      this.applicantService.paymentInfoModel.coordinator_P4 ));
  }

  canSaveAgencyPaymentInfo() {
    return (this.applicantService.paymentInfoModel.agency_TotalAgreed == (this.applicantService.paymentInfoModel.agency_P1 + 
      this.applicantService.paymentInfoModel.agency_P2 + 
      this.applicantService.paymentInfoModel.agency_P3 + 
      this.applicantService.paymentInfoModel.agency_P4 ));
  }

  canSaveGERAdvisorPaymentInfo() {
    return (this.applicantService.paymentInfoModel.agency_TotalAgreed == (this.applicantService.paymentInfoModel.agency_P1 + 
      this.applicantService.paymentInfoModel.agency_P2 + 
      this.applicantService.paymentInfoModel.agency_P3 + 
      this.applicantService.paymentInfoModel.agency_P4 ));
  }
}