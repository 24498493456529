import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ResponseModel } from '../../shared/models/response.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CalenderDataPost, CallScheduleDataPost, DashboardPost } from './dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private service: DataService) { }

  getYearsForDashboardTimePeriod(): Observable<ResponseModel> {
    let url = '/gerapi/Dashboard/getYearsForDashboardTimePeriod';
    return this.service.get(url, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
   }

  getAllDashboardData(data: DashboardPost): Observable<ResponseModel> {
    let url = '/gerapi/Dashboard/getall';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
   }

   getCallScheduleDataForDashboard(data : CallScheduleDataPost): Observable<ResponseModel> {
    let url = '/gerapi/Dashboard/getCallScheduleDataForDashboard';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getAllCalendarDataForDashboard(data: CalenderDataPost): Observable<ResponseModel> {
    let url = '/gerapi/Dashboard/getAllCalendarDataForDashboard';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
   }

 
   getAllActivity(data: any): Observable<ResponseModel> {
    let url = '/gerapi/dashboard/getAllActivities';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
 
}
