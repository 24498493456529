import { Component, Input, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { EditPaymentsRecord, EditPaymentsModel, AdditionalPayment, AdditionalPaymentModel } from '../edit-payments/edit-payments.model'
import { EditPaymentsService } from '../edit-payments/edit-payments.service'
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditLogsComponent } from '../../adminConsole/audit-logs/audit-logs.component';
import { HeaderTitleService } from '@services/header-title.service';
import { StorageService } from '@services/storage.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-edit-payments',
  templateUrl: './edit-payments.component.html',
  styleUrls: ['./edit-payments.component.scss']
})
export class EditPaymentsComponent implements OnInit {

  //for date Validation

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();;
  maxDate = moment({ year: this.year, month: this.month, day: this.day }).format('YYYY-MM-DD');

  //for Applicant
  @Input() applicantId: number = 0;
  //for Liaison
  notEditable_Applicant: boolean = true;
  notEditable_Liaison: boolean = true;
  notEditable_Agency: boolean = true;
  notEditable_GerAdvisor: boolean = true;
  notEditable_AdditionalPayment: boolean = true;
  //for Agency
  isSaving_Applicant: boolean = false;
  isSaving_Liaison: boolean = false;
  isSaving_Agency: boolean = false;
  isSaving_GerAdvisor: boolean = false;
  isSaving_AdditionalPayment: boolean = false;
  editpaymentsrecord = new EditPaymentsRecord();
  editpaymentsLiaisonmodel = new EditPaymentsModel();
  editpaymentsAgencymodel = new EditPaymentsModel();
  editpaymentsGerAdvisormodel = new EditPaymentsModel();
  editpaymentsApplicantmodel = new EditPaymentsModel();
  additionalPaymentModel = new AdditionalPaymentModel();
  datepipe: any;

  canSaveAPPLPR: boolean = false;
  constructor(
    public storageservice: StorageService,
    public route: ActivatedRoute,
    public headerTitleService: HeaderTitleService,
    public editpaymentService: EditPaymentsService,
    private toaster: ToastrService,
    private config1: NgbModalConfig,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.storageservice.store('applicantDetailsTID', 3);
    if (this.headerTitleService.PermissionVM.payments) {
      this.GetPaymentsData()
    }
  }

  private handleError(error: any) {
        this.isSaving_Applicant = false;
    this.isSaving_Liaison = false;
    this.isSaving_Agency = false;
    this.isSaving_GerAdvisor = false;
    this.isSaving_AdditionalPayment = false;
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

  //#region get Methods
  public GetPaymentsData() {
    if (this.headerTitleService.PermissionVM.viewAgreementWithApplicantPayments)
    this.getPaymentDataForApplicant();
    
     if (this.headerTitleService.PermissionVM.viewAgreementWithLiaisonPayments)
    this.getPaymentDataForLiaison();

  if (this.headerTitleService.PermissionVM.viewAgreementWithAgencyPayments)
    this.getPaymentDataForAgency();

    if (this.headerTitleService.PermissionVM.viewCommissionsToGERAdvisorsPayments)
    this.getPaymentDataForGERAdvisor();

  if (this.headerTitleService.PermissionVM.viewAdditionalPayments)
    this.getApplicantAdditionalPayments();
  }

  getApplicantAdditionalPayments() {
    this.editpaymentService.getAdditionalPaymetsByApplicantId(this.applicantId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.additionalPaymentModel = res.response;
          if (this.filter(this.additionalPaymentModel.additionalPayments).length == 0) {
            this.addAnotherPayment();
          }
        }
      });
  }

  getPaymentDataForApplicant() {
    const paymentRecord = new EditPaymentsRecord();
    paymentRecord.applicantId = this.applicantId;
    paymentRecord.paymentReceiverId = 1;
    this.editpaymentService.getEditPaymentsRecordsReceiverIdForApplicant(paymentRecord)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.editpaymentsApplicantmodel = new EditPaymentsModel();
          this.editpaymentsApplicantmodel = res.response;
          //this.editpaymentsApplicantmodel.p1_ReceivedDate= this.datepipe.transform(this.editpaymentsApplicantmodel.p1_ReceivedDate, 'MM/dd/yyyy')
        }
      });
  }

  getPaymentDataForLiaison() {
    const paymentRecord = new EditPaymentsRecord();
    paymentRecord.applicantId = this.applicantId;
    paymentRecord.paymentReceiverId = 2;
    this.editpaymentService.getEditPaymentsRecordsReceiverIdForApplicant(paymentRecord)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.editpaymentsLiaisonmodel = new EditPaymentsModel();
          this.editpaymentsLiaisonmodel = res.response;
        }
      });
  }

  getPaymentDataForAgency() {
    const paymentRecord = new EditPaymentsRecord();
    paymentRecord.applicantId = this.applicantId;
    paymentRecord.paymentReceiverId = 3;
    this.editpaymentService.getEditPaymentsRecordsReceiverIdForApplicant(paymentRecord)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.editpaymentsAgencymodel = new EditPaymentsModel();
          this.editpaymentsAgencymodel = res.response;
        }
      });
  }

  getPaymentDataForGERAdvisor() {
    const paymentRecord = new EditPaymentsRecord();
    paymentRecord.applicantId = this.applicantId;
    paymentRecord.paymentReceiverId = 4;
    this.editpaymentService.getEditPaymentsRecordsReceiverIdForApplicant(paymentRecord)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess == true) {
          this.editpaymentsGerAdvisormodel = new EditPaymentsModel();
          this.editpaymentsGerAdvisormodel = res.response;
        }
      });
  }

  //#endregion

  //#region update Methods
  //for Applicant Button
  updateApplicantPayment() {
    //Edit
    if (this.notEditable_Applicant) {
      this.notEditable_Applicant = false;
    }
    //Update
    else {
      // Check Validation
      if (!this.checkValidation(this.editpaymentsApplicantmodel)) {
        this.toaster.error('Date must be entered')
        return;
      }

      // call update API
      this.notEditable_Applicant = true;
      this.isSaving_Applicant = true;

      this.editpaymentService.updatePaymentRecordForApplicant(this.editpaymentsApplicantmodel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.isSaving_Applicant = false;
            this.getPaymentDataForApplicant();
          }
        });
    }
  }

  //for Liaison Button
  updateLiaisonPayment() {
    //Edit
    if (this.notEditable_Liaison) {
      this.notEditable_Liaison = false;
    }
    //Update
    else {
      // Check Validation
      if (!this.checkValidation(this.editpaymentsLiaisonmodel)) {
        this.toaster.error('Date must be entered')
        return;
      }

      // call update API
      this.notEditable_Liaison = true;
      this.isSaving_Liaison = true;
      this.editpaymentService.updatePaymentRecordForApplicant(this.editpaymentsLiaisonmodel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.isSaving_Liaison = false;
            this.getPaymentDataForLiaison();
          }
        });
    }
  }

  //for Agency Button
  updateAgencyPayment() {
    //Edit
    if (this.notEditable_Agency) {
      this.notEditable_Agency = false;
    }
    //Update
    else {
      // Check Validation
      if (!this.checkValidation(this.editpaymentsAgencymodel)) {
        this.toaster.error('Date must be entered')
        return;
      }

      // call update API
      this.notEditable_Agency = true;
      this.isSaving_Agency = true;
      this.editpaymentService.updatePaymentRecordForApplicant(this.editpaymentsAgencymodel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.isSaving_Agency = false;
            this.getPaymentDataForAgency();
          }
        });
    }
  }

  //for GerAdvisor Button
  updateGerAdvisorPayment() {
    //Edit
    if (this.notEditable_GerAdvisor) {
      this.notEditable_GerAdvisor = false;
    }
    //Update
    else {
      // Check Validation
      if (!this.checkValidation(this.editpaymentsGerAdvisormodel)) {
        this.toaster.error('Date must be entered')
        return;
      }

      // call update API
      this.notEditable_GerAdvisor = true;
      this.isSaving_GerAdvisor = true;
      this.editpaymentService.updatePaymentRecordForApplicant(this.editpaymentsGerAdvisormodel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.isSaving_GerAdvisor = false;
            this.getPaymentDataForGERAdvisor();
          }
        });
    }
  }

  //for Additional Payment Button
  updateAdditionalPayment() {
    //Edit
    if (this.notEditable_AdditionalPayment) {
      this.notEditable_AdditionalPayment = false;
    }
    //Update
    else {
      if(this.additionalPayRequiredValue() == true){
      // call update API
      this.notEditable_AdditionalPayment = true;
      this.isSaving_AdditionalPayment = true;
      this.editpaymentService.addUpdateAdditionalPaymetsForApplicant(this.additionalPaymentModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          if (res.isSuccess == true) {
            this.toaster.success(res.message);
            this.isSaving_AdditionalPayment = false;
            this.getApplicantAdditionalPayments();
          }
        });
      }else {
        this.toaster.error('Fill require fields')
      }
    }
  }

  additionalPayRequiredValue():boolean{
    if(this.additionalPaymentModel.additionalPayments.every(check => check.payment_Name && check.payment_Amount && check.payment_ReceivedDate)){
      return true
    } else {
      return false
    }
  }

  addAnotherPayment() {
    this.additionalPaymentModel.additionalPayments.push(
      {
        id: 0,
        applicantId: this.applicantId,
        payment_Name: '',
        payment_Amount: 0.00,
        payment_ReceivedDate: null,
        isDeleted: false,
      });
  }

  removePayment(index: number) {
    // this.additionalPaymentModel.additionalPayments.splice(index, 1);
    // this.filter(this.additionalPaymentModel.additionalPayments)[index].isDeleted = true;

    if(this.additionalPaymentModel.additionalPayments[index].id == 0)
    {
      this.additionalPaymentModel.additionalPayments.splice(index, 1);
    }
    else
    {
    this.additionalPaymentModel.additionalPayments[index].isDeleted = true;
    }
    // this.additionalPaymentModel.additionalPayments = this.additionalPaymentModel.additionalPayments.filter(item => item.isDeleted == false);
    if (this.filter(this.additionalPaymentModel.additionalPayments).length == 0) {
      this.addAnotherPayment();
    }
  }

  public filter(itemList: AdditionalPayment[]): AdditionalPayment[] {
    return itemList?.filter(a => !a.isDeleted);
  }

  //#endregion

  //#region calculations
  // For Applicant Payment //
  calApplPOutstandingDue(event): number {
    this.sumApplPOutstandingDue();
    this.canSaveAPPLPR = true;

    if (this.editpaymentsApplicantmodel.outstandingAmount < 0) {
      event.target.focus();
      event.target.value = '';

      this.toaster.error('Please enter valid amount');
      this.canSaveAPPLPR = false;
      return 0;
    }

    return this.stringToNumber(event.target.value);
  }

  sumApplPOutstandingDue() {
    let installmentSum = this.stringToNumber(this.editpaymentsApplicantmodel.p1_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsApplicantmodel.p2_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsApplicantmodel.p3_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsApplicantmodel.p4_ReceivedAmount)

    this.editpaymentsApplicantmodel.outstandingAmount = this.editpaymentsApplicantmodel.paymentInfo.totalAgreed - installmentSum;

  }

  // For liaison Payment //
  cal_liaison_OutstandingDue(event): number {
    this.sum_liaison_OutstandingDue();
    this.canSaveAPPLPR = true;

    if (this.editpaymentsLiaisonmodel.outstandingAmount < 0) {
      event.target.focus();
      event.target.value = '';

      this.toaster.error('Please enter valid amount');
      this.canSaveAPPLPR = false;
      return 0;
    }

    return this.stringToNumber(event.target.value);
  }

  sum_liaison_OutstandingDue() {
    let installmentSum = this.stringToNumber(this.editpaymentsLiaisonmodel.p1_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsLiaisonmodel.p2_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsLiaisonmodel.p3_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsLiaisonmodel.p4_ReceivedAmount)

    this.editpaymentsLiaisonmodel.outstandingAmount = this.editpaymentsLiaisonmodel.paymentInfo.totalAgreed - installmentSum;

  }

  // For Agency Payment //
  cal_agency_OutstandingDue(event): number {
    this.sum_agency_OutstandingDue();
    this.canSaveAPPLPR = true;

    if (this.editpaymentsAgencymodel.outstandingAmount < 0) {
      event.target.focus();
      event.target.value = '';

      this.toaster.error('Please enter valid amount');
      this.canSaveAPPLPR = false;
      return 0;
    }

    return this.stringToNumber(event.target.value);
  }

  sum_agency_OutstandingDue() {
    let installmentSum = this.stringToNumber(this.editpaymentsAgencymodel.p1_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsAgencymodel.p2_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsAgencymodel.p3_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsAgencymodel.p4_ReceivedAmount)

    this.editpaymentsAgencymodel.outstandingAmount = this.editpaymentsAgencymodel.paymentInfo.totalAgreed - installmentSum;

  }
  //#endregion


  // For GerAdvisor Payment //
  cal_gerAdvisor_OutstandingDue(event): number {
    this.sum_gerAdvisor_OutstandingDue();
    this.canSaveAPPLPR = true;

    if (this.editpaymentsGerAdvisormodel.outstandingAmount < 0) {
      event.target.focus();
      event.target.value = '';

      this.toaster.error('Please enter valid amount');
      this.canSaveAPPLPR = false;
      return 0;
    }

    return this.stringToNumber(event.target.value);
  }

  sum_gerAdvisor_OutstandingDue() {
    let installmentSum = this.stringToNumber(this.editpaymentsGerAdvisormodel.p1_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsGerAdvisormodel.p2_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsGerAdvisormodel.p3_ReceivedAmount) +
      this.stringToNumber(this.editpaymentsGerAdvisormodel.p4_ReceivedAmount)

    this.editpaymentsGerAdvisormodel.outstandingAmount = this.editpaymentsGerAdvisormodel.paymentInfo.totalAgreed - installmentSum;

  }
  //#endregion

  //#region  Convertors
  stringToNumber(value) {
    if (value === '') {
      return parseFloat("0");
    }
    else {
      return parseFloat(value);
    }
  }

  stringToDecimal(event) {
    if (event.target.value == "") {
      event.target.value = "0.00";
    }
    event.target.value = parseFloat(event.target.value).toFixed(2);
  }

  //#endregion
  checkValidation(model: EditPaymentsModel) {

    if (model.p1_ReceivedAmount != 0) {
      if ((typeof (model.p1_ReceivedDate) === 'undefined' || model.p1_ReceivedDate == null || model.p1_ReceivedDate == '')) {
        return false;
      }
    }

    if (model.p2_ReceivedAmount != 0)
      if ((typeof (model.p2_ReceivedDate) === 'undefined' || model.p2_ReceivedDate == null || model.p2_ReceivedDate == '')) {
        return false;
      }

    if (model.p3_ReceivedAmount != 0)
      if ((typeof (model.p3_ReceivedDate) === 'undefined' || model.p3_ReceivedDate == null || model.p3_ReceivedDate == '')) {
        return false;
      }

    if (model.p4_ReceivedAmount != 0)
      if ((typeof (model.p4_ReceivedDate) === 'undefined' || model.p4_ReceivedDate == null || model.p4_ReceivedDate == '')) {
        return false;
      }
    return true;
  }

  setEnabled(amount: number, paymentType: number): boolean {
    if (paymentType === 1) {
      if (!this.notEditable_Applicant) {
        return (amount <= 0) ? true : false;
      }
    }
    else if (paymentType === 2) {
      if (!this.notEditable_Liaison) {
        return (amount <= 0) ? true : false;
      }
    }
    else if (paymentType === 3) {
      if (!this.notEditable_Agency) {
        return (amount <= 0) ? true : false;
      }
    }
    else if (paymentType === 4) {
      if (!this.notEditable_GerAdvisor) {
        return (amount <= 0) ? true : false;
      }
    }
    else if (paymentType === 5) {
      if (!this.notEditable_AdditionalPayment) {
        return (amount <= 0) ? true : false;
      }
    }

    return true;
  }


  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.pageId = 3;
  }
  // Open Audit Trail Model //

  checkValidationforeditPayment(model: EditPaymentsModel) {}
}
