import { PaymentPerAgencyReportComponent } from './../../components/adminConsole/reports/payment-per-agency-report/payment-per-agency-report.component';
import { PaymentPerSponsorReportComponent } from './../../components/adminConsole/reports/payment-per-sponsor-report/payment-per-sponsor-report.component';
import { TotalPaymentReportComponent } from './../../components/adminConsole/reports/total-payment-report/total-payment-report.component';
import { AdvisorReportComponent } from './../../components/adminConsole/reports/advisor-report/advisor-report.component';
import { AttorneyReportComponent } from './../../components/adminConsole/reports/attorney-report/attorney-report.component';
import { SponsorReportComponent } from './../../components/adminConsole/reports/sponsor-report/sponsor-report.component';
import { ActivityLogReportComponent } from './../../components/adminConsole/reports/activity-log-report/activity-log-report.component';
import { StagesReportComponent } from './../../components/adminConsole/reports/stages-report/stages-report.component';
import { AgencyReportComponent } from './../../components/adminConsole/reports/agency-report/agency-report.component';
import { EditApplicantInformationComponent } from './../../components/applicants/edit-applicant-information/edit-applicant-information.component';
import { EditApplicantComponent } from './../../components/applicants/edit-applicant/edit-applicant.component';
import { Routes } from '@angular/router';
import { AddApplicantComponent } from 'src/app/components/applicants/add-applicant/add-applicant.component';
import { ApplicantsListComponent } from 'src/app/components/applicants/applicants-list/applicants-list.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { AuditLogsComponent } from 'src/app/components/adminConsole/audit-logs/audit-logs.component';
import { ReportsComponent } from 'src/app/components/adminConsole/reports/reports.component';
import { MasterComponent } from 'src/app/components/adminConsole/master/master.component';
import { UsersManagementComponent } from 'src/app/components/adminConsole/users-management/users-management.component';
import { AllNotificationsComponent } from 'src/app/components/all-notifications/all-notifications.component';
import { ViewAllActivityLogComponent } from 'src/app/components/dashboard/view-all-activity-log/view-all-activity-log.component';
import { ActivityLogTabComponent } from 'src/app/components/dashboard/view-all-activity-log/activity-log-tab/activity-log-tab.component';
import { EditActivityLogComponent } from 'src/app/components/applicants/edit-activity-log/edit-activity-log.component';
import { UserAccessPermissionComponent } from 'src/app/components/user-access-permission/user-access-permission.component';
import { AccountsReceivableComponent } from 'src/app/components/adminConsole/reports/accounts-receivable/accounts-receivable.component';

export const content: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard', component: DashboardComponent
  },
  {
    path: 'dashboard/edit-applicant', component: EditApplicantComponent
  },
  {
    path: 'dashboard/activity-log-tab', component: ActivityLogTabComponent
  },
  {
    path: 'applicants-list', component: ApplicantsListComponent,
  },
  {
    path: 'applicants-list/activity-log-tab', component: ActivityLogTabComponent
  },
  {
    path: 'add-applicant', component: AddApplicantComponent
  },
  {
    path: 'master', component: MasterComponent
  },
  {
    path: 'audit-logs', component: AuditLogsComponent
  },
  {
    path: 'reports', component: ReportsComponent
  },
  {
    path: 'master/users-management', component: UsersManagementComponent
  },
  {
    path: 'dashboard/all-notifications', component: AllNotificationsComponent
  },
  {
    path: 'applicants-list/edit-applicant', component: EditApplicantComponent
  },
  {
    path: 'edit-applicant', component: EditApplicantComponent
  },
  {
    path: 'edit-applicant-information', component: EditApplicantInformationComponent
  },
  {
    path: 'view-all-activity-log', component: ViewAllActivityLogComponent
  },
  {
    path: 'all-notifications', component: AllNotificationsComponent
  },
  {
    path: 'reports/accounts-receivable-report', component: AccountsReceivableComponent
  },
  {
    path: 'reports/agency-report', component: AgencyReportComponent
  },
  {
    path: 'reports/stages-report', component: StagesReportComponent
  },
  {
    path: 'reports/activity-log-report', component: ActivityLogReportComponent
  },
  {
    path: 'reports/sponsor-report', component: SponsorReportComponent
  },
  {
    path: 'reports/attorney-report', component: AttorneyReportComponent
  },
  {
    path: 'reports/advisor-report', component: AdvisorReportComponent
  },
  {
    path: 'reports/total-payment-report', component: TotalPaymentReportComponent
  },
  {
    path: 'reports/payment-per-sponsor-report', component: PaymentPerSponsorReportComponent
  },
  {
    path: 'reports/payment-per-agency-report', component: PaymentPerAgencyReportComponent
  },
  {
    path: 'activity-log-tab', component: ActivityLogTabComponent
  },
  {
    path: 'applicants/edit-activity-log', component: EditActivityLogComponent
  },
  {
    path: 'user-permission', component: UserAccessPermissionComponent
  },
];
