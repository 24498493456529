<!-- content starts-->
<div class="content-wrapper theme-font-size" style="position: relative;">
  
  <div class="row1">
    <div class=" h-25">
      <div class="default-according" id="accordion">
        <div class="card shadow mb-3" [ngClass]="{'border-radius-top': showEditBtn}">

          <div class="tab-content card-body border-0 p-t-30 p-b-35" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
            <div class="tab-pane fade show active" id="app-info" role="tabpanel" aria-labelledby="pills-home-tab">
              <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                <div class="row col-xl-12 px-0">
                  <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 p-l-20 p-r-0">
                    Family Information
                    <hr class="mt-1">
                  </span>
                </div>
                <div class=" row p-l-5">
                  <div class="col-xl-12 px-0">
                    <div class="col-xl-12 mt-3">
                      
                      <div class="row g-3">
                        <div class="col-md-11 mt-0">
                          <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom9">
                            Marital Status:<span class="text-danger">*</span>
                          </label>
                          <div class="col p-l-0" style="position: relative;z-index: 5;" >
                            <div class="m-t-10 m-checkbox-inline custom-radio-ml media-body align-self-center f-12 roboto-font" [ngClass]="{'disabled-content': notEditable == true}">                            
                              <div class="form-check form-check-inline  radio radio-primary m-0">
                                <input class="form-check-input" id="Single" type="radio" name="Single"
                                  [(ngModel)]="applicantService.familyInfoModel.maritalStatusId" [disabled]="notEditable"
                                  [value]="applicantService.maritalStatus.Single" data-bs-original-title="" title="">
                                <label class="form-check-label text-muted mb-0 " for="Single">
                                  Single
                                </label>
                              </div>
                              <div class="form-check form-check-inline radio radio-primary m-0">
                                <input class="form-check-input" id="Married" type="radio" name="Married"
                                  [(ngModel)]="applicantService.familyInfoModel.maritalStatusId" [disabled]="notEditable"
                                  [value]="applicantService.maritalStatus.Married" data-bs-original-title="" title="">
                                <label class="form-check-label text-muted mb-0" for="Married">
                                  Married
                                </label>
                              </div>
                              <div class="form-check form-check-inline radio radio-primary m-0">
                                <input class="form-check-input" id="Divorced" type="radio" name="Divorced"
                                  [(ngModel)]="applicantService.familyInfoModel.maritalStatusId" [disabled]="notEditable"
                                  [value]="applicantService.maritalStatus.Divorced" data-bs-original-title="" title="">
                                <label class="form-check-label text-muted mb-0" for="Divorced">
                                  Divorced
                                </label>
                              </div>
                              <div class="form-check form-check-inline radio radio-primary m-0">
                                <input class="form-check-input" id="Widowed" type="radio" name="Widowed"
                                  [(ngModel)]="applicantService.familyInfoModel.maritalStatusId" [disabled]="notEditable"
                                  [value]="applicantService.maritalStatus.Widowed" data-bs-original-title="" title="">
                                <label class="form-check-label text-muted mb-0" for="Widowed">
                                  Widowed
                                </label>
                              </div>
                              <div class="form-check form-check-inline radio radio-primary m-0">
                                <input class="form-check-input" id="DomesticPartnership" type="radio" name="DomesticPartnership"
                                  [(ngModel)]="applicantService.familyInfoModel.maritalStatusId" [disabled]="notEditable"
                                  [value]="applicantService.maritalStatus.DomesticPartnership" data-bs-original-title=""
                                  title="">
                                <label class="form-check-label text-muted mb-0" for="DomesticPartnership">
                                  Free Union
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row col-xl-12 px-0 mx-0" 
                      *ngIf="applicantService.familyInfoModel.maritalStatusId==applicantService.maritalStatus.Married">
                        <div class="row g-3 col-md-11 px-0 pt-3 my-1">
                          <div class="col-md-3">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Spouse's First Name:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control firstName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                            (blur)="enablePartnerBirthDate($event)"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.firstName"
                              name="spouseInfo.firstName" data-bs-original-title="" title="" required="" onlyAlphabets
                              (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Spouse's Middle Name:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.middleName"
                              name="spouseInfo.middleName" data-bs-original-title="" title=""
                              (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Spouse's Last Name:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control lastName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                            (blur)="enablePartnerBirthDate($event)"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.lastName"
                              name="spouseInfo.lastName" data-bs-original-title="" title="" required=""
                              (keypress)=" OnlyletterAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font mt-1" for="">
                              Spouse's Date of Birth:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control dob py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="date" [max]="maxDate"
                              name="applicantService.familyInfoModel.spouseInfo.dob"
                              disabled="disabled" 
                              (ngModelChange)="applicantService.familyInfoModel.spouseInfo.dob=$event" 
                              [ngModel]="applicantService.familyInfoModel.spouseInfo.dob | date:'yyyy-MM-dd'" 
                              (change)="applicantService.familyInfoModel.spouseInfo.age = applicantService.calculateAge($event.target.value)" 
                              [disabled]="notEditable" required="">
                          </div>
                        </div>
                        <div class="col-md-1 col-11 pl-xl-2 pl-md-2 pr-xl-0 pr-md-0 px-0 pt-3 my-1">
                          <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font mt-1"
                            for="validationCustom5">
                            Spouse's Age:<span class="text-danger">*</span>
                          </label>
                          <input class="form-control py-2 f-12 col-xl-12 b-r-5 mt-1" 
                          [value]="applicantService.familyInfoModel.spouseInfo.age" 
                          id="validationCustom5"  
                          autocomplete="off" type="search"
                            data-bs-original-title="" title="" min="0" max="100" disabled>
                        </div>
                      </div>
                      <!-- for Free Union -->
                      <div class="row col-xl-12 px-0 mx-0"
                      *ngIf="applicantService.familyInfoModel.maritalStatusId==applicantService.maritalStatus.DomesticPartnership">
                        <div class="row g-3 col-md-11 px-0 pt-3 my-1">
                          <div class="col-md-3">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Partner First Name:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control firstName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.firstName"
                              name="spouseInfo.firstName" data-bs-original-title="" title="" required="" onlyAlphabets
                              (blur)="enablePartnerBirthDate($event)"
                              (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Partner Middle Name:
                            </label>
                            <input class="form-control py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.middleName"
                              name="spouseInfo.middleName" data-bs-original-title="" title=""
                              (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label  text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Partner Last Name:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control lastName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                              autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.spouseInfo.lastName"
                              name="spouseInfo.lastName" data-bs-original-title="" title="" required=""
                              (blur)="enablePartnerBirthDate($event)"
                              (keypress)=" OnlyletterAllowed($event)" [disabled]="notEditable">
                          </div>
                          <div class="col-md-3 ">
                            <label class="form-label  text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                              Partner Date of Birth:<span class="text-danger">*</span>
                            </label>
                            <input class="form-control dob py-2 f-12 col-xl-9 b-r-5 mt-1" 
                            id="" 
                            type="date"  
                            required=""
                            disabled="disabled" 
                            [max]="maxDate"
                              [ngModel]="applicantService.familyInfoModel.spouseInfo.dob | date:'yyyy-MM-dd'" 
                              (ngModelChange)="applicantService.familyInfoModel.spouseInfo.dob=$event" 
                              name="applicantService.familyInfoModel.spouseInfo.dob"
                              data-bs-original-title="" title="" 
                              [disabled]="notEditable"
                              (change)="applicantService.familyInfoModel.spouseInfo.age=applicantService.calculateAge($event.target.value)" 
                               
                              >
                          </div>
                        </div>
                        <div class="col-md-1 col-11 pl-xl-2 pl-md-2 pr-xl-0 pr-md-0 px-0 pt-3 my-1">
                          <label class="form-label text-nowrap text-nowrap subhead-blue m-b-0 f-14 roboto-font"
                            for="validationCustom5">
                            Partner Age:<span class="text-danger">*</span>
                          </label>
                          <input class="form-control py-2 f-12 col-xl-12 b-r-5 mt-1" 
                          [value]="applicantService.familyInfoModel.spouseInfo.age" id="validationCustom5"  
                          required="" autocomplete="off" type="search"
                            data-bs-original-title="" title="" min="0" max="100" disabled>
                        </div>
                      </div>
                      <!-- for Free Union -->
  
                      <div class="row g-4 py-3">
                        <div class="col-md-3">
                          <label class="form-label subhead-blue m-b-0 f-14 roboto-font  text-nowrap" for="">
                            Number of Children:
                            <span class="text-dark f-10">
                              (Under the age of 21)
                            </span>
                          </label>
  
                          <input class="form-control py-2 col-xl-8 col-11 f-12 b-r-5 mt-1" id="" name="noOfChildren"
                            [(ngModel)]="applicantService.familyInfoModel.numberOfChildren" (ngModelChange)="updateChildren()" type="text"
                            data-bs-original-title="" title="" onlyNumbers maxlength="1" [disabled]="notEditable">
  
                        </div>
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-3">
                        </div>
                      </div>
                      <div *ngIf="applicantService.familyInfoModel.childrens.length > 0">
                        <div class="row col-xl-12 px-0 mx-0"  
                          *ngFor='let item of applicantService.familyInfoModel.childrens ;let i = index'>
                            <div class="row col-md-11 px-0 g-3 my-1">
                              <div class="col-md-3">
                                <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                                  Child's First Name:<span class="text-danger">*</span>
                                </label>
  
                                <input class="form-control firstName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text" required=""
                                  autocomplete="disabled"
                                  [(ngModel)]="applicantService.familyInfoModel.childrens[i].firstName" onlyAlphabets
                                  name="{{'firstName_'+i}}" data-bs-original-title="" title="" (blur)="enableChildBirthDate($event)"
                                  (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3 ">
                                <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                                  Child's Middle Name:
                                </label>
  
                                <input class="form-control middleName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text"
                                  autocomplete="disabled"
                                  [(ngModel)]="applicantService.familyInfoModel.childrens[i].middleName"
                                  name="{{'middleName_'+i}}" data-bs-original-title="" title=""
                                  (keypress)="onlyNumbersLettersAllowed($event)" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3 ">
                                <label class="form-label text-nowrap subhead-blue m-b-0 f-14 roboto-font" for="">
                                  Child's Last Name:<span class="text-danger">*</span>
                                </label>
  
                                <input class="form-control lastName py-2 f-12 col-xl-9 b-r-5 mt-1" id="" type="text" required=""
                                  autocomplete="disabled" [(ngModel)]="applicantService.familyInfoModel.childrens[i].lastName"
                                  name="{{'lastName_'+i}}" data-bs-original-title="" title="" (blur)="enableChildBirthDate($event)"
                                  (keypress)=" OnlyletterAllowed($event)" [disabled]="notEditable">
                              </div>
                              <div class="col-md-3">
                                <label class="form-label text-nowrap subhead-blue m-b-0 text-nowrap f-14 roboto-font" for="">
                                  Child's Date of Birth:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control dob col-xl-9 py-2 f-12 b-r-5 mt-1" 
                                id="" 
                                type="date" 
                                required="" 
                                disabled="disabled" 
                                [max]="maxDate1" 
                                
                                  autocomplete="disabled" 
                                  [ngModel]="applicantService.familyInfoModel.childrens[i].dob | date:'yyyy-MM-dd'" 
                                  (ngModelChange)="applicantService.familyInfoModel.childrens[i].dob=$event"
                                  name="{{'childDob'+i}}" data-bs-original-title="" title="" 
                                  [disabled]="notEditable"
                                  (change)="applicantService.familyInfoModel.childrens[i].child_Age = applicantService.calculateAge($event.target.value)">
                              </div>
                            </div>
                            <div class="col-md-1 col-11 pl-xl-2 pl-md-2 pr-xl-0 pr-md-0 px-0 my-1">
                              <label class="form-label text-nowrap text-nowrap subhead-blue m-b-0 f-14 roboto-font"
                                for="validationCustom5">
                                Child's Age:<span class="text-danger">*</span>
                              </label>
                              <input class="form-control py-2 f-12 col-xl-12 b-r-5 mt-1" 
                              [value]="applicantService.familyInfoModel.childrens[i].child_Age" 
                              id="validationCustom5"  autocomplete="off" type="search"
                              name="{{'childAge_'+i}}" required=""
                                data-bs-original-title="" title="" min="0" max="100" disabled>
                            </div>
                            <div *ngIf="!notEditable" class="pl-2 pt-3 media">
                                <svg (click)="removeChildren(i)" type="button" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-trash-2 media-body align-self-center text-danger"
                                  _ngcontent-ucv-c109="">
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                  </path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </div>
                          </div>
                          <div class="row g-3 my-1 pl-3" *ngIf="!notEditable">
                            <span type="button" class="title subhead-blue media f-20 pt-3" (click)="AddChildren()">
                                <i class="fa fa-plus-square-o align-self-center"></i>
                                <span class="f-14 roboto-font media-body align-content-center p-l-10 ">Add another
                                  child </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    <button class="btn btn-edit btn-position" *ngIf="showEditBtn"  type="submit"
                      [disabled]="isSaving">
                      <i class="fa mr-1"
                        [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                      {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                    </button>
                  </div>
                </div>
              </form>

              <!-- Family information -->
            </div>
          </div>
          <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewApplicantInformation">
            <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewApplicantInformation"
              hasBorder="true" hasFullHeight="true"></app-user-access-permission>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->