import { Component, Input, OnInit } from '@angular/core';
import {HeaderTitleService} from '@services/header-title.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit {
  @Input() userPermission:boolean;
  show:boolean;
  tabId: number = 1;
  constructor(public headerTitleService:HeaderTitleService,
    public storageService: StorageService) { }

  ngOnInit(): void {
    this.show = true;
    this.tabId = this.storageService.retrieve('userManagementTID');
    this.headerTitleService.title="User Management";
  }

}
