export class MasterModel {
  masterTables: MasterTableVM[] = [];
}

export class MastersVM {
    masterActivityType = new MasterTableVM();
    masterAdvisor = new MasterTableVM();
    masterAgency = new MasterTableVM();
    masterAttorney = new MasterTableVM();
    masterCurrentVisa = new MasterTableVM();
    masterDocumentType = new MasterTableVM();
    masterSponsor = new MasterTableVM();
    masterSponsorLiaison = new MasterTableVM();
    // masterStage = new MasterTableVM();
    masterVisaProgram = new MasterTableVM();
    masterLevel = new MasterTableVM();
    masterDepartment = new MasterTableVM();
    masterTandemProgram = new MasterTableVM();
    newApplicationNumber:string = null;
}

export class MasterTableVM {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
    masterValues: MasterValueVM[] = [];
    hasAddedNewItem = false;
}

export class MasterValueVM {
  id: number;
  masterTableId: number;
  name: string;
  description: string;
  isActive: boolean;
  isCheckBoxDisabled = false;
  isTextEditable = false;
  isSaving = false;
  isEditBtnDisabled = false;
  isSaveBtnDisabled = false;
  showSaveBtn = false;
}

export class UserAgencyVM {
  agencyId: number;
  agencyName: string;
}

export class AgencyVM {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  isInUsed:boolean;
}


export enum StageEnum {
  "Sponsor Approval" = 1,
  "Initial Payment" = 2,
  "LC Approved Date" = 3,
  "I-140 Process" = 4,
  "Adjustment / Consular Process" = 5,
  "Placement Complete" = 6
}
export class SaveMasterModel {
  id: number;
  masterTableId: number;
  name: string;
  description: string;
  isActive: boolean;
}
