import { PaginationBaseModel } from '../../../shared/models/pagination.model';
export class Report {
}
export class AgencyReportVM {
    id: number;
    applicantName: string;
    sponsorName: string;
    countryOfOrigin: string;
    email: string;
    phone: string;
    attorney: string;
    stage: string;
    totalRecords: number;
}

export class SponsorReportVM {
    id: number;
    applicantName: string;
    sponsorName: string;
    countryOfOrigin: string;
    email: string;
    phone: string;
    attorney: string;
    stage: string;
    totalRecords: number;
}
export class AdvisorReportVM {
    id: number;
    applicantName: string;
    sponsorName: string;
    countryOfOrigin: string;
    email: string;
    phone: string;
    attorney: string;
    stage: string;
    totalRecords: number;
}
export class AttorneyReportVM {
    id: number;
    applicantName: string;
    sponsorName: string;
    countryOfOrigin: string;
    email: string;
    phone: string;
    attorney: string;
    stage: string;
    totalRecords: number;
}
export class TotalPaymentVM {
    id: number;
    applicantName: string;
    agencyName: string;
    sponsorName: string;
    coordinator: string;
    amountOwedByApplicant: number;
    firstPayment: number;
    dateOfFirstPayment: string | null;
    secondPayment: number;
    dateOfSecondPayment: string | null;
    thirdPayment: number;
    dateOfThirdPayment: string | null;
    fourthPayment: number;
    dateOfFourthPayment: string | null;
    coordinatorFeeTotal: number;
    coordinatorFirstPayment: number;
    coordinatorSecondPayment: number;
    coordinatorThirdPayment: number;
    coordinatorFourthPayment: number;
    totalRecords: number;
}
export class ActivityLogVM {
    applicantName: string;
    assignToUserName: string;
    dueDate: string;
    totalRecords: number;
}
export class StageVM {
    stageName: string;
    applicantCount: number;
}

export class AccountsReceivableVM {
    id: number;
    applicantName: string;
    outstandingDueAmount: number;
    totalRecords: number;
}

export class ReportRequestModel extends PaginationBaseModel {
    fromDate: string ;
    toDate: string ;
    searchTypeId: number = 0;
}
// export class ReportLogRequestModel extends PaginationBaseModel {
//     fromDate: string ;
//     toDate: string ;
//     selectedSearchType: string ;
//     selectedApplicationNumber: string ;
    
// }
export class TotalPaymentRequestModel extends PaginationBaseModel {
    fromDate: string ;
    toDate: string ;
    searchSponsorTypeId: number;
    searchAgencyTypeId: number;
}


