import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ForgotPasswordSendOTPRequest, ForgotPasswordVerifyOTPRequest, ForgotPasswordResetPasswordRequest, LoginRequest } from './login.model';
import { DataService } from 'src/app/shared/services/data.service';
import { ResponseModel } from 'src/app/shared/models/response.model';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isLoggedIn: boolean = false;
  constructor(private service: DataService) {
  }

  // sign in function
  SignIn(data: LoginRequest): Observable<ResponseModel> {
    let url = '/identityapi/account/Login';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  
  // Send OTP in function
  SendOTP(data: ForgotPasswordSendOTPRequest): Observable<ResponseModel> {
    let url = '/identityapi/account/forgotpassword/sendotp';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // verify OTP in function
  VerifyOTP(data: ForgotPasswordVerifyOTPRequest): Observable<ResponseModel> {
    let url = '/identityapi/account/forgotpassword/verifyotp';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // reset password in function
  ResetPassword(data: ForgotPasswordResetPasswordRequest): Observable<ResponseModel> {
    let url = '/identityapi/account/forgotpassword/resetpassword';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}