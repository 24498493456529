<div class="media profile-media">
  <img class="b-r-10 px-2" src="../../../../../../assets/images/svg/Admin.png" height="35px" alt="">
  <div class="media-body ml-0 align-self-center">
    <span class="theme-font-color-blue">{{userDeatils?.firstName}} {{userDeatils?.lastName}}</span>
    <p class="mb-0 font-roboto theme-font-color-gray theme-font-size">{{userRole}}<i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li>
    <a href="javascript:void(0)" (click)="logout()">
      <svg xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" viewBox="0 0 24 24" fill="none" 
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
            class="feather feather-log-in" _ngcontent-ncw-c109="">
          <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
          <polyline points="10 17 15 12 10 7"></polyline>
          <line x1="15" y1="12" x2="3" y2="12"></line>
      </svg>
      <span style="vertical-align: text-bottom;" >Logout</span>
    </a>
  </li>
</ul>