import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '@models/response.model';
import { DataService } from '@services/data.service';
import { MasterValueVM } from './master.model';

@Injectable({
  providedIn: 'root'
})

export class MasterService {

  constructor(private service: DataService) { }

  //get all masters api 
  public getAllMasters(requiredSingleMaster: boolean = false, lookupTableId: number = 0): Observable<ResponseModel> { 
    let url = '/gerapi/Master/getall';
    const data = {
      "requiredSingleMaster": requiredSingleMaster,
      "lookupTableId": lookupTableId
    };
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));  
  }

  //get masters
  public getMasters(isApplicationNumberRequired: boolean = false): Observable<ResponseModel> { 
    let url = `/gerapi/Master/get/${isApplicationNumberRequired}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));  
  }

  //get user agencies
  public getUserAgencies(): Observable<ResponseModel> { 
    let url = `/gerapi/Master/GetUserAgencies`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));  
  }

  //get masters
  public loadAgencyData(userId:string): Observable<ResponseModel> { 
    let url = `/gerapi/Master/GetAgencies/${userId}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));  
  }
  

  public saveMaster(saveMasterModel: MasterValueVM): Observable<ResponseModel> { 
    let url = '/gerapi/Master/save';
    const data = saveMasterModel;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
