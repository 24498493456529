<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.reports">
    <div class="report-card py-4">
        <div *ngFor="let item of reportList" class="card"  [hidden]="!item.isVisible" [routerLink]="item.link">
            <div class="card-body">
                <div class="circle" [ngStyle]="{'background-color': item.color}">
                    <img src="./assets/images/svg/report-icon.svg" alt="">
                </div>
                <div class="report-name text-center mt-4 theme-font-color-dark f-family-oswald font-weight-bold">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</div>

<app-user-access-permission [userPermission]="headerTitleService.PermissionVM.reports"></app-user-access-permission>