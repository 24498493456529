import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService }  from '../../../shared/services/data.service';
import { ResponseModel } from '../../../shared/models/response.model';
import { HttpEvent, HttpUploadProgressEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EditDocumentsService {

  constructor(private service: DataService) { }

  uploadDocument(data: FormData): Observable<any> {
    let url = '/gerapi/document/upload';
    return this.service.upload(url, data)
    .pipe<any>(tap((response: any) => {
      return response;
    }));
  }

  updateDocument(data: any): Observable<ResponseModel> {
    let url = `/gerapi/document/update`;
    return this.service.post(url, data)
    .pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}
