import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-access-permission',
  templateUrl: './user-access-permission.component.html',
  styleUrls: ['./user-access-permission.component.scss']
})
export class UserAccessPermissionComponent implements OnInit {

  @Input() userPermission:boolean;
  @Input() hasBorder:boolean = false;
  @Input() hasFullHeight:boolean = false;
  @Input() message:string = "You don’t have permission to access this section.";
  constructor() { }

  ngOnInit(): void {
  }
}