<!-- For Audit Modal  -->
<!-- Model popup on New Activity-->
    <div class="p-0 mb-0 pb-0" #modalId>
      <div class="card-body px-0 pt-2 pb-0">
        <span class="close-popup pull-right">
          <svg (click)="closed()" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
            <path d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z" transform="translate(0 -0.001)" fill="#a1a1a1"/>
          </svg>
        </span>
        <div class="ngxdt audit-logs">
          <div class="ngxdt-header audit-trail">
            <span class="subhead-blue roboto-font align-self-center f-w-600 header-name">Audit Trail</span>
            <div *ngIf="applicantName !==''" class="pull-right align-self-center">
              <span class="mr-2 f-w-600  align-self-center text-muted">Applicant Name :</span>
              <span class="mr-5 f-w-600  align-self-center d-fle subhead-blue">{{applicantName}}</span>
            </div>
            <div class="ngxdt-select" *ngIf="sectionFieldCanVisible">
              <label for="pageFilter">Section :</label>
              <select id="pageFilter" class="mr-0" name="select" [(ngModel)]="selectedActionSourceId"
                (change)="sortByActionSource()">
                <!-- <option class="subhead-blue"></option> -->
                <option *ngFor="let auditActionSource of auditActionSourceModel" class="text-muted" [value]="auditActionSource.displayValue">
                  {{auditActionSource.displayName}}
                </option>
              </select>
            </div>
            <!-- <div class="ngxdt-search" >
              <div class="ngxdt-search-wrapper">
                <i class="fa fa-search"></i>
                <input type="text" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText">
                <button class="btn-search" (click)="search()">
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div> -->
          </div>

          <div class="ngxdt-body">
            <ngx-datatable #table class="bootstrap" [messages]="{
            emptyMessage: ngxDatatableSSRConfig.emptyMessage | translate,
            totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
            selectedMessage: false
          }" [footerHeight]="ngxDatatableSSRConfig.footerHeight" [limit]="ngxDatatableSSRConfig.pageSize == 1 ? ngxDatatableSSRConfig.totalRecords : ngxDatatableSSRConfig.pageSize" [rows]="rows"
              [columnMode]="ngxDatatableSSRConfig.columnMode.force" [offset]="ngxDatatableSSRConfig.tableOffset"
              [loadingIndicator]="false" [scrollbarH]="true"
              [scrollbarV]="true" [virtualization]="false">
              <ngx-datatable-column [width]="50" minWidth="30" headerClass="make-center" cellClass="make-center" prop="id" name="#"
                [sortable]="false" [draggable]="false">
                <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                  {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.currentPageNumber - 1)) + rowIndex}}.
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="120" minWidth="80" prop="user" name="User" [sortable]="true" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" minWidth="70" prop="userRole" name="User Role" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" minWidth="70" prop="auditAction" name="Action" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <!-- <ngx-datatable-column [width]="120" prop="auditActionSource" name="Page" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column> -->
              <ngx-datatable-column *ngIf="pageId === 0" [width]="150" minWidth="100" prop="applicantName" name="Applicant" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" minWidth="70" prop="fieldName" name="Modified Item" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" minWidth="70" prop="oldValue" name="Old Value" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" minWidth="70" prop="newValue" name="New Value" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div title="{{ value }}">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" minWidth="120" prop="timeStamp" name="Timestamp" [sortable]="true"
                [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value+'Z' | date:'MMM dd yyyy hh:mm' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                        (ngModelChange)="onPageSizeChanged($event)">
                        <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}
                        </option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination #ngbPage [boundaryLinks]="false"
                        [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                        [pageSize]="ngxDatatableSSRConfig.pageSize == 1 ? ngxDatatableSSRConfig.totalRecords : ngxDatatableSSRConfig.pageSize" 
                        [page]="ngxDatatableSSRConfig.currentPageNumber"
                        [maxSize]="5" (pageChange)="onPageChanged($event)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>

            </ngx-datatable>
          </div>
          <div class="ngxdt-footer">
          </div>
        </div>
      </div>

      <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.auditLog"></app-user-access-permission>
  <!-- For Audit Modal  -->