<!-- content starts-->
<div class="content-wrapper">
    <div class="card card-full-height">
        <div class="card-body px-0 pt-2 pb-3">
            <div class="ngxdt audit-logs">
        
              <div class="ngxdt-header report-tab">
                <div class="ngxdt-select">
                  <label for="pageFilter">Agency:</label>
                  <select id="pageFilter" name="select" [(ngModel)]="selectedSearchTypeId" (change)="sortByAgency()"
                  style="width: 140px;">
                  <option class="subhead-blue" [value]="0">
                  </option>
                  <option class="text-muted" *ngFor="let option of userAgencyList" [value]="option.agencyId">
                    {{option.agencyName}}</option>
                </select>
                  <div class="date-range d-flex">
                    <label for="dateRange">Date Range:</label>
                    <div class="date-range-picker">
                      <app-date-range-picker (selectedDates)="onDateRangeChange($event)"></app-date-range-picker>
                    </div>
                    <!-- <div class="date-input range-datepicker">
                      <div class="input-group">
                        <input
                           #myRangeInput
                          class="form-control" 
                          placeholder="mm/dd/yyyy - mm/dd/yyyy"
                          name="dp" 
                          [(ngModel)]="model" 
                          ngbDatepicker 
                          [dayTemplate]="t"
                          [autoClose]="'outside'"
                          [displayMonths]="2"
                          [maxDate]="maxDate"
                          [minDate]="minDate"
                          [value]="inputDate"
                          #d="ngbDatepicker">
                  
                          <ng-template #t let-date="date" let-focused="focused">
                              <span class="custom-day"
                                  [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                                  [class.faded]="isHovered(date) || isInside(date)"
                                  (click)="onDateSelection(date)"
                                  (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null"
                                  >
                              {{ date.day }}
                              </span>
                          </ng-template>
                        <div class="">
                          <button class="btn" (click)="d.toggle()" type="button"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.72" height="17.496" viewBox="0 0 18.72 17.496">
                            <g id="calendar_2_" data-name="calendar (2)" transform="translate(-1 -2)">
                              <path id="Path_207" data-name="Path 207" d="M17.679,2H15.93V3.75a.583.583,0,1,1-1.166,0V2H5.432V3.75a.583.583,0,1,1-1.166,0V2H2.516A1.606,1.606,0,0,0,1,3.75v2.1H19.662V3.75A1.968,1.968,0,0,0,17.679,2ZM1,7.074V17.746A1.583,1.583,0,0,0,2.575,19.5H17.737a1.968,1.968,0,0,0,1.983-1.75V7.074Zm5.19,9.8h-1.4a.46.46,0,0,1-.467-.467V14.947a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467V16.4a.511.511,0,0,1-.525.467Zm0-5.249h-1.4a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467H6.249a.46.46,0,0,1,.467.467v1.458a.511.511,0,0,1-.525.467Zm4.665,5.249H9.4a.46.46,0,0,1-.467-.467V14.947A.46.46,0,0,1,9.4,14.48h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,10.856,16.871Zm0-5.249H9.4a.46.46,0,0,1-.467-.467V9.7A.46.46,0,0,1,9.4,9.232h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,10.856,11.623Zm4.665,5.249H14.063A.46.46,0,0,1,13.6,16.4V14.947a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467V16.4A.429.429,0,0,1,15.521,16.871Zm0-5.249H14.063a.46.46,0,0,1-.467-.467V9.7a.46.46,0,0,1,.467-.467h1.458a.46.46,0,0,1,.467.467v1.458A.429.429,0,0,1,15.521,11.623Z" fill="#a1a1a1"/>
                            </g>
                          </svg>
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="ngxdt-reports-button" >
                    <span ngbDropdown placement="bottom" autoClose="true">
                        <button class="btn-download" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icofont icofont-download-alt"></i>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li (click)="downloadAgencyPaymentReportData('PDF')">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                              <span>Export as PDF</span>
                            </li>
                            <hr class="hr-gray">
                            <li (click)="downloadAgencyPaymentReportData('Excel')">
                              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                              <span>Export as Excel</span>
                            </li>
                          </div>
                    </span>
                    <!-- <span ngbDropdown placement="bottom">
                        <button class="btn-search" ngbDropdownToggle>
                            <i class="fa fa-filter"></i>
                          </button>
                          <div class="dropdown-menu" ngbDropdownMenu>
                              <li><input type="checkbox" id="option1">
                                <label for="option1">Option 1</label></li>
                              <li><input type="checkbox" id="option1">
                                <label for="option1">Option 2</label></li>
                          </div>
                        </span>     -->
                </div>
              </div>
          
              <div class="ngxdt-body">
                <ngx-datatable 
                #table 
                class="bootstrap" 
                [messages]="{
                  emptyMessage: ngxDatatableSSRConfig.emptyMessage | translate,
                  totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                  selectedMessage: false
                }" 
                [footerHeight]="ngxDatatableSSRConfig.footerHeight" 
                [limit]="ngxDatatableSSRConfig.pageSize" 
                [rows]="rows"
                [columnMode]="ngxDatatableSSRConfig.columnMode.force" 
                [offset]="ngxDatatableSSRConfig.tableOffset" 
                [loadingIndicator]="false"
                [scrollbarH]="true"
                [scrollbarV]="true" 
                [virtualization]="false">
                  <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                      {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.currentPageNumber - 1)) + rowIndex}}.
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="name" name="Applicant Name" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <span [title]="value">{{ value }}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="agencyName" name="Agency Name" [sortable]="false"
                  [draggable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                  </ng-template>
                </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="sponsorName" name="Sponsor Name" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <span [title]="value">{{ value }}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="coordinator" name="Coordinator" [sortable]="false"
                  [draggable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span [title]="value">{{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>
                  <ngx-datatable-column [width]="180" prop="amountOwedByApplicant" name="Amount Owed By Applicant" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="120" prop="firstPayment" name="First Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="dateOfFirstPayment" name="Date Of First Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ row.firstPayment == 0 ? 'NA': (value ? (value + 'z' | date:'yyyy/MM/dd') : 'NA') }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="120" prop="secondPayment" name="Second Payment" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="dateOfSecondPayment" name="Date Of Second Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ row.secondPayment == 0 ? 'NA': (value ? (value + 'z' | date:'yyyy/MM/dd') : 'NA') }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="120" prop="thirdPayment" name="Third Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="dateOfThirdPayment" name="Date Of Third Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ row.thirdPayment == 0 ? 'NA': (value ? (value + 'z' | date:'yyyy/MM/dd') : 'NA') }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="120" prop="fourthPayment" name="Fourth Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" prop="dateOfFourthPayment" name="Date Of Fourth Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ row.fourthPayment == 0 ? 'NA': (value ? (value + 'z' | date:'yyyy/MM/dd') : 'NA') }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column flex [width]="150" prop="coordinatorFeeTotal" name="Coordinator Fee Total" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column flex [width]="180" prop="coordinatorFirstPayment" name="Coordinator First Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column flex [width]="180" prop="coordinatorSecondPayment" name="Coordinator Second Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column flex [width]="180" prop="coordinatorThirdPayment" name="Coordinator Third Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column flex [width]="180" prop="coordinatorFourthPayment" name="Coordinator Fourth Payment" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                      <div>
                        <div class="page-size-control">
                          Show
                          <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                            (ngModelChange)="onPageSizeChanged($event)">
                            <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}
                            </option>
                          </select>
                          entries
                        </div>
                        <div class="pagination-control">
                          <ngb-pagination #ngbPage [boundaryLinks]="false"
                            [collectionSize]="ngxDatatableSSRConfig.totalRecords" [pageSize]="ngxDatatableSSRConfig.pageSize == 1?ngxDatatableSSRConfig.totalRecords:ngxDatatableSSRConfig.pageSize"
                            [page]="ngxDatatableSSRConfig.currentPageNumber" [maxSize]="5"
                            (pageChange)="onPageChanged($event)">
                          </ngb-pagination>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>
      
                </ngx-datatable>
              </div>
              <div class="ngxdt-footer">
              </div>
            </div>
          </div>
    </div>
</div>
<!-- content Ends -->

