<div>  
  <div class="ngxdt user-table">

    <div class="ngxdt-header user-tab">
      <div class="ngxdt-buttons">
        <button class="btn btn-add nav-pills" (click)="openModal(modalId)">
          <i class="fa fa-plus-square-o mr-2 align-self-center f-12"></i>
          <span>Add New Role</span>
        </button>
      </div>
      <div class="ngxdt-search">
        <div class="ngxdt-search-wrapper">
          <i class="fa fa-search"></i>
          <!-- <input type="text" placeholder="Search"> -->
          <input type="text" placeholder="Search" (keyup)="updateFilter($event)">
          <button class="btn-search">
            <i class="fa fa-filter"></i>
          </button> 
        </div>
      </div>
    </div>

    <div class="ngxdt-body">
      <ngx-datatable 
      #table
      class="bootstrap"
      [footerHeight]="50"
      [rows]="rows"
      [columnMode]="'flex'"
      [offset]="'tableOffset'"
      [scrollbarH]="true" 
      [scrollbarV]="true"
      [count]="15"
      [limit]="15">

        <ngx-datatable-column [width]="100" [flexGrow]="0.17" minWidth="50"  headerClass="make-center" cellClass="make-center" prop="id" name="#" [sortable]="false" [draggable]="false">
          <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template >
            {{ rowIndex }}.
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column  [width]="700"  [flexGrow]="1.1" minWidth="130" prop="name" name="Role Type" [sortable]="true" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span [title]="value">{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column  [width]="150"   [flexGrow]="0.25" minWidth="80"  headerClass="make-center" cellClass="make-center" prop="isActive" name="Active" [sortable]="false" [draggable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <!-- <input type="checkbox" [checked]="value" (click)="false"> -->
            <div class="checkbox checkbox-custom">
              <input type="checkbox" id="checkbox_{{row.id}}"
                  [checked]="value"
                  [disabled]="true"
                  (click)="false">
              <label for="checkbox_{{row.id}}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150"   [flexGrow]="0.25" minWidth="80"  [sortable]="false" [draggable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <button (click)="openModal(modalId, row)" class="btn btn-edit" *ngIf="row.name.toLowerCase() != 'Admin'.toLowerCase()">
              <i class="fa fa-edit"></i>
              <span class="ml-1">EDIT</span>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>

<ng-template #modalId let-modal>
  <form class="theme-form">
    <div class="modal-header">
      <span class="modal-title">{{modalTitle}}</span>
      <button class="modal-close" type="" (click)="modal.dismiss()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64"><path d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z" transform="translate(0 -0.001)" fill="#a1a1a1"/></svg>
      </button>
    </div>
    <div class="modal-body">
      <input type="hidden" [(ngModel)]="modalData.id" name="id" [value]="modalData.id">
      <div class="row">
        <div class="col-md-6 pl-0">
          <label class="form-label">Role Type:
            <input  type="text" 
                    [(ngModel)]="modalData.name" 
                    name="name" 
                    class="form-control"
                    onlyAlphabets
                    required>
          </label>
        </div>
        <div class="col-md-6 pr-0 d-flex align-items-center">
          <input  type="checkbox" 
                  name="isActive" 
                  class="mr-2"
                  [(ngModel)]="modalData.isActive"
                  [checked]="modalData.isActive">
          <label class="form-label mb-0">Active</label>
        </div>        
      </div>    
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-save" (click)="saveData(modalData, modal)" [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
        {{isSaving?"Saving":"SAVE"}}
      </button>
    </div>
  </form>
</ng-template>