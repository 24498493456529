<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <div class="logo">
              <img class="img-fluid for-light p-1" src="../assets/images/logo/ger_logo.png" alt="login" width="180"
                height="180">
              <img class="img-fluid for-dark p-1" src="../assets/images/logo/ger_logo.png" alt="login" width="180"
                height="180">
            </div>
          </div>
          <div class="login-main" *ngIf="formId==1">
            <form class="theme-form needs-validation">
              <div class="form-group pt-2">
                <label class="col-form-label text-muted f-11 py-0">
                  Email Address
                </label>
                <input class="form-control py-2 mb-3" type="email" name="email" [(ngModel)]="loginRequest.email" required
                  autocomplete="off" placeholder="sample@example.com" (keydown)="onKeydown($event,'#password')">
              </div>
              <div class="form-group">
                <label class="col-form-label d-block text-muted f-11 py-0">
                  Password
                  <div class="password-showhide-control mt-0">
                    <input [type]="hidePassword ? 'password' : 'text'" name="password" id="password"
                      class="form-control" [(ngModel)]="loginRequest.password" placeholder="********" autocomplete="off"
                      required (keydown)="onKeydown($event,'#signIn')">
                    <button class="btn-showHide color-darkblue"
                      (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                  </div>
                </label>
              </div>
              <div class="form-group mb-0">
                <div class="mt-2">
                  <label class="col-form-label text-muted d-flex align-items-center" for="checkbox1">
                    <input id="checkbox1" name="rememberMe" class="mr-1 bg-dark" [(ngModel)]="loginRequest.rememberMe"
                      [checked]="loginRequest.rememberMe" type="checkbox">
                    <span>Remember me</span>
                  </label>
                </div>
                <a class="link color-darkblue" type="button" (click)="onForgetPassword()">
                  Forgot password?
                </a>
                <div class="text-end mt-4">
                  <button (click)="authenticate()" id="signIn" class="btn btn-block btn-submit" type="submit">
                    Sign in
                  </button>
                </div>
              </div>

            </form>
          </div>

        <!-- Forgot password view -->
          <div class="login-main" *ngIf="formId==2">
            <form class="theme-form needs-validation">
              <span class="title">Reset your Password</span>
              <div class="form-group" style="margin-top:2.70rem;">
                <label class="col-form-label text-muted f-11 py-0">
                  Enter Your Email Address:
                </label>
                <input class="form-control py-2 mb-5" type="email" name="email" [(ngModel)]="sendOTPRequest.email" required
                  placeholder="sample@example.com">
              </div>
              <div class="form-group mb-0">
                <div class="text-end mt-4">
                  <button (click)="sendOTP()" class="btn btn-block btn-submit" type="submit">
                    Send OTP
                  </button>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <span class="available-password">Already have a password?</span>
                <span type="button" class="sign-in ml-1" (click)="ngOnInit()"> <u>Sign in</u>
                </span>
              </div>

            </form>
          </div>

          <div class="login-main" *ngIf="formId==3">
            <form class="theme-form needs-validation">
              <span class="title">Reset your Password</span>
              <div *ngIf="!show">
                <div class="form-group pt-4">
                  <label class="col-form-label text-muted f-11 py-0">
                    Enter OTP
                  </label>
                  <div class="digit-group">
                    <input class="input" type="text" id="digit1" name="digit1"
                      (keyup)="onKeyUp($event,'#digit2','#digit1')" autofocus maxlength="1" autocomplete="off" required
                      onlyNumbers>
                    <input class="input" type="text" id="digit2" name="digit2"
                      (keyup)="onKeyUp($event,'#digit3','#digit1')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit3" (keyup)="onKeyUp($event,'#digit4','#digit2')"
                      name="digit3" maxlength="1" disabled="disabled" autocomplete="off" required onlyNumbers>
                    <input class="input" type="text" id="digit4" name="digit4"
                      (keyup)="onKeyUp($event,'#digit5','#digit3')" maxlength="1" disabled="disabled" autocomplete="off"
                      required onlyNumbers>
                    <input class="input" type="text" id="digit5" name="digit5" disabled="disabled"
                      (keyup)="onKeyUp($event,'#digit6','#digit4')" maxlength="1" autocomplete="off" required
                      onlyNumbers>
                    <input class="input" type="text" id="digit6" name="digit6" disabled="disabled"
                      (keyup)="onKeyUp($event,'#digit6','#digit5')" maxlength="1" autocomplete="off" required
                      onlyNumbers>
                  </div>
                </div>
                <div class="mb-4">
                  <span class="available-password">Didn’t receive OTP?</span>
                  <span type="button" class="ml-2 sign-in" (click)="sendOTP()"> <u>Resend</u>
                  </span>
                </div>
                <div *ngIf="verifying" class="d-flex justify-content-center mb-4">
                  <span class="title">Verifing OTP. Please wait...</span>
                </div>
              </div>
              <div *ngIf="show">
                <div class="form-group pt-4 mb-3">
                  <label class="col-form-label d-block text-muted f-11 py-0">
                    New Password
                    <div class="password-showhide-control mt-0">
                      <input [type]="hidePassword ? 'password' : 'text'" name="newPassword" class="form-control"
                        [(ngModel)]="resetPasswordRequest.newPassword" required>
                      <button class="btn-showHide color-darkblue"
                        (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
                <div class="form-group mb-4">
                  <label class="col-form-label d-block text-muted f-11 py-0">
                    Confirm Password
                    <div class="password-showhide-control mt-0">
                      <input [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword" class="form-control"
                        [(ngModel)]="resetPasswordRequest.confirmPassword" required>
                      <button class="btn-showHide color-darkblue"
                        (click)="changeConfirmPasswordVisibility()">{{hideConfirmPassword?"show":"hide"}}</button>
                    </div>
                  </label>
                </div>
                <div class="form-group mb-0">
                  <div class="text-end">
                    <button (click)="onResetPassword()" class="btn btn-block btn-submit" type="submit">
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <span class="available-password">Already have a password?</span>
                <span type="button" class="sign-in ml-1" (click)="ngOnInit()"> <u>Sign in</u>
                </span>
              </div>

            </form>
          </div>

          <div class="login-main" *ngIf="formId==5">
            <form class="theme-form needs-validation">
              <button class="pop-up-btn-position" (click)="ngOnInit()" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
                  <path
                    d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                    transform="translate(0 -0.001)" fill="#a1a1a1" />
                </svg>
              </button>
              <span class="popup-title">Contact Admin</span>
              <div class="middle-text pt-3">
                <span>Please contact an application administrator</span>
                <span>to reset Your password</span>
              </div>
            </form>
          </div>
        <!-- Forgot password view -->
        </div>
      </div>
    </div>
  </div>
</div>