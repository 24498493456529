export class UserRoles {

}

export class UserRole {  
  id: number;
  name: string;
  isActive: boolean = true;
  checked?:boolean;
  isDisabled?:boolean;
}
