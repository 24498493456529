import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../../shared/models/response.model';
import { DataService } from '../../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  constructor(private service: DataService) { }

  //get all
  public getAllRoles(search:string = " "): Observable<ResponseModel> { 
    let url = `/identityapi/Role/GetRoles?search=${encodeURIComponent(search)}`;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //save role
  public saveRole(data: any): Observable<ResponseModel> { 
    let url = '/identityapi/Role/save';
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

}
