import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApplicantsService } from '../applicants.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuditLogsComponent } from '../../adminConsole/audit-logs/audit-logs.component';
import { HeaderTitleService } from '@services/header-title.service';
import { HttpStatusCode } from '@angular/common/http';
declare var require;
const Swal = require('sweetalert2')

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  @Input() showEditBtn: boolean;
  @Input() isEditable: boolean = true;
  notEditable: boolean = true;
  isSaving: boolean = false;
  phoneNumber: string = "";
  age;
  showAge;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  masterService: any;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();;
  maxDate = moment({ year: this.year, month: this.month, day: this.day }).format('YYYY-MM-DD');
  // minDate = moment({year: this.year - 100, month: this.month, day: this.day}).format('YYYY-MM-DD');

  constructor(
    public applicantService: ApplicantsService,
    private cdref: ChangeDetectorRef,
    private config1: NgbModalConfig,
    private modalService: NgbModal,
    public headerTitleService: HeaderTitleService,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    this.notEditable = !this.isEditable;
    this.showEditBtn = (this.showEditBtn) ? this.headerTitleService.PermissionVM.editApplicantInformation : this.showEditBtn ;
  }

  ngAfterContentChecked() {
    if (this.applicantService.personalInfoModel.dob == undefined) {
      this.applicantService.personalInfoModel.age = 0;
    }
    else {
      this.applicantService.calculateAge(this.applicantService.personalInfoModel.dob);
    }
  }
  phoneNumbers(event): Boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 8 || (charCode >= 48 && charCode <= 57 || charCode == 43)) {
        return true;
    }
    return false;
  }

  OnlyletterAllowed(event): Boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return false;
    }
    return true;
  }

  onlyNumbersLettersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode == 46) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)))) {
      return false;
    }
    return true;
  }
  formatPhoneNumberAsUS(phoneNumber: string) {
    var arrayA = phoneNumber.split("-");
    // get number without -
    var aa = arrayA.join("");
    // slice number again with xxxx-xxx-xxx format
    let v1 = aa.slice(0, 4);
    let v2 = aa.slice(4, 7);
    let v3 = aa.slice(7, 10);
    // get number in xxxx-xxx-xxx format
    let newphoneNumber = `${v1}-${v2}-${v3}`;

    return newphoneNumber == "--" ? null : newphoneNumber;
  }
  formatPhoneNumberAsNumber(phoneNumber: string) {
    var arrayA = phoneNumber.split("-");
    return arrayA.join("");
  }

  inputFilter(event: any) {
    const pattern = /^[-]?([0-9]*[.])?[0-9]+$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  //avoid repeating characters when pressing and holding the key
  _keyDown(event: any) {
    if (event.repeat) event.preventDefault();
  }

  _keyUp(event: any) {
    const pattern = /^([0-9]*[+])?[0-9]+$/;

    if ( //allows the writing of the first time of the character
      (event.key == "+") &&
      (this.applicantService.personalInfoModel.phone.indexOf(event.key) == this.applicantService.personalInfoModel.phone.lastIndexOf(event.key))
    ) return true;

    if (!pattern.test(this.applicantService.personalInfoModel.phone)) {
      this.applicantService.personalInfoModel.phone = this.applicantService.personalInfoModel.phone.slice(0, -1);
    }
  }

  //clean the wrong characters when exiting the input
  _OnBlur(event: any) {
    if (this.applicantService.personalInfoModel.phone.length > 0) {
      if (this.applicantService.personalInfoModel.phone.slice(-1) == "+") {
        this.applicantService.personalInfoModel.phone = this.applicantService.personalInfoModel.phone.slice(0, -1);
      }
      if (this.applicantService.personalInfoModel.phone.slice(0, 1) == "+") this.applicantService.personalInfoModel.phone = "0" + this.applicantService.personalInfoModel.phone
    }
  }

  allowEdit() {
    //Edit
    if (this.notEditable) {
      this.notEditable = false;
    }
    //Update
    else {
      // call update API
      this.notEditable = true;
      this.isSaving = true;
      this.applicantService.personalInfoModel.NotesDetail = this.applicantService.notesModel.notesDetail;
      this.applicantService.updateApplicantPersonalInformation(this.applicantService.personalInfoModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.isSaving = false;
          this.toaster.success(res.message);
          this.applicantService.applicant.next(true);
        });
    }
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      this.toaster.error(error.error.message);
    }
    this.isSaving = false;
    return throwError(error);
  }

  // Open Audit Trail Model //
  openAuditLog() {
    const modalRef = this.modalService.open(AuditLogsComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.pageId = 1;
  }
  // Open Audit Trail Model //
}
