<!-- content starts-->
<div class="content-wrapper theme-font-size">
    <div class="row1 p-r-0">
        <div class=" h-25 p-r-0">
          <div class="default-according" id="accordion">
            <div class="card shadow mb-3">
              <div class="tab-content card-body border-0 p-t-30 p-b-35" id="pills-tabContent" *ngIf="headerTitleService.PermissionVM.viewApplicantInformation">
                <div class="tab-pane fade show active" id="app-info" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                  <form (ngSubmit)="allowEdit()" class="needs-validation" ngNativeValidate>
                    <div class="row p-l-20">
                      <div class="row col-xl-12 p-l-0">
                        <span class="subhead-blue f-14 roboto-font font-weight-bold col-xl-12 p-r-0">
                          Background Information
                          <hr class="mt-1">
                        </span>
                      </div>
                          <div class="col-xl-12 px-0">
                            <div class="row col-md-11 px-0 g-3 mt-3">
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom10">
                                  Country of Citizenship:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" id="validationCustom10" type="text" autocomplete="disabled"
                                name="citizenshipCountry" [(ngModel)]="applicantService.backgroundInfoModel.citizenshipCountry"
                                  required="" (keypress)="OnlyletterAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable" (keydown)="onKeydown($event,'#validationCustom11')">
                              </div>
                              <div class="col-md-3">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom11"> 
                                  Country of Birth:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" id="validationCustom11" type="text" 
                                name="birthCountry" [(ngModel)]="applicantService.backgroundInfoModel.birthCountry"
                                  required="" (keypress)="OnlyletterAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable" (keydown)="onKeydown($event,'#validationCustom12')">
                              </div>
                              <div class="col-md-3 ">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom12">
                                  Education Level:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" id="validationCustom12" type="text"
                                name="educationLevel" [(ngModel)]="applicantService.backgroundInfoModel.educationLevel"
                                  required="" (keypress)="OnlyletterAllowed($event)" data-bs-original-title="" title="" [disabled]="notEditable" (keydown)="onKeydown($event,'#validationCustom13')">
                              </div>
                              <div class="col-md-3 ">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom13">
                                  Career:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-9 mt-1" id="validationCustom13" type="text"
                                name="career" (keypress)="OnlyletterAllowed($event)" [(ngModel)]="applicantService.backgroundInfoModel.career"
                                  required="" data-bs-original-title="" title="" [disabled]="notEditable" (keydown)="onKeydown($event,'#validationCustom14')">
                              </div>
                            </div>
                            <button class="btn btn-edit btn-position" *ngIf="showEditBtn" type="submit" [disabled]="isSaving">
                              <i class="fa mr-1" [ngClass]="isSaving?'fa-spin fa-spinner':(notEditable?'fa-edit':'fa-floppy-o')"></i>
                              {{isSaving?'Saving...':(notEditable?'Edit':'Update')}}
                            </button>
                          </div>
                          <div class="col-xl-12 px-0">
                            <div class="row col-md-11 px-0 g-3 mt-3">
                              <div class="col-md-4">
                                <label class="form-label subhead-blue m-b-0 f-14 roboto-font" for="validationCustom14">
                                  Current Residence:<span class="text-danger">*</span>
                                </label>
                                <input class="form-control py-2 f-12 b-r-5 col-xl-10 mt-1" id="validationCustom14" type="text" autocomplete="disabled"
                                name="currentResidence" [(ngModel)]="applicantService.backgroundInfoModel.currentResidence" (keypress)="OnlyletterAllowed($event)"
                                  required="" data-bs-original-title="" title="" [disabled]="notEditable">
                              </div>
                          </div>
                    </div>
                    </div>
                  </form>
                <!-- Family information -->
              </div>
            </div>
            <div style="height: 100%;" *ngIf="!headerTitleService.PermissionVM.viewApplicantInformation">
              <app-user-access-permission [userPermission]="headerTitleService.PermissionVM.viewApplicantInformation"
                hasBorder="true" hasFullHeight="true"></app-user-access-permission>
            </div>
          </div>
        </div>
      </div>
</div>
<!-- content Ends-->
