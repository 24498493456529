import { UserRole } from "../user-roles/user-roles.model";

export class UserList {
}

export class User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  isActive: boolean = true;
  createdDate: string;
}

export class UserModalModel {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  userRole: string;
  isActive: boolean = true;
  isEditAction: boolean = false;
  isResetAction: boolean = false;
}

export class AddUserModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  userRole: string;
  agencies: number[];
  departments: Department[];
}

export class UpdateUserModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  userRole: string;
  isActive: boolean;
  agencies: number[];
  departments: Department[];
}

export class Department {
  id: number;
  isSupervisor: boolean;
  timestamp: string;
}

export class ResetPasswordByAdmin {
  userId: string;
  password: string;
  confirmPassword: string;
}

export class CheckBoxItem{
  id:number;
  name:string;
  checked:boolean;
}
