export class UserPermissions {
}
export class PermissionRoleMappingModel {
  availablePermissions: PermissionRecordModel[];
  availableCustomerRoles: Role[];
  allowed: Allowed[];
  permissionRoleMappings: PermissionRoleMapping[];
}

export class Result {
  isSuccess: boolean;
  message: string;
  response: PermissionRoleMappingModel
}

export class PermissionRecordModel {
  id: number;
  name: string;
  systemName: string;
  category: string;
}

export class Role {
  name: string;
  id: string;
  isActive: boolean;
}

export class Allowed {
  permissionName: string;
  permissionsToRole: RoleMap[];
}

export class RoleMap {
  roleId: string;
  roleName: string;
  isSelected: boolean;
}

export class PermissionRoleMapping {
  id: number;
  permissionRecordId: number;
  roleId: string;
}

export class PermissionVM {
  dashboardWidgets: boolean = false;

  auditLog: boolean = false;

  applicantList: boolean = false;
  exportApplicantList: boolean = false;
  
  applicantInformation: boolean = false;
  viewApplicantInformation: boolean = false;
  editApplicantInformation: boolean = false;
  viewImmigrationInformation: boolean = false;
  editImmigrationInformation: boolean = false;
  
  documents: boolean = false;
  documentsUpload: boolean = false;
  documentsDelete: boolean = false;
  documentsView: boolean = false;
  documentsDownload: boolean = false;
  documentsEdit: boolean = false;

  payments: boolean = false;
  viewAgreementWithApplicantPayments: boolean = false;
  viewAgreementWithLiaisonPayments: boolean = false;
  viewAgreementWithAgencyPayments: boolean = false;
  viewCommissionsToGERAdvisorsPayments: boolean = false;
  viewAdditionalPayments: boolean = false;

  viewActivityLogs: boolean = false;
  editActivityLogs: boolean = false;
  addActivityLogs: boolean = false;

  timeline: boolean = false;

  menuDashboard: boolean = false;
  menuApplicantList: boolean = false;
  menuNewApplicant: boolean = false;
  menuReports: boolean = false;
  menuAdminConsole: boolean = false;

  reportPerAgency : boolean = false;
  reportPerSponsor : boolean = false;
  reportPerAttorney : boolean = false;
  reportPerAdvisor : boolean = false;
  reportStages : boolean = false;
  reportActivityLog : boolean = false;
  reportTotalPayments : boolean = false;
  reportPaymentsPerSponsor : boolean = false;
  reportPaymentsPerAgency : boolean = false;
  reportAccountsReceivable : boolean = false;
  reports : boolean = false;
}