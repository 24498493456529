<div>
  <div class="ngxdt user-table">

    <div class="ngxdt-header user-tab">
      <div class="ngxdt-buttons">
        <button class="btn btn-add nav-pills" (click)="openModal(modalId)">
          <i class="fa fa-plus-square-o mr-2 align-self-center f-12"></i>
          <span>Create New User</span>
        </button>
      </div>
      <div class="ngxdt-search">
        <div class="ngxdt-search-wrapper">
          <i class="fa fa-search"></i>
          <!-- <input type="text" placeholder="Search"> -->
          <input type="text" placeholder="Search" (keyup)="updateFilter($event)">
          <button class="btn-search">
            <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="ngxdt-body">
      <ngx-datatable 
        #table class="bootstrap" 
        [footerHeight]="50" 
        [rows]="rows" 
        [columnMode]="'force'"
        [scrollbarH]="true" 
        [scrollbarV]="true" 
        [count]="15" 
        [limit]="15">

        <ngx-datatable-column [width]="100" headerClass="make-center"  minWidth="30" cellClass="make-center" prop="userId" name="#"
          [sortable]="false" [draggable]="false">
          <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
            {{ rowIndex }}.
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150" name="User"  minWidth="100" [sortable]="true" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.firstName + " " + row.lastName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150" prop="userRole"  minWidth="100" name="User Role" [sortable]="true" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span [title]="value">{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="250" prop="email"  minWidth="150" name="E-mail ID" [sortable]="true" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span [title]="value">{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100"  minWidth="80" prop="createdDate" name="Created On" [sortable]="true" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value+'Z' | date:'MM-dd-yy'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100"  minWidth="80" headerClass="make-center" cellClass="make-center" prop="isActive"
          name="Active" [sortable]="false" [draggable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <!-- <input type="checkbox" [checked]="value" (click)="false"> -->
            <div class="checkbox checkbox-custom">
              <input type="checkbox" id="checkbox_{{row.id}}"
                  [checked]="value"
                  [disabled]="true"
                  (click)="false">
              <label for="checkbox_{{row.id}}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="220"  minWidth="200" [sortable]="false" [draggable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <button (click)="openModal(modalId, row)" class="btn btn-edit">
              <i class="fa fa-edit"></i>
              <span class="ml-1">EDIT</span>
            </button>
            <button (click)="openResetPasswordModal(resetPasswordModal, row)" class="btn btn-edit ml-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="9.008" height="9.003" viewBox="0 0 13.008 13.003">
                <g id="Group_756" data-name="Group 756" transform="translate(0 0)">
                  <g id="Group_755" data-name="Group 755" transform="translate(0)">
                    <path id="Path_223" data-name="Path 223"
                      d="M.14,4.281a7.412,7.412,0,0,1,1.6-2.513A7.242,7.242,0,0,1,4.233.2,2.043,2.043,0,0,1,6.4.66l2,1.986A2.033,2.033,0,0,1,8.82,4.9c-.1.236-.219.466-.34.689l4.379,4.379a.508.508,0,0,1,.149.359v2.235a.508.508,0,0,1-.508.508H10.264a.508.508,0,0,1-.508-.508v-.915H8.841a.508.508,0,0,1-.508-.508v-.914H7.419a.508.508,0,1,1,0-1.016H8.841a.508.508,0,0,1,.508.508v.914h.915a.508.508,0,0,1,.508.508v.915h1.219V10.537L7.486,6.032a.508.508,0,0,1-.075-.623,8.222,8.222,0,0,0,.481-.922,1.011,1.011,0,0,0-.213-1.121l-2-1.986A1.025,1.025,0,0,0,4.6,1.15,6.221,6.221,0,0,0,2.456,2.488,6.392,6.392,0,0,0,1.086,4.652a1.009,1.009,0,0,0,.228,1.084l2.019,2.01a1.023,1.023,0,0,0,1.118.218A6.6,6.6,0,0,0,5.366,7.5a.508.508,0,1,1,.532.866A7.616,7.616,0,0,1,4.842,8.9a2.037,2.037,0,0,1-2.225-.436L.6,6.457A2.024,2.024,0,0,1,.14,4.281Z"
                      transform="translate(0 -0.067)" fill="#fff" />
                    <ellipse id="Ellipse_63" data-name="Ellipse 63" transform="translate(0.008 13)" fill="#fff" />
                  </g>
                </g>
              </svg>

              <span class="ml-1">RESET PASSWORD</span>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>

<ng-template #modalId let-modal>
  <form class="theme-form">
    <div class="agencyError" *ngIf="isAgencyUsedMessageDisplay">
      <div class="agencyError-title roboto-font">Unable to remove agency:</div>
      <div class="agencyError-message roboto-font">This user has open tasks for an agency you are attempting to remove. The user must complete these tasks before you can remove the agency assignment.</div>
    </div>
    <div class="modal-header">
      <span class="modal-title">{{modalTitle}}</span>
      <button class="position-initial modal-close" type="" (click)="modal.dismiss()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
          <path
            d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
            transform="translate(0 -0.001)" fill="#a1a1a1" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <input type="hidden" [(ngModel)]="modalData.userId" name="userId" [value]="modalData.userId">
      <div class="row">
        <div class="col-md-5 pl-0">
          <label class="form-label">First Name:
            <input type="text" [(ngModel)]="modalData.firstName" name="firstName" class="form-control" required></label>
        </div>
        <div class="col-md-5">
          <label class="form-label">Last Name:
            <input type="text" [(ngModel)]="modalData.lastName" name="lastName" class="form-control" required></label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 pl-0">
          <label class="form-label">E-mail
            <input type="text" [(ngModel)]="modalData.email" name="email" class="form-control" required>
          </label>
        </div>
        <div class="col-md-5 pr-0 d-flex align-items-center" *ngIf="modalData.isEditAction">
          <input type="checkbox" name="isActive" class="mr-2" [(ngModel)]="modalData.isActive"
            [checked]="modalData.isActive">
          <label class="form-label mb-0">Active</label>
        </div>
      </div>
      <div *ngIf="isVisiblePasswordSection">
        <div class="row">
          <div class="col-md-6 pl-0">
            <label class="form-label mb-0">Create Password:
              <div class="password-showhide-control">
                <input [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="modalData.password" name="password"
                  class="form-control" required>
                <button class="btn-showHide"
                  (click)="hidePassword = !hidePassword">{{hidePassword?"Show":"Hide"}}</button>
              </div>

            </label>
          </div>
          <span class="text-danger f-10 mb-3">
            Passwords must have at least one non alphanumeric character, one digit ('0'-'9') and one uppercase
            ('A'-'Z').
          </span>
          <!-- <label class="form-label red ">Passwords must have at least one non alphanumeric character, one digit ('0'-'9') and one uppercase ('A'-'Z').</label> -->
        </div>
        <div class="row">
          <div class="col-md-6 pl-0">
            <label class="form-label">Confirm Password:
              <div class="password-showhide-control">
                <input [type]="hideConfirmPassword ? 'password' : 'text'" [(ngModel)]="modalData.confirmPassword"
                  name="confirmPassword" class="form-control" required>
                <button class="btn-showHide"
                  (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword?"Show":"Hide"}}</button>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 pl-0">
          <label class="form-label">Role:
            <select class="form-control" name="userRole" (ngModelChange)="onRoleSelection($event)" [(ngModel)]="modalData.userRole" required>
              <option class="text-muted" *ngFor="let role of filter(roles)" [value]="role.name">{{role.name}}</option>
            </select>
          </label>
        </div>
        <div class="col-md-5">
          <label class="form-label">Agency:
            <app-multi-select-dropdown [list]="masterAgencyList" [selectedAgencyList]="selectedAgencyList"
              (shareCheckedList)="shareCheckedList($event)"
              (shareIndividualCheckedList)="shareIndividualCheckedList($event)" [isAdminRoleSelected]="isAdminRoleSelected"
              (hoverEvent)="isAgencyUsedMessageDisplay = $event">
            </app-multi-select-dropdown>
          </label>
        </div>
      </div>

      <div *ngIf="addedDepartmentList.length > 0">
        <div class="row mb-1">
          <div class="col-md-12 p-0">
            <label class="form-label mb-0">Department:</label>
          </div>
        </div>
        <div class="row mb-2 align-items-center" *ngFor='let item of addedDepartmentList;let i = index'>
          <div class="col-md-5 pl-0">
            <select class="form-control py-2 f-12 b-r-5 mt-0" [value]="item.id" name="department_{{item.timestamp}}"
              [(ngModel)]="item.id" required>
              <option class="text-muted" *ngFor="let department of masterDepartmentList" [value]="department.id"
                [disabled]="isDepartmentSelectable(department.id)">{{department.name}}
              </option>
            </select>
          </div>
          <div class="col-md-5">
            <label class="form-label mb-0 d-flex align-items-center">
              <input type="checkbox" [checked]="item.isSupervisor" name="isSupervisor_{{item.timestamp}}" [(ngModel)]="item.isSupervisor"> 
              <span class="ml-1">Supervisor</span>
            </label>
          </div>
          <div class="col-md-2 media">
            <svg (click)="deleteAddedDepartment(i)" type="button" xmlns="http://www.w3.org/2000/svg"
              width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-trash-2 media-body align-self-center text-danger" _ngcontent-ucv-c109="">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
              </path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        </div>
      </div>
      <div class="row g-3 my-1" *ngIf="addedDepartmentList.length < masterDepartmentList.length">
        <span type="button" class="title subhead-blue media f-12" (click)="addNewDepartment()">
          <i class="fa fa-plus-square-o align-self-center"></i>
          <span class="roboto-font media-body align-content-center p-l-10 ">Add Department</span>
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-save" (click)="saveModalData(modalData, modal)" [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
          {{isSaving?"Saving":"SAVE"}}
      </button>
    </div>
  </form>
</ng-template>

<!-- Password Reset Pop-UP  -->
<ng-template #resetPasswordModal let-modal>
  <form class="theme-form">
    <div class="modal-header">
      <span class="modal-title">{{modalTitle}}</span>
      <button class="modal-close" type="" (click)="modal.dismiss()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
          <path
            d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
            transform="translate(0 -0.001)" fill="#a1a1a1" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-10 pl-0">
          <label class="form-label">New Password:
            <div class="password-showhide-control">
              <input [type]="hidePassword ? 'password' : 'text'" name="password" class="form-control"
                [(ngModel)]="modalData.password" name="password" required>
              <button class="btn-showHide"
                (click)="hidePassword = !hidePassword">{{hidePassword?"Show":"Hide"}}</button>
            </div>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 pl-0">
          <label class="form-label">Confirm Password:
            <div class="password-showhide-control">
              <input [type]="hideConfirmPassword ? 'password' : 'text'" [(ngModel)]="modalData.confirmPassword"
                name="confirmPassword" class="form-control" required>
              <button class="btn-showHide"
                (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword?"Show":"Hide"}}</button>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-save" (click)="saveModalData(modalData, modal,'ResetPassword')"
        [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
        {{isSaving?"Saving":"SAVE"}}
      </button>
    </div>
  </form>
</ng-template>
<!-- Password Reset Pop-UP  -->