<!-- content starts-->
<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.menuAdminConsole">
  <div class="card card-full-height">
      <div class="card-body px-0 py-0">
        <nav ngbNav #nav="ngbNav" [activeId]="tabId" class="nav-tabs nav-pills">
          <ng-container [ngbNavItem]=1>
            <a ngbNavLink>User Roles</a>
            <ng-template ngbNavContent>
              <app-user-roles></app-user-roles>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem]=2>
            <a ngbNavLink>User Permissions</a>
            <ng-template ngbNavContent>
              <app-user-permissions></app-user-permissions>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem]=3>
            <a ngbNavLink>User List</a>
            <ng-template ngbNavContent>
              <app-user-list></app-user-list>
            </ng-template>
          </ng-container>
        </nav>
        
        <div [ngbNavOutlet]="nav" class="mt-0"></div>
        
      </div>
  </div>
</div>

<app-user-access-permission [userPermission]="headerTitleService.PermissionVM.menuAdminConsole"></app-user-access-permission>