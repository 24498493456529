import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { User } from 'src/app/components/adminConsole/users-management/user-list/user-list.model';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  userDeatils:User;
  userRole:string="Admin";
  constructor(private router: Router,
    public storageService: StorageService) { }

  ngOnInit() {
    this.userDeatils = this.storageService.retrieve('user');

    if (this.userDeatils?.userRole != 'Admin') {
      this.userRole = "User";
    }
  }

  logout(){
    this.storageService.clear();
    this.router.navigate(['/auth/login']);
  }
}
