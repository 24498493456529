<div class="content-wrapper" *ngIf="headerTitleService.PermissionVM.documents">
  <div class="px-0 py-3">
    <nav ngbNav #nav="ngbNav" class="nav-tabs nav-pills">
      <ng-container ngbNavItem>
        <a ngbNavLink>Program</a>
        <ng-template ngbNavContent>
          <app-view-all-documents [folderTypeId]="1" [applicantId]="applicantId"></app-view-all-documents>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>Legal</a>
        <ng-template ngbNavContent>
          <app-view-all-documents [folderTypeId]="2"  [applicantId]="applicantId"></app-view-all-documents>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class=""></div>
  </div>
</div>