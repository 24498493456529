<!-- <div *ngIf="headerTitleService.PermissionVM.viewApplicantInformation"> -->
<form *ngIf="headerTitleService.PermissionVM.applicantInformation" ngNativeValidate>
    <app-personal-information [isEditable]="false" showEditBtn="true"></app-personal-information>
    <app-family-information [isEditable]="false" showEditBtn="true"></app-family-information>
    <app-background-information [isEditable]="false" showEditBtn="true"></app-background-information>
    <app-program-information [isEditable]="false" showEditBtn="true"></app-program-information>
    <app-immigration-information [isEditable]="false" showEditBtn="true"></app-immigration-information>
    <app-payment-information [isEditable]="false" showEditBtn="true"></app-payment-information>
    <app-notes [isEditable]="false" showEditBtn="true"></app-notes>
</form>
<!-- </div> -->