<!-- content starts-->
<div class="content-wrapper">
    <div class="card card-full-height">
        <div class="card-body px-0 pt-2 pb-3">
            <div class="ngxdt audit-logs stages">
        
              <div class="ngxdt-header">
                <div class="ngxdt-select">
                </div>
                <div class="ngxdt-reports-button" >
                    <span ngbDropdown placement="bottom" autoClose="true">
                        <button class="btn-download" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icofont icofont-download-alt"></i>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li (click)="downloadStageReportData('PDF')">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                              <span>Export as PDF</span>
                            </li>
                            <hr class="hr-gray">
                            <li (click)="downloadStageReportData('Excel')">
                              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                              <span>Export as Excel</span>
                            </li>
                          </div>
                    </span>
                    <!-- <span ngbDropdown placement="bottom">
                        <button class="btn-search" ngbDropdownToggle>
                            <i class="fa fa-filter"></i>
                          </button>
                          <div class="dropdown-menu" ngbDropdownMenu>
                              <li><input type="checkbox" id="option1">
                                <label for="option1">Option 1</label></li>
                              <li><input type="checkbox" id="option1">
                                <label for="option1">Option 2</label></li>
                          </div>
                        </span>     -->
                </div>
              </div>
          
              <div class="ngxdt-body">
                <ngx-datatable 
                #table
                class="bootstrap"
                [footerHeight]="50"
                [rows]="rows"
                [columnMode]="'flex'"
                [scrollbarH]="true"
                [scrollbarV]="true" 
                [count]="20"
                [limit]="20">
                  <ngx-datatable-column [flexGrow]="0.25" minWidth="30" headerClass="make-center" cellClass="make-center" prop="id" name="#"
                    [sortable]="false" [draggable]="false">
                    <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                      {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.currentPageNumber - 1)) + rowIndex}}.
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [flexGrow]="0.75" minWidth="100" prop="stageName" name="Stages" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div [title]="value">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [flexGrow]="0.75" minWidth="100" prop="applicantCount" name="# of Applicants" [sortable]="false"
                    [draggable]="false">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      {{ value }}
                    </ng-template>
                  </ngx-datatable-column>
                 
                </ngx-datatable>
              </div>
            </div>
          </div>
    </div>
</div>
<!-- content Ends -->

